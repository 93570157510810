import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as apiList from '../../../apiList'
import { postData } from '../../../Util'
import Button from '@mui/material/Button';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from './ByCustomer.module.css';

import { GmapsDispatchThunk } from '../../../store/actions/GmapsActions';

const ByCustomer = (props) => {
  const [loginID, setLoginID] = useState('')
  const [loginUserData, setLoginUserData] = useState([])
  const [customerID, setCustomerID] = useState('')
  const [customerData, setCustomerData] = useState({});

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setLoginID(inputValue);
  };


  const submitByCustomer = () => {
    props.menuData(false);
    var inputValue = document.getElementById('wlcm_select_by_customer')?.value;

    if (inputValue) {
      let dataObj = {
        platform: "w",
        userId: props.ctr.userid,
        loginId: customerID,
        mappingType: "customer",
        vin: []
      };

      let vehtoken = props.ctr.token;
      const datas = postData(apiList.createVIN, dataObj, vehtoken);
      datas.then((dataVals) => {
        if (dataVals?.statuscode === 200) {
          props?.menuData(true);
          props?.callBack();
          props?.gmapsDispatch({ showWelcomeModal: false })
          document?.getElementsByClassName('searchBtn')[0]?.click();
        }
      });
    }
  }

  useEffect(() => {
    console.log("Triggered useEffect with loginID:", loginID);
    if (loginID.length >= 4) {
      const userDetails = {
        loginData: loginID,
        platform: props.ctr.platform,
      };
      const token = props.ctr.token;
      async function fetchData() {
        try {
          const data = await postData(apiList.alUserProfileData, userDetails, token);
          console.log(data, "Fetched alUserProfileData");
          setLoginUserData(data.data || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      fetchData();
    }
  }, [loginID, props.ctr.platform, props.ctr.token]);


  return (
    <div className={styles.ByCustomerContainer}>
      {/* <Autocomplete
      id="wlcm_select_by_customer"
      options={loginUserData || []}
      // {...defaultProps} // Ensure `defaultProps` includes the `options` array
      value={customerData || null}  // Default to `null` instead of an empty object
      onChange={(event, value) => {
        if (value) {
          setCustomerID(value.user_id);
          setCustomerData(value);
        } else {
          setCustomerID(null);
          setCustomerData(null);
        }
      }}
      getOptionLabel={(option) => option?.login_id || ''}  // Ensure valid option label
      isOptionEqualToValue={(option, value) => option.user_id === value.user_id} // Ensures correct selection
      renderInput={(params) => (
        <TextField
          {...params}
          label="Login ID/Name/Transporter Name"
          variant="standard"
          style={{ width: '50%' }}
          onChange={handleInputChange}
        />
      )}
    /> */}
      <Autocomplete
        id="wlcm_select_by_customer"
        options={loginUserData || []} // Use fetched data
        value={customerData || null}
        onChange={(event, value) => {
          console.log("Selected value:", value);
          if (value) {
            setCustomerID(value.user_id);
            setCustomerData(value);
          } else {
            setCustomerID(null);
            setCustomerData(null);
          }
        }}
        getOptionLabel={(option) => {
          return option?.user_info || ""; // Display `user_info`
        }}
        isOptionEqualToValue={(option, value) => option.user_id === value?.user_id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Login ID/Name/Transporter Name"
            variant="standard"
            style={{ width: "50%" }}
            onChange={handleInputChange}
          />
        )}
      />


      <div>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<ArrowForwardIos />}
          children="Submit"
          onClick={submitByCustomer}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ctr: state.userdata.id
})

const mapdispatchToprops = dispatch => {
  return {
    menuData: (data) => dispatch({ type: 'MENU', value: data }),
    gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload))
  };
}

export default connect(mapStateToProps, mapdispatchToprops)(ByCustomer)






