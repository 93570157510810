export const UPDATE_GMAPS_STATE = 'UPDATE_GMAPS_STATE'
export const TOGGLE_HEADER = 'TOGGLE_HEADER'
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const ACTION_EV_ENORM= 'EV_ENORM'
export const GLOBAL_FILTER_DATA = 'SET_GLOBAL_FILTER_DATA'
export const MENU_API_DATA = 'MENU_API_DATA'
export const RELEASE_NOTES = 'RELEASE_NOTES'
export const MONTHLY_REPORT_DATA = 'MONTHLY_REPORT_DATA'
export const MAPPED_SCREEN_DATA = 'MAPPED_SCREEN_DATA'

