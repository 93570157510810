// cartReducer.js
import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, CLEAR_CART } from './actions/cartAction';

const initialState = {
  cartItems: [], // Initial state for the cart is an empty array
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
            return {
              ...state,
              cartItems: Array.isArray(action.payload) ? [...state.cartItems, ...action.payload] : [...state.cartItems, action.payload],
            };

    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.id !== action.payload.id), // Remove item from cart
      };
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [], // Clear all cart items
      };
    default:
      return state;
  }
};

export default cartReducer;
