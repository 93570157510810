import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getLocationData, getVehicleDetails } from '../API/useApi'
import scroll from '../Scroll'

const Input = (props) => {
    const ctr = useSelector((state) => state.userdata.id);
    const { setState } = props
    const [VINRegnValue, setVINRegnValue] = useState('')
    const [userInput, setuserInput] = useState('')
    const [VINList, setVINList] = useState([])

    useEffect(() => {
        setVINList(props.state.vin_number)
    }, [props.state.vin_number]);

    const onChangeVin = (e, value) => {
        if (value !== null) {
            setVINRegnValue(value)
            handleSelect(value)
        }
        else{
            setVINRegnValue('')
        }
    }

    const handleSelect = async (value) => {
        setState(state => ({ ...state, selected_vin: value.vin_number}))
        if(props.payload.question === 'current_location'){
            setState(state => ({ ...state, loader: true, vinData: []}))
            let filter_data = props.vin_number.filter((data) => data.vin_number === value.vin_number)
            const response = await getLocationData(ctr, props, value.mapped_obu ? value.mapped_obu : value.obu_id);
            if(response.statuscode === 200){
                if(response.data.length > 0){
                    setState(state => ({ ...state, vinData: response.data[0], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                    props.actionProvider.resultHandler(props, response.data[0]);
                }
            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false}))
                props.actionProvider.resultHandler(props, response.data);
            }
        }
        else{
            props.actionProvider.DateHandler(props.payload.question, props.payload.title, props.payload.api, props.payload.column, props.payload.parent_title);
        }
        scroll();
    }

    const selectVIN = (e) => {
        setuserInput(e.target.value)
    }

    var defaultVINProps = {
        options: VINList.length > 0 ? VINList : [],
        getOptionLabel: option => option.regn_number +' - '+ option.vin_number
    }

  return (
    <div className='input-div'>
        <div className='col-md-10 pad-0'>
            {/* <Autocomplete
                {...defaultVINProps}
                id="loginIDAutoComplete"
                value={VINRegnValue || null}
                // onChange={(event, value) => { onChangeVin(event, value) }}
                renderInput={params => (
                    <TextField {...params} onChange={selectVIN} placeholder="Write your message here" disableClearable variant="outlined" margin="normal" fullWidth />
                )}
            /> */}
            <TextField placeholder="Write your message here" variant="outlined"></TextField>
        </div>
        <div className='col-md-2 pad-0 btn-div'>
            <button className='input-snd-btn'><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
        </div>
    </div>
  )
}

export default Input