import {messaging} from './firebase/firebaseConfig'
import {VAPID_KEY} from './apiList'
import { getToken, onMessage } from "firebase/messaging";

// Initialize Firebase Cloud Messaging and get a reference to the service
// const messaging = getMessaging(app);
// Handle background messages
  // var messaging;

  // if (firebase.messaging.isSupported()) {
  //   messaging = firebase.messaging();
  //   const messaging = getMessaging(app);

  //   console.log("Firebase is supported");
  // } else {
  //   console.log("Firebase is not supported");
  // }

export async function requestPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();
  localStorage.setItem('pushToken', '');
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: VAPID_KEY,
    });
    localStorage.setItem('pushToken', token);
    //We can send token to server
    console.log("Token generated : ", token);
  } else if (permission === "denied") {
    //notifications are blocked
    console.log("You denied for the notification");
  }
}
  export const onMessageListener = () =>
    new Promise((resolve) => {
      if (messaging) {
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          alert(payload)
          resolve(payload);
        });
      } else {
        console.log('Messaging not available');
        resolve(false);
      }
  });