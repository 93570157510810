// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ByVehicle_ByVehicleContainer__\\+Mro5 {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 1rem;
  flex-grow: 1;
}

.ByVehicle_ByVehicleContainer__\\+Mro5 button {
  background-color: #427be6;
}

.ByVehicle_VinNumberAdd__KeY3w, .ByVehicle_SearchVehicle__4t6YE {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
}

.ByVehicle_containerbulkupload__Yj9Dh {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: top;
}

.ByVehicle_ListContainer__M0-dK {
  height: 10rem;
  overflow: auto;
  border: 1px solid #adb3ad;
  width: 50%;
}

.ByVehicle_close__Of4gg{
  font-size: 21px !important;
  color: black;
  font-weight: 600;
}

.ByVehicle_ResetSubmit__dFy4F {
  display: inline-block;
  margin-right: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/ByVehicle/ByVehicle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uCAAuC;EACvC,cAAc;EACd,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".ByVehicleContainer {\r\n  display: grid;\r\n  grid-template-rows: auto auto auto auto;\r\n  grid-gap: 1rem;\r\n  flex-grow: 1;\r\n}\r\n\r\n.ByVehicleContainer button {\r\n  background-color: #427be6;\r\n}\r\n\r\n.VinNumberAdd, .SearchVehicle {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: end;\r\n}\r\n\r\n.containerbulkupload {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: top;\r\n}\r\n\r\n.ListContainer {\r\n  height: 10rem;\r\n  overflow: auto;\r\n  border: 1px solid #adb3ad;\r\n  width: 50%;\r\n}\r\n\r\n.close{\r\n  font-size: 21px !important;\r\n  color: black;\r\n  font-weight: 600;\r\n}\r\n\r\n.ResetSubmit {\r\n  display: inline-block;\r\n  margin-right: 2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ByVehicleContainer": `ByVehicle_ByVehicleContainer__+Mro5`,
	"VinNumberAdd": `ByVehicle_VinNumberAdd__KeY3w`,
	"SearchVehicle": `ByVehicle_SearchVehicle__4t6YE`,
	"containerbulkupload": `ByVehicle_containerbulkupload__Yj9Dh`,
	"ListContainer": `ByVehicle_ListContainer__M0-dK`,
	"close": `ByVehicle_close__Of4gg`,
	"ResetSubmit": `ByVehicle_ResetSubmit__dFy4F`
};
export default ___CSS_LOADER_EXPORT___;
