// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/Dashboadimgs/pencil.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forceUpdateUserProfileModal {
  padding: 0;
  padding-top: 100px;
  background-color: rgba(0, 0, 0, 0.8);
}
.forceUpdateUserProfileModal .modal-content {
  width: 100%;
  padding: 0;
}
.forceUpdateUserProfileModal .yellow-btn {
  font-family: "Calibri";
  font-size: 13px;
  font-weight: bold;
  padding: 8px 42px 7px 16px;
  border-radius: 25px;
  box-shadow: none;
  background: #f9b937;
  border: 0px;
  cursor: pointer;
}
.forceUpdateUserProfileModal .userProfileBtn {
  float: right;
  display: inline-block;
  margin-top: -40px;
  margin-right: 30px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: 100px;
  background-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Modal/Modal.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,oCAAA;AACJ;AACI;EACI,WAAA;EACA,UAAA;AACR;AAEI;EACI,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAAR;AAEI;EACI,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;EAEA,yDAAA;EACA,4BAAA;EACA,0BAAA;EACA,qBAAA;AADR","sourcesContent":[".forceUpdateUserProfileModal {\r\n    padding: 0;\r\n    padding-top: 100px;\r\n    background-color: rgba(0, 0, 0, 0.8);\r\n\r\n    .modal-content {\r\n        width: 100%;\r\n        padding: 0;\r\n    }\r\n\r\n    .yellow-btn {\r\n        font-family: 'Calibri';\r\n        font-size: 13px;\r\n        font-weight: bold;\r\n        padding: 8px 42px 7px 16px;\r\n        border-radius: 25px;\r\n        box-shadow: none;\r\n        background: #f9b937;\r\n        border: 0px;\r\n        cursor: pointer;\r\n    }\r\n    .userProfileBtn {\r\n        float: right;\r\n        display: inline-block;\r\n        margin-top: -40px;\r\n        margin-right: 30px;\r\n        \r\n        background-image: url('../../../public/images/Dashboadimgs/pencil.png');\r\n        background-repeat: no-repeat;\r\n        background-position: 100px;\r\n        background-size: 15px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
