// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ByCustomer_ByCustomerContainer__OnvlR {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
}

.ByCustomer_ByCustomerContainer__OnvlR button {
  background-color: #427be6;
}

`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/ByCustomer/ByCustomer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".ByCustomerContainer {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: end;\r\n}\r\n\r\n.ByCustomerContainer button {\r\n  background-color: #427be6;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ByCustomerContainer": `ByCustomer_ByCustomerContainer__OnvlR`
};
export default ___CSS_LOADER_EXPORT___;
