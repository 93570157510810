import React, {useEffect, useState, useRef} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import './trends.scss';
import { useSelector, useDispatch } from 'react-redux';
import { postData } from '../../Util';
import * as apilist from '../../apiList';
import DateComponent from '../Vehicleinfo/DateComponent';
import Vitals from './Vitals';
import { ChartConfig, Divelement, isDEFAvailable } from './Util';

var resertopt = '';
var resetonly = '';
let uservehicledate = {};

const Trends = (props) => {

    const ctr = useSelector(state => state.vehicledata.results);
    const uD = useSelector(state => state.userdata.id);

    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(null)
    const [endDate, setendDate] = useState(null)
    const [startTime, setstartTime] = useState(null)
    const [endTime, setendTime] = useState(null)
    const [userDet, setuserDet] = useState(uD)
    const [trends, settrends] = useState([])
    const [vitalarray, setvitalarray] = useState([])
    const [vitalvalues, setvitalvalues] = useState(0)
    const [loader, setloader] = useState(false)
    const [vitalsState, setvitalsState] = useState([])
    const [vitalslistvalue, setvitalslistvalue] = useState([])

    let chartval = [];

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
		am4core.addLicense("ch-custom-attribution");
    },[])

    const vitalselect = () => {
		props.vitallistarray(vitalarray);
		if (ctr.selectedVehicle && vitalarray.length === 0) {
            setloader(true)
			let selectedVeh = ctr.selectedVehicle.currentInfo;
			let propDate = new Date(selectedVeh.eventUTC);
			let toDate = new Date(propDate);
			let fromDate = new Date(selectedVeh.eventUTC).setHours(propDate.getHours() - 1);

            setStartDate(fromDate); setendDate(toDate); setstartTime(fromDate); setendTime(toDate);

			let vitalkeys;
			let vitalobj = {}
			vitalobj.model = ctr.selectedVehicle.vehicleType;
			vitalobj.is_bs6 = ctr.selectedVehicle.isBS6;
			const adBlueTankCap = ctr.selectedVehicle.adBlueTankCap;
			const adBlueTankCapParsed = !isNaN(adBlueTankCap) && parseInt(adBlueTankCap) > 0 ? adBlueTankCap : "";
			vitalobj.ad_blue_cap = adBlueTankCapParsed;
			vitalobj.platform = "w";
			let vehtoken = uD.token;
			const vitaldatas = postData(apilist.getVitals, vitalobj, vehtoken);

			vitaldatas.then((vitals) => {
                setloader(false)
				vitalkeys = (Object.keys(vitals.data))
				let vitalsData = [];

				vitalkeys.forEach(element => {
					let obj = {}
					obj['key'] = element;
					obj['value'] = false;
					vitalsData.push(obj);
				})
                setvitalslistvalue(vitals.data)
                setvitalsState(vitalsData)
			});
		}
	}

    const trendView = (statVals, isSubmit) => {
		if (statVals !== null) {
            setloader(true)
            setvitalvalues(0)
			let selectedVeh = ctr.selectedVehicle.currentInfo;
			let previousSelected_isBs6 = ctr.previousSelected_isBs6;
			let previouseSelectedAdblue = ctr.previouseSelectedAdblue
			let vitalArray = vitalarray;
			const currentVehicleAdBlue = isDEFAvailable(ctr.selectedVehicle.adBlueTankCap);
			if (previousSelected_isBs6 !== undefined && previousSelected_isBs6 !== "" && previousSelected_isBs6 !== ctr.selectedVehicle.isBS6 || (previouseSelectedAdblue !== undefined && currentVehicleAdBlue !== previouseSelectedAdblue)) {
				vitalselect();

				let mVal = {};
				mVal.selectedVehicle = ctr.selectedVehicle;
				mVal.previousSelected_isBs6 = mVal.selectedVehicle.isBS6;
				mVal.previouseSelectedAdblue = currentVehicleAdBlue;
				uservehicledate = mVal;
				vitalArray = [];
                dispatch({ type: 'VehicleDatas', value: uservehicledate });
				resetChart();
                setloader(false)
				return;
			}
			let sD = statVals.fromDate,
				eD = statVals.toDate,
				sT = statVals.fromTime,
				eT = statVals.toTime;
			let freq = statVals.frequency;

			let dataObj = {};
			dataObj.freq = freq;
			dataObj.obu = selectedVeh.obuId;
			dataObj.from = sD + " " + sT;
			dataObj.to = eD + " " + eT;
			dataObj.vitals = vitalArray;
			if (dataObj.vitals.length !== 0) {
				dataObj.vehicleType = ctr.selectedVehicle.vehicleType;
				dataObj.is_bs6 = ctr.selectedVehicle.isBS6;
				dataObj.ad_blue_cap = ctr.selectedVehicle.adBlueTankCap;
				dataObj.fuel_tank_cap = ctr.selectedVehicle.fuelTankCap;
				dataObj.vinNumberdetails = selectedVeh.vinNumber;
				let vehtoken = uD.token;
				dataObj.platform = "w"
				const datas = postData(apilist.getTrends, dataObj, vehtoken);
				datas.then((dataVals) => {
					if(dataVals.statuscode === 200){
						if (dataVals.data !== null) {
							let listofvitals = (Object.keys(dataVals.data.TREND_RESPONSE))
							for (let i = 0; i < listofvitals.length; i++) {
								if ((dataVals.data.TREND_RESPONSE[listofvitals[i]].length) !== 0) {
									setvitalvalues(listofvitals)
								} else {
									resertopt = "notreset";
									setvitalvalues('')
								}
							}
	
							props.selecteddate(dataObj);
							props.excelDownload(dataVals.data.TREND_RESPONSE);
							ChartConfig(listofvitals, dataVals.data, chartval, vitalslistvalue, SetVitalNull, ctr.selectedVehicle.vehicleType)
							setloader(false)
						}
					}
					 else {
						alert("No Vital Data are Available for the selected Date Range")
                        setloader(false)
					}
				});
			} else {
				resetChart();
				if (isSubmit) {
					alert("Please select the vital before proceed");
				}
                setloader(false)
			}
		} else {
			resetonly = "active";
            setvitalvalues('')
            setvitalarray([])
			var vitalkeyss = (Object.keys(vitalslistvalue))
			for (let i = 0; i < vitalkeyss.length; i++) {
				document.getElementById(vitalkeyss[i]).checked = false;
			}
		}
	}

    const SetVitalNull = () => {
        setvitalvalues('')
    }

    const handleCheckbox = (e, index) => {
		let vitalKeys = vitalsState;
		let checked = e.target.checked;
		let defaultValue = e.target.value;
		vitalKeys[index]['value'] = e.target.checked;
		setvitalsState(vitalKeys);
        if (checked === true) {
			setvitalarray((prevVitalArray) => [...prevVitalArray, ...vitalslistvalue[defaultValue]]);
		  } else {
			setvitalarray((prevVitalArray) => {
			  const updatedArray = prevVitalArray.filter((item) => !vitalslistvalue[defaultValue].includes(item));
			  return updatedArray.length === 0 ? [] : updatedArray;
			});
		}
    };

    const resetChart = () => {
		if (chartval.length > 0) {
			for (let i = 0; i < chartval.length; i++) {
				chartval[i].dispose();
			}
			let vitalKeys = vitalsState;
			vitalKeys.map((item) => item['value'] = false)
			chartval = [];
            setvitalarray([]); setvitalsState(vitalKeys); setvitalvalues(0)
		}
		else{
			let vitalKeys = vitalsState;
			vitalKeys.map((item) => item['value'] = false)
			chartval = [];
            setvitalarray([]); setvitalsState(vitalKeys); setvitalvalues(0)
		}

		props.disableDownBtn();
	}

    return (
        <div>
            {loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
            <div className="datacomponent">
				<div className="content">
					<DateComponent
						userDetails={userDet}
						receiveProps={props}
						selectedvitals={vitalarray}
						goTrace={trendView}
						showMiniToolbar={props.showminimizedToolbar}
						downloadbtn={props.downloadbtn}
						updateExcel={props.updateExcel}
						resetChart={resetChart}
						screenName="trend"
						downloadbutton={props.downloadbutton} 
						vitalselect={vitalselect}/>
				</div>
			</div>
            <div className="borderline_tnd"></div>
            <div className="container-wrapper">
				<div className="row ">
                    <Vitals vitals={vitalsState} handleCheckbox={handleCheckbox} />
					<div className="col-md-10">
						<div class="row" name="chart-container" id="chartContainer">
							<Divelement data={vitalvalues} resertopt={resertopt} resetonly={resetonly} />
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default Trends