import React, {useState, useEffect, useMemo} from "react";
import {
  useLoadScript,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  Polyline
  //Rectangle
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../apiList";
import { mathRound } from "../../Util";

const mapContainerStyle = {
  width: '100%',
  height: '100vh'
};

const getFormatedData = (data, forField) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  try {

    if (forField == 'status') {
      let status = "";
      if (data.vehstatus === 0) {
        status = 'RUNNING';
      } else if (data.vehstatus === 1 || data.vehstatus === 3) {
        status = data.vehstatus === 1 ? 'STOPPED' : 'TOWED';
      } else if (data.vehstatus === 2) {
        status = 'IDLING';
      }
      return status;
    }
    else if (forField == "timestamp") {
      let current_datetime = new Date(data.localtimestamp);
      let hours = current_datetime.getHours();
      let minutes = current_datetime.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;

      return months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear() + " " + strTime;
    }
    else if (forField == "getTime") {
      let current_datetime = new Date(data.localtimestamp);
      let hours = current_datetime.getHours();
      let minutes = current_datetime.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes;
      return <><div className="txt-cl1">{strTime}<span className="inter-css">{ampm}</span></div></>


    }
    else if (forField == "getDate") {
      let current_datetime = new Date(data.localtimestamp);
      return months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear();
    }
    else if (forField == "engineRpm") {
      return Math.round(data.enginespeed)
    }
    else if (forField == "gpsSpeed") {
      return Math.round(data.gpsspeed);
    }
    else if (forField == "fuelLevel" || forField == "fuelConsumption") {
      let fL = data.fuellevel ? data.fuellevel : "0";
      let fC = data.fuelVal ? data.fuelVal : 0;
      let isbs6cng, isbs6cng_cons, isbs6unit, isbs6unitkg = "";
      if (data.isBS6 === 2) {
        isbs6cng = "Gas Used"
        isbs6cng_cons = "Gas Consumption :"
        isbs6unit = "bar"
        isbs6unitkg = " Kg"
      } else {
        isbs6cng = "Fuel Used"
        isbs6cng_cons = "Fuel Consumption :"
        isbs6unit = "ltr"
        isbs6unitkg = " Litre"
      }
      return <><div className="col-20 col-border" ><div className="col-aln-cn"><div className="txt-cl1">{Math.round(fC)}<span className="inter-css">{isbs6unit}</span></div><div className="col-txt-mg">{isbs6cng}</div></div></div></>
    }
    else if (forField == "ignition") {
      return data.ignition ? 'ON' : 'OFF';
    }
    else if (forField == "distance") {
      return data.distance != undefined ? data.distance.toFixed(1) : 0;
    }
    else if (forField == "location") {
      return data.location;
    }
  }
  catch (e) {
    return "None"
  }

}
var seconds = 6000000,output = 0
const countdown=() =>{
  //seconds = seconds-1

    // const d = new Date();
    var elem = document.getElementById('some_div');
    if(elem!== null){
      //seconds = d.getSeconds();
      //console.log(seconds,"rfv")
      elem.innerHTML = 'Next update in 00:' + ( Math.abs(seconds--)%60).toString()+ ' SECS';
    }
}


// let seconds = 0;
// function showTime() {
//     //update the time as hours, minutes, and seconds 00:00:00
//     seconds++;
//     let secs = Math.floor(seconds % 60);
//     let output =secs.toString().padStart(2, '0');
//     console.log(output);
//     if(elem!== null && timeLeft !== null){
//      elem.innerHTML = 'Next update in 00:'+output + ' SECS';
//     }
// }

  // Function to group points into segments based on is_deviation
  const getPolylineSegments = (data) => {
    const segments = [];
    let currentSegment = [{ lat: data[0].latitude, lng: data[0].longitude }];
    let currentDeviationStatus = data[0].is_deviation;

    for (let i = 1; i < data.length; i++) {
      const point = data[i];
      const newPoint = { lat: point.latitude, lng: point.longitude };

      if (point.is_deviation !== currentDeviationStatus) {
        // Close the current segment with the transition point
        currentSegment.push(newPoint);
        segments.push({ path: currentSegment, is_deviation: currentDeviationStatus });

        // Start a new segment, reusing the transition point
        currentSegment = [newPoint];
        currentDeviationStatus = point.is_deviation;
      } else {
        // Continue the current segment
        currentSegment.push(newPoint);
      }
    }

    // Push the last segment
    if (currentSegment.length) {
      segments.push({ path: currentSegment, is_deviation: currentDeviationStatus });
    }

    return segments;
  };


// const MapBox = compose(
//   withProps({
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=places,geometry,visualization,drawing&key="+GOOGLE_MAP_API_KEY+"&v=3.exp&sensor=false",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `100vh` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   lifecycle({
//     componentDidMount() {
//       // this.interval =  setInterval(() =>  countdown() ,10000)
//     },
//     componentWillReceiveProps(nextProps){
//       //this.interval =  setInterval(() =>  countdown() ,1000)
//       if(nextProps.route_directions
//        || nextProps.routeIndex !== this.props.routeIndex ){
//         const DirectionsService = new window.google.maps.DirectionsService();
//         let waypoints = [];
//         if(nextProps.waypoints && nextProps.waypoints.length){
//           for(var i=0; i<nextProps.waypoints.length;i++){
//             let waypoint = {};
//             waypoint.location = new window.google.maps.LatLng(nextProps.waypoints[i].lat,nextProps.waypoints[i].lng);
//             waypoint.stopover = true;
//             waypoints.push(waypoint);
//           }
//         }
//         if(nextProps.route_directions !== undefined){
//           if('startLat' in nextProps.route_directions && 'destLat' in nextProps.route_directions){
//             var rendererOptions = {
//               routeIndex: nextProps.routeIndex
//             }
//             let directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);

//             DirectionsService.route({
//               origin: new window.google.maps.LatLng(nextProps.route_directions.startLat, nextProps.route_directions.startLng),
//               destination: new window.google.maps.LatLng(nextProps.route_directions.destLat, nextProps.route_directions.destLng),
//               travelMode: window.google.maps.TravelMode.DRIVING,
//               provideRouteAlternatives: true,
//               optimizeWaypoints:true,
//               waypoints: waypoints,
//             }, (result, status) => {
//               if (status === window.google.maps.DirectionsStatus.OK) {
//                 // directionsDisplay.setRouteIndex(nextProps.routeIndex);
//                 // console.log(directionsDisplay.getRouteIndex());
//                 directionsDisplay.setDirections(result);
//                 // console.log('directionsDisplay', directionsDisplay);
//                 // var path = result.routes[nextProps.routeIndex].overview_path;

//                 if (nextProps.routeIndex >= 0 && nextProps.routeIndex < result.routes.length) {
//                   result["routes"] = [result["routes"][nextProps.routeIndex]]
//                 } 

//                 this.setState({
//                   directions: result,
//                   routeIndex:directionsDisplay.routeIndex,
//                   showDirection:true,
//                   showMarker:false,
//                   //bounds:viewRouteBoxes
//                 },()=>{
//                   this.props.handleRoutes(this.state)
//                 });
//               } else {
//                 console.error(`error fetching directions ${result}`);
//                 this.setState({
//                   directions: result,
//                 },()=>{
//                   this.props.handleRoutes("")
//                 });
//               }
//             });
//           }
//           else{
//             this.setState({
//               showMarker:true,
//             })
//           }
//         }


//       }
//     },
//   })
// )(props =>
//   <GoogleMap
//     zoom={props.zoom}
//     center={props.center}
//     ref={ props.zoomToMarkers }
//     //ref={props.onMapMounted}
//   >

//           {
    
//             props.traceData ?
//               props.traceData?.map((mark, index) => {
//                 var MarkerOptions = {
//                   path: window.google.maps.SymbolPath.CIRCLE,
//                   fillOpacity: mark.is_deviation === true ? 4 : 3,
//                   scale: mark.is_deviation === true ? 4 : 3,
//                   strokeColor:mark.is_deviation === true ? "#e06c07" : '#009900', // ,#006622 dots
//                   strokeWeight: mark.is_deviation === true ? 4 : 3
//                 };

//                 let latVal = mark.latitude,
//                   lngVal = mark.longitude;
//                 //        debugger
//                 //  var iconVal = '';
//                 //console.log(props.dataVals[props.dataVals.length-1]);
//                 var title = "";

//                 if (mark.vehstatus === 0 && mark.is_deviation === true) {
//                   MarkerOptions.strokeColor = '#e06c07';
//                 } else if (mark.vehstatus === 0 && mark.is_deviation === false) {
//                   MarkerOptions.strokeColor = '#009900';
//                 } else if (mark.vehstatus === 1 || mark.vehstatus === 3) {
//                   MarkerOptions.strokeColor = '#ff3300';
//                 } else if (mark.vehstatus === 2) {
//                   MarkerOptions.strokeColor = '#ffff00';
//                 } else {
//                   //MarkerOptions = MarkerOptions;
//                 }

//                 if (index === 0) {
//                   MarkerOptions = '';
//                   title = title + "Start Point \n";
//                 } else if (props.dataVals !== undefined) {
//                   if (index === props.dataVals.length - 1) {
//                     title = title + "End Point \n";
//                     MarkerOptions = props.endVehicle;
//                   }
//                 } else {
//                   title = "";
//                 }
//                 //let status = mark.vehstatus===0 ? 'RUNNING' : (mark.vehstatus===1 ? 'STOPPED' : (mark.vehstatus===2) ? 'IDLING' : 'TOWED');

//                 let fL = mark.fuellevel ? mark.fuellevel : "0",
//                   iS = mark.ignition ? 'ON' : 'OFF',
//                   fC = mark.is_deviation === false ? mark.fuelVal > 0 ? Math.round(mark.fuelVal) : 0 : '';

//                 let DEFS = mark.defsVal ? mark.defsVal : 0;

//                 let formatted_date = getFormatedData(mark, 'timestamp');

//                 //console.log(formatted_date)
//                 var isbs6at
//                 if (props.currentMarker !== undefined) {
//                   if( props.currentMarker.isBS6 === 1){
//                     isbs6at = "DEF Consumption : " + DEFS + " Litre \n" +
//                     "DEF Level : " + Math.round(mark.catalystTankLevel) + " Litre";
//                   }
//                 } else {
//                   isbs6at = '';
//                 }
//                 // let fL = mark.fuellevel ? mark.fuellevel : "0",
//                 // iS = mark.ignition ? 'ON' : 'OFF',
//                 // fC = mark.fuelVal ? mark.fuelVal : 0;
//                 var isbs6cng;
//                 var isbs6cng_cons;
//                 var isbs6unit;
//                 var isbs6unitkg;
//                 var isDisTra;
//                 var isDistance;
//                 var is_deviation_id;
//                 if (props.currentMarker !== undefined) {
//                   if (props.currentMarker.isBS6 === 2) {
//                     isbs6cng = "Gas Level :"
//                     isbs6cng_cons = "Gas Consumption :"
//                     isbs6unit = " Bar"
//                     isbs6unitkg = " Kg"
//                   }
//                 }
//                   else {
//                   isbs6cng = "Fuel Level :"
//                   isbs6cng_cons = mark.is_deviation === false ? "Fuel Consumption : " : ''
//                   isbs6unit = " Litre"
//                   isbs6unitkg = mark.is_deviation === false ? " Litre" : ''
//                 }
//                 isDisTra = mark.is_deviation === true ? 'Deviated Distance :' : 'Distance Travelled : '
//                 isDistance = mark.is_deviation === true ? Math.round(mark.nearest_distance) : Math.round(mark.distance)
//                 is_deviation_id = mark.is_deviation === true ? 'Deviation Id : ' : ''
//                 title = title + isDisTra + isDistance + " KM \n" + is_deviation_id + mark.deviation_id +
//                 isbs6cng_cons + fC + isbs6unitkg + " \n" + isbs6at +
//                   "Vehicle Status : " + getFormatedData(mark, 'status') + "\n" +
//                   "TimeStamp : " + formatted_date + "\n" +
//                   "Engine RPM : " + Math.round(mark.enginespeed) + "\n" +
//                   "GPS Speed : " + Math.round(mark.gpsspeed) + " km/hr \n" +
//                   isbs6cng + Math.round(fL) + isbs6unit + "\n" +
//                   "Ignition Status : " + iS + "\n" +
//                   "Location : " + mark.location;
//                 return <Marker icon={MarkerOptions} position={{ lat: latVal, lng: lngVal }} key={index} title={title} >
//                 </Marker>
//               }) : ''

//           }

//     {props.showMarker && <Marker position={props.markerPosition} />}
//     {props.showDirection ? <DirectionsRenderer  options={props.traceData.length !==0?{ preserveViewport: true }:''} directions={props.directions}
//       routeIndex={props.routeIndex}/> : null}
//     {props.traceData.length > 0 && getPolylineSegments(props.traceData).map((segment, index) => (
//       <Polyline 
//         key={index}
//         path={segment.path}
//         options={{
//           strokeColor: segment.is_deviation ? '#e06c07' : 'green',
//           strokeOpacity: 1.0,
//           strokeWeight: 2,
//           geodesic: true
//         }}>
//       </Polyline>
//     ))}
//   </GoogleMap>
// );

const MapBox = (props) => {
  const [directions, setDirections] = useState(null);
  const [showDirection, setShowDirection] = useState(false);
  const [showMarker, setShowMarker] = useState(true);
  const libraries = useMemo(() => ["places", "geometry", "visualization", "drawing"], []);
  // Load the Google Maps API
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY, // Pass your API key here
    libraries: libraries,
  });

  const { 
    zoom, 
    handleRoutes, 
    center, 
    markerPosition, 
    height, 
    route_directions, 
    waypoints, 
    routeIndex, 
    // showDirection, 
    // showMarker, 
    traceData, 
    onMapMounted, 
    zoomToMarkers, 
    moveTowardsEnd, 
    currentMarker,
    endVehicle
  } = props;

  useEffect(() => {
    const updateDirections = () => {
      const DirectionsService = new window.google.maps.DirectionsService();
  
      if (route_directions && routeIndex !== undefined) {
        const formattedWaypoints =
          waypoints?.map((point) => ({
            location: new window.google.maps.LatLng(point.lat, point.lng),
            stopover: true,
          })) || [];
  
        if (route_directions.startLat && route_directions.destLat) {
          const rendererOptions = { routeIndex: routeIndex };
          const directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);
  
          DirectionsService.route(
            {
              origin: new window.google.maps.LatLng(route_directions.startLat, route_directions.startLng),
              destination: new window.google.maps.LatLng(route_directions.destLat, route_directions.destLng),
              travelMode: window.google.maps.TravelMode.DRIVING,
              provideRouteAlternatives: true,
              optimizeWaypoints: true,
              waypoints: formattedWaypoints,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                if (routeIndex >= 0 && routeIndex < result.routes.length) {
                  result.routes = [result.routes[routeIndex]];
                }
  
                setDirections(result);
                setShowDirection(true);
                setShowMarker(false);
  
                handleRoutes?.({
                  directions: result,
                  routeIndex: directionsDisplay.routeIndex,
                });
              } else {
                console.error(`Error fetching directions ${status}`);
                setDirections(null);
                handleRoutes?.("");
              }
            }
          );
        } else {
          setShowMarker(true);
        }
      }
    };
    if (isLoaded) {
      updateDirections();
    }
  }, [route_directions, routeIndex, waypoints]);
  

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap 
      zoom={zoom} 
      center={center} 
      ref={zoomToMarkers} 
      mapContainerStyle={mapContainerStyle}
    >
      {/* {traceData?.map((mark, index) => {
        const MarkerOptions = {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillOpacity: mark.is_deviation ? 4 : 3,
          scale: mark.is_deviation ? 4 : 3,
          strokeColor: mark.is_deviation ? "#e06c07" : "#009900",
          strokeWeight: mark.is_deviation ? 4 : 3,
        };

        const title = `Vehicle Status: ${mark.vehstatus}\nLocation: ${mark.location}\n...`; // Construct your title here.

        return <Marker icon={MarkerOptions} position={{ lat: mark.latitude, lng: mark.longitude }} key={index} title={title} />;
      })} */}

  {
    
    traceData ?
      traceData?.map((mark, index) => {
        var MarkerOptions = {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillOpacity: mark.is_deviation === true ? 4 : 3,
          scale: mark.is_deviation === true ? 4 : 3,
          strokeColor:mark.is_deviation === true ? "#e06c07" : '#009900', // ,#006622 dots
          strokeWeight: mark.is_deviation === true ? 4 : 3
        };

        let latVal = mark.latitude,
          lngVal = mark.longitude;
        //        debugger
        //  var iconVal = '';
        //console.log(dataVals[dataVals.length-1]);
        var title = "";

        if (mark.vehstatus === 0 && mark.is_deviation === true) {
          MarkerOptions.strokeColor = '#e06c07';
        } else if (mark.vehstatus === 0 && mark.is_deviation === false) {
          MarkerOptions.strokeColor = '#009900';
        } else if (mark.vehstatus === 1 || mark.vehstatus === 3) {
          MarkerOptions.strokeColor = '#ff3300';
        } else if (mark.vehstatus === 2) {
          MarkerOptions.strokeColor = '#ffff00';
        } else {
          //MarkerOptions = MarkerOptions;
        }

        if (index === 0) {
          MarkerOptions = '';
          title = title + "Start Point \n";
        } else if (traceData !== undefined) {
          if (index === traceData.length - 1) {
            title = title + "End Point \n";
            MarkerOptions = endVehicle;
          }
        } else {
          title = "";
        }
        //let status = mark.vehstatus===0 ? 'RUNNING' : (mark.vehstatus===1 ? 'STOPPED' : (mark.vehstatus===2) ? 'IDLING' : 'TOWED');

        let fL = mark.fuellevel ? mark.fuellevel : "0",
          iS = mark.ignition ? 'ON' : 'OFF',
          fC = mark.is_deviation === false ? mark.fuelVal > 0 ? Math.round(mark.fuelVal) : 0 : '';

        let DEFS = mark.defsVal ? mark.defsVal : 0;

        let formatted_date = getFormatedData(mark, 'timestamp');

        //console.log(formatted_date)
        var isbs6at
        if (currentMarker !== undefined) {
          if( currentMarker.isBS6 === 1){
            isbs6at = "DEF Consumption : " + DEFS + " Litre \n" +
            "DEF Level : " + Math.round(mark.catalystTankLevel) + " Litre";
          }
        } else {
          isbs6at = '';
        }
        // let fL = mark.fuellevel ? mark.fuellevel : "0",
        // iS = mark.ignition ? 'ON' : 'OFF',
        // fC = mark.fuelVal ? mark.fuelVal : 0;
        var isbs6cng;
        var isbs6cng_cons;
        var isbs6unit;
        var isbs6unitkg;
        var isDisTra;
        var isDistance;
        var is_deviation_id;
        if (currentMarker !== undefined) {
          if (currentMarker.isBS6 === 2) {
            isbs6cng = "Gas Level :"
            isbs6cng_cons = "Gas Consumption :"
            isbs6unit = " Bar"
            isbs6unitkg = " Kg"
          }
        }
          else {
          isbs6cng = "Fuel Level :"
          isbs6cng_cons = mark.is_deviation === false ? "Fuel Consumption : " : ''
          isbs6unit = " Litre"
          isbs6unitkg = mark.is_deviation === false ? " Litre" : ''
        }
        isDisTra = mark.is_deviation === true ? 'Deviated Distance :' : 'Distance Travelled : '
        isDistance = mark.is_deviation === true ? Math.round(mark.nearest_distance) : Math.round(mark.distance)
        is_deviation_id = mark.is_deviation === true ? 'Deviation Id : ' : ''
        title = title + isDisTra + isDistance + " KM \n" + is_deviation_id + mark.deviation_id +
        isbs6cng_cons + fC + isbs6unitkg + " \n" + isbs6at +
          "Vehicle Status : " + getFormatedData(mark, 'status') + "\n" +
          "TimeStamp : " + formatted_date + "\n" +
          "Engine RPM : " + Math.round(mark.enginespeed) + "\n" +
          "GPS Speed : " + Math.round(mark.gpsspeed) + " km/hr \n" +
          isbs6cng + Math.round(fL) + isbs6unit + "\n" +
          "Ignition Status : " + iS + "\n" +
          "Location : " + mark.location;
        return <Marker icon={MarkerOptions} position={{ lat: latVal, lng: lngVal }} key={index} title={title} >
        </Marker>
      }) : ''

  }

      {showMarker && <Marker position={markerPosition} />}
      {showDirection && (
        <DirectionsRenderer
          options={traceData.length !== 0 ? { preserveViewport: true } : ""}
          directions={directions}
          routeIndex={routeIndex}
        />
      )}

      {traceData.length > 0 &&
        getPolylineSegments(traceData).map((segment, index) => (
          <Polyline
            key={index}
            path={segment.path}
            options={{
              strokeColor: segment.is_deviation ? "#e06c07" : "green",
              strokeOpacity: 1.0,
              strokeWeight: 2,
              geodesic: true,
            }}
          />
        ))}
    </GoogleMap>
  );
};
class CreateMap extends React.Component{

  constructor(props){
    super(props);
    this.handleRoutes = this.handleRoutes.bind(this);
  }

  // componentWillReceiveProps (newProps) {
  //   if( newProps.traceData !== this.props.traceData ){/* do stuff */
  //     alert("success")
  //   }
  // }

  handleRoutes = (_State) =>{
    this.props.handleRoutes(_State);
  }

  // handleMapMounted = (map) => {
  //   const { traceData } = this.props

  //   this._map = map
  //   if (map) {
  //     const bounds = new window.google.maps.LatLngBounds()

  //     traceData.map(position => {
  //       bounds.extend(position)
  //     })
  //     console.log(bounds);
  //     this._map.fitBounds(bounds)

  //   }
  // }
  

  render(){
    return <div>
      { this.props.traceData.length ?
        <div>
          <div class="borderDataAlign">
            <div className="alignData col-md-12" style={{height:'40px',display : 'flex',left:'-32px',margin:'7px'}}>
              <div className="borderAligntext col-md-3">
                <span>{this.props.traceData[this.props.traceData.length - 1].localtimestamp}</span><br/>
              </div>
              <div className="borderAlign col-md-2">
                <span>{Math.round(this.props.traceData[this.props.traceData.length - 1].gpsspeed)}</span><span>&nbsp;kmph</span><br/>
                <label>Vehicle Speed</label>
              </div>
              <div className="borderAlign">
                <span>{Math.round(this.props.traceData[this.props.traceData.length - 1].distance)}</span><span>&nbsp;km</span><br/>
                <label>Distance Travelled</label>
              </div>
              <div className={this.props.traceData[0].status !== "Completed" ? "borderAlign" : "borderAlign"}>
                <span>{this.props.traceData[this.props.traceData.length - 1].fuelVal}</span><span>&nbsp;ltr</span><br/>
                <label>Fuel Used</label>
              </div>
              <div className={this.props.traceData[0].status !== "Completed" ? "borderAlign" : "borderAlign "}>
                <span>{this.props.traceData[this.props.traceData.length - 1].deviated_distance}</span><span>&nbsp;km</span><br/>
                <label>Deviated Distance</label>
              </div>
              <div className={this.props.traceData[0].status !== "Completed" ? "borderAlign col-md-2" : "dataAlign col-md-2"}>
                <span>{this.props.traceData[this.props.traceData.length - 1].deviation_count}</span><br/>
                <label>Deviation Count</label>
                
              </div>
              {this.props.traceData[0].status !== "Completed" ?
              <div id="some_div" className="borderAligntext2 col-md-3">
                <span>Every 60 secs</span><br/>
                <span>Refresh Frequency</span>
              </div> : ''
              }
            </div>
            <div className="location"><i class="fa fa-map-marker" aria-hidden="true"></i><span>&nbsp;{this.props.traceData[this.props.traceData.length - 1].location}</span></div>
          </div>
          {/* <MapBox isMarkerShown={this.props.markerPosition} mapCenter={this.props.center}
            mapZoom={this.props.zoom} showNormalPolyline={true}
            dataVals={this.props.traceData}  traceData={this.props.traceData}
            selectedMarker={this.props.showMarker} onClick={this.handleClick}
            onProgressCompleted={this.handleMapMounted} currentMarker={this.props.showMarker}
            zoomToMarkers={this.props.zoomToMarkers}/> */}
        </div> : <div></div>
      }
        <div>
          <MapBox zoom={this.props.zoom} handleRoutes={this.handleRoutes}
          center={this.props.center}  markerPosition={this.props.markerPosition}
          height={this.props.height} route_directions={this.props.route_directions}
          waypoints={this.props.waypoints} routeIndex={this.props.routeIndex}
          showDirection={this.props.showDirection} showMarker={this.props.showMarker}
          traceData={this.props.traceData} onMapMounted={this.handleMapMounted}
          zoomToMarkers={ this.props.zoomToMarkers }
          moveTowardsEnd={this.moveTowardsEnd} currentMarker={this.props.showMarker}
          endVehicle={this.props.endVehicle}
          >

          </MapBox>
          {this.props.zoomToMarkers!==undefined?<div className="Tripnote-alignment"><img src="/images/Trip-note.png" alt="Trip Note"/></div>:""}
        </div>
      </div>

 }
}

export default CreateMap;

// stateVal.datas !== '' ? <MyMapComponent isMarkerShown={stateVal.isMarkerShown} mapCenter={stateVal.mapCenter}
//  mapZoom={stateVal.mapZoom} showNormalPolyline={stateVal.showNormalPolyline} progress={stateVal.progress}
//  finalDst={this.state.finalDistance} progressVl={this.state.progressValue} getFormatedData={this.getFormatedData}
//  onMarkerClick={this.handleMarkerClick} dataVals={stateVal.datas} infoWindowClose={this.handleToggleClose}
//   infoWindowOpen={this.handleToggleOpen} infoWindowState={stateVal.isOpen} selectedMarker={stateVal.selectedMarker}
//   onClick={this.handleClick} onProgressCompleted={this.state.onProgressCompleted}
//    currentMarker={this.props.ctr.selectedVehicle} vehStatus={stateVal.vehStatus}
//     zoomToMarkers={stateVal.zoomToMarkers} endVehicle={stateVal.endVehicle}
//      circledata={stateVal.circlegeofencedata} polygondata={stateVal.polygeofencedata}
//      geofencehideandshow={stateVal.enablegeofence} refcircle={this.circle} />
