// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.todos-widget {
  color: black;
}
.todos-widget .disable {
  pointer-events: none;
}

.questions-widget {
  position: sticky;
  top: 0px;
  z-index: 100000;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  padding: 10px;
  border-bottom: 1px solid #b8b8b8;
}

.todos-widet-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.todo-widget-list-item {
  font-size: 1rem;
  margin: 8px 0;
  text-align: left;
}

.questions-div {
  list-style-type: none;
  padding: 0px;
  color: #4279e0;
  margin: 0px;
}
.questions-div .btn {
  margin: 10px;
}

ul.questions-div li {
  margin: 0px 0px 2px 0px;
  cursor: pointer;
  color: #4279e0;
  font-size: 14px;
  font-family: calibri;
}

.auto-suggetion {
  font-size: 18px;
  font-weight: bold;
}

.fa-info-circle:before {
  color: #4279e0;
}

.MuiAccordionDetails-root {
  padding: 5px 0px 5px 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Chatbot/Questions/Questions.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACI;EACI,oBAAA;AACR;;AAEA;EACI,gBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,gCAAA;AACJ;;AACA;EACI,SAAA;EACA,UAAA;EACA,qBAAA;AAEJ;;AAAA;EACI,eAAA;EACA,aAAA;EACA,gBAAA;AAGJ;;AADA;EACI,qBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;AAIJ;AAFI;EACI,YAAA;AAIR;;AADA;EACI,uBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,oBAAA;AAIJ;;AAFA;EACI,eAAA;EACA,iBAAA;AAKJ;;AAHA;EACI,cAAA;AAMJ;;AAJA;EACI,oCAAA;AAOJ","sourcesContent":[".todos-widget{\r\n    color: black;\r\n\r\n    .disable{\r\n        pointer-events: none;\r\n    }\r\n}\r\n.questions-widget{\r\n    position: sticky;\r\n    top: 0px;\r\n    z-index: 100000;\r\n    background: #fff;\r\n    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;\r\n    padding: 10px;\r\n    border-bottom: 1px solid #b8b8b8;\r\n}\r\n.todos-widet-list{\r\n    margin: 0;\r\n    padding: 0;\r\n    list-style-type: none;\r\n}\r\n.todo-widget-list-item{\r\n    font-size: 1rem;\r\n    margin: 8px 0;\r\n    text-align: left;\r\n}\r\n.questions-div{\r\n    list-style-type: none;\r\n    padding: 0px;\r\n    color: #4279e0;\r\n    margin: 0px;\r\n\r\n    .btn{\r\n        margin: 10px;\r\n    }\r\n}\r\nul.questions-div li {\r\n    margin: 0px 0px 2px 0px;\r\n    cursor: pointer;\r\n    color: #4279e0;\r\n    font-size: 14px;\r\n    font-family: calibri;\r\n}\r\n.auto-suggetion{\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n}\r\n.fa-info-circle:before {\r\n    color: #4279e0;\r\n}\r\n.MuiAccordionDetails-root{\r\n    padding: 5px 0px 5px 30px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
