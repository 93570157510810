// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
}

.selectWrapper {
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  margin-right: 6px;
  margin-bottom: 4px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.selectBox {
  border: 0px;
  margin: 1px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Tracking/NumberPickerDropdown.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,8EAAA;AACJ;;AACA;EACI,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oCAAA;AAEJ;;AAAE;EACE,WAAA;EACA,WAAA;EACA,aAAA;AAGJ","sourcesContent":[".number-container{\r\n    border: 1px solid rgba(0, 0, 0, 0.1);\r\n    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);\r\n}\r\n.selectWrapper{\r\n    border-radius: 10px;\r\n    display: inline-block;\r\n    overflow: hidden;\r\n    margin-right: 6px;\r\n    margin-bottom: 4px;\r\n    background: #fff;\r\n    border: 1px solid rgba(0, 0, 0, 0.1);\r\n  }\r\n  .selectBox{\r\n    border: 0px;\r\n    margin: 1px;\r\n    outline: none;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
