import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as apiList from '../../../apiList'
import { postData } from '../../../Util'
import CircularProgress from '@mui/material/CircularProgress';
import "./MappedVehicles.scss";
import moment from 'moment';

import { GmapsDispatchThunk } from '../../../store/actions/GmapsActions';

const MappedVehicles = (props) => {
    console.log(props);
    const [selectAll, setSelectAll] = useState(false);
    const [open, setOpen] = useState(false);
    var Data,login_id,transporterName,customerName;

    if(props.vehicleData.vinData && props.vehicleData.vinData.length){
      login_id = props.vehicleData.loginId
      transporterName = props.vehicleData.transporterName
      customerName = props.vehicleData.customerName
      let array = props.vehicleData.vinData;
      console.log(array);
      
      const  uniqueVal = (data, key) => {
        return [
            ...new Map(
              data.map(x => [key(x), x])
            ).values()
          ];
      }

      Data = uniqueVal(array, it => it.vin);
      console.log(Data)
    }

    setTimeout(function(){
      let el = document.getElementsByClassName('Pageloading')[0];
      if(el){
        el.style.display = 'none';
      }
    }, 3000);

    const _selectAll = (event) => {
      console.log('Select All');
      if(!selectAll){
        let elements = document.getElementsByClassName('td_checkboxx');
        console.log(elements);
        for(let i=0; i<elements.length; i++){
          elements[i].checked = true;
        }

        setSelectAll(true);
      }else{
        let elements = document.getElementsByClassName('td_checkboxx');
        console.log(elements);
        for(let i=0; i<elements.length; i++){
          elements[i].checked = false;
        }

        setSelectAll(false);
      }
    }

    const getMappedVehicleList=()=>{
      let token = props.ctr.token;
      let loader = document.getElementsByClassName('mv_loader')[0];
      let url = apiList.getMappedVehicleListExcel;
      loader.style.display = 'block';
        fetch(url, {
            "headers": {
                    'Content-Type' : 'application/json',
                    "Authorizationl": token
                } ,   
            method: 'post',
        }).then(response => response.blob()).then(myBlob  => {
            const blobUrl = window.URL.createObjectURL(myBlob);
            const link = document.createElement("a");
            loader.style.display = 'none';
            link.href = blobUrl;
            link.download = "MappedVehiclesData.xlsx";
            document.body.appendChild(link);
            link.click();
        });
        
    }

    const _removeVehicles = () => {
      let checkbox = document.querySelector('.td_checkboxx');
      if(checkbox && window.confirm("Are you sure want to remove the vehicle(s)?")){
        let loader = document.getElementsByClassName('mv_loader')[0];
        let elements = document.getElementsByClassName('td_checkboxx');
        let arr = []
        for(let i=0; i<elements.length; i++){
          if(elements[i].checked === true){
            arr.push(elements[i].value);
          }
        }
        console.log(arr)

        if(arr && arr.length){
          loader.style.display = 'block';
          
          let vehtoken = props.ctr.token;
          const dataObj = {
            vinNumber: arr
          }
          const datas = postData(apiList.deleteVehicles, dataObj, vehtoken);  
          datas.then((dataVals) => {
            console.log(dataVals);
              if(dataVals.statuscode === 200){
                alertOpen();
                if(elements && elements.length){
                  for(let i=0; i<elements.length; i++){
                    if(elements[i].checked === true){
                      elements[i].checked = false;
                    }
                  }
                }
                
                setTimeout(function(){
                  loader.style.display = 'none';
                }, 1000);
              }
              props.callBack();
          });
        }
      }
    }

    const alertOpen = () => {
      setOpen(true);
    }

    const alertClose = () => {
      setOpen(false);
    }

    return (
        <div className="al_mapped_vehicles">
          <div className="container">
            <div className="row">
              <h2 className="mapped_heading">Mapped Vehicles</h2>
              {login_id ? <h6 className="alignLogin">Mapped Login ID : {login_id}</h6>:''}
              {login_id && customerName ? <h6 className="alignLogin">Customer : {customerName}</h6>:''}
              {login_id && transporterName? <h6 className="alignLogin">Transporter : {transporterName}</h6>:''}
            </div>
            <div className="mapBtn">
              {Data && Data.length ? <input type="button" className="DwnldBtn fa fa-download" onClick={getMappedVehicleList} value="Download"/>:''}
              <button className="btn DwnldBtn" onClick={_removeVehicles}>Remove</button>
            </div>
            <CircularProgress className="mv_loader" style={{left:'50%', position:'absolute', display: 'none'}} />
            {/* <dialog open="true">Vehicle(s) removed successfully.</dialog> */}
            <div className="modal active-modal" style={open === true ? {display: "block"} : {display: "none"}}>
              <div className="modal-content modal-content-success">
                <div className="row">
                    <span className="col-lg-11 col-sm-11">Vehicle(s) removed successfully</span>
                    <span className="col-lg-1 col-sm-1 close" onClick={alertClose}>&times;</span>
                </div>
              </div>
            </div>
            
            <div className="table-responsive mt-2">
              {Data && Data.length ? 
                <table className="table">
                  <thead>
                    <tr>
                      {props.vehicleData.mappingType !== 'customer' ? 
                        <th className="checkbox">
                          <input type="checkbox" id="" name="" value="" onClick={_selectAll}/>
                        </th>
                        :
                        null
                      }
                      <th className="sNo">S.No. </th>
                      <th>Vin No.</th>
                      <th>Reg No.</th>
                      <th>Subscription Valid To</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Data.map((vehicle, index) => (
                      <tr key={index}>
                        {props.vehicleData.mappingType !== 'customer' ? 
                          <td className="checkbox"><input type="checkbox" className="td_checkboxx" id="" name="" value={vehicle.vin} /></td>
                          :
                          null
                        }
                        <td className="sNo">{index+1}</td>
                        <td>{vehicle.vin}</td>
                        <td>{vehicle.regn_number}</td>
                      <td>{vehicle.valid_to ? moment(vehicle.valid_to).format('DD-MM-YYYY') : ''}</td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              :
                <div> 
                  <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div>
                  <h6>No Data Available</h6>
                </div>
              }
            </div>
          </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
  ctr: state.userdata.id
})

const mapdispatchToprops = dispatch => {
  return {
      gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload))
  };
}

export default connect(mapStateToProps, mapdispatchToprops)(MappedVehicles)






