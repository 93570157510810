import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { GmapsDispatchThunk } from '../../store/actions/GmapsActions'
import * as apiList from '../../apiList'
import { postData } from '../../Util'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Close from '@mui/icons-material/Close';
import styles from './WelcomeModal.module.css'
import ByCustomer from './ByCustomer/ByCustomer'
import ByVehicle from './ByVehicle/ByVehicle'
import ByModel from './ByModel/ByModel';
import MappedVehicles from './MappedVehicles/MappedVehicles';
import './WelcomeModal.scss';

const tabStyles = {
  marginBottom: '1rem',
  borderBottom: '1px solid #ccc',
  backgroundColor: '#427be6',
  color: '#fff'
}

export const WelcomeModal = (props) => {
  const [value, setValue] = useState(0);
  const [mappedVehiclesData, setMappedVehiclesData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [modelData, setModelData] = useState({});

  useEffect(() => {
    getMappedData();
  }, []);

  const getMappedData = () => {
    let vehtoken = props.token;
    let dataObj = {};
    const datas = postData(apiList.getMappedVehicles, dataObj, vehtoken);  // http://172.16.11.161:8000/api/aluserlogin/getMappedVehicles,
    datas.then((dataVals) => {
      console.log(dataVals);
      if(dataVals.statuscode === 200){
        setValue(0);

        if(dataVals.data && dataVals.data.vinData && dataVals.data.vinData.length){
          setMappedVehiclesData(dataVals.data);
        }
        
        if(dataVals.data.mappingType === 'vehicle'){
        }else if(dataVals.data.mappingType === 'model'){
          setModelData({
            model: dataVals.data.vpartDesc
          })
        }else if(dataVals.data.mappingType === 'customer'){
          setCustomerData({
            user_id: dataVals.data.customerUserId,
            login_id: dataVals.data.loginId,
            transporter_name: dataVals.data.transporterName,
            customer_name: dataVals.data.customerName
          })
        }
      }else{
        setMappedVehiclesData([]);
      }
    });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    props.gmapsDispatch({ showWelcomeModal: false })
  }

  const callBack = () => {
    props.rerenderParentCallback();
  }

  const callBackMapApi = () => {
    getMappedData();
    props.rerenderParentCallback();
    console.log(mappedVehiclesData);
  }

  const updateSubcription = () => {
    props.updateSubcription();
  }

  return (
    <div className={'welcomeALEmpModal ' + styles.Container}>
      <div className={styles.Modal}>
        <div className={styles.ModalHeader}>
          <h5>WELCOME {props.userName.toUpperCase()}</h5>
          <div className={styles.CloseIcon}>
            <Close onClick={handleClick}/>
          </div>
        </div>

        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="tabs"
          variant="fullWidth"
          style={tabStyles}
        >
          <Tab label="Vehicles Mapped" />
          <Tab label="By Customer" />
          <Tab label="By Vehicle" />
          <Tab label="By Model" />
        </Tabs>

        {value === 1 ? <ByCustomer callBack={callBack} customerData={customerData} /> : null}
        {value === 2 ? <ByVehicle callBack={callBack} updateSubcription={updateSubcription} /> : null}
        {value === 3 ? <ByModel callBack={callBack} modelData={modelData} /> : null}
        {value === 0 ? <MappedVehicles callBack={callBackMapApi} vehicleData={mappedVehiclesData} /> : null}

      </div>
    </div>
  )
}

const mapdispatchToprops = dispatch => {
  return {
      gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload))
  };
}

export default connect(null, mapdispatchToprops)(WelcomeModal);