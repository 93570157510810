import React, { Component } from 'react'
import {
  convertedDateFormat,
  diffTimeCalculator,
} from '../../Util/commonConverter'
import moment from 'moment'
import './NewListView.scss'
import { postData } from '../../Util'
import * as apilist from '../../apiList'
import { calculateAdBlueLevels, calculateTankPercent, calculateTankPercentBS6, formatFuelLevel, formatPercentage, getAlertCSS, getBatteryLevel, getExhaustTemp, getFuelLevelAndImage, getFuelLevelAndImageAddBlue, getFuelLevelAndImageBS6, getFuelLevelValue, getGearNumber, getStatusColors } from './Utils'
import { showExhaust, showGearUI, getGASVehicleUnit } from '../../Util/VehicleDashUtils'
import { convertDateFormat, ddmmyyyyhhmmssA, DDMMYYYY } from '../../Util/DateUtils'
const vehicleTypes = {
  1: 'BSVI',
  2: 'BSVI CNG',
  3: 'LCV',
  4: 'EV',
  0: 'BSIV'
};
function VehicleFuelLevel(props) {
  const { fuelLevel, tankpercentbs6, tankpercentaddblue, DEFTankLevel,
    fuelimg_bs6, fuelevel_bs6, fuelimg_addblue, fuelevel_addblue } = props;

  return (
    <div>
      <div className="custom-row">
        <div className="custom-col-25">
          <div className="col-one-mg text-right">
            <div className="span-text-cl span-txt-small">FUEL</div>
            <div className="span-value-cl span-txt-small">{formatFuelLevel((fuelLevel).toFixed(1))}</div>
          </div>
        </div>
        <div className="custom-col-25 custom-col-mg">
          <img className="section-four-img1" src="images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" />
          <div className={fuelimg_bs6}>
            <img className={fuelevel_bs6} src="images/Vehicle_Dashboard/Fuel_bs6.png" alt="dash" />
          </div>
          <div className="section-three-img-cent1">{formatPercentage(tankpercentbs6)}%</div>
        </div>
        <div className="custom-col-25 custom-col-mg">
          <img className="section-four-img2" src="images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
          <div className={fuelimg_addblue}>
            <img className={fuelevel_addblue} src="images/Vehicle_Dashboard/Adblue_bs6.png" alt="dash" />
          </div>
          <div className="section-three-img-cent2">{formatPercentage(tankpercentaddblue)}%</div>
        </div>
        <div className="custom-col-25">
          <div className="col-one-mg text-left">
            <div className="span-text-cl span-txt-small">DEF</div>
            <div className="span-value-cl span-txt-small">{formatFuelLevel(DEFTankLevel)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
function EVBatteryLevel(props) {
  const fuelType = 'Battery';
  const fuelLevel = (getFuelLevelValue(props.fuelLevel)) || 0;
  const tankPercent = props.tankpercent > 100 ? 100 : props.tankpercent < 0 ? 0 : props.tankpercent;
  return (
    <div>
      <div className="custom-row">
        <div className="custom-col-25">
        </div>
        <div className="custom-col-50 custom-col-mg">
          <img
            className="section-four-img4"
            src={`images/EV_Truck/${props.fuelLevel < 20 ? 'ev_0_20.png'
              : props.fuelLevel <= 40 && props.fuelLevel >= 20 ? 'ev_20_40.png'
                : props.fuelLevel <= 60 && props.fuelLevel >= 40 ? 'ev_40_60.png'
                  : props.fuelLevel <= 80 && props.fuelLevel >= 60 ? 'ev_60_80.png'
                    : props.fuelLevel > 80 ? 'ev_80_100.png' : ''}`}
            alt="dash"
          />
          <div className="section-three-img-cent">
            {tankPercent}%
          </div>
        </div>
        <div className="custom-col-25">
          <div className="col-one-mg text-left">
            <div className="span-text-cl span-txt-small">
              {fuelType}
            </div>
            <div className="span-value-cl span-txt-small">
              {fuelLevel + ' %'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function CNGVehicleFuelLevel(props) {
  const isCNG = props.data.isBS6 === 2;
  const fuelType = isCNG ? 'GAS' : 'FUEL';
  const fuelLevel = (getFuelLevelValue(props.fuelLevel)) || 0;
  const tankPercent = props.tankpercent > 100 ? 100 : props.tankpercent < 0 ? 0 : props.tankpercent;

  return (
    <div>
      <div className="custom-row">
        <div className="custom-col-25">
          {isCNG && <div className="col-one-mg1 text-right">
            <div className="span-text-cl span-txt-small span-text-cr">
              {props.data.vehicleType === 'EDC_LNG' ? 'LNG' : 'CNG'}
            </div>
          </div>}
        </div>
        <div className="custom-col-50 custom-col-mg">
          <img
            className="section-four-img3"
            src="images/Vehicle_Dashboard/BS_iv.png"
            alt="dash"
          />
          <div className={props.fuelimg}>
            <img
              className={props.fuelevel}
              src="images/Vehicle_Dashboard/BS_iv.png"
              alt="dash"
            />
          </div>
          <div className="section-three-img-cent">
            {tankPercent}%
          </div>
        </div>
        <div className="custom-col-25">
          <div className="col-one-mg text-left">
            <div className="span-text-cl span-txt-small">
              {fuelType}
            </div>
            <div className="span-value-cl span-txt-small">
              {fuelLevel + props.fuelrange}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SpeedoMeterUI(props) {
  return (
    <div className="section-one-width">
      <div className="section-middle-one">
        {/* value shown inside clock image */}
        <span>
          <img
            className="section-middle-one-img"
            src="images/NewListView/speedometer.png"
            alt="Odo"
          />
        </span>

        {(showGearUI(props.data.isBS6)) && (
          <span className="section-middle-one-nb">{props.gearNumber}</span>
        )}
         {(showGearUI(props.data.isBS6)) && (
          <span className="section-middle-text-one">GEAR</span>
        )}
        <span className="section-middle-two-nb">
          {Math.round(
            props.currentInfo != undefined && props.currentInfo.gpsSpeed
          ) || 0}
        </span>
        <span className="section-middle-text-two">KMPH</span>
        <span className="section-middle-three-nb">
          {Math.round(
            props.currentInfo != undefined && props.currentInfo.engineSpeed
          ) || 0}
        </span>
        <span className="section-middle-text-three">RPM</span>
      </div>
    </div>
  )
}

function DriverPhoneUI(props) {
  return (
    <div>
      <span>
        <img
          className="section-three-pn-image"
          onClick={props.onPhoneImageClick}
          src="images/NewListView/phone.png"
        />
      </span>
    </div>
  )
}

function HeaderDetailsUI(props) {
  return (
    <div className="custom-row">
      {props.data.driverId != '' && props.data.driverMobileNo && (
        <DriverPhoneUI onPhoneImageClick={props.onPhoneImageClick} />
      )}
      <div className="middle-three-section-one middle-one-tp">
        <div className="middle-header-col-one">
          <span className="span-text-cl">Consumption</span>
        </div>
        <div className="middle-header-col-two">
          <span className="span-text-cl">Pressure</span>
        </div>
        <div className="middle-header-col-three">
          <span className="span-text-cl">Temperature</span>
        </div>
      </div>
    </div>
  )
}
function HeaderDetailsUIEV(props) {
  const { isBS6, fuelConsumption, txt_fuelrange } = props
  const consumption = (fuelConsumption) || 0
  const fuelType = 'Energy Consumed'
  return (
    <div className="custom-row">
      {props.data.driverId != '' && props.data.driverMobileNo && (
        <DriverPhoneUI onPhoneImageClick={props.onPhoneImageClick} />
      )}
      <div className="middle-three-section-one middle-one-tp">
        <div className="middle-header-col-one">
          <span className="span-text-cl span-text-lt-mg">{fuelType}</span>
            <span className="span-value-cl">{consumption + 'kWh'}</span>
        </div>
        <div className="middle-header-col-two">
          <span className="span-text-cl">  </span>
        </div>
        <div className="middle-header-col-three">
          <span className="span-text-cl">Coolant Temp</span>
        </div>
      </div>
    </div>
  )
}

function DEFUI(props) {
  return (
    <div className="middle-two-col-lt">
      <span className="span-text-cl span-text-lt-mg">DEF</span>
      <span className="span-value-cl"> {formatFuelLevel(props.fluidUsed)}</span>
    </div>
  )
}

function BatteryEVUI(props) {
  return (
    <div className="middle-two-col-lt">
      {/* <span className="span-text-cl span-text-lt-mg">Battery Status</span>
      <span className="span-value-cl"> {formatFuelLevel(props.fluidUsed)}</span> */}
    </div>
  )
}


function OilPressureUI(props) {
  return (
    <div className="middle-two-col-lx">
      <span className="span-text-cl span-text-lt-mg">Oil</span>
      <span className="span-value-cl">
        {props.oilPressureImageValue(props.oilPressure, props.currentInfo)}
      </span>
    </div>
  )
}

function ExhaustUI(props) {
  const isShowExhaust = showExhaust(props.data.isBS6);

  return (
    <div className="middle-two-col-lh">
      <span className="span-text-cl span-text-lt-mg">Exhaust</span>
      <span className="span-value-cl">
        {isShowExhaust ? props.exhaust_temp : 'NA'}
        {isShowExhaust && props.exhaust_temp !== 'NA' && props.exhaust_temp !== '-' && (
          <span className="span-unit">&#8451;</span>
        )}
      </span>
    </div>
  );
}



function AirPressureUI(props) {
  return (
    <div className="middle-two-col-lx">
      <span className="span-text-cl span-text-lt-mg">Air</span>
      <span className="span-value-cl">
        {props.data.isBS6 === 0 &&
          (props.data.vehicleType === 'EDC' ||
            props.data.vehicleType === 'IL') ? (
          '-'
        ) : props.data.vehicleType === 'EDC_LCV' ||
          props.data.vehicleType === 'EEA_LCV' ||
          props.data.vehicleType === 'LCV' ? (
          'N/A'
        ) : (props.airPressure != '-' &&
          props.currentInfo.status == 'RUNNING') ||
          props.currentInfo.status == 'IDLING' ? (
          props.airPressure == 'GOOD' ? (
            <img
              className="span-img-cl"
              src="images/NewListView/thumps_up.png"
            />
          ) : (
            <img
              className="span-img-cl"
              src="images/NewListView/thumps_down.png"
            />
          )
        ) : (
          '-'
        )}
      </span>
    </div>
  )
}
function EVPressureUI(props) {
  return (
    <div className="middle-two-col-lx">
      <span className="span-text-cl span-text-lt-mg">  </span>
      <span className="span-value-cl">
      </span>
    </div>
  )
}

function FuelGasBatteryConsumptionUI(props) {
  const { isBS6, fuelConsumption, txt_fuelrange } = props
  const consumption = (fuelConsumption) || 0
  const fuelType = isBS6 === 2 ? 'Gas' : 'Fuel'

  return (
    <div className="middle-two-col-lt">
      <span className="span-text-cl span-text-lt-mg">{fuelType}</span>
      <span className="span-value-cl">{consumption + txt_fuelrange}</span>
    </div>
  )
}

function BatteryConsumptionUIEV(props) {
  const { isBS6, fuelConsumption, txt_fuelrange } = props
  const consumption = (fuelConsumption) || 0
  const fuelType = 'Energy Consumed'

  return (
    <div className="middle-two-col-lt">
      <span className="span-text-cl span-text-lt-mg">{fuelType}</span>
      <span className="span-value-cl">{consumption + 'kWh'}</span>
    </div>
  )
}

function CoolantUi(props) {
  return (
    <div className="middle-two-col-lh">
      <span className="span-text-cl span-text-lt-mg">Coolant</span>
      <span className="span-value-cl">
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING'
          ? props.currentInfo.eng_coolant_temp
          : '-'}
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING' ? (
          <span className="span-unit">&#8451;</span>
        ) : null}
      </span>
    </div>
  )
}

function TemperatureUIEV(props) {
  return (
    <div className="middle-two-col-lh Main_Coolant_position">
      <span className="span-text-cl span-text-lt-mg">Main</span>
      <span className="span-value-cl">
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING'
          ? props.currentInfo.eng_coolant_temp
          : '-'}
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING' ? (
          <span className="span-unit">&#8451;</span>
        ) : null}
      </span>
    </div>
  )
}

function BatteryCoolantUIEV(props) {
  return (
    <div className="middle-two-col-lh Battery_Coolant_position">
      <span className="span-text-cl span-text-lt-mg">Battery Out </span>
      <span className="span-value-cl">
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING'
          ? props.currentInfo.aft1_exhaust_temp_1
          : '-'}
        {props.currentInfo.status == 'RUNNING' ||
          props.currentInfo.status == 'IDLING' ? (
          <span className="span-unit">&#8451;</span>
        ) : null}
      </span>
    </div>
  )
}

function ConsumptionUI(props) {
  return (
    <div className="section-three-width">
      <HeaderDetailsUI
        data={props.data}
        onPhoneImageClick={props.onPhoneImageClick}
      />
      <div className="custom-row">
        <div className="middle-three-section-two middle-two-tp">
          <FuelGasBatteryConsumptionUI
            isBS6={props.data.isBS6}
            fuelConsumption={getFuelLevelValue(props.currentInfo.fuelConsumption)}
            txt_fuelrange={props.txt_fuelrange}
          />
          <AirPressureUI
            data={props.data}
            airPressure={props.airPressure}
            currentInfo={props.currentInfo}
          />
          {/* <div className="middle-two-col-lx"><span className="span-text-cl span-text-lt-mg">Air</span><span className="span-value-cl">{ data.vehicleType === 'EDC_LCV' || data.vehicleType === 'EEA_LCV' ?  '-' :  this.airPressure != '-' && currentInfo.status == "RUNNING" || currentInfo.status == "IDLING"?this.airPressure == "GOOD"?<img className="span-img-cl" src="images/NewListView/thumps_up.png" />:<img  className="span-img-cl" src="images/NewListView/thumps_down.png" />:"-"}</span></div> */}
          <CoolantUi currentInfo={props.currentInfo} />
        </div>
      </div>

      <div className="custom-row">
        <div className="middle-three-section-three middle-three-tp">
          <DEFUI fluidUsed={props.fluidUsed} />
          <OilPressureUI
            oilPressureImageValue={props.oilPressureImageValue}
            oilPressure={props.oilPressure}
            currentInfo={props.currentInfo}
          />
          <ExhaustUI
            data={props.data}
            exhaust_temp={props.exhaust_temp}
          ></ExhaustUI>
        </div>
      </div>
    </div>
  )
}
function CoolanTemparatureEVUI(props) {

  return (
    <div className="middle-two-col-lh">
      <span className="span-text-cl span-text-lt-mg">Coolant</span>
      <span className="span-value-cl">
        {props.exhaust_temp}
        {props.exhaust_temp !== 'NA' && props.exhaust_temp !== '-' && (
          <span className="span-unit">&#8451;</span>
        )}
      </span>
    </div>
  );
}

function ConsumptionUIEV(props) {
  return (
    <div className="section-three-width">
      <HeaderDetailsUIEV
        data={props.data}
        onPhoneImageClick={props.onPhoneImageClick}
        fuelConsumption={getFuelLevelValue(props.currentInfo.fuelConsumption)}
      />
      <div className="custom-row">
        <div className="middle-three-section-two middle-two-tp">
          {/* <BatteryConsumptionUIEV
            isBS6={props.data.isBS6}
            fuelConsumption={getFuelLevelValue(props.currentInfo.fuelConsumption)}
            txt_fuelrange={props.txt_fuelrange}
          /> */}
          {/* <EVPressureUI /> <br/> */}
          <div className="middle-two-col-lx"><span className="span-text-cl span-text-lt-mg">Air Pressure</span>
            <span className="span-value-cl">{props.data.vehicleType === 'EDC_LCV' || props.data.vehicleType === 'EEA_LCV' ? '-'
              : props.airPressure != '-' && props.currentInfo.status == "RUNNING" || props.currentInfo.status == "IDLING" ? props.airPressure == "GOOD"
                ? <img className="span-img-cl" src="images/NewListView/thumps_up.png" />
                : <img className="span-img-cl" src="images/NewListView/thumps_down.png" /> : "-"}
            </span></div>
            <div className="middle-two-col-lx"><span className="span-text-cl span-text-lt-mg"></span>
            <span className="span-value-cl">
              {/* {props.data.vehicleType === 'EDC_LCV' || props.data.vehicleType === 'EEA_LCV' ? '-'
              : props.airPressure != '-' && props.currentInfo.status == "RUNNING" || props.currentInfo.status == "IDLING" ? props.airPressure == "GOOD"
                ? <img className="span-img-cl" src="images/NewListView/thumps_up.png" />
                : <img className="span-img-cl" src="images/NewListView/thumps_down.png" /> : "-"} */}
            </span></div>
          <TemperatureUIEV currentInfo={props.currentInfo} />
        </div>
      </div>

      <div className="custom-row">
        <div className="middle-three-section-three middle-three-tp">
          <BatteryEVUI fluidUsed={props.fluidUsed} />
          <EVPressureUI />
          {/* <CoolanTemparatureEVUI
            data={props.data}
            exhaust_temp={props.exhaust_temp}
          ></CoolanTemparatureEVUI> */}
          {/* <BatteryCoolantUIEV currentInfo={props.currentInfo} /> */}
        </div>
      </div>
    </div>
  )
}

function OdoMeterUI(props) {
  const { vehicleType, dropdownValue, data, gps_odo, gpsSpeed, vehicle_odo } = props
  const isLCV = vehicleType === 'EDC_LCV' || vehicleType === 'EEA_LCV'
  const isEV = data.isBS6 === 4

  let odometer
  if (isLCV) {
    odometer = Math.round(gps_odo)
  } else if (isEV) {
    odometer = Math.round(vehicle_odo)
  } else {
    odometer =
      dropdownValue === 'myTerritory'
        ? gpsSpeed(data, dropdownValue)
        : gpsSpeed(data)
  }

  return (
    <div className="middle-two-col-ft">
      <span className="span-text-cl span-text-lt-mg">Odometer</span>
      <span className="span-value-cl">{odometer} km</span>
    </div>
  )
}

function VInUI(props) {
  return (
    <div className="middle-two-col-ext">
      <span className="span-text-cl span-text-lt-mg">Vin</span>
      <span className="span-value-cl">{props.vinNumber}</span>
    </div>
  )
}

function ObuIdUI(props) {
  return (
    <div className="middle-two-col-lk">
      <span className="span-text-cl span-text-lt-mg">Obu Id</span>
      <span className="span-value-cl">{props.obuId}</span>
    </div>
  )
}

function AltitudeUI(props) {
  const altitude = props.currentInfo?.altitude

  return (
    <div className="middle-two-col">
      <span className="span-text-cl span-text-lt-mg">Altitude</span>
      <span className="span-value-cl">
        {altitude !==null ? `${altitude.toFixed(2)} m` : 'N/A'}
      </span>
    </div>
  )
}

function EngineRowUI(props) {
  const { isBS6, engineHrs } = props
  return (
    <div className="middle-two-col-ft">
      <span className="span-text-cl span-text-lt-mg">{isBS6 === 4 ? 'Distance to Empty ' : 'Engine Hrs'}</span>
      <span className="span-value-cl">
        {isBS6 === 4 ? engineHrs + ' km' && engineHrs + ' km': engineHrs && parseFloat(engineHrs).toFixed(2) + ' hrs'}
      </span>
    </div>
  )
}

function VehicleTypeUI(props) {
  return (
    <div className="middle-two-col-ext">
      <span className="span-text-cl span-text-lt-mg">Vehicle Type</span>
      <span className="span-value-cl">{props.vehicleType}</span>
    </div>
  )
}

function VehicleModelUI(props) {
  return (
    <div className="middle-two-col-lk">
      <span className="span-text-cl span-text-lt-mg">Model</span>
      <span className="span-value-cl">{props.vehicleModel}</span>
    </div>
  )
}

function BatteryUI(props) {
  return (
    <div className="middle-two-col">
      <div>
        {' '}
        <span className="span-text-cl span-text-lt-mg">Battery</span>
        <span className="span-value-cl">
          {props.battPotential.toFixed(2) + ' v' || 0}
        </span>
        {/* <img className="span-three-img-cl" src="images/NewListView/battery.png" alt="battery" /> */}
      </div>
      {/* <div className={batimg}>
                     <img  className={batlevel} src="images/NewListView/battery.png" alt="dash" />
                 </div> */}
    </div>
  )
}

class NewListView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowData: this.props.data,
      rowIndex: this.props.rowIndex,
      showContainerTwo: false,
      editedRegNo: '',
      editIndex: '',
      selectedVinNo: '',
      changeRegNo: '',
    }
    this.differenceDays = 0
    this.dropdownValue = this.props.gmapsState.dropdownValue
    this.differenceDataCaluclation()

    this.oilPressure =
      this.dropdownValue === 'myTerritory'
        ? this.props.oilPressure(this.props.data, this.dropdownValue)
        : this.props.oilPressure(this.props.data)
    this.airPressure =
      this.dropdownValue === 'myTerritory'
        ? this.props.airPressure(this.props.data, this.dropdownValue)
        : this.props.airPressure(this.props.data)
    this.DEFTankLevel =
      this.dropdownValue === 'myTerritory'
        ? this.props.catalystTankLevel(this.props.data, this.dropdownValue)
        : this.props.catalystTankLevel(this.props.data)
    this.fluidUsed =
      this.dropdownValue === 'myTerritory'
        ? this.props.fluidUsed(this.props.data, this.dropdownValue)
        : this.props.fluidUsed(this.props.data)
    let gear =
      this.dropdownValue === 'myTerritory'
        ? this.props.data.gearnumber
        : this.props.data.currentInfo.gearnumber
    this.gearNumber = getGearNumber(gear)
  }

  oilPressureImageValue = (oilPressure, currentInfo) => {
    if (
      oilPressure !== '-' &&
      oilPressure !== 'N/A' &&
      (currentInfo.status === 'RUNNING' || currentInfo.status === 'IDLING')
    ) {
      return oilPressure === 'GOOD' ? (
        <img className="span-img-cl" src="images/NewListView/thumps_up.png" />
      ) : (
        <img className="span-img-cl" src="images/NewListView/thumps_down.png" />
      )
    } else {
      return oilPressure
    }
  }
  differenceDataCaluclation() {
    //days calaculation to show vaildity days
    let currentInfo =
      this.dropdownValue == 'myTerritory'
        ? this.props.data
        : this.props.data.currentInfo
    if (this.props.data != undefined && currentInfo != undefined) {
      var start = moment(new Date())
      var end = moment(this.props.data.validTo)
      this.differenceDays = end.diff(start, 'days') + 1 //calcualting with end date
    }
  }

  onPhoneImageClick = () => {
    this.setState({
      showContainerTwo: this.state.showContainerTwo == true ? false : true,
    })
  }

  editRegno = (regId, vinNo, key) => {
    this.props.updateClassicEditIndex(key)
    this.setState({
      selectedVinNo: vinNo,
      editedRegNo: regId,
      editIndex: key,
    })
  }

  getEveReg = (e) => {
    let typedRedNo = e.target.value.toUpperCase()
    this.setState({
      changeRegNo: typedRedNo,
    })
  }

  sendReg_VinNo = async (oldRegNo) => {
    const selectedItem = this.props.gmapsState.markers.filter(
      (item) => item.regnNumber === this.state.changeRegNo
    )
    if (selectedItem.length <= 0) {
      selectedItem.regnNumber = this.state.changeRegNo // to update reg no

      const dataObj = {
        vinNumber: this.state.selectedVinNo,
        regn_no: this.state.changeRegNo,
      }
      const vehtoken = this.props.classicToken
      try {
        const { statuscode } = await postData(
          apilist.updateRegistrationNumber,
          dataObj,
          vehtoken
        )
        if (statuscode === 200) {
          this.props.updateClassicEditIndex('')
          this.setState({
            editIndex: '',
            editedRegNo: this.state.changeRegNo,
          })
          const data = this.props.gmapsState.markers.find(
            (item) => item.regnNumber === oldRegNo
          )
          data.regnNumber = this.state.changeRegNo
          const text = 'Reg Number Updated Successfully.'
          document.querySelector('.save_modal_message').textContent = text
          document.getElementById('saved_user_data_modal').style.display =
            'block'
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      const text = 'Check Your Register No.'
      document.querySelector('.error_modal_message').textContent = text
      document.getElementById('error_data_submit_modal').style.display = 'block'
    }
  }

  close_error_data_submit_modal(e) {
    let modal = document.getElementById('error_data_submit_modal')
    modal.style.display = 'none'
  }

  close_saved_user_data_modal(e) {
    let modal = document.getElementById('saved_user_data_modal')
    modal.style.display = 'none'
  }

  tankComponent(isBS6, DEFTankLevel, data, tankpercentbs6, tankpercentaddblue, fuelevel_bs6, fuelimg_bs6, fuelevel_addblue, fuelimg_addblue, currentInfo, fuelrange, fuelevel, fuelimg, tankpercent) {
    if (isBS6 === 1 || (isBS6 === 3 && DEFTankLevel !== 'N/A')) {
      return (
        <VehicleFuelLevel
          DEFTankLevel={DEFTankLevel}
          tankpercentbs6={tankpercentbs6}
          tankpercentaddblue={tankpercentaddblue}
          fuelevel_bs6={fuelevel_bs6}
          fuelimg_bs6={fuelimg_bs6}
          fuelevel_addblue={fuelevel_addblue}
          fuelimg_addblue={fuelimg_addblue}
          fuelLevel={currentInfo.fuelLevel}
        />
      );
    } else if (isBS6 === 4) {
      return (
        <EVBatteryLevel
          data={data}
          tankpercent={tankpercent}
          fuelevel={fuelevel}
          fuelimg={fuelimg}
          fuelLevel={currentInfo.fuelLevel}
          fuelrange={fuelrange}
        />
      );
    } else {
      return (
        <CNGVehicleFuelLevel
          data={data}
          tankpercent={tankpercent}
          fuelevel={fuelevel}
          fuelimg={fuelimg}
          fuelLevel={currentInfo.fuelLevel}
          fuelrange={fuelrange}
        />
      );
    }
  }


  render() {
    const fontSizeAlert = {
      fontSize: 14,
    }
    const data = this.state.rowData
    let tankpercentbs6 = 0
    let tankpercent = 0
    let tankpercentaddblue = 0
    let tantpercentadbluelevel = 0
    let fuelevel_bs6 = ''
    let fuelimg_bs6 = ''
    let fuelevel_addblue = ''
    let fuelimg_addblue = ''
    let fuelevel = ''
    let fuelimg = ''
    let batterypercent = 0
    let localTimeStamp = ''
    let statusAge = ''
    let currentInfo =
      this.dropdownValue == 'myTerritory' ? data : data.currentInfo
    if (currentInfo.localTimeStamp) {
      localTimeStamp = convertedDateFormat(currentInfo.localTimeStamp)
      if (
        this.dropdownValue == 'myTerritory' &&
        this.props.viewType == 'Dealer'
      ) {
        statusAge = diffTimeCalculator(localTimeStamp)
      } else {
        if (currentInfo.status == 'DISCONNECTED') {
          statusAge = currentInfo.statusAge
        } else {
          if (currentInfo.status == 'STOPPED') {
            statusAge = currentInfo.statusAge
              ? currentInfo.statusAge
              : diffTimeCalculator(localTimeStamp)
          } else {
            statusAge = currentInfo.statusAge ? currentInfo.statusAge : ''
          }
        }
      }
    }

    if (data.isBS6 === 1 || (data.isBS6 === 3 && this.DEFTankLevel !== 'N/A')) {
      // Example usage:
      tankpercentbs6 = calculateTankPercentBS6(data, currentInfo);

      // Example usage:
      const { fuelLevelBs6, fuelImageBs6 } = getFuelLevelAndImageBS6(tankpercentbs6);
      fuelevel_bs6 = fuelLevelBs6
      fuelimg_bs6 = fuelImageBs6
      if (tankpercentbs6 > 100) {
        tankpercentbs6 = 100
      }

      if (data.vehicleType === 'EDC_LCV' || data.vehicleType === 'EEA_LCV') {
        tankpercentaddblue = 80
        this.DEFTankLevel = Math.round((data.adBlueTankCap / 100) * 80)
      } else {
        tankpercentaddblue = Math.round(currentInfo.catalyst_tank_level)
        tantpercentadbluelevel = Math.round(
          (currentInfo.catalyst_tank_level / 100) * data.adBlueTankCap
        )
      }
      // let {tankpercentaddblue, tantpercentadbluelevel} = calculateAdBlueLevels(data);
      const { fuelLevelClass, fuelImageClass } = getFuelLevelAndImageAddBlue(tankpercentaddblue);
      fuelevel_addblue = fuelLevelClass;
      fuelimg_addblue = fuelImageClass

      if (tankpercentaddblue > 100) {
        tankpercentaddblue = 100
        this.DEFTankLevel = parseInt(data.adBlueTankCap)
      }
      //End of Add Blue//
    } else {
      tankpercent = calculateTankPercent(data, currentInfo);
      const { fuelLevel, fuelImage } = getFuelLevelAndImage(tankpercent);
      fuelevel = fuelLevel
      fuelimg = fuelImage
      if (tankpercent > 100) {
        tankpercent = 100
      }
    }

    //Battery Logic//
    var batterythreshold
    if (data.batteryType === '12V Battery') {
      batterythreshold = 18
    } else {
      batterythreshold = 32
    }
    // Usage:
    const batteryPercent = (currentInfo.battPotential / batterythreshold) * 100
    const { batlevel, batimg } = getBatteryLevel(batteryPercent)
    // Usage
    let { statusColor, statusTextColor } = getStatusColors(currentInfo.status);

    let criticalInfo =
      this.dropdownValue == 'myTerritory'
        ? this.props.getSeverity(data, this.dropdownValue)
        : this.props.getSeverity(data)
    let alertCSS = getAlertCSS(criticalInfo);

    let fuelrange = ' ltr'
    let txt_fuelrange = ' ltr'
    if (data.isBS6 == 2) {
      fuelrange = getGASVehicleUnit(data.vehicleType)
      txt_fuelrange = ' kg'
    }

    let exhaust_temp = getExhaustTemp(data, currentInfo)

    return (
      <>
        <div id="error_data_submit_modal" className="modal">
          <div id="modal-content" className="modal-content modal-content-error">
            <div className="row">
              <span className="col-lg-11 col-sm-11 error_modal_message"></span>
              <span
                className="col-lg-1 col-sm-1 close"
                onClick={this.close_error_data_submit_modal.bind(this)}
              >
                &times;
              </span>
            </div>
          </div>
        </div>
        <div id="saved_user_data_modal" className="modal">
          <div
            id="modal-content"
            className="modal-content modal-content-success"
          >
            <div className="row">
              <span
                className="col-lg-11 col-sm-11 save_modal_message"
                style={fontSizeAlert}
              ></span>
              <span
                className="col-lg-1 col-sm-1 close"
                onClick={this.close_saved_user_data_modal.bind(this)}
              >
                &times;
              </span>
            </div>
          </div>
        </div>

        <div
          key={this.state.rowIndex}
          className="newListView-row"
          id={'new_list_view_' + this.state.rowIndex}
        >
          <div
            className={
              this.state.showContainerTwo == false
                ? 'container-body'
                : 'container-body-ext'
            }
          >
            {/* top header of container */}
            <div className="custom-row">
              <div className="col-one">
                <div className="header-one classic_vi_editregno">
                  <div>
                    {currentInfo.status === 'RUNNING' ||
                      currentInfo.status === 'IDLING' ? (
                      <span
                        className="onStatus-on"
                        style={{ display: 'inline-block' }}
                      >
                        ON
                      </span>
                    ) : (
                      <span
                        className="onStatus-off"
                        style={{ display: 'inline-block' }}
                      >
                        OFF
                      </span>
                    )}
                    {data.isBS6 === 1 || data.isBS6 === 2 || data.isBS6 === 3 ? (
                      <span>
                        <img
                          className="header-one-img"
                          src="images/NewListView/bsvi_logo.png"
                        />
                      </span>
                    ) : (<span className='span-value-cl'> {vehicleTypes[data.isBS6] + " "}</span>)}
                    <span
                      className="header-one-span"
                      onClick={(e) => {
                        this.props.onLinkClick(data, true)
                      }}
                    ></span>

                    {this.props.classicEditIndex !== this.state.rowIndex ? (
                      <span
                        onClick={(e) => {
                          this.props.onLinkClick(data, true)
                        }}
                      >
                        {data.regnNumber.replace(
                          data.regnNumber.substring(
                            2,
                            data.regnNumber.length - 6
                          ),
                          '...'
                        )}
                      </span>
                    ) : (
                      <input
                        className="input_wid"
                        minLength={6}
                        maxLength={10}
                        onChange={(e) => this.getEveReg(e)}
                        defaultValue={
                          data.regnNumber.length > 16
                            ? data.regnNumber.replace(
                              data.regnNumber.substring(
                                2,
                                data.regnNumber.length - 6
                              ),
                              '...'
                            )
                            : data.regnNumber
                        }
                      />
                    )}
                  </div>
                  <div className="edit_ok_icon">
                    {this.props.classicEditIndex !== this.state.rowIndex &&
                      this.props.userRole !== 'AL EMPLOYEE' ? (
                      <i
                        class="fa fa-pencil edit_icon"
                        id={data.regnNumber}
                        onClick={(e) => {
                          this.editRegno(
                            data.regnNumber,
                            data.currentInfo.vinNumber,
                            this.state.rowIndex
                          )
                        }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      this.state.changeRegNo.length > 6 && (
                        <i
                          class="fa fa-check edit_icon"
                          onClick={(e) => {
                            this.sendReg_VinNo(data.regnNumber)
                          }}
                          aria-hidden="true"
                        ></i>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-two">
                <div className="header-two">
                  {/* clock image  */}
                  <div className="custom-row custom-row-ht">
                    <div className={'header-two-section3 ' + statusColor}>
                      <div className="header-two-sub">
                        <span
                          className={
                            'section-middle-text-four ' + statusTextColor
                          }
                        >
                          {currentInfo.status}
                        </span>
                        <span
                          className={
                            'section-middle-four-nb ' + statusTextColor
                          }
                        >
                          {statusAge}
                        </span>
                      </div>
                    </div>
                    <div className="header-two-section1">
                      <img
                        className="header-two-img1"
                        src="images/NewListView/clock_wt.png"
                        alt="clock"
                      />
                      <div class="header-date">
                        {convertDateFormat(localTimeStamp, ddmmyyyyhhmmssA)}
                      </div>
                    </div>
                    <div
                      className="header-section-two"
                      onClick={(e) => {
                        this.props.onLinkClick(data, true)
                      }}
                    >
                      <a className="header-two-section2">
                        <img
                          className="header-two-img"
                          src="images/NewListView/pin_wt.png"
                          alt="pin"
                        />
                        <span>
                          {currentInfo.location != '<null>'
                            ? currentInfo.location
                            : ''}
                        </span>
                      </a>
                    </div>
                    {this.props.userId === 6 ||
                      this.props.userId === 8 ||
                      this.props.userRole === 'AL EMPLOYEE' ? (
                      <span
                        style={{
                          padding: '0px 15px 0px 0px',
                          color: '#fff',
                          cursor: 'pointer',
                          width: '130px',
                        }}
                        onClick={(e) => {
                          this.props.share_click(data)
                        }}
                      >
                        <i
                          className="fa fa fa-share-alt"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;Share Location
                      </span>
                    ) : (
                      ''
                    )}
                    <div className="header-section-three">
                      {criticalInfo && (
                        <div className={alertCSS}>{criticalInfo}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="header-three">

                    {"Validity:"+this.differenceDays+" Days Left"}
                </div> */}
            <div className="custom-row">
              <SpeedoMeterUI
                gearNumber={this.gearNumber}
                data={data}
                currentInfo={currentInfo}
              ></SpeedoMeterUI>
              <div className="section-two-width">
                <div className="custom-row">
                  {/* First data row showing  */}
                  <div
                    className={
                      this.props.autoreload
                        ? 'middle-section-one middle-one-tp1'
                        : 'middle-section-one middle-one-tp'
                    }
                  >
                    {/* <div className="middle-header-col-one"><span className="span-text-cl">Consumption</span></div>
                                <div className="middle-header-col-two"><span className="span-text-cl">Pressure</span></div>
                                <div className="middle-header-col-three"><span className="span-text-cl">Temperature</span></div> */}
                    <div className="middle-four-col-one">
                      <span className="span-text-cl span-text-lt-mg">
                        Subscription Period
                      </span>
                      <span className="span-value-cl">
                        {convertDateFormat(data.validFrom, DDMMYYYY) +
                          ' to ' +
                          convertDateFormat(data.validTo, DDMMYYYY)}
                      </span>
                    </div>
                    <div className="middle-two-span-txt">
                      <span
                        className={
                          this.differenceDays <= 30
                            ? 'middle-two-rd'
                            : 'normal-bl'
                        }
                      >
                        {'Validity : ' + this.differenceDays + ' Days Left'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="custom-row">
                  <div
                    className={
                      this.props.autoreload
                        ? 'middle-section-two middle-two-tp1'
                        : 'middle-section-two middle-two-tp'
                    }
                  >
                    <OdoMeterUI
                      dropdownValue={this.dropdownValue}
                      gpsSpeed={this.props.gpsSpeed}
                      data={data}
                      gps_odo={currentInfo.gps_odo}
                      vehicle_odo={currentInfo.vehicle_odo}
                    ></OdoMeterUI>
                    <VInUI vinNumber={data.vinNumber}></VInUI>
                    <ObuIdUI obuId={currentInfo.obuId}></ObuIdUI>
                    <AltitudeUI currentInfo={currentInfo}></AltitudeUI>
                  </div>
                </div>

                <div className="custom-row">
                  <div
                    className={
                      this.props.autoreload
                        ? 'middle-section-three middle-three-tp1'
                        : 'middle-section-three middle-three-tp'
                    }
                  >
                    <EngineRowUI engineHrs={data.isBS6 === 4 ? currentInfo.dist_to_empty : currentInfo.engineHrs} isBS6={data.isBS6} />
                    {/* <div className="middle-two-col-lt"><span className="span-text-cl span-text-lt-mg">DEF</span><span className="span-value-cl"> {this.props.fluidUsed(data)}</span></div>
                                <div className="middle-two-col-lx"><span className="span-text-cl span-text-lt-mg">Oil</span><span className="span-value-cl">{this.oilPressure == '-'?'-':this.oilPressure == "GOOD"?<img className="span-img-cl" src="images/NewListView/thumps_up.png" />:<img  className="span-img-cl" src="images/NewListView/thumps_down.png" />}</span></div>
                                <div className="middle-two-col-lh"><span className="span-text-cl span-text-lt-mg">Exhaust</span><span className="span-value-cl">{currentInfo.aft1_exhaust_temp_1 == null?'N/A':Math.round(currentInfo.aft1_exhaust_temp_1)}</span></div> */}
                    <VehicleTypeUI vehicleType={data.vehicleType} />
                    <VehicleModelUI vehicleModel={data.vehicleModel} />
                    <BatteryUI battPotential={currentInfo.battPotential} />
                  </div>
                </div>

                <div className="custom-row">
                  <div className="middle-section-four">
                    {/* fourth data row showing  */}
                    {/* <div className="middle-four-col-one"><span className="span-text-cl span-text-lt-mg">Subscription Period</span><span className="span-value-cl">{moment(data.validFrom).format("DD-MM-YYYY")+" - "+ moment(data.validTo).format("DD-MM-YYYY")}</span></div> */}
                    {this.props.autoreload == true ? (
                      <div className="middle-four-col-two">
                        {this.props.viewType == 'Dealer' ? (
                          <>
                            <span className="span-text-cl span-text-lt-mg">
                              Customer Name & Contact
                            </span>
                            <span className="span-value-cl">
                              {' '}
                              {data.customerName +
                                ' & ' +
                                data.customerMobileNo}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="span-text-cl span-text-lt-mg">
                              Geofence Name
                            </span>
                            <span className="span-value-cl">
                              {' '}
                              {data.geofencenames !== undefined
                                ? data.geofencenames
                                : 'NA'}
                            </span>
                          </>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              {data.isBS6 === 4 ? <ConsumptionUIEV
                onPhoneImageClick={this.onPhoneImageClick}
                airPressure={this.airPressure}
                fluidUsed={this.fluidUsed}
                oilPressureImageValue={this.oilPressureImageValue}
                oilPressure={this.oilPressure}
                data={data}
                currentInfo={currentInfo}
                txt_fuelrange={txt_fuelrange}
                exhaust_temp={exhaust_temp}
              ></ConsumptionUIEV>
                : <ConsumptionUI onPhoneImageClick={this.onPhoneImageClick}
                  airPressure={this.airPressure}
                  fluidUsed={this.fluidUsed}
                  oilPressureImageValue={this.oilPressureImageValue}
                  oilPressure={this.oilPressure}
                  data={data}
                  currentInfo={currentInfo}
                  txt_fuelrange={txt_fuelrange}
                  exhaust_temp={exhaust_temp}
                ></ConsumptionUI>}
              <div
                className={
                  data.isBS6 === 2
                    ? 'section-four-width hightlight_cng'
                    : 'section-four-width'
                }
              >
                {/* fuel tank image showing  */}
                {this.tankComponent(data.isBS6, this.DEFTankLevel, data, tankpercentbs6, tankpercentaddblue, fuelevel_bs6, fuelimg_bs6, fuelevel_addblue, fuelimg_addblue, currentInfo, fuelrange, fuelevel, fuelimg, tankpercent)}
                {/* {data.isBS6 === 1 || (data.isBS6 === 3 && this.DEFTankLevel !== 'N/A') ? (
                  <VehicleFuelLevel
                    DEFTankLevel={this.DEFTankLevel}
                    tankpercentbs6={tankpercentbs6}
                    tankpercentaddblue={tankpercentaddblue}
                    fuelevel_bs6={fuelevel_bs6}
                    fuelimg_bs6={fuelimg_bs6}
                    fuelevel_addblue={fuelevel_addblue}
                    fuelimg_addblue={fuelimg_addblue}
                    fuelLevel={currentInfo.fuelLevel}
                  ></VehicleFuelLevel>
                ) : data.isBS6 === 4 ? (
                <EVBatteryLevel data={data} tankpercent={tankpercent}
                  fuelevel={fuelevel}
                  fuelimg={fuelimg}
                  fuelLevel={currentInfo.fuelLevel}
                  fuelrange={fuelrange}/> )
                  : (
                  <CNGVehicleFuelLevel
                    data={data}
                    tankpercent={tankpercent}
                    fuelevel={fuelevel}
                    fuelimg={fuelimg}
                    fuelLevel={currentInfo.fuelLevel}
                    fuelrange={fuelrange}
                  ></CNGVehicleFuelLevel>
                )} */}
              </div>
            </div>
          </div>
          {this.state.showContainerTwo && (
            <div className="container-body-two">
              <div className="custom-row">
                <div className="custom-col-ext"></div>
                <div className="custom-col-50">
                  <span className="span-text-cl span-text-lt-mg">
                    Driver Name
                  </span>
                  <span className="span-value-cl"> {data.driverName}</span>
                  <span className="span-text-cl span-text-lt-mg span-custom-txt">
                    Phone Number
                  </span>
                  <span className="span-value-cl">{data.driverMobileNo}</span>
                </div>
                <div className="custom-col-25"></div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default NewListView
