// Import the original App component
import App from '../App';  // Adjust the path as needed

import { useNavigate, useLocation } from 'react-router-dom';

function AppWithNavigate(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <App {...props} navigate={navigate} location={location} />;
}

export default AppWithNavigate;
