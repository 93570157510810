// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ul-class {
  list-style-type: none;
  padding: 10px 0px 0px 10px;
}

.table-insights > tbody > tr {
  line-height: 5px;
}

.table-top-bottom > tbody > tr {
  line-height: 5px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Chatbot/Result/Answers/Answer.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".ul-class{\r\n    list-style-type: none;\r\n    padding: 10px 0px 0px 10px;\r\n}\r\n\r\n.table-insights > tbody > tr{\r\n    line-height: 5px;\r\n}\r\n\r\n.table-top-bottom > tbody > tr{\r\n    line-height: 5px;\r\n    font-size: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
