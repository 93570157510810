// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 10px;
  margin: 5px;
  width: 10px;
  border-radius: 10px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }
  50% {
    background-color: #4279e0;
    transform: scale(1.3);
  }
  100% {
    background-color: grey;
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Chatbot/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAUE;EACE,sCAAA;EACA,sBAAA;EACA,qBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;AAPJ;;AAUE;EACE,qBAAA;AAPJ;;AAUE;EACE,qBAAA;AAPJ;;AAUE;EACE;IAAK,sBAAA;IAAwB,mBAAA;EAL/B;EAME;IAAM,yBAAA;IAA2B,qBAAA;EAFnC;EAGE;IAAO,sBAAA;IAAwB,mBAAA;EACjC;AACF","sourcesContent":[".loading {\r\n\r\n    position: relative;\r\n    margin-top: 10px;\r\n    margin-bottom: 10px;\r\n    // bottom: 31px;\r\n    // position: absolute;\r\n    // margin-bottom: 0px;\r\n    // background: #fff;\r\n    // z-index: 1;\r\n    // width: 96%;\r\n    // height: 20px;\r\n  }\r\n  \r\n  .loading__dot {\r\n    animation: dot ease-in-out 1s infinite;\r\n    background-color: grey;\r\n    display: inline-block;\r\n    height: 10px;\r\n    margin: 5px;\r\n    width: 10px;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .loading__dot:nth-of-type(2) {\r\n    animation-delay: 0.2s;\r\n  }\r\n  \r\n  .loading__dot:nth-of-type(3) {\r\n    animation-delay: 0.3s;\r\n  }\r\n  \r\n  @keyframes dot {\r\n    0% { background-color: grey; transform: scale(1); }\r\n    50% { background-color: #4279e0; transform: scale(1.3); }\r\n    100% { background-color: grey; transform: scale(1); }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
