// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferList_SearchFieldContainer__3XQ2F {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: .6rem;
  justify-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/ByModel/TransferList/TransferList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;EACpB,qBAAqB;AACvB","sourcesContent":[".SearchFieldContainer {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n  margin-bottom: .6rem;\r\n  justify-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchFieldContainer": `TransferList_SearchFieldContainer__3XQ2F`
};
export default ___CSS_LOADER_EXPORT___;
