// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcomeALEmpModal .MuiButton-containedSecondary:hover {
  background-color: #427be6;
}
.welcomeALEmpModal .MuiTab-root, .welcomeALEmpModal label, .welcomeALEmpModal span, .welcomeALEmpModal input, .welcomeALEmpModal button, .welcomeALEmpModal li {
  font-family: "Calibri" !important;
  font-size: 14px !important;
}
.welcomeALEmpModal .MuiTabs-root button, .welcomeALEmpModal .MuiTabs-root span {
  color: #fff;
  opacity: 0.7;
}
.welcomeALEmpModal .MuiTabs-root .Mui-selected {
  color: #fff !important;
  opacity: 1;
}
.welcomeALEmpModal button.MuiAutocomplete-popupIndicator, .welcomeALEmpModal button.MuiAutocomplete-clearIndicatorDirty {
  background-color: transparent;
}
.welcomeALEmpModal button.MuiAutocomplete-popupIndicator:hover, .welcomeALEmpModal button.MuiAutocomplete-clearIndicatorDirty:hover {
  background-color: #ebebeb;
}
.welcomeALEmpModal .MuiTabs-indicator {
  height: 5px;
  border-radius: 1px;
  background-color: #fff;
}
.welcomeALEmpModal .MuiCheckbox-colorSecondary.Mui-checked {
  color: #427be6;
}
.welcomeALEmpModal .mappedVehiclesTable td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.MuiAutocomplete-popper li {
  font-family: "Calibri" !important;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/WelcomeModal.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;AADR;AAII;EACI,iCAAA;EACA,0BAAA;AAFR;AAMQ;EACI,WAAA;EACA,YAAA;AAJZ;AAMQ;EACI,sBAAA;EACA,UAAA;AAJZ;AAWQ;EACE,6BAAA;AATV;AAYQ;EACE,yBAAA;AAVV;AAcI;EACI,WAAA;EACA,kBAAA;EACA,sBAAA;AAZR;AAeI;EACI,cAAA;AAbR;AAkBQ;EACI,oBAAA;EACA,uBAAA;AAhBZ;;AAqBA;EACI,iCAAA;EACA,eAAA;AAlBJ","sourcesContent":[".welcomeALEmpModal {\r\n\r\n    .MuiButton-containedSecondary:hover{\r\n        background-color: #427be6;\r\n    }\r\n\r\n    .MuiTab-root, label, span, input, button, li {\r\n        font-family: 'Calibri' !important;\r\n        font-size: 14px !important;\r\n    }\r\n\r\n    .MuiTabs-root{\r\n        button, span{\r\n            color: #fff;\r\n            opacity: 0.7;\r\n        }\r\n        .Mui-selected{\r\n            color: #fff !important;\r\n            opacity: 1;\r\n        }\r\n    }\r\n\r\n    \r\n\r\n    button {\r\n        &.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicatorDirty {\r\n          background-color: transparent;\r\n        }\r\n      \r\n        &.MuiAutocomplete-popupIndicator:hover, &.MuiAutocomplete-clearIndicatorDirty:hover {\r\n          background-color: #ebebeb;\r\n        }\r\n    }\r\n\r\n    .MuiTabs-indicator{\r\n        height: 5px;\r\n        border-radius: 1px;\r\n        background-color: #fff;\r\n    }\r\n\r\n    .MuiCheckbox-colorSecondary.Mui-checked{\r\n        color: #427be6;\r\n    }\r\n\r\n\r\n    .mappedVehiclesTable{\r\n        td{\r\n            padding-top: 0.25rem;\r\n            padding-bottom: 0.25rem;\r\n        }\r\n    }\r\n}\r\n\r\n.MuiAutocomplete-popper li{\r\n    font-family: 'Calibri' !important;\r\n    font-size: 14px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
