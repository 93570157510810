// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WelcomeModal_Container__nK0gt {
  position: fixed;
  background: rgba(0, 0, 0, .2);
  right: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

/* .Container button {
  background-color: #427be6;
} */

.WelcomeModal_Modal__8uI4y {
  width: 80%;
  height: 32rem;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #8a8686;
}

.WelcomeModal_Modal__8uI4y button:focus,
.WelcomeModal_Modal__8uI4y button:active  {
  outline: none;
}

.WelcomeModal_ModalHeader__9DAnV {
  display: flex;
  justify-content: space-between;
}

.WelcomeModal_ModalHeader__9DAnV h5 {
  color: #0d459a;
}

.WelcomeModal_Tabs__PT4Gf {
  display: none;
}

.WelcomeModal_CloseIcon__Lf5It {
  height: 24px;
}

.WelcomeModal_CloseIcon__Lf5It:hover {
  box-shadow: 0px 0px 10px #427be6;
  transition: .1s ease-in;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/WelcomeModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B;EAC7B,QAAQ;EACR,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;;GAEG;;AAEH;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gCAAgC;EAChC,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".Container {\r\n  position: fixed;\r\n  background: rgba(0, 0, 0, .2);\r\n  right: 0;\r\n  left: 0;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  z-index: 500;\r\n}\r\n\r\n/* .Container button {\r\n  background-color: #427be6;\r\n} */\r\n\r\n.Modal {\r\n  width: 80%;\r\n  height: 32rem;\r\n  background: #fff;\r\n  padding: 1rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  border-radius: 10px;\r\n  box-shadow: 0px 0px 10px #8a8686;\r\n}\r\n\r\n.Modal button:focus,\r\n.Modal button:active  {\r\n  outline: none;\r\n}\r\n\r\n.ModalHeader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.ModalHeader h5 {\r\n  color: #0d459a;\r\n}\r\n\r\n.Tabs {\r\n  display: none;\r\n}\r\n\r\n.CloseIcon {\r\n  height: 24px;\r\n}\r\n\r\n.CloseIcon:hover {\r\n  box-shadow: 0px 0px 10px #427be6;\r\n  transition: .1s ease-in;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `WelcomeModal_Container__nK0gt`,
	"Modal": `WelcomeModal_Modal__8uI4y`,
	"ModalHeader": `WelcomeModal_ModalHeader__9DAnV`,
	"Tabs": `WelcomeModal_Tabs__PT4Gf`,
	"CloseIcon": `WelcomeModal_CloseIcon__Lf5It`
};
export default ___CSS_LOADER_EXPORT___;
