import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";
import { FIREBASE_API_KEY, REACT_APP_AUTH_DOMAIN, REACT_APP_DATABASE_URL, 
  REACT_APP_PROJECT_ID, REACT_APP_STORAGE_BUCKET, REACT_APP_MESSAGING_SENDER_ID, REACT_APP_APP_ID } from '../apiList'

//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_APP_ID
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
