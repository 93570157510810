// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-chatbot-kit-chat-container .dateBlockreport {
  margin-left: 0px;
}
.react-chatbot-kit-chat-container .rdrMonth {
  width: auto;
}
.react-chatbot-kit-chat-container .rdrDateDisplayWrapper {
  min-width: 260px;
}

.rs-picker-daterange-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  transition: none;
  display: flex;
  flex-direction: column;
  right: 2%;
  top: 25% !important;
  z-index: 10 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Chatbot/Date/Date.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,WAAA;AAAR;AAEI;EACI,gBAAA;AAAR;;AAGA;EACI,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,sBAAA;EACA,sCAAA;EACA,uEAAA;EACA,oCAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,sBAAA;AAAJ","sourcesContent":[".react-chatbot-kit-chat-container{\r\n    .dateBlockreport{\r\n        margin-left: 0px;\r\n    }\r\n    .rdrMonth{\r\n        width: auto;\r\n    }\r\n    .rdrDateDisplayWrapper{\r\n        min-width: 260px;\r\n    }\r\n}\r\n.rs-picker-daterange-menu{\r\n    position: absolute;\r\n    text-align: left;\r\n    z-index: 7;\r\n    border-radius: 6px;\r\n    background-color: #fff;\r\n    background-color: var(--rs-bg-overlay);\r\n    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);\r\n    box-shadow: var(--rs-shadow-overlay);\r\n    overflow: hidden;\r\n    transition: none;\r\n    display: flex;\r\n    flex-direction: column;\r\n    right: 2%;\r\n    top: 25% !important;\r\n    z-index: 10 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
