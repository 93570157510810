// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-field {
  font-family: "calibri", sans-serif;
  overflow: hidden;
  margin-top: -15px !important;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  display: inline-block;
  width: 100px;
  background-color: white;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Tracking/ToggleSwitch.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,gBAAA;EACA,4BAAA;AACJ;;AAEE;EACI,6BAAA;EACA,sBAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,gBAAA;AACN;;AAEE;EACE,qBAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,oCAAA;EACA,8EAAA;EACA,gCAAA;AACJ;;AAEE;EACI,eAAA;AACN;;AAEE;EACE,yBAAA;EACE,yBAAA;EACF,gBAAA;AACJ;;AAEE;EACE,0BAAA;AACJ;;AAEE;EACE,0BAAA;AACJ","sourcesContent":[".switch-field {\r\n    font-family: \"calibri\", sans-serif;\r\n    overflow: hidden;\r\n    margin-top: -15px !important;\r\n  }\r\n    \r\n  .switch-field input {\r\n      position: absolute !important;\r\n      clip: rect(0, 0, 0, 0);\r\n      height: 1px;\r\n      width: 1px;\r\n      border: 0;\r\n      overflow: hidden;\r\n  }\r\n  \r\n  .switch-field label {\r\n    display: inline-block;\r\n    width: 100px;\r\n    background-color: white;\r\n    color: #000;\r\n    font-size: 14px;\r\n    font-weight: normal;\r\n    text-align: center;\r\n    text-shadow: none;\r\n    border: 1px solid rgba(0, 0, 0, 0.1);\r\n    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);\r\n    transition: all 0.1s ease-in-out;\r\n  }\r\n  \r\n  .switch-field label:hover {\r\n      cursor: pointer;\r\n  }\r\n  \r\n  .switch-field input:checked + label {\r\n    background-color: #ffc107;\r\n      color: rgba(0, 0, 0, 0.7);\r\n    box-shadow: none;\r\n  }\r\n  \r\n  .switch-field label:first-of-type {\r\n    border-radius: 4px 0 0 4px;\r\n  }\r\n  \r\n  .switch-field label:last-of-type {\r\n    border-radius: 0 4px 4px 0;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
