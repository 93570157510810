import React, { useEffect, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../Components/ErrorBoundry/ErrorBoundry";
import { Loader } from "../Pages/Tracking/Loader";
import Dashboard from "../Components/Dashboard";
import GoogleMap from "./../Components/GoogleMap";
import Vehicleinfo from "./../Pages/Vehicleinfo/Vehicleinfo";
import DealerMaps from './../Pages/Dealermap/dealermapview';
import withNavigate from '../hooks/withNavigate';

sessionStorage.reload = true;

// Imported lazy-loaded components...
const Loginpage = lazy(()=>import('./../Pages/Login/Loginpage'))
const MyPeople = lazy(()=>import('./../Pages/MyPeople/People'))
// const GoogleMap = lazy(()=>import('./../Components/GoogleMap'))
const trace = lazy(()=>import('./../Pages/Trace/TraceScreen'))
const VehiclTracking = lazy(()=>import('./../Pages/Tracking/VehicleTracking'))
// const Vehicleinfo = lazy(()=>import('./../Pages/Vehicleinfo/Vehicleinfo'))
// const Dashboard = lazy(()=>import('./../Components/Dashboard'))
const FleetInsightsDashboard = lazy(()=>import('./../Pages/FleetInsights/fleetinsightsdashboard'))
const FleetInsightsDash = lazy(()=>import('./../Pages/FleetInsights/other files/fleetinsightsDash'))
const FleetInsightsReports = lazy(()=>import('./../Pages/FleetInsights/FIreport'))
const FleetInsightsRepnew = lazy(()=>import('./../Pages/FleetInsights/newreport'))
const FleetInsightsRep = lazy(()=>import('./../Pages/FleetInsights/fleetinsightsreport'))
const Gearutilrep = lazy(()=>import('./../Pages/FleetInsights/gearutilreport'))
const FleetInsightsGearUtil = lazy(()=>import('./../Pages/FleetInsights/other files/fidashgearutil'))
const Utilization = lazy(()=>import('./../Pages/FleetInsights/other files/utilizationdash'))
const FleetManagement = lazy(()=>import('./../Pages/AddVehicle/fleetManagement'))
const UserProfile = lazy(()=>import('./../Pages/Settings/UserProfile'))
const Geofence = lazy(()=>import('./../Pages/GeoFence/geofence'))
// const DealerMaps = lazy(()=>import('./../Pages/Dealermap/dealermapview'))
const FleetDrivers = lazy(()=>import('./../Pages/FleetDrivers/FleetDrivers'))
const CustomerSupport = lazy(()=>import('./../Pages/CustomerSupport/CustomerSupport'))
const Remapping = lazy(()=>import('./../Pages/Remapping/Remapping'))
const CreateTraining = lazy(() =>import('./../Pages/UserTraning/UserTraningCreate'))
const CustomerTraining = lazy(() =>import('./../Pages/CustomerTraining/CutomerTrainingPage'))
const VehicleDetails = lazy(()=>import('./../Pages/VehicleDetails/VehicleDetails'))
const Tabdashboad = lazy(()=>import('./../Pages/Tableau_dashboard/tab_dashboard'))
const AIS140ActivationRequest = lazy(()=>import('./../Pages/AIS140/AIS140Activation'))
const Logoutfunctions = lazy(()=>import('./../Pages/logout/logout'))
const Successfunctions = lazy(()=>import('./../Pages/logout/logout'))
const SarathyDashboard = lazy(()=>import('./../Components/SarathyDashboard/SarathyDashboard'))
const Approval = lazy(()=>import('./../Pages/Approval/Approval'))
const Billingmoduleapp = lazy(()=>import('./../Pages/billingmodule/billingmodule'))
const BillingsubscribedUser = lazy(()=>import('./../Pages/billingmodule/billingsubscribedUser'))
const BillingDealerSMEApproval = lazy(() =>import('./../Pages/billingmodule/BillingCVG/HOApprovel'))
const Discount = lazy(()=>import('./../Pages/Discount/discount'))
const AlertsSafety = lazy(()=>import('./../Pages/FleetInsights/other files/AlertsSafety'))
const Fuel = lazy(()=>import('./../Pages/FleetInsights/other files/Fuel'))
const Bs6alerts = lazy(()=>import('./../Pages/FleetInsights/other files/Bs6Alerts'))
const Billingmodulepacks = lazy(()=>import('./../Pages/billingmodule/billingmodulePacks'))
const Redirectpageslist = lazy(()=>import('./../Components/directpage/redirectpages'))
const PackageCreation = lazy(()=>import('./../Pages/Packagecreation/packagecreation'))
const DseMaster = lazy(()=>import('./../Pages/Dsemaster/dseMaster.js'))
const Dealersales = lazy(()=>import('./../Pages/dealersales/dealersalesdash'))
const Dealerdashboad = lazy(()=>import('./../Pages/dealersales/dealerdash'))
const AppPage = lazy(()=>import('./../Pages/appspage/appspage'))
const UserManual = lazy(()=>import('./../Pages/Usermanual/usermanual'))
const ShowVas = lazy(()=>import('./../Components/ShowVas/showVas'))
const CreateVas = lazy(()=>import('./../Components/CreateVas/createVas'))
const Alapprovallist = lazy(()=>import('./../Pages/ALapproval/alapprovallist'))
const Allusertileview = lazy(()=>import('./../Pages/allusertileview/allusertileview'))
const Allusertiledash = lazy(()=>import('./../Pages/allusertileview/allusertiledash'))
const Monthlyreportview = lazy(()=>import('./../Pages/monthlyReport/monthlyreportview'))
const GeoFenceReports = lazy(()=>import('./../Pages/GeoFence/GeoFenceReports'))
const Vehicledashctrl = lazy(()=>import('./../Pages/VehicleDashboard/vehicledashboard'))
const SalesAdminExcel = lazy(()=>import('./../Pages/ALSalesAdmin/SalesAdminExcel'))
const CommunicationSettings = lazy(()=>import('./../Pages/CustomerSettings/CustomerSettings'))
const DealerPOI = lazy(()=>import('./../Pages/DealerPOI/DealerPOI'))
const Notification = lazy(()=>import('./../Pages/NotificationAlert/notification'))
const FuncRawData = lazy(() =>import('./../Pages/Rawdata/Functional_Component/RawDataDownload'))
const PSBAlerts = lazy(()=>import('./../Pages/PSBAlerts/PsbAlerts'))
const TripList = lazy(()=>import('./../Components/TripList'))
const CreateTrips = lazy(()=>import('./../Components/CreateTrips'))
const FleetAlerts = lazy(()=>import('./../Components/FleetAlerts/fleetAlerts'))
const SarathyShowTrip = lazy(()=>import('./../Components/SarathyShowTrip/SarathyShowTrip'))
const SarathyCreateTrip = lazy(()=>import('./../Components/SarathyCreateTrip/SarathyCreateTrip'))
// const Rawdata = lazy(()=>import('./../Pages/Rawdata/rawdataScreen'))
const TripDeviationSummary = lazy(()=>import('./../Pages/TripDeviation/TripDeviationSummary'))
const CreateMap = lazy(()=>import('./../Pages/TripDeviation/CreateTripDeviation'))
const FuelReport = lazy(()=>import('./../Pages/FuelReport/FuelReport'))
const ReleaseNotes = lazy(()=>import('./../Components/ReleaseNotes/ReleaseNotes'))
const MailerTemplate = lazy(()=>import('./../Components/MailerTemplate/Template'))
const TrainingApproval =  lazy(()=>import('./../Pages/TrainingRequest/RequestList'))
import DashboardHeader from '../Components/DashboardHeader/index';

const AppRouters = (props) => {

  const termsAndConditionRoutePath = "/termsandconditions";
  const switchTermsAndConditionsRoutePath = '/iontermsandconditions'
  
  const navigateOthers = () => {
    // Handle special cases for session reload
    if (['/success', '/camportal', '/apps'].includes(props.location.pathname)) {
      sessionStorage.reload = '';
    } else if (props.location.pathname.startsWith('/tracking/')) {
      let id = props.location.pathname.split('/')[2]
      if (props.location.pathname !== `/tracking/${id}`) {
        sessionStorage.reload = '';
        props.navigate(`/tracking/${id}`);
      }
    } else if (sessionStorage.reload && props.location.pathname !== '/') {
      if (props.location.pathname.includes(termsAndConditionRoutePath)) {
        sessionStorage.reload = '';
        props.navigate(termsAndConditionRoutePath);
      } else if (props.location.pathname.includes(switchTermsAndConditionsRoutePath)) {
        sessionStorage.reload = '';
        props.navigate(switchTermsAndConditionsRoutePath);
      } else {
        sessionStorage.reload = '';
        props.navigate('/login');
      }
    } else if (
      props.location.pathname === '/billingmodule/packages' &&
      props.props.props.gmapsState.naviagteLink === 'billingpackages'
    ) {
      sessionStorage.reload = '';
      props.navigate('/billingmodule/packages');
    } else {
      sessionStorage.reload = '';
    }
  }

  useEffect(() => {
    if(!props.props.token && !props.location.pathname.startsWith('/tracking/') && !props.location.pathname.startsWith('/success')){
      if (props.location.pathname !== `/login`) {
        sessionStorage.reload = '';
        props.navigate('/login');
      }
    }else{
      navigateOthers();
    }
  }, [props.location, props.props.token]); // Dependencies to re-run useEffect

  return(
  <>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Loginpage />} />
        <Route path="/login" element={<Loginpage />} />
        <Route path="/tracking/:id" element={<VehiclTracking />} />
        <Route path="/success" element={<Successfunctions />} />
        {props.props.token && 
        <>
        <Route path="/map" element={<GoogleMap />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/tripList" element={<TripList />} />
        <Route path="/fleetinsightsdashboard" element={<FleetInsightsDashboard />} />
        <Route path="/fleetinsightsdash" element={<FleetInsightsDash />} />
        <Route path="/fleetinsightsrep" element={<FleetInsightsRep />} />
        <Route path="/FleetKPI" element={<FleetInsightsRepnew />} />
        <Route path="/gearutilrep" element={<Gearutilrep />} />
        <Route path="/fleetinsightsreport" element={<FleetInsightsReports />} />
        <Route path="/utilizationdash" element={<Utilization />} />
        <Route path="/fleetManagement" element={<FleetManagement />} />
        <Route path="/createTrips" element={<CreateTrips />} />
        <Route path="/trace" element={<trace />} />
        <Route path="/vehicleInfo/trace/*" element={<Vehicleinfo />} />
        <Route path="/vehicleInfo/trend/*" element={<Vehicleinfo />} />
        <Route path="/vehicleInfo/trendcompare/*" element={<Vehicleinfo />} />
        <Route path="/vehicleInfo/alerts/*" element={<Vehicleinfo />} />
        <Route path="/vehicleInfo/findDealer/*" element={<Vehicleinfo />} />
        <Route path="/mypeople" element={<MyPeople />} />
        <Route path="/fleetalerts" element={<FleetAlerts />} />
        <Route path="/fuelReport" element={<FuelReport />} />
        <Route path="/UserProfile" element={<UserProfile />} />
        <Route path="/SarathyCreateTrip" element={<SarathyCreateTrip />} />
        <Route path="/SarathyShowTrip" element={<SarathyShowTrip />} />
        <Route path="/SarathyDashboard" element={<SarathyDashboard />} />
        <Route path="/Rawdata" element={<FuncRawData />} />
        <Route path="/fleetDrivers" element={<FleetDrivers />} />
        <Route path="/CustomerSupport" element={<CustomerSupport />} />
        <Route path="/TrainingRequest" element={<CustomerTraining />} />
        <Route path="/CreateTraining" element={<CreateTraining />} />
        <Route path="/Remapping" element={<Remapping />} />
        <Route path="/vehicleDetails" element={<VehicleDetails />} />
        <Route path="/AIS140" element={<AIS140ActivationRequest />} />
        
        <Route
          path="/Dealermap"
          element={
            <ErrorBoundary>
              <DealerMaps />
            </ErrorBoundary>
          }
        />
        
        <Route path="/Dashboad" element={<Tabdashboad />} />
        <Route path="/fleetmanagement/geofence" element={<Geofence />} />
        <Route path="/figearutil" element={<FleetInsightsGearUtil />} />
        <Route path="/logout" element={<Logoutfunctions />} />
        <Route path="/billingmodule/packages" element={<Billingmoduleapp />} />
        <Route path="/billingmodule/orderhistory" element={<BillingsubscribedUser />} />
        <Route path="/discount" element={<Discount />} />
        <Route path="/Approval" element={<Approval />} />
        <Route path="/un_approval_subscription_data_dlr_sme" element={<BillingDealerSMEApproval />} />
        <Route path="/alertssafety" element={<AlertsSafety />} />
        <Route path="/fuel" element={<Fuel />} />
        <Route path="/bs6alerts" element={<Bs6alerts />} />
        <Route path="/billingmodulepacks" element={<Billingmodulepacks />} />
        <Route path="/dealersales" element={<Dealersales />} />
        <Route path="/dealerdash" element={<Dealerdashboad />} />
        <Route path="/apps" element={<AppPage />} />
        <Route path="/camportal" element={<Redirectpageslist />} />
        <Route path="/packagecreation" element={<PackageCreation />} />
        <Route path="/dsemaster" element={<DseMaster />} />
        <Route path="/usermanual" element={<UserManual />} />
        <Route path="/showVas" element={<ShowVas />} />
        <Route path="/createVas" element={<CreateVas />} />
        <Route path="/alapproval" element={<Alapprovallist />} />
        <Route path="/allusertiledash" element={<Allusertiledash />} />
        <Route path="/allusertileview" element={<Allusertileview />} />
        <Route path="/monthlyreport" element={<Monthlyreportview />} />
        <Route path="/GeoFenceReports" element={<GeoFenceReports />} />
        <Route path="/vehicleInfo/VehicleDash/*" element={<Vehicleinfo />} />
        <Route path="/SalesAdminExcel" element={<SalesAdminExcel />} />
        <Route path="/communicationsettings" element={<CommunicationSettings />} />
        <Route path="/DealerPOI" element={<DealerPOI />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/PSBAlert" element={<PSBAlerts />} />
        <Route path="/trip_deviation_list" element={<TripDeviationSummary />} />
        <Route path="/create_trip" element={<CreateMap />} />
        <Route path="/ReleaseNotes" element={<ReleaseNotes />} />
        <Route path="/monthlymailer" element={<MailerTemplate />} />
        <Route path="/TrainingApproval" element={<TrainingApproval />} />
        </>
        }
      </Routes>
    </Suspense>
  </>
  )
}

export default withNavigate(AppRouters);
