// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customAutoComp {
  width: 150px;
  margin: 20px 5px 0px 5px;
}

.custom-time-picker1 {
  width: 9%;
}

.custom-time-picker1 input {
  width: 20% !important;
}

.dc_datepicker_btn1 {
  margin-left: -10px !important;
}

.timer_class {
  width: 80px !important;
}

.dateBlock .inputContainer i {
  position: absolute;
  top: 5px !important;
  right: 15px !important;
}

.dateandTimeComponent .dc_datepicker_div {
  width: 8%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TrendCompare/CompDataComponent.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,wBAAA;AACJ;;AAGA;EACI,SAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;;AAGA;EACI,6BAAA;AAAJ;;AAEA;EACI,sBAAA;AACJ;;AACA;EACI,kBAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;;AAAA;EACI,SAAA;AAGJ","sourcesContent":[".customAutoComp{\r\n    width: 150px;\r\n    margin: 20px 5px 0px 5px;\r\n  \r\n}\r\n\r\n.custom-time-picker1{\r\n    width: 9%;\r\n}\r\n\r\n.custom-time-picker1 input{\r\n    width: 20% !important;\r\n}\r\n\r\n.dc_datepicker_btn1{\r\n    margin-left: -10px !important;\r\n}\r\n.timer_class{\r\n    width: 80px !important;\r\n}\r\n.dateBlock .inputContainer i {\r\n    position: absolute;\r\n    top: 5px !important;\r\n    right: 15px !important;\r\n}\r\n.dateandTimeComponent .dc_datepicker_div{\r\n    width: 8%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
