// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup.FleetWarningForm {
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  border: solid 1px;
  display: block;
}

.warning_close {
  cursor: pointer;
  float: right;
}

.warning-title {
  border-bottom: solid 1px #e3e1e1;
  margin-bottom: 0.5px;
  text-align: center;
  color: red;
}

.warning-fleet-popup-body {
  background: white;
  width: 60%;
  padding: 28px;
  top: 15%;
  position: absolute;
  left: 20%;
  font-family: "calibri";
}

.warning-dialog-body {
  font-size: 16px;
  font-weight: bold;
  font-family: Calibri;
}

.warning-dialog-message {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/Components/Warning/FleetInsightDataWarning.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AACA;EACI,eAAA;EACA,YAAA;AAEJ;;AAAA;EACI,gCAAA;EACA,oBAAA;EACA,kBAAA;EACA,UAAA;AAGJ;;AADA;EACI,iBAAA;EACA,UAAA;EACA,aAAA;EACA,QAAA;EACA,kBAAA;EACA,SAAA;EACA,sBAAA;AAIJ;;AAFA;EACI,eAAA;EACA,iBAAA;EACA,oBAAA;AAKJ;;AAHA;EACI,aAAA;EACA,uBAAA;EACA,qBAAA;AAMJ","sourcesContent":[".popup.FleetWarningForm{\r\n    position: absolute;\r\n    z-index: 1;\r\n    border-radius: 7px;\r\n    border: solid 1px;\r\n    display: block;\r\n}\r\n.warning_close{\r\n    cursor: pointer;\r\n    float: right;\r\n}\r\n.warning-title{\r\n    border-bottom: solid 1px #e3e1e1;\r\n    margin-bottom: 0.5px;\r\n    text-align: center;\r\n    color: red;\r\n}\r\n.warning-fleet-popup-body{\r\n    background: white;\r\n    width: 60%;\r\n    padding: 28px;\r\n    top: 15%;\r\n    position: absolute;\r\n    left: 20%;\r\n    font-family: 'calibri';\r\n}\r\n.warning-dialog-body{\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    font-family: Calibri;\r\n}\r\n.warning-dialog-message{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
