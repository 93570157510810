// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ByModel_ByModelContainer__jmEmL {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 1rem;
  flex-grow: 1;
}

.ByModel_ByModelContainer__jmEmL button {
  background-color: #427be6;
}

.ByModel_SearchContainer__Be3mx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}


.ByModel_TransferListContainer__ghrbe {
  width: 80%;
}

.ByModel_TransferListContainer__ghrbe button {
  background-color: #fff;
}

.ByModel_LoginId__uiTtS {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
}


.ByModel_ResetSubmit__JCb0N {
  display: inline-block;
  margin-right: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/WelcomeModal/ByModel/ByModel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uCAAuC;EACvC,cAAc;EACd,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;;AAGA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;;AAGA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".ByModelContainer {\r\n  display: grid;\r\n  grid-template-rows: auto auto auto auto;\r\n  grid-gap: 1rem;\r\n  flex-grow: 1;\r\n}\r\n\r\n.ByModelContainer button {\r\n  background-color: #427be6;\r\n}\r\n\r\n.SearchContainer {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n  grid-gap: 3rem;\r\n}\r\n\r\n\r\n.TransferListContainer {\r\n  width: 80%;\r\n}\r\n\r\n.TransferListContainer button {\r\n  background-color: #fff;\r\n}\r\n\r\n.LoginId {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  align-items: end;\r\n}\r\n\r\n\r\n.ResetSubmit {\r\n  display: inline-block;\r\n  margin-right: 2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ByModelContainer": `ByModel_ByModelContainer__jmEmL`,
	"SearchContainer": `ByModel_SearchContainer__Be3mx`,
	"TransferListContainer": `ByModel_TransferListContainer__ghrbe`,
	"LoginId": `ByModel_LoginId__uiTtS`,
	"ResetSubmit": `ByModel_ResetSubmit__JCb0N`
};
export default ___CSS_LOADER_EXPORT___;
