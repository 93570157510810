import React, { forwardRef } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DatePicker from "react-datepicker";
import { postData } from '../../Util';
import * as apilist from '../../apiList';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import './CompDataComponent.scss'

let uservehicledate = {};
let shownlistview = {}

class CompDataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null, endDate: null, startTime: null, endTime: null, loadedVins: [], submitbtn: '',
            startTimeText: "", endTimeText: "",
            vitals1_text: '',
            vitals2_text: '',
            ...this.determineVitals(this.props.ctr?.selectedVehicle)
        }
        this.inputFocus = "";
        this.subInputFocus = "";
    }

    resetAll = () => {
        this.calltracetrendfn();
        this.setState({
            startTimeText: "",
            endTimeText: "",
            ...this.determineVitals(this.props.ctr?.selectedVehicle)
        }, () => {
            //this.clearVitals();
            this.props.resetChart();
            this.callTrace();
        })
        this.inputFocus = '';
        this.subInputFocus = "";

    }
    determineVitals = (vehicleDetails) => {
        let isEV = vehicleDetails?.isBS6 === 4;
        return {
            vitals1: isEV ? "MOTOR_SPEED_MCU" : "ENGINE_SPEED",
            vitals2: isEV ? "WHEEL_SPEED" : "GPS_SPEED",
            selectedVital1: { label: isEV ? "Motor Speed" : "Engine Speed", vitalVal: isEV ? "MOTOR_SPEED_MCU" : "ENGINE_SPEED" },
            selectedVital2: { label: "Vehicle Speed", vitalVal: isEV ? "WHEEL_SPEED" : "GPS_SPEED" }
        };
    }
    clearVitals = () => {
        let btn = document.getElementsByClassName('MuiAutocomplete-clearIndicator');
        if (btn && btn.length) {
            for (let val of btn) {
                val.click();
            }
        }
    }

    calltracetrendfn = () => {
        if (window.location.href.toLowerCase().indexOf('/trendcompare') > -1) {
            if (this.props.selectedvitals.length === 0) {
                this.setState({ submitbtn: "btndisable" })
            }
        }
        if (this.props.userDetails || this.props.uD) {
            let uData = this.props.userDetails || this.props.uD;
            this.loadSelectVehicle(uData);
        }

        if (this.props.ctr.selectedVehicle) {
            //  debugger
            let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
            let propDate = new Date(selectedVeh.localTimeStamp);
            let toDate = new Date(propDate);
            let fromDate = new Date(selectedVeh.localTimeStamp).setHours(propDate.getHours() - 6);
            let vehStatus = selectedVeh.status;
            let start_time = this.formateTime(fromDate);
            let end_time = this.formateTime(toDate);
            if (window.location.href.toLowerCase().indexOf('/trendcompare') > -1) {
                this.setState({
                    startDate: fromDate,
                    endDate: toDate,
                    startTime: new Date(fromDate),
                    endTime: toDate,
                    vstatus: vehStatus,
                    endVehicle: '',
                    startTimeText: start_time,
                    endTimeText: end_time
                }, () => {
                    this.calldatefns(fromDate, "startDate", toDate, 'endDate');
                    this.showChart();
                }
                );
            } else {
                this.setState({
                    startDate: fromDate,
                    endDate: toDate,
                    startTime: new Date(fromDate),
                    endTime: toDate,
                    vstatus: vehStatus,
                    endVehicle: '',
                    startTimeText: start_time,
                    endTimeText: end_time
                },
                    this.callTrace);
            }
        }
    }

    formateTime = (val) => {
        let timeVal = new Date(val);
        return this.convertTimetoString(timeVal.getHours()) + ":" + this.convertTimetoString(timeVal.getMinutes());
    }

    convertTimetoString = (i) => {
        let n = i > 9 ? "" + i : "0" + i;
        return n;
    }

    componentDidMount() {
        shownlistview = "showlist"
        this.props.listviewshown();
        this.calltracetrendfn();
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    calldatefns = (d1, d2, d3, d4) => {
        this.handlechangefromdisplay(d1, d2)
        this.handlechangetodisplay(d3, d4)
    }

    loadSelectVehicle = (uD) => {
        let newObj = {};
        let vehtokens = uD.token;
        newObj.platform = 'w';
        newObj.userId = uD.userid;

        const datas = postData(apilist.vehicleInfo, newObj, vehtokens);
        datas.then((dataVals) => {
            if (dataVals.statuscode === 200) {
                dataVals.data.map((data) => {
                    data.title = data.regn_number;
                    return data;
                });
                this.setState({
                    loadedVins: {
                        options: dataVals.data,
                        getOptionLabel: option => option.title,
                    }
                });
                if (this.props.ctr.selectedVehicle === undefined) {
                    let vehlist = {};
                    vehlist.userId = this.props.uD.userid;
                    vehlist.userRole = this.props.uD.userrole;
                    vehlist.obuIdList = [];
                    let vehtoken = this.props.uD.token;
                    vehlist.platform = "w";
                    let urls;
                    if (this.props.uD.userrole === "DEALER OUTLET ADMIN") {
                        urls = apilist.allvehicledatadealer
                    } else {
                        urls = apilist.allvehicledata
                    }
                    const response = postData(urls, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
                    response.then((datas) => {
                        if (datas.statuscode === 200) {
                            var obulist = {
                                obu_id: datas.data[0].obuId
                            }
                            this.updateState(obulist)
                        }
                    });
                }
            } else {
                alert('Failed to load Change Vehicle Data..')
            }
        });
    }

    addDays = (date, days) => {
        let dateVal = date;
        dateVal.setDate(date.getDate() + days)
        return dateVal;
    }

    calcDays = (date, days) => {
        let dateVal = date;
        dateVal.setDate(date.getDate() + (days));
        return dateVal;
    }

    handlechangefromdisplay = (date, name) => {
        let sD = new Date(date)

        sD = new Date(sD);
        let mmVal = sD.getMonth() + 1

        let yy = sD.getFullYear(),
            mm = (mmVal + "").length === 1 ? "0" + mmVal : mmVal,
            dd = (sD.getDate() + "").length === 1 ? "0" + sD.getDate() : sD.getDate();
        sD = yy + '-' + mm + '-' + dd;
        var ssD;
        ssD = dd + '-' + mm + '-' + yy;
        this.setState({ displayfromdate: ssD })

        this.setState({ [name]: date });
    }

    handlechangetodisplay = (date, name) => {
        let eD = new Date(date)
        let emVal = eD.getMonth() + 1;
        eD = new Date(eD);
        let tyy = eD.getFullYear(),
            tmm = (emVal + "").length === 1 ? '0' + emVal : emVal,
            tdd = (eD.getDate() + "").length === 1 ? "0" + eD.getDate() : eD.getDate();
        eD = tyy + '-' + tmm + '-' + tdd;
        var eeD;
        eeD = tdd + '-' + tmm + '-' + tyy;
        this.setState({ displaytodate: eeD })
        this.setState({ [name]: date });
    }

    handleChange = (date, name) => {
        this.setState({ [name]: date });
    };

    getFrequency = (daycount) => {
        var freq = daycount;
        daycount.days = Math.abs(daycount.days)
        var fr = ".5";
        if (freq.days > 3 && freq.days <= 5) {
            fr = "5";
        } else if (freq.days > 5 && freq.days <= 10) {
            fr = "7";
        } else if (freq.days > 10 && freq.days <= 15) {
            fr = "10";
        } else if (freq.days > 15 && freq.days <= 30) {
            fr = "14";
        } else if (freq.days > 30 && freq.days <= 45) {
            fr = "20";
        } else if (freq.days > 45 && freq.days <= 60) {
            fr = "30";
        } else if (freq.days > 60 && freq.days <= 90) {
            fr = "45";
        } else if (freq.days > 90 && freq.days <= 120) {
            fr = "30";
        } else if (freq.days > 120 && freq.days <= 150) {
            fr = "45";
        } else if (freq.days > 150 && freq.days <= 180) {
            fr = "60";
        }
        return fr
    }

    getFrequencyunified = (daycount) => {
        var freq = daycount;
        daycount.days = Math.abs(daycount.days)
        var fr = "2.5";
        if (freq.days > 3 && freq.days <= 5) {
            fr = "15";
        } else if (freq.days > 5 && freq.days <= 10) {
            fr = "25";
        } else if (freq.days > 10 && freq.days <= 15) {
            fr = "40";
        } else if (freq.days > 15 && freq.days <= 30) {
            fr = "50";
        } else if (freq.days > 30 && freq.days <= 45) {
            fr = "75";
        } else if (freq.days > 45 && freq.days <= 60) {
            fr = "100";
        } else if (freq.days > 60 && freq.days <= 90) {
            fr = "125";
        } else if (freq.days > 90 && freq.days <= 120) {
            fr = "150";
        } else if (freq.days > 120 && freq.days <= 150) {
            fr = "225";
        } else if (freq.days > 150 && freq.days <= 180) {
            fr = "300";
        }
        return fr;
    }

    dateDiff = (earlierDate, laterDate) => {
        var oDiff = {};
        var nTotalDiff = laterDate.getTime() - earlierDate.getTime();
        oDiff.days = Math.round(nTotalDiff / 1000 / 60 / 60 / 24);
        oDiff.hours = Math.round(nTotalDiff / 1000 / 60 / 60);
        oDiff.minutes = Math.round(nTotalDiff / 1000 / 60);
        oDiff.seconds = Math.round(nTotalDiff / 1000);
        var hourtext = '00';
        if (oDiff.days > 0) { hourtext = String(oDiff.days); }
        if (hourtext.length === 1) { hourtext = '0' + hourtext }
        var mintext = '00';
        if (oDiff.minutes > 0) { mintext = String(oDiff.minutes); }
        if (mintext.length === 1) { mintext = '0' + mintext }
        var sectext = '00';
        if (oDiff.seconds > 0) { sectext = String(oDiff.seconds); }
        if (sectext.length === 1) { sectext = '0' + sectext }
        var sDuration = hourtext + ':' + mintext + ':' + sectext;
        oDiff.duration = sDuration;
        return oDiff;
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.location.href.toLowerCase().indexOf('/trend') > -1) {
            if (this.props.selectedvitals.length !== 0) {
                if (prevProps.selectedvitals !== this.props.selectedvitals) {
                    this.setState({ submitbtn: '' })
                }
            }
        }
    }

    callTrace = () => {
        if (this.state.vitals1 === "" && this.state.vitals2 === "") {
            this.props.resetChart();
            alert("Please select 2 different Vitals to proceed.");
        }
        else if (this.state.vitals1 === "" || this.state.vitals2 === "") {
            this.props.resetChart();
            alert("Please select both Vitals to proceed.");
        }
        else if (this.state.vitals1 === this.state.vitals2) {
            alert("Vital 1 and Vital 2 cannot be same. Please select 2 different Vitals to proceed.");
        } else {
            //debugger
            let checkVital1 = this.props.selectedvitals.filter((item) => item.vitalVal === this.state.vitals1);
            let checkVital2 = this.props.selectedvitals.filter((item) => item.vitalVal === this.state.vitals2);
            if (checkVital1.length > 0 && checkVital2.length > 0) {
                this.showChart();
            } else {
                if (checkVital1.length === 0 && checkVital2.length > 0) {
                    alert("Vital 1 is not available for this vehicle. Please select a different Vital to proceed.");
                }
                else if (checkVital2.length === 0 && checkVital1.length > 0) {
                    alert("Vital 2 is not available for this vehicle. Please select a different Vital to proceed")
                }
                else if (checkVital2.length === 0 && checkVital1.length === 0) {
                    alert("Vital 1  and Vital 2 are not available for this vehicle. Please select different Vitals to proceed")
                }
            }
        }
    }

    showChart = () => {
        let selectedVeh = this.props.ctr.selectedVehicle.currentInfo;
        let stat = this.state;
        if (selectedVeh && Object.keys(selectedVeh).length > 0) {
            let sD = new Date(stat.startDate), eD = new Date(stat.endDate), sT = new Date(stat.startTime), eT = new Date(stat.endTime);

            sD = new Date(sD).setHours(sT.getHours());
            sD = new Date(sD).setMinutes(sT.getMinutes());

            eD = new Date(eD).setHours(eT.getHours());
            eD = new Date(eD).setMinutes(eT.getMinutes());
            eD = new Date(eD);
            sD = new Date(sD);
            let tDiff = eD.getTime() - sD.getTime();

            let dDiff = this.dateDiff(eD, sD);
            let freq = '2.5';
            if (selectedVeh.packet_type === 6) {
                freq = this.getFrequencyunified(dDiff);
            } else {
                freq = this.getFrequency(dDiff);
            }

            let fsT = (sT.getHours() + "").length === 1 ? "0" + sT.getHours() : sT.getHours(),
                fsM = (sT.getMinutes() + "").length === 1 ? "0" + sT.getMinutes() : sT.getMinutes(),
                teT = (eT.getHours() + "").length === 1 ? "0" + eT.getHours() : eT.getHours(),
                teM = (eT.getMinutes() + "").length === 1 ? "0" + eT.getMinutes() : eT.getMinutes();
            sT = fsT + ":" + fsM;
            eT = teT + ":" + teM;

            if (sD === null || !stat.startDate) {
                alert("Please enter the Valid Start Date.");
                return false;
            } else if (eD === null || !stat.endDate) {
                alert("Please enter the Valid End Date.");
                return false;
            } else if (sT === null || !stat.startTime) {
                alert("Please enter the Valid Start Time.");
                return false;
            } else if (eT === null || !stat.endTime) {
                alert("Please enter the Valid End Time.");
                return false;
            } else if (tDiff < 0) {
                alert("Please enter Start Time Less than End Time.");
                return false;
            } else {
                let mmVal = sD.getMonth() + 1,
                    emVal = eD.getMonth() + 1;
                let yy = sD.getFullYear(),
                    mm = (mmVal + "").length === 1 ? "0" + mmVal : mmVal,
                    dd = (sD.getDate() + "").length === 1 ? "0" + sD.getDate() : sD.getDate();
                sD = yy + '-' + mm + '-' + dd;
                let tyy = eD.getFullYear(),
                    tmm = (emVal + "").length === 1 ? '0' + emVal : emVal,
                    tdd = (eD.getDate() + "").length === 1 ? "0" + eD.getDate() : eD.getDate();
                eD = tyy + '-' + tmm + '-' + tdd;
                var ssD;
                var eeD;
                ssD = dd + '-' + mm + '-' + yy;
                eeD = tdd + '-' + tmm + '-' + tyy;
                this.setState({ displayfromdate: ssD })
                this.setState({ displaytodate: eeD })

                let vitalsarray = [];
                if (this.state.vitals1 === "GAS_LEVEL") {
                    vitalsarray.push("CNG_LEVEL");
                } else if (this.state.vitals1 === "GAS_CONSUMPTION") {
                    vitalsarray.push("CNG_CONSUMPTION");
                } else {
                    vitalsarray.push(this.state.vitals1);
                }

                if (this.state.vitals2 === "GAS_LEVEL") {
                    vitalsarray.push("CNG_LEVEL");
                } else if (this.state.vitals2 === "GAS_CONSUMPTION") {
                    vitalsarray.push("CNG_CONSUMPTION");
                } else {
                    vitalsarray.push(this.state.vitals2);
                }

                let newObj = { "fromDate": sD, "toDate": eD, "fromTime": sT, "toTime": eT, "obuId": selectedVeh.obuId, "frequency": freq, "vinNumberdetails": selectedVeh.vinNumber, vitals: vitalsarray }
                this.props.goTrace(newObj);
            }
        } else {
            alert("No Data Found....");
            return false;
        }
    }

    updateState = (newVal, event) => {
        let uDet = this.props.userDetails || this.props.uD;
        if (newVal && uDet) {

            let newObj = {};
            newObj.platform = 'w';
            newObj.userId = uDet.userid;
            newObj.userRole = uDet.userrole;
            let tokensvalue = uDet.token;
            newObj.obuIdList = [newVal.obu_id];
            let urls;
            if (uDet.userrole === "DEALER OUTLET ADMIN") {
                urls = apilist.allvehicledatadealer
            } else {
                urls = apilist.allvehicledata
            }
            const datas = postData(urls, newObj, tokensvalue);
            datas.then((dataVals) => {
                if (dataVals.statuscode === 200) {
                    let mVal = {};
                    mVal.selectedVehicle = dataVals.data[0];
                    uservehicledate = mVal;
                    this.props.ctrs();

                    this.props.receiveProps.updateChanges();
                    this.props.vitalselect();
                    let selectedVehl = mVal.selectedVehicle.currentInfo;
                    if (selectedVehl && Object.keys(selectedVehl).length > 0) {
                        let propDate = new Date(selectedVehl.localTimeStamp);
                        let toDate = new Date(propDate);
                        let fromDate = new Date(selectedVehl.localTimeStamp).setHours(propDate.getHours() - 6);
                        let vehStatus = selectedVehl.status;
                        const vitalUpdate = this.determineVitals(mVal.selectedVehicle)
                        this.setState({
                            startDate: fromDate,
                            endDate: toDate,
                            startTime: new Date(fromDate),
                            endTime: toDate,
                            vstatus: vehStatus,
                            endVehicle: '',
                            selectedVeh: uservehicledate,
                            ...vitalUpdate
                        }, () => {
                            this.calldatefns(fromDate, "startDate", toDate, 'endDate');
                        });
                    } else {
                        alert("No Data Available for selected Vehilce...");
                        this.setState({
                            datas: ''
                        });
                    }
                } else {
                    alert("Please check...");
                    this.setState({
                        datas: ''
                    });
                    return false;
                }
            });
        } else {
            console.log('Redux user Data or selected Val Error');
            return false;
        }
    };

    updateVitalState = (newVal, event, forVital) => {
        if (forVital === "vitalsVal1") {
            this.setState({
                vitals1: newVal ? newVal.vitalVal : '',
                selectedVital1: newVal,
            }, () => {
                this.btnDisableCheck();
            })
        }
        else {
            this.setState({
                vitals2: newVal ? newVal.vitalVal : '',
                selectedVital2: newVal
            }, () => {
                this.btnDisableCheck();
            })
        }
    }

    btnDisableCheck = () => {
        if (this.state.vitals1 !== "" && this.state.vitals2 !== "") {
            this.setState({
                submitbtn: ""
            })
        }
    }

    setDefaultValue = () => {
        if (this.props.ctr.selectedVehicle.isBS6 === 2) {
            alert("hello gas")
            return ({ label: "Engine Speed", vitalVal: "ENGINE_SPEED" })
        }
        else if (this.props.ctr.selectedVehicle.isBS6 === 1) {
            alert("hello fuel")
            return ({ label: "Engine Speed", vitalVal: "ENGINE_SPEED" })
        }
        else {
            alert("hi")
        }
    }

    onChangeText = (e) => {
        const re = /[a-zA-Z]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }

    }

    render() {
        const ref = React.createRef();
        const CustomDatePicker = forwardRef(({ value, onClick, labelVal, inputname, selectedvalie }, ref) => (
            <div className="dateBlock" ref={ref}>
                <p className="inputContainer">
                    <input
                        type="text"
                        value={selectedvalie}
                        onChange={onClick}
                        onClick={onClick}
                        placeholder="Start Date"
                        required name={inputname}
                    />
                    <i className="fa fa-calendar-o icon cs_cursor_pointer" onClick={onClick}></i>
                    <label>{labelVal}</label>
                </p>
            </div>
        ));

        let start_time = this.state.startTimeText.split(":");
        let end_time = this.state.endTimeText.split(":");

        return (
            <div className="dateandTimeComponent">
                <div className="customAutoComp">
                    <Autocomplete
                        {...this.state.loadedVins}
                        id="debug"
                        // disableOpenOnFocus
                        onChange={
                            (event, newval) => { this.updateState(newval, event) }
                        }
                        renderInput={params =>
                            <TextField
                                {...params}
                                label="Reg Number"
                                margin="normal"
                                fullWidth
                                variant="standard"
                            />
                        }
                    />
                </div>
                <div className="customAutoComp">
                    <Autocomplete
                        options={this.props.selectedvitals}
                        getOptionLabel={(option) => option.label}
                        id="debug"
                        // disableOpenOnFocus
                        onChange={
                            (event, newval) => { this.updateVitalState(newval, event, "vitalsVal1") }
                        }
                        value={this.state.selectedVital1}
                        renderInput={params =>
                            <TextField
                                {...params}
                                onKeyPress={(e) => this.onChangeText(e)}
                                label="Vitals 1"
                                margin="normal"
                                fullWidth
                                variant="standard"
                            />
                        }
                    />
                </div>
                <div className="customAutoComp">
                    <Autocomplete
                        options={this.props.selectedvitals}
                        value={this.state.selectedVital2}
                        getOptionLabel={(option) => option.label}
                        id="debug"
                        // disableOpenOnFocus
                        onChange={
                            (event, newval) => { this.updateVitalState(newval, event, "vitalsVal2") }
                        }
                        renderInput={params =>
                            <TextField
                                {...params}
                                onKeyPress={(e) => this.onChangeText(e)}
                                label="Vitals 2"
                                margin="normal"
                                fullWidth
                                variant="standard"
                            />
                        }
                    />
                </div>

                <DatePicker
                    selected={this.state.startDate}
                    style={{ display: 'none' }}
                    onChange={date => this.handlechangefromdisplay(date, "startDate")}
                    minDate={this.calcDays(new Date(), -90)}
                    maxDate={this.state.endDate || new Date()}
                    dateFormat="dd/MM/YYY"
                    customInput={<CustomDatePicker labelVal='Start Date' selectedvalie={this.state.displayfromdate} inputname="StartDate" ref={ref} />}
                />

                <DatePicker
                    selected={this.state.endDate}
                    onChange={date => this.handlechangetodisplay(date, 'endDate')}
                    minDate={this.state.startDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/YYY"
                    customInput={<CustomDatePicker labelVal='End Date' selectedvalie={this.state.displaytodate} inputname="endDate" />}
                />
                        <div className="dc_datepicker_div dc_datepicker_div_1">
							<label>Start Time</label>
							<br />
							<label className="date-picker-label">
								<i class="fa fa-clock-o form-control-feedback" aria-hidden="true"></i>
								<DatePicker
									selected={this.state.startTime}
									onChange={date => this.handleChange(date, 'startTime')}
									showTimeSelect={true}
									showTimeSelectOnly={true}
									timeFormat="HH:mm"
									timeIntervals={5}
									timeCaption="time"
									dateFormat="HH:mm"
								/>
							</label>
						</div>
                        <div className="dc_datepicker_div dc_datepicker_div_2">
							<label>End Time</label>
							<br />
							<label className="date-picker-label">
								<i class="fa fa-clock-o form-control-feedback" aria-hidden="true"></i>
								<DatePicker
									selected={this.state.endTime}
									onChange={date => this.handleChange(date, 'endTime')}
									showTimeSelect={true}
									showTimeSelectOnly={true}
									timeFormat="HH:mm"
									timeIntervals={5}
									timeCaption="time"
									dateFormat="HH:mm"
								/>
							</label>
						</div>


                <button
                    className={this.state.submitbtn === "btndisable" ?
                        "common-button ffub13 buttonBlock buttonDisable dc_datepicker_btn1" :
                        "common-button ffub13 buttonBlock dc_datepicker_btn1"}
                    onClick={this.callTrace}>
                    Submit &#x00A0;
                    <i className="fa fa-check" aria-hidden="true"></i>
                </button>
                <button
                    className="common-button ffub13 buttonBlock"
                    onClick={this.resetAll} >
                    Reset &#x00A0;
                    <i className="fa fa-repeat" aria-hidden="true"></i>
                </button>
            </div>
        )
    }
}

const mapStateToprops = state => {
    return {
        ctr: state.vehicledata.results,
        uD: state.userdata.id
    };
}

const mapdispatchToprops = dispatch => {
    return {
        ctrs: () => dispatch({ type: 'VehicleDatas', value: uservehicledate }),
        listviewshown: () => dispatch({ type: 'listviewshow', value: shownlistview })
    };
}

export default connect(mapStateToprops, mapdispatchToprops)(CompDataComponent);


