import React from 'react';
import Maps from './dealersmap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import './common.css';
import { postData } from '../../Util';
import * as apiList from '../../apiList';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

let uservehicledate = {}

const styles = theme => ({
    defaultfont: {
      fontFamily: "calibri",
    }
});

// Styled Tooltip components
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "auto",
      height: "30px",
      borderRadius: "0px 20px 20px 0px",
      border: "1px solid #fff",
      backgroundColor: "#427ae6",
      marginLeft: "5px",
      pointerEvents: "auto", // Allow interaction
      zIndex: 1300, // Ensure tooltip appears above other elements
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 30,
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #0d459a",
      },
    },
  }));

  const CustomTooltip2 = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "auto",
      height: "auto",
      borderRadius: "0px 20px 20px 0px",
      border: "1px solid #fff",
      backgroundColor: "gray",
      marginLeft: "5px",
      pointerEvents: "auto", // Allow interaction
      zIndex: 1300, // Ensure tooltip appears above other elements
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 30,
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #0d459a",
      },
    },
  }));

//   const CustomTooltip2 = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       width: "auto",
//       height: "auto",
//       borderRadius: "0px 20px 20px 0px",
//       border: "1px solid #fff",
//       backgroundColor: "gray",
//       marginLeft: "10px",
//       zIndex: 1300, // Ensure it's on top
//       padding: "10px",
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//       fontSize: 30,
//       "&::before": {
//         backgroundColor: "white",
//         border: "1px solid #0d459a",
//       },
//     },
//   }));

class leftmenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listShow: true,
            zoom: 7,
            center: { lat: 20.5937, lng: 78.9629 },
            options: { strokeColor: "#0099cc", strokeWeight: 1 },
            mydata: [],
            filterData: [],
            icon: "/images/Map_screen/dealer_new.png",
            iconselect: "/images/Map_screen/dealer_selected.png",
            vehicleimg_r: "/images/Trace/Map_screen/RUNNING/06.png",
            vehicleimg_s: "/images/Trace/Map_screen/STOPPED/06.png",
            vehicleimg_i: "/images/Trace/Map_screen/IDLING/06.png",
            vehicleimg_t: "/images/Trace/Map_screen/TOWED/06.png",
            vehicleimg_d: "/images/Trace/Map_screen/DISCONNECTED/06.png",
            vehicleimg: "/images/Trace/Map_screen/IDLING/06.png",
            listDetail: 0,
            vehStatus: "IDLING",
            awayKms: "0 kms",
            kms: 200,
            loader:false,
            loadedVins: [],
            radisFun: {},
            dealertypesselected:"All",
            selctedVehicle: true,
            searchdealervalue : "",
            currentradisInfo: null,
            onlydealernames: null,
            delaertypefilter : [],
            dealertypedata : [],
            getdealerdata : [],
            dealershiplists:[],
            directions:[],
            dealerList:[],
            active_index: {
                Service: true,
                Sales: true,
                DLR: true,
                HPCL_Outlet: true,
                IOCL_Outlet: true,
                POI: true,
                ALASC: true,
                VD: true,
                VD_3S: true,
                VD_2S: true,
                SASSY: true,
                ASC: true,
                DEALER_DLR: true
                
            },
            dropdownChanged: false,
        }
        this.hover.bind(this);
        this.clickList.bind(this);
        this.hideDetails.bind(this);
        this.MarkerClick.bind(this);
        this.hoverOut.bind(this);
        this.menuItemClick.bind(this);

    };

    componentDidMount() {
        this.initFunc();
    }

    getDealerValue=(event, value)=>{
        
        const selectedIndex = this.state.mydata.findIndex(
            option => `${option.dealer_outlet_name} - ${option.dealership_type}` === value
        );
        const selectedDealer = this.state.mydata[selectedIndex];

        if (value===null){
            this.hoverOut();
        }
        else{
        this.state.mydata.map((list) => {
            if(list.dealer_outlet_name+' - '+list.dealership_type === value){
                if(list.dealership_type === 'DLR'){
                    list.icon = "/images/Map_screen/dealer_selected.png"
                }
                else if(list.dealership_type === 'HPCL OUTLET'){
                    list.icon = "/images/Map_screen/hpcl_selected.png"
                }
                else if(list.dealership_type === 'IOCL OUTLET'){
                    list.icon = "/images/Map_screen/iocl_selected.png"
                }
                else if(list.dealership_type === 'POI'){
                    list.icon = "/images/Map_screen/poi_selected.png"
                }
                else if(list.dealership_type === 'VD'){
                    list.icon = "/images/Map_screen/sales_selected.png"
                }
                else{
                    list.icon = "/images/Map_screen/dealer_selected.png"
                }

                var centerPoint = {
                    lat: parseFloat(list.lattitude),
                    lng: parseFloat(list.longitude)
                }
                this.props.searchupdatedata(centerPoint);
            }
        })
        if(this.state.mydata.findIndex((data)=>{return data.dealer_outlet_name+' - '+data.dealership_type ===value})!== -1){
            var myindex = this.state.mydata.findIndex((data)=>{return data.dealer_outlet_name+' - '+data.dealership_type===value})
            if(this.state.mydata[myindex].dealership_type === 'DLR'){
                this.state.mydata[myindex].icon = "/images/Map_screen/dealer_selected.png"
            }
            else if(this.state.mydata[myindex].dealership_type === 'HPCL OUTLET'){
                this.state.mydata[myindex].icon = "/images/Map_screen/hpcl_selected.png"
            }
            else if(this.state.mydata[myindex].dealership_type === 'IOCL OUTLET'){
                this.state.mydata[myindex].icon = "/images/Map_screen/iocl_selected.png"
            }
            else if(this.state.mydata[myindex].dealership_type === 'POI'){
                this.state.mydata[myindex].icon = "/images/Map_screen/poi_selected.png"
            }
            else if(this.state.mydata[myindex].dealership_type === 'VD'){
                this.state.mydata[myindex].icon = "/images/Map_screen/sales_selected.png"
            }
            else{
                this.state.mydata[myindex].icon = "/images/Map_screen/dealer_selected.png"
            }

            this.setState({
                mydata:this.state.mydata
            })
        }     
    }  
        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route({
        origin: new window.google.maps.LatLng(parseFloat(selectedDealer.lattitude), parseFloat(selectedDealer.longitude)),
        destination: new window.google.maps.LatLng(this.state.center.lat, this.state.center.lng),
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        optimizeWaypoints:true,
            }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
            this.setState({
            directions: result,
            })
        } else {
            console.error(`error fetching directions ${result}`);
            this.setState({
            directions: result,
            })
        }
        })
        if(this.state.dealertypesselected!=="DEALER"){
        let vehtoken = this.props.uD.token;
        let findkmobj={}
        findkmobj.dealership_type=this.state.dealertypesselected;
        findkmobj.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
        findkmobj.destination_location=selectedDealer.lat_lng;
        const response = postData(apiList.finddealerkms, findkmobj, vehtoken);
        response.then((datas) => {
            this.setState({awayKms:datas.data.g_distance,listShow:false,listDetail: selectedIndex, 
                dealertypesselected : selectedDealer.dealership_type ==="DLR" || selectedDealer.dealership_type ==="DEALER" || selectedDealer.dealership_type ==="ALASC" || selectedDealer.dealership_type ==="VD" ? 'DEALER' : selectedDealer.dealership_type
            })
        })
        }else{
            this.setState(

                {
                    listShow: false,
                    listDetail: selectedIndex,
                    awayKms: this.state.mydata[selectedIndex].g_distance,
                    dealertypesselected : selectedDealer.dealership_type ==="DLR" || selectedDealer.dealership_type ==="DEALER" || selectedDealer.dealership_type ==="ALASC" || selectedDealer.dealership_type ==="VD" ? 'DEALER' : selectedDealer.dealership_type
                }
            )
        }
    }

    getDealertypesearch = (event,value)=>{
        var pushdata = []
        let token = this.props.uD.token;
        if(value!=null){
            var iconshow;
            this.setState({searchdealervalue:value,kms:value.distance_limit,displaytext:value.distance_limit})
            this.setState({dealertypesselected:value.dealership_type})
            let apiDealeriput={};
            if(value.dealership_type==="POI"){
                iconshow= "/images/Map_screen/poi_new.png"
                this.setState({icon: '/images/Map_screen/poi_new.png',directions:[]})
                this.setState({iconselect: '/images/Map_screen/poimarker_selected.png'})
                apiDealeriput.dealership_type="poi";
                var res = value.dealership_name.split("_");
                apiDealeriput.poi_group_id=res[0];
                apiDealeriput.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
                
            }else if (value.dealership_type==="FUEL") {
                iconshow= "/images/Map_screen/hpcl_new.png"
                this.setState({icon: "/images/Map_screen/hpcl_new.png"})
                this.setState({iconselect: "/images/Map_screen/fuel-fd_selected.png"})
                apiDealeriput.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
                apiDealeriput.dealership_type=value.dealership_name;
                apiDealeriput.poi_group_id=""           
            }
            else{ 
                iconshow= "/images/Map_screen/dealer_new.png"
                this.setState({icon: "/images/Map_screen/dealer_new.png"})
                this.setState({iconselect: "/images/Map_screen/dealer_selected.png"})
                apiDealeriput.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
                apiDealeriput.dealership_type=value.dealership_name;
                apiDealeriput.poi_group_id="";} 
                this.setState({ loader: true})  
                   
            var dealerData = postData(apiList.getDealer, apiDealeriput, token)
            dealerData.then((data) => {
                this.setState({ loader: false})  
               if (data.data !== null) {         
                            data.data.map((list, i) => list.icon = iconshow)
                            this.setState({getdealerdata:data.data,
                                mydata: data.data,
                                filterData: data.data
                            });
                       
                           }
            
           })            
        }else{
            this.setState({mydata : this.state.getdealerdata,searchdealervalue:value, filterData: this.state.getdealerdata})
        }
        
        // if(value!=null){
        //     this.state.getdealerdata.map((list) => {
        //         if(value.includes(list.dealership_type)){
        //             pushdata.push(list)
        //         }
        //     })
        //     this.state.dealertypedata=pushdata
        //     if(this.state.dealertypedata.length!=0){
        //         this.setState({mydata : this.state.dealertypedata,searchdealervalue:value})
        //     }
        // }
        // else{
        //     this.setState({mydata : this.state.getdealerdata,searchdealervalue:value})
        // }
    }

    initFunc = () => {
        this.setState({ loader: true})
        if (this.props.userDetails || this.props.uD) {
            let uData = this.props.userDetails || this.props.uD;
            this.loadSelectVehicle(uData);
        }
        let token = this.props.uD.token;
        let apiData;
        if (this.props.ctr.selectedVehicle !== undefined && (this.props.receiveProps?.location?.state === undefined)) {
            let unique = []
            let apiDealeriput={}
          
            this.setState({
                center: {
                    lat: this.props.ctr.selectedVehicle.currentInfo.latitude,
                    lng: this.props.ctr.selectedVehicle.currentInfo.longitude
                }
            })
            this.props.searchupdatedata({lat: this.props.ctr.selectedVehicle.currentInfo.latitude,lng: this.props.ctr.selectedVehicle.currentInfo.longitude})
            
            var obulist = {
                obu_id: this.props.ctr.selectedVehicle.obuId
            }
            this.updateState(obulist)
        } else {
            //changes for Directly landed on this page without selecting vehicles - wiki//
            let vehlist = {};
            vehlist.userId = this.props.uD.userid;
            vehlist.userRole = this.props.uD.userrole;
            let vehtoken = this.props.uD.token;
            vehlist.platform = "w";

            let uData = this.props.userDetails || this.props.uD;
            let newObj = {};
            newObj.platform = 'w';
            newObj.userId = uData.userid;
            let obu_id = '';

            const filter_response = postData(apiList.vehicleInfo, newObj, vehtoken);
            filter_response.then((dataVals) => {
                if (dataVals.statuscode === 200) {
                    if(this.props.receiveProps.location.state !== null && this.props.receiveProps.location.state !== undefined && this.props.receiveProps.location.state.vin_no !== undefined){
                        let filterData = dataVals.data.filter((data) => data.vin_number == this.props.receiveProps.location.state.vin_no);
                        if(filterData.length>0){
                            obu_id = filterData[0].obu_id
                        }else{
                            alert('No vehicle found...');
                            return false;
                        }
                    }
                    
                    this.setState({ 
                        loader: true
                    },() => { 
                        vehlist.obuIdList = obu_id ? [obu_id]:[];
                        const response = postData(apiList.allvehicledata, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
                        response.then((datas) => {
                            
                            if (datas.statuscode === 200) {
                                var obulist = {
                                    obu_id: datas.data[0].obuId
                                }
            
                                this.updateState(obulist)
                            }
                        });
                    })

                }
            });

            //End of changes//
            // apiData = {
            //     "platform": "w",
            //     "lat": this.state.center.lat,
            //     "lng": this.state.center.lng
            // }
            this.setState({
                selctedVehicle: false,
                loader: false,
                center: {
                    lat: this.state.center.lat,
                    lng: this.state.center.lng
                }
            })
            this.props.searchupdatedata(this.state.center)
        }
    }

    hover = (i, value) => {
        // this.state.mydata.map((list) => list.icon = this.state.icon)
        let icon = ''
        if(value.dealership_type === 'DLR'){
            icon = "/images/Map_screen/dealer_selected.png"
        }
        else if(value.dealership_type === 'HPCL OUTLET'){
            icon = "/images/Map_screen/hpcl_selected.png"
        }
        else if(value.dealership_type === 'IOCL OUTLET'){
            icon = "/images/Map_screen/iocl_selected.png"
        }
        else if(value.dealership_type === 'POI'){
            icon = "/images/Map_screen/poi_selected.png"
        }
        else if(value.dealership_type === 'VD'){
            icon = "/images/Map_screen/sales_selected.png"
        }
        else{
            icon = "/images/Map_screen/dealer_selected.png"
        }

        let mydata = this.state.mydata;
        mydata[i].icon = icon;
        this.setState({
            mydata: mydata,
            options: { strokeColor: "#0099cc" },
        })
    }

    hoverOut = (i, value) => {
        let mydata = this.state.mydata;
        for (var i = 0; i < mydata.length; i++) {
            if(mydata[i].dealership_type === 'DLR'){
                mydata[i].icon = "/images/Map_screen/dealer_new.png"
            }
            else if(mydata[i].dealership_type === 'HPCL OUTLET'){
                mydata[i].icon = "/images/Map_screen/hpcl_new.png"
            }
            else if(mydata[i].dealership_type === 'IOCL OUTLET'){
                mydata[i].icon = "/images/Map_screen/iocl_new.png"
            }
            else if(mydata[i].dealership_type === 'POI'){
                mydata[i].icon = "/images/Map_screen/poi_new.png"
            }
            else if(mydata[i].dealership_type === 'VD' || mydata[i].dealership_type === 'VD-2S'){
                mydata[i].icon = "/images/Map_screen/sales_new.png"
            }
            else{
                mydata[i].icon = "/images/Map_screen/dealer_new.png"
            }
        }
        this.setState({
            mydata: mydata,
        });
    }

    removesduplicatedealertype = () =>{
        let data = this.state.getdealerdata
        let unique = []
        data.forEach(element=>{
            if(unique.length == 0 ||!unique.includes(element.dealership_type)){
                unique.push(element.dealership_type)
            }
        })
        this.setState({delaertypefilter : unique})
    }

    clickList = (i,list,val) => {
        //awayKms
            const DirectionsService = new window.google.maps.DirectionsService();
            DirectionsService.route({
            origin: new window.google.maps.LatLng(parseFloat(list.lattitude), parseFloat(list.longitude)),
            destination: new window.google.maps.LatLng(this.state.center.lat, this.state.center.lng),
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,
            optimizeWaypoints:true,
             }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
               this.setState({
                directions: result,
               })
            } else {
              console.error(`error fetching directions ${result}`);
              this.setState({
                directions: result,
              })
            }
			})
           if(this.state.dealertypesselected!=="DEALER"){
            let vehtoken = this.props.uD.token;
            let findkmobj={}
            findkmobj.dealership_type=this.state.dealertypesselected;
            findkmobj.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
            findkmobj.destination_location=list.lat_lng;
            const response = postData(apiList.finddealerkms, findkmobj, vehtoken);
            response.then((datas) => {
                this.setState({awayKms:datas.data.g_distance,listShow:false,listDetail: i, 
                    dealertypesselected : list.dealership_type ==="DLR" || list.dealership_type ==="DEALER" || list.dealership_type ==="ALASC" || list.dealership_type ==="VD" ? 'DEALER' : list.dealership_type
                })
            })
        }else{
            this.setState(

                {
                    listShow: false,
                    listDetail: i,
                    awayKms: this.state.mydata[i].g_distance,
                    dealertypesselected : list.dealership_type ==="DLR" || list.dealership_type ==="DEALER" || list.dealership_type ==="ALASC" || list.dealership_type ==="VD" ? 'DEALER' : list.dealership_type
                }
            )
        }
        
    }

    MarkerClick = (data) => {
        let icon = ''
        if(data.dealership_type === 'DLR'){
            icon = "/images/Map_screen/dealer_selected.png"
        }
        else if(data.dealership_type === 'HPCL OUTLET'){
            icon = "/images/Map_screen/hpcl_selected.png"
        }
        else if(data.dealership_type === 'IOCL OUTLET'){
            icon = "/images/Map_screen/iocl_selected.png"
        }
        else if(data.dealership_type === "POI"){
            icon = "/images/Map_screen/poi_selected.png"
        }
        else if(data.dealership_type === 'VD'){
            icon = "/images/Map_screen/sales_selected.png"
        }
        else{
            icon = "/images/Map_screen/dealer_selected.png"
        }

        let mydata = this.state.mydata;
        if(data.dealership_type !=="DLR" && data.dealership_type !=="ALASC" && data.dealership_type !=="SASSY" && data.dealership_type !== 'ASC' && data.dealership_type !== 'VD-2S'){
            var dynamicValue = 0;
            for (var i = 0; i < mydata.length; i++) {
                if (mydata[i].dealer_outlet_id === data.dealer_outlet_id) {
                    mydata[i].icon = icon;
                    dynamicValue = i;
                }
                else{
                    if(mydata[i].dealership_type === 'DLR' || mydata[i].dealership_type === 'ALASC' || mydata[i].dealership_type === 'ASC' || mydata[i].dealership_type === 'VD-2S' || mydata[i].dealership_type === 'SASSY'){
                        mydata[i].icon = "/images/Map_screen/dealer_new.png"
                    }
                    if(mydata[i].dealership_type === 'HPCL OUTLET'){
                        mydata[i].icon = "/images/Map_screen/hpcl_new.png"
                    }
                    if(mydata[i].dealership_type === 'IOCL OUTLET'){
                        mydata[i].icon = "/images/Map_screen/iocl_new.png"
                    }
                    if(mydata[i].dealership_type === 'POI'){
                        mydata[i].icon = "/images/Map_screen/poi_new.png"
                    }
                    if(mydata[i].dealership_type === 'VD' || mydata[i].dealership_type === 'VD-3S'){
                        mydata[i].icon = "/images/Map_screen/sales_new.png"
                    }
                }
                
            }
            
            let vehtoken = this.props.uD.token;
            let findkmobj={}
            findkmobj.dealership_type=this.state.dealertypesselected;
            findkmobj.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
            findkmobj.destination_location=data.lat_lng;
            const response = postData(apiList.finddealerkms, findkmobj, vehtoken);
            response.then((datas) => {
                this.setState({
                    listDetail: dynamicValue, 
                    awayKms:datas.data.g_distance,
                    listShow:false,
                    dealertypesselected : data.dealership_type,
                    options: { strokeColor: "#0099cc" }
                })
            })
        }else{
            var dynamicValue = 0;
            // this.state.mydata.map((list) => list.icon = this.state.icon)
            for (var i = 0; i < mydata.length; i++) {
                if (mydata[i].dealer_outlet_id === data.dealer_outlet_id) {
                    dynamicValue = i;
                    mydata[i].icon = icon;
                    this.setState({
                        awayKms: mydata[i].g_distance,
                        options: { strokeColor: "#0099cc" }
                    })
                }
                else{
                    if(mydata[i].dealership_type === 'DLR' || mydata[i].dealership_type === 'ALASC' || mydata[i].dealership_type === 'ASC' || mydata[i].dealership_type === 'VD-2S' || mydata[i].dealership_type === 'SASSY'){
                        mydata[i].icon = "/images/Map_screen/dealer_new.png"
                    }
                    if(mydata[i].dealership_type === 'HPCL OUTLET'){
                        mydata[i].icon = "/images/Map_screen/hpcl_new.png"
                    }
                    if(mydata[i].dealership_type === 'IOCL OUTLET'){
                        mydata[i].icon = "/images/Map_screen/iocl_new.png"
                    }
                    if(mydata[i].dealership_type === 'POI'){
                        mydata[i].icon = "/images/Map_screen/poi_new.png"
                    }
                    if(mydata[i].dealership_type === 'VD' || mydata[i].dealership_type === 'VD-3S'){
                        mydata[i].icon = "/images/Map_screen/sales_new.png"
                    }
                }
                    
            }
            this.setState(
                {
                    listShow: false,
                    listDetail: dynamicValue,
                    dealertypesselected : data.dealership_type ==="DLR" || data.dealership_type ==="ALASC" || data.dealership_type ==="VD" || data.dealership_type ==="ASC" || data.dealership_type ==="VD-2S" ? 'DEALER' : data.dealership_type
                    //center:{lat:parseFloat(this.state.mydata[i].lattitude), lng:parseFloat(this.state.mydata[i].longitude)}
                }
            )
        }
        this.setState({mydata: mydata})
    }

    hideDetails = (i, list) => {
        this.hoverOut();
        this.setState(
            {
                listShow: true,
                directions:[]
            }
        )
    }
    loadSelectVehicle = (uD) => {
        let newObj = {};
        newObj.platform = 'w';
        newObj.userId = uD.userid;
        const datas = postData(apiList.vehicleInfo, newObj, uD.token);
        datas.then((dataVals) => {
            if (dataVals.statuscode === 200) {
                dataVals.data.map((data) => {
                    data.title = data.regn_number;
                    return data;
                });
                this.setState({
                    loadedVins: { options: dataVals.data || [], getOptionLabel: option => option.title },
                });
            } else {
                alert('Failed to load Change Vehicle Data..')
            }
        });
    }

    updateState = (newVal, event) => {
        const clearIndicators = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
        if (clearIndicators[1]) {
            clearIndicators[1].click();
        }
        this.setState({
            selctedVehicle: true,
            dealertypesselected: 'All',
            icon: "/images/Map_screen/dealer_new.png",
            iconselect: "/images/Map_screen/dealer_selected.png",
            loader: true
        })
        let uDet = this.props.userDetails || this.props.uD;
        if (newVal && uDet) {

            let newObj = {};
            newObj.platform = 'w';
            newObj.userId = uDet.userid;
            newObj.userRole = uDet.userrole;
            newObj.obuIdList = [newVal.obu_id];
            // if(this.props.regn_number === undefined){
            //     newObj.obuIdList = [newVal.obu_id];
            // }
            // else{
            //     newObj.obuIdList = [newVal];
            // }
            const datas = postData(apiList.allvehicledata, newObj, uDet.token);
            datas.then((dataVals) => {
                if (dataVals.statuscode === 200) {

                    let mVal = {};
                    mVal.selectedVehicle = dataVals.data[0];
                    uservehicledate = mVal;

                    this.setState({
                        center: {
                            lat: mVal.selectedVehicle.currentInfo.latitude,
                            lng: mVal.selectedVehicle.currentInfo.longitude
                        },
                        vehStatus: mVal.selectedVehicle.currentInfo.status
                    })
                    let odo_val = 0;
                    if(mVal.selectedVehicle.isBS6 === 0 && (mVal.selectedVehicle.vehicleType === 'EDC' || mVal.selectedVehicle.vehicleType === 'IL')){
                        odo_val = mVal.selectedVehicle.currentInfo.gps_odo
                    }
                    else{
                        odo_val = mVal.selectedVehicle.currentInfo.vehicle_odo
                    }
                    this.props.OdoValue(odo_val);
                    this.props.searchupdatedata(this.state.center);
                    let vehicleSts = this.state.vehStatus;
                    switch (vehicleSts) {
                        case "STOPPED":
                            this.setState({
                                vehicleimg: this.state.vehicleimg_s
                            })
                            break;
                        case "RUNNING":
                            this.setState({
                                vehicleimg: this.state.vehicleimg_r
                            })
                            break;
                        case "IDLING":
                            this.setState({
                                vehicleimg: this.state.vehicleimg_i
                            })
                            break;
                        case "TOWED":
                            this.setState({
                                vehicleimg: this.state.vehicleimg_t
                            })
                            break;
                        case "DISCONNECTED":
                            this.setState({
                                vehicleimg: this.state.vehicleimg_d
                            })
                            break;
                        default:
                            this.setState({
                                vehicleimg: this.state.vehicleimg_s
                            })
                    }

                    this.props.ctrs();
                    this.props.updateChanges();
                    let apiData = {
                        "platform": "w",
                        "lat": this.state.center.lat,
                        "lng": this.state.center.lng
                    }
                    let token = this.props.uD.token;
                    let unique=[];
                    let apiDealeriput={};
                    var dealerData_list = postData(apiList.getDealerupdated, null, token)
                    dealerData_list.then((data) => {
                        if (data.statuscode === 200) {
                            let dealerList = data.data.sort(function (a, b) {
                                if (a.dealership_id && b.dealership_id) {
                                    a = a.dealership_id.toLowerCase();
                                    b = b.dealership_id.toLowerCase();
                                    return a < b ? -1 : a > b ? 1 : 0;
                                }
                                return null;
                            });
                            this.setState({
                                dealerList: dealerList,
                            })
                        }
                        this.setState({dealershiplists:data.data})
                        for (var i = 0; i < data.data.length; i++) {
                            if(data.data[i].dealership_type==="DEALER" || data.data[i].dealership_type==="DLR"){
                                this.setState({kms:data.data[i].distance_limit,displaytext:data.data[i].distance_limit})
                            }
                        }
                        data.data.forEach(element=>{
                            if(unique.length == 0 ||!unique.includes(element.dealership_type)){
                                unique.push(element.dealership_type)
                            }
                        })
                        let dealerobject={}
                        dealerobject.type_name="All"
                        dealerobject.dealership_type="All"
                        this.setState({searchdealervalue:dealerobject})
                        this.setState({delaertypefilter:unique})   
                     apiDealeriput.dealership_type="All";
                     apiDealeriput.poi_group_id="";
                     apiDealeriput.orgin_location=this.props.ctr.selectedVehicle.currentInfo.latitude +" " + this.props.ctr.selectedVehicle.currentInfo.longitude;
                     var dealerData = postData(apiList.getDealer, apiDealeriput, token)
                     dealerData.then((data) => {
                        if (data.data !== null && data.data.length > 0 && data.statuscode === 200) {        
                                    let arr = [];    
                                    data.data.map((list, i) => {
                                        arr.push(list.dealership_type);
                                        if(list.dealership_type === 'DLR'){
                                            list.icon = "/images/Map_screen/dealer_new.png"
                                        }
                                        else if(list.dealership_type === 'HPCL OUTLET'){
                                            list.icon = "/images/Map_screen/hpcl_new.png"
                                        }
                                        else if(list.dealership_type === 'IOCL OUTLET'){
                                            list.icon = "/images/Map_screen/iocl_new.png"
                                        }
                                        else if(list.dealership_type === 'POI'){
                                            list.icon = "/images/Map_screen/poi_new.png"
                                        }
                                        else if(list.dealership_type === 'VD'){
                                            list.icon = "/images/Map_screen/sales_new.png"
                                        }
                                        else{
                                            list.icon = "/images/Map_screen/dealer_new.png"
                                        }
                                    })
                                    this.setState({
                                        getdealerdata:data.data,
                                        mydata: data.data,
                                        filterData: data.data,
                                        loader: false
                                    });
                        }
                        else{
                            alert('No Data Found..')
                            this.setState({
                                loader: false
                            });
                        }
                     
                    })
                    this.setState(prevState => ({
                        active_index: {                   
                            ...prevState.active_index,    
                            Service: true,
                            Sales: true,
                            DLR: true,
                            HPCL_Outlet: true,
                            IOCL_Outlet: true,
                            POI: true,
                            ALASC: true,
                            VD: true,
                            VD_3S: true,
                            VD_2S: true,
                            SASSY: true,
                            ASC: true,
                            DEALER_DLR: true
                        },
                        dropdownChanged: true,
                    }))
                    
                })
                    // var dealerData = postData(apiList.getDealer, apiData, token)
                    // dealerData.then((data) => {
                    //     this.setState({ loader: false})
                    //     if (data.data !== null) {               
                    //         data.data.map((list, i) => list.icon = this.state.icon)
                    //         this.setState({getdealerdata:data.data,
                    //             mydata: data.data
                    //         });
                    //         this.removesduplicatedealertype()
                    //     }
                    // })
                    //this.initFunc();
                } else {
                    this.props.OdoValue(0);
                    alert("No Data Available for selected Vehilce...");
                    this.setState({
                        datas: '',
                        loader: false
                    });
                }
            });
        }
        else{
            this.setState({
                loader: false
            });
        }
    }

    menuItemClick = (name, type) => {
        try {
            if(type === undefined){
                this.setState({ active_index: { ...this.state.active_index, [name]: !this.state.active_index[name]}}, () => { this.updateNewState(name) });
            }
            else{
                if(this.state.active_index.HPCL_Outlet || this.state.active_index.IOCL_Outlet){
                    this.setState({ active_index: { 
                        ...this.state.active_index, ['IOCL_Outlet'] : false,
                        ['HPCL_Outlet'] : false
                    }}, () => { 
                        this.updateNewState(name) 
                    });
                }
                else{
                    this.setState({ active_index: { 
                        ...this.state.active_index, ['IOCL_Outlet'] : true,
                        ['HPCL_Outlet'] : true
                    }}, () => { 
                        this.updateNewState(name) 
                    });
                }
               
            }
        }
        catch(err){
            console.log('error', err);
        }
    }

    updateNewState = (name) => {
        const { active_index } = this.state;
        const newState = { ...active_index };
        if (name === 'Service') {
            if (active_index.Service && active_index.Sales) {
                newState.DLR = true;
                newState.ALASC = true;
                newState.VD_2S = true;
                newState.SASSY = true;
                newState.ASC = true;
                newState.DEALER_DLR = true;
                newState.VD = true;
                newState.VD_3S = true;
            }else if(active_index.Service && !active_index.Sales){
                newState.DLR = true;
                newState.ALASC = true;
                newState.VD_2S = true;
                newState.SASSY = true;
                newState.ASC = true;
                newState.DEALER_DLR = true;
                newState.VD = false;
                newState.VD_3S = false;
            }else if(!active_index.Service && active_index.Sales){
                newState.DLR = true;
                newState.ALASC = true;
                newState.VD_2S = true;
                newState.SASSY = true;
                newState.ASC = true;
                newState.DEALER_DLR = true;
                newState.VD = true;
                newState.VD_3S = true;
            }
            else if (!active_index.Service && !active_index.Sales) {
                newState.DLR = false;
                newState.ALASC = false;
                newState.VD = false;
                newState.VD_2S = false;
                newState.VD_3S = false;
                newState.SASSY = false;
                newState.ASC = false;
                newState.DEALER_DLR = false;
            }
        } else if (name === 'Sales') {
            if (active_index.Sales) {
                newState.VD = true;
                newState.VD_3S = true;
                newState.DLR = true;
                newState.ALASC = true;
                newState.VD_2S = true;
                newState.SASSY = true;
                newState.ASC = true;
                newState.DEALER_DLR = true;
            }else if(!active_index.Sales && active_index.Service){
                newState.VD = false;
                newState.VD_3S = false;
                newState.DLR = true;
                newState.ALASC = true;
                newState.VD_2S = true;
                newState.SASSY = true;
                newState.ASC = true;
                newState.DEALER_DLR = true;
            }
            else if (!active_index.Sales && !active_index.Service) {
                newState.DLR = false;
                newState.ALASC = false;
                newState.VD = false;
                newState.VD_2S = false;
                newState.VD_3S = false;
                newState.SASSY = false;
                newState.ASC = false;
                newState.DEALER_DLR = false;
            }
        }
        this.setState({ active_index: newState }, () => {
            this.getByKeyName();
        });
    }

    getByKeyName = () => {
       
        let obj = this.state.active_index;

        // get all state keys
        var keys = Object.keys(obj);

        // filter only true state keys
        var true_keys = keys.filter(function(key) {
            return obj[key]
        });

        // replace all underscores to spaces
        const result = [];
        true_keys.forEach(function (key) {
            if(key === 'HPCL_Outlet'){
                result.push((key.toUpperCase()).replace(/_/g, ' '));
            }
            if(key === 'IOCL_Outlet'){
                result.push((key.toUpperCase()).replace(/_/g, ' '));
            }
            if(key === 'VD_3S'){
                result.push(key.replace(/_/g, '-'));
            }
            if(key === 'VD_2S'){
                result.push(key.replace(/_/g, '-'));
            }else{
                result.push(key);
            }
        });

        // filter data based on array keys
        let filter_data = []
        if(result.length > 0 ){
            filter_data  = this.state.filterData.filter((data) => result.indexOf(data.dealership_type) !== -1)
        }
    
        this.setState({mydata : filter_data})
    }

    getImageSource = (type) => {
         switch (type) {
            case 'HPCL OUTLET':
                return '/images/Map_screen/hpcl.png';
            case 'IOCL OUTLET':
                return '/images/Map_screen/iocl.png';
            case 'POI':
                return '/images/Map_screen/poi.png';
            case 'VD':
                return '/images/Map_screen/sales.png';
            default:
                return '/images/Map_screen/dealer.png';
         }
    };

    dropdownChanged = () => {
        this.setState({dropdownChanged: false})
    }
      
    render() {
        const { mydata, } = this.state;

        const DealerImage = ({ type }) => (
            <img className='list-img' src={this.getImageSource(type)} alt={type} />
        );
        var listData = "";
        var headerData = "";
        if (this.state.listShow === true) {
            headerData = <div className='leftmenu-inputs'>
               
                <div className="header">
                    <div style={{ width: "270px", marginBottom: "0px",marginTop: "-16px" }} className="customAutoCompVehicle">
                        <Autocomplete {...this.state.loadedVins} id="debug" disableOpenOnFocus onKeyDown={e => { if (e.key === 'Enter') return false }} onChange={
                            (event, newval) => { this.updateState(newval, event) }} renderInput={params => <TextField {...params} onKeyDown={e => { if (e.key === 'Enter') return false }} label="Reg No" placeholder="&#128269; Search"  margin="normal" variant='standard' fullWidth />} />
                    </div>
                </div>
                <div className="searchDealer">
                    <div style={{ width: "280px", fontFamily:"calibri"}} className="customAutoCompDealer">                        
                        <i className="fa fa-search"></i>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            onChange={this.getDealerValue}
                            options={this.state.mydata.map(option => option.dealer_outlet_name+' - '+option.dealership_type)}
                            renderInput={params => (
                                <TextField {...params} placeholder="Find Outlets/ POI" margin="normal"  variant='standard' fullWidth />
                            )}
                        />
                    </div>
                </div>
                <div className='outlet-len'>
                    {!this.state.loader &&
                        <p>{mydata.length} outlets found</p>
                    }
                </div>
            </div>
            listData = mydata.map((list, i) =>
                <li data={i} onClick={this.clickList.bind(this, i,list)} onMouseOver={this.hover.bind(this, i, list)} onMouseLeave={this.hoverOut.bind(this, i, list)} key={list.dealer_outlet_id}>
                    <DealerImage type={list.dealership_type} />
                    <div className="hover_effect"></div>
                    <div className="cname">{list.dealer_outlet_name}</div>
                    <small>{list.address_state ? list.address_state : "No City Available"}</small>
                    {list.g_distance ?<button>{list.g_distance ? Math.round(list.g_distance) +" km away" : ""}</button>:''}
                </li>
            )
        } else {
            listData = mydata.slice(this.state.listDetail, this.state.listDetail + 1).map((list, i) =>
                <div className="listDetails" key={list.dealer_outlet_id}>
                    <div className="title">
                        <img className="closeIco" onClick={this.hideDetails.bind(this, i, list)} src="/images/Map_screen/close.png" alt="" />
                        <h6>{list.dealer_outlet_name}</h6>
                        <p className="compname">{list.address_state}</p>
                        <p className="ckms">{this.state.awayKms} kms Away</p>
                    </div>
                    {/* <hr />
                <div className="clearfix"></div>
                <div className="direction">
                    <div className="col-icons">
                        <img src="/images/Map_screen/direction_ico.png" alt="" />
                        <div>Get Directions</div>
                    </div>
                    <div className="col-icons">
                        <img src="/images/Map_screen/sms_ico.png" alt="" />
                        <div>Send details via SMS</div>
                    </div>
                    <div className="col-icons">
                        <img src="/images/Map_screen/mail_ico.png" alt="" />
                        <div>Send details via Email</div>
                    </div>
                </div> */}
                   
                    <div className="clearfix"></div>
                    <hr />
                    {this.state.dealertypesselected==="DEALER" || this.state.dealertypesselected==="VD" || this.state.dealertypesselected==="HPCL OUTLET" || this.state.dealertypesselected==="IOCL OUTLET" ?
                    <div className="address">
                        <div className="ad_line">
                            <div className="col-content-ico">
                                <img src="/images/Map_screen/loc_mod.png" alt="" />
                            </div>
                            <div className="col-content">{list.address_1},{list.address_2},{list.address_state},{list.address_pin}</div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="ph_line">
                            <div className="col-content-ico">
                                <img src="/images/Map_screen/Phone.png" alt="" />
                            </div>
                            <div className="col-content">{list.phone}, {list.wm_phone}</div>
                        </div>
                        <div className="clearfix"></div>
                        {(this.state.dealertypesselected !=="HPCL OUTLET" && this.state.dealertypesselected !=="IOCL OUTLET") ?
                            <>
                            <div className="mail_line">
                                <div className="col-content-ico">
                                    <img src="/images/Map_screen/mail.png" alt="" />
                                </div>
                                <div className="col-content" style={{color:'#457be8'}}><a target="_blank" href={"https://outlook.office.com/?path=/mail/action/compose&to="+list.email_id.toString()}><u>{list.email_id?list.email_id:'-'}</u></a></div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="tags_line">
                                <div className="col-content-ico">
                                    <img src="/images/Map_screen/function.png" alt="" />
                                </div>
                                <div className="col-content">{list.address_country_code}</div>
                            </div> 
                            </> : ''
                        }
                        <div className="clearfix"></div>
                    </div>:''}
                    {this.state.dealertypesselected==="DEALER" || this.state.dealertypesselected==="VD"?
                    <div className="alse">
                        <div className="alsetag">Ashok Leyland Service Engineer</div>
                        <div  className="alsetext">
                            <div className="tags_line">
                                <div className="col-content-ico">
                                    <img src="/images/Map_screen/function.png" alt="" />
                                </div>
                                <div className="col-content">{list.alse_name} - {list.dealership_type}</div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="ph_line">
                                <div className="col-content-ico">
                                    <img src="/images/Map_screen/Phone.png" alt="" />
                                </div>
                                <div className="col-content">{list.alse_phone}</div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mail_line">
                                <div className="col-content-ico">
                                    <img src="/images/Map_screen/mail.png" alt="" />
                                </div>
                                <div className="col-content" style={{color:'#457be8'}}><a target="_blank" href={"https://outlook.office.com/?path=/mail/action/compose&to="+list.alse_email_id.toString()}>
                                <u>{list.alse_email_id?list.alse_email_id:'-'}</u></a></div>
                            </div>
                        </div>
                    </div>:""}
                </div>
            )
        }

        return (
            <div>
                {this.state.loader ? <div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> : ''}
                <div className={this.props.showminimizedToolbar?"leftmenu ht-exp":"leftmenu"}>
                    {(!this.state.loader && this.state.filterData.length > 0) &&
                          <div className='right-side-icons'>
                          <ul>
                              <li className={this.state.active_index.Service ? 'active-icon': 'in_active'} onClick={() => {this.menuItemClick('Service')}}>
                                  <CustomTooltip  title={
                                        <React.Fragment>
                                            <p  style={{color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000'}}>Service Outlet</p>
                                        </React.Fragment>
                                        }
                                        interactive
                                        placement="right" > 
    
                                        <p style={{ marginBottom: '0px' }}><img className={this.state.active_index.Service ? "normal-img" : "gray-img" } src="/images/Map_screen/dealer.png" alt="dealer" /></p>
    
                                  </CustomTooltip>
                                  
                              </li>
                              <li className={this.state.active_index.Sales ? 'active-icon': 'in_active'} onClick={() => {this.menuItemClick('Sales')}}>
                                  <CustomTooltip  title={
                                        <React.Fragment>
                                            <p  style={{color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000'}}>Sales & Service Outlet</p>
                                        </React.Fragment>
                                        }
                                        interactive
                                        placement="right" > 
    
                                        <p style={{ marginBottom: '0px' }}><img className={this.state.active_index.Sales ? "normal-img" : "gray-img" } src="/images/Map_screen/sales.png" alt="dealer" /></p>
    
                                  </CustomTooltip>
                                  
                              </li>
                              <li className={(this.state.active_index.HPCL_Outlet || this.state.active_index.IOCL_Outlet) ? 'active-icon': 'in_active'}>
                                 
                                <CustomTooltip2 title={
                                    <React.Fragment>
                                        <p onClick={() => {this.menuItemClick('HPCL_Outlet')}}  style={this.state.active_index.HPCL_Outlet ? {backgroundColor: '#427ae6', color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000', marginBottom: '10px', padding: '5px', cursor: 'pointer', borderRadius: '5px'}
                                            : {backgroundColor: 'gray', color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000', marginBottom: '10px', padding: '5px', cursor: 'pointer', borderRadius: '5px'}}>HPCL Outlet
                                        </p>
                                        <p onClick={() => {this.menuItemClick('IOCL_Outlet')}}  style={this.state.active_index.IOCL_Outlet ? {backgroundColor: '#427ae6', color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000', padding: '5px', cursor: 'pointer', borderRadius: '5px'} 
                                            : {backgroundColor: 'gray', color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000', marginBottom: '10px', padding: '5px', cursor: 'pointer', borderRadius: '5px'}}>IOCL Outlet
                                        </p>
                                    </React.Fragment>
                                }

                                interactive
                                placement="right" > 
                  
                                <p style={{ marginBottom: '0px' }}><img onClick={() => {this.menuItemClick('HPCL_Outlet', 'hpcl')}} className={(this.state.active_index.HPCL_Outlet || this.state.active_index.IOCL_Outlet) ? "normal-img" : "gray-img" }  src="/images/Map_screen/fuel.png" alt="fuel" /></p>

                                </CustomTooltip2>

                              </li>
                              <li className={this.state.active_index.POI ? 'active-icon': 'in_active'} onClick={() => {this.menuItemClick('POI')}}>
                                    <CustomTooltip  title={
                                        <React.Fragment>
                                            <p  style={{color:'#fff',fontSize:'13px',fontWeight:'600', marginTop: '3px', zIndex: '100000'}}>POI</p>
                                        </React.Fragment>
                                        }
                                        interactive
                                        placement="right" > 
    
                                        <p style={{ marginBottom: '0px' }}><img className={this.state.active_index.POI ? "normal-img" : "gray-img" } src="/images/Map_screen/poi.png" alt="poi" /></p>
    
                                    </CustomTooltip>
                              </li>
                          </ul>
                      </div>
                    }
                  
                    {headerData}
                    <ul>
                        {listData}
                    </ul>
                    
                </div>
                <div className={this.props.showminimizedToolbar?"mapData ht-exp":"mapData"}>
                    <Maps options={this.state.options} zoom={this.state.zoom}
                        vehicleSts={this.state.selctedVehicle} center={this.state.center} truck={this.state.vehicleimg} directionsed={this.state.directions} 
                        mydata={this.state.mydata} getDatas={this.MarkerClick} kmsradiusval={this.state.kms} displaytexted={this.state.displaytext}
                        dropdownChangedFunc={this.dropdownChanged} dropdown_value={this.state.dropdownChanged}>
                    </Maps>
                </div>
            </div>
        )
    }
}
const mapStateToprops = state => {
    return {
        ctr: state.vehicledata.results,
        uD: state.userdata.id
    };
}
const mapdispatchToprops = dispatch => {
    return {
        searchupdatedata: (data) => dispatch({ type: 'searchupdatedata', value: data }),
        searchdealerpoint: (data) => dispatch({ type: 'searchdealerpoint', value: data }),
        ctrs: () => dispatch({ type: 'VehicleDatas', value: uservehicledate })
    };
    
} 
export default (connect(mapStateToprops, mapdispatchToprops)(leftmenu));
