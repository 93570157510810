import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import styles from './TransferList.module.css'

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([...props.dataArray]);
  const [right, setRight] = React.useState([]);
  const [rightRenderData, setRightRenderData] = React.useState([]);
  const [leftRenderData, setLeftRenderData] = React.useState([]);
  const [leftSearch, setLeftSearch] = React.useState('');
  const [rightSearch, setRightSearch] = React.useState('');

  useEffect(() => {
    setLeft([...props.dataArray])
  }, [props])

  useEffect(() => {
    setRight([])
    setLeftSearch('')
    setRightSearch('')
  }, [props.resetButtonFired])

  useEffect(() => {
    if (leftSearch.length > 0) {
      const filteredArray = leftRenderData && leftRenderData.length > 0 
    ? leftRenderData.filter(vins => vins.includes(leftSearch))
    : (left && left.length > 0
        ? left.filter(vins => vins.includes(leftSearch))
        : []
    );

setLeft(filteredArray);
    } else {
      const initialData = props.dataArray.filter(vins => !rightRenderData.includes(vins));
      const resetArray = leftRenderData.length === 0 ? initialData : leftRenderData;
      setLeft(resetArray);
    }
  }, [leftSearch]);

  useEffect(() => {
    if (rightSearch.length > 0) {
      const filteredArray = [...right] && [...right].length > 0
        ? [...right].filter(vins => vins.includes(rightSearch))
        : [];
      // setRight(rightRenderData.length > 0 ? rightRenderData : filteredArray);
      setRight(filteredArray);
    } else {
      const initialData = [...right].filter(vins => !leftRenderData.includes(vins));
      const resetArray = rightRenderData.length === 0 ? initialData : rightRenderData;
      setRight(resetArray);
      // setRight(rightRenderData);
    }
  }, [rightSearch]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setRightRenderData(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setRightRenderData(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setLeftRenderData(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setLeftRenderData(left.concat(right));
    setRight([]);
  };

  const handleLeftSearchChange = (e) => {
    setLeftSearch(e.target.value)
  }

  const handleRightSearchChange = (e) => {
    setRightSearch(e.target.value)
  }

  const customList = items => (
    <Paper
    sx={{
      height: '10rem',
      overflow: 'auto',
      border: '1px solid #427be6',
      paddingBottom: '1rem',
      minWidth: '10rem',
    }}
    >
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              style={{ padding: '0 1rem' }}
            >
              <ListItemIcon style={{ minWidth: 0 }}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  size='small'
                  style={{ padding: '0 .5rem 0 0' }}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <div className={styles.SearchFieldContainer}>
        <TextField
          id="standard-basic"
          label="Search"
          type="search"
          style={{width: '50%'}}
          disabled={props.dataArray.length === 0}
          value={leftSearch}
          onChange={handleLeftSearchChange}
          variant='standard'
        />
        <TextField
          id="standard-basic"
          label="Search"
          type="search"
          style={{width: '50%'}}
          disabled={props.dataArray.length === 0 || rightRenderData.length === 0}
          value={rightSearch}
          onChange={handleRightSearchChange}
          variant='standard'
        />
      </div>

      <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }} justify="space-evenly" alignItems="center">
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
      </Grid>
    </>
  );
}
