// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm-style .gm-style-iw-c {
  padding: 0;
}
.gm-style .gm-style-iw-c .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-style-iw-c .gm-style-iw-d p {
  margin: 0;
  padding: 0.4rem;
}

.gm-style .gm-style-iw-t::after {
  display: none !important;
}

img[src*="https://maps.gstatic.com/mapfiles/undo_poly.png"] {
  display: none;
}

.layoutdash__image {
  z-index: 200;
}

.lv_tr_dropdown {
  margin-right: 25px;
  height: 35px;
  width: 130px;
  padding-left: 10px;
  border: transparent;
  border-radius: 22px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  cursor: pointer;
}

.layoutdesign_dealer .imggray {
  filter: grayscale(1);
}

.particulate_icon_ST {
  position: absolute;
  margin-top: 10.5%;
  margin-left: 70%;
}

.particulate_icon_ST_bs6 {
  position: absolute;
  margin-top: 10.5%;
  margin-left: 89.5%;
}

.custom-input-mg {
  margin-left: 10px;
}

.trip-menu {
  float: right;
  display: flex;
  margin-right: 7%;
  margin-top: -2%;
  width: 55%;
}

.dealerMapsMain {
  height: 80vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dealermap/dealermapCommon.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,yBAAA;AACJ;AAGE;EACE,SAAA;EACA,eAAA;AADJ;;AAOA;EACE,wBAAA;AAJF;;AAOA;EACE,aAAA;AAJF;;AAOA;EACE,YAAA;AAJF;;AAOA;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,4BAAA;EACA,eAAA;AAJF;;AAQE;EACI,oBAAA;AALN;;AASA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;AANF;;AASA;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;AANF;;AASA;EACE,iBAAA;AANF;;AASA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,UAAA;AANF;;AASA;EACE,YAAA;AANF","sourcesContent":[".gm-style .gm-style-iw-c {\r\n  padding: 0;\r\n\r\n  .gm-style-iw-d {\r\n    overflow: auto !important;\r\n  }\r\n  \r\n\r\n  .gm-style-iw-d p {\r\n    margin: 0;\r\n    padding: .4rem;\r\n  }\r\n}\r\n// .gm-style .gm-style-iw-a {\r\n//   left: 253px !important; top: 19px;\r\n// }\r\n.gm-style .gm-style-iw-t::after {\r\n  display: none !important;\r\n}\r\n\r\nimg[src*=\"https://maps.gstatic.com/mapfiles/undo_poly.png\"] {\r\n  display: none;\r\n}\r\n\r\n.layoutdash__image {\r\n  z-index: 200;\r\n}\r\n\r\n.lv_tr_dropdown {\r\n  margin-right: 25px;\r\n  height: 35px;\r\n  width: 130px;\r\n  padding-left: 10px;\r\n  border: transparent;\r\n  border-radius: 22px !important;\r\n  font-size: 13px !important;\r\n  font-weight: bold !important;\r\n  cursor: pointer;\r\n}\r\n\r\n.layoutdesign_dealer {\r\n  .imggray{\r\n      filter: grayscale(1);\r\n  }\r\n}\r\n\r\n.particulate_icon_ST{\r\n  position: absolute;\r\n  margin-top: 10.5%;\r\n  margin-left: 70%;\r\n}\r\n\r\n.particulate_icon_ST_bs6{\r\n  position: absolute;\r\n  margin-top: 10.5%;\r\n  margin-left: 89.5%;\r\n}\r\n\r\n.custom-input-mg{\r\n  margin-left: 10px;\r\n}\r\n\r\n.trip-menu{\r\n  float: right;\r\n  display: flex;\r\n  margin-right: 7%;\r\n  margin-top: -2%;\r\n  width:55%;\r\n}\r\n\r\n.dealerMapsMain{\r\n  height:80vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
