import React, { Component } from 'react'
import Dealermaps from './FindDealer'

export default class dealersmap extends Component {
    constructor(props){
        super(props);
        this.CircleRef = React.createRef();
        this.circleInstance = null;
        this.state={
            isCircleLoaded: false
        }
    }

    onRadiusChanged=()=>{
        if (!this.circleInstance) return;

        // Retrieve the radius from the circle instance
        const rawDistance = this.circleInstance.getRadius();
        let kms = Math.floor(rawDistance / 1000);
        let displayText = kms;

        // Enforce radius constraints
        if (kms > 200) {
            kms = this.state.kms === 200 ? 201 : 200;
            displayText = 200;
        }

        // Update state with the adjusted radius
        this.setState({ kms, displaytext: displayText });
        this.props.dropdownChangedFunc();
    }

    // Callback function for the Circle's `onLoad` prop
    onCircleLoad = (circle) => {
        this.circleInstance = circle;
        this.setState({ isCircleLoaded: true });
    }

    render() {
        return (
            <>
               <Dealermaps {...this.props} isCircleLoaded={this.state.isCircleLoaded} CircleRef={this.onCircleLoad}  directions={this.state.directiontofuel} displaytexted={this.state.displaytext} kms={this.state.kms} onRadiusChanged={this.onRadiusChanged} dropdown_value={this.props.dropdown_value}/> 
            </>
        )
    }
}
