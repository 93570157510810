import React from 'react';
import {StandaloneSearchBox} from "@react-google-maps/api";
import classnames from 'classnames';
import { GOOGLE_SEARCH_API_KEY } from "../../apiList";

const PlacesWithStandaloneSearchBox = ({
  module,
  placeholder,
  disabled,
  change,
  value,
  name,
  focus,
  item,
  children,
  placeVals,
  bounds,
}) => {
  const searchBoxRef = useRef(null);
  const [places, setPlaces] = useState([]);
  const [placesArr, setPlacesArr] = useState([]);

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const newPlaces = searchBoxRef.current.getPlaces();
      setPlaces(newPlaces);
      placeVals(newPlaces[0]); // Pass the first place to the parent via prop
    }
  };

  return (
    <div
      data-standalone-searchbox=""
      className={classnames(module === "S" ? null : "form-group", "col-lg-11")}
    >
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        bounds={bounds}
        onPlacesChanged={onPlacesChanged}
      >
        <div className="mlf-3">
          <input
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            onChange={change}
            value={value}
            name={name}
            onFocus={focus}
            data-item={item}
            className="form-control custom-control pl-lg-4"
          />
          {children}
        </div>
      </StandaloneSearchBox>
    </div>
  );
};

class AutoCompleteContent extends React.Component{

  constructor(props){
    super(props);
    this.handlePlaces = this.handlePlaces.bind(this);
  }

  handlePlaces=(placeVals)=>{
    //console.log(placeVals);
    this.props.placeVals(placeVals);
  }

  render(){
      return (
          <PlacesWithStandaloneSearchBox placeholder={this.props.placeholder}
            value={this.props.value} change={this.props.change} name={this.props.name} 
            placeVals={this.handlePlaces} focus={this.props.focus} item={this.props.item} 
            disabled={this.props.disabled} module={this.props.module}>
              {this.props.children}
          </PlacesWithStandaloneSearchBox>
      )
  }   
}

export default AutoCompleteContent;