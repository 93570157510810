import React, { useState, useRef, useEffect} from "react";
import { GmapsDispatchThunk } from "../../store/actions/GmapsActions";
import { GoogleMap, LoadScriptNext, useLoadScript, OverlayView, Marker, Polygon, Circle, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import withNavigate from '../../hooks/withNavigate';
// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { postData } from "../../Util";
import styles from "./dealermapview.module.scss";
import TableListView from '../../Components/TableListView/TableListView';
import NewListView from '../../Components/NewListView/NewListView';
import * as apilist from "../../apiList";
import { connect } from "react-redux";
import "./dealermapCommon.scss";
import moment from "moment";
import * as alasql from "alasql";
import XLSX from "xlsx";
import MenuItem from '@mui/material/MenuItem';
import { sortingColumsClassicView } from '../../Util/commonConverter';
import {diffTimeCalculator} from '../../Util/commonConverter';
import { alert_types } from '../../Components/GoogleMap/alertTypes'
import { airPressureCalculation, exhaustCalculation, exhaustCalculationDashboard, exhaustTempCalculation, fluidValueCalculation, getStatusAndSeverity, getStatusOnConditon, oilPressureCalculation, showExhaust, showGearUI, vehicleTypes } from "../../Util/VehicleDashUtils";
import { formatValue, getFuelLevelValue,getFuelLevelUnit } from "../../Components/NewListView/Utils";
import { GOOGLE_MAP_API_KEY } from "../../apiList";

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

const mapContainerStyle = {
  width: '100%',
  height: '80vh'
};

const libraries = ["places", "geometry", "visualization", "drawing"]

let uservehicledate = {};
let shownlistview = {};
const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});
const minZoomOut = {
  minZoom: 3
  // maxZoom: 18,
}
let globalprops = {};

// Define custom styles for the cluster icons
const clusterStyles = [
  {
    url: '/images/Map_screen/blue.png', // Use the imported SVG file as the cluster icon URL
    height: 70, // Adjust the height as needed
    width: 70, // Adjust the width as needed
    textColor: "#fff", // Customize text color (optional)
    textSize: 12, // Customize text size (optional)
  },
  // You can define more styles if you have multiple cluster icon sizes
];

const MapWithAMarkerClusterer = (props) => {
  const {
    selectedMarker,
    key,
    onClick,
    markers,
    dealerLat,
    dealerLong,
    dealerIcon,
    zoomMarkers,
    vehicledash,
    dialviews,
    moreinfovalue,
    closedashboardbox,
    onchangevalue,
    poigroups,
    poigroupvalue,
    poionchange,
    poilatlong,
    showselectpoi,
    poicheckbox,
    showlabelcheckbox,
    showlabelvals,
    radius,
    showCircle,
    circleRef,
    renderCircle,
    ttldatecompare,
    mapCenter,
    zoom,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [circleInstance, setCircleInstance] = useState(null);
  const [circleRadius, setcircleRadius] = useState(100000);
  const [searchCenter, setSearchCenter] = useState({});
  const mapRef = useRef(null);
  const circleRef_Func = useRef(null);
  const searchBoxRef = useRef(null);

  // Load the Google Maps API
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY, // Pass your API key here
    libraries: libraries,
  });

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const onLoad = (map) => {
    mapRef.current = map;
  };

  // Capture the Circle instance when it's loaded
  const onCircleLoad = (circle) => {
    setCircleInstance(circle)
  };

  const onRadiusChanged = () => {
    if (!circleInstance) return; // Check if circleInstance is loaded
  
    const maxRadius = 100000;
    const currentRadius = circleInstance.getRadius();
  
    if (currentRadius <= maxRadius) {
      setcircleRadius(currentRadius);
    } else {
      setcircleRadius(maxRadius);
    }
  };

  const onBoundsChanged = () => {
    if (mapRef.current) {
      setBounds(mapRef.current.getBounds());
      setSearchCenter(mapRef.current.getCenter());
    }
  };

  const onSearchBoxMounted = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach((place) => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });

    const nextMarkers = places.map((place) => ({
      position: place.geometry.location,
    }));

    const nextCenter =
      nextMarkers.length > 0
        ? {
            lat: nextMarkers[0].position.lat(),
            lng: nextMarkers[0].position.lng(),
          }
        : searchCenter;

    setSearchCenter(nextCenter);

    globalprops.searchupdatedata(nextCenter);

    globalprops.gmapsDispatch({
      zoomToMarkers: (map) => {
        if (map) {
          map.fitBounds({
            west: Math.min(nextCenter.lng),
            east: Math.max(nextCenter.lng),
            north: Math.min(nextCenter.lat),
            south: Math.max(nextCenter.lat),
          });
        }
      },
    });
  };

  useEffect(() => {
    globalprops.searchupdatedata(searchCenter);
  }, [searchCenter, globalprops]); 

  if (!isLoaded) return <div>Loading...</div>;

  return zoomMarkers ? (
    <GoogleMap
      zoom={zoom}
      onLoad={onLoad}
      center={mapCenter}
      mapContainerStyle={mapContainerStyle}
    >
      {dealerLat && dealerLong ? (
        <Marker
          noRedraw={true}
          label={{
            text: "dealerLoc",
            color: "black",
            fontWeight: "bold",
            fontSize: "9pt",
            backgroundColor: "white",
          }}
          icon={dealerIcon}
          title={""}
          position={{
            lat: parseFloat(dealerLat),
            lng: parseFloat(dealerLong),
          }}
        ></Marker>
      ) : null}
      <MarkerClusterer
        // onClick={onMarkerClustererClick}
        // averageCenter
        // key={"MK_CL_1"}
        // styles={[
        //   {
        //     textColor: "white",
        //     height: 78,
        //     url: "/images/Map_screen/blue.png",
        //     width: 78,
        //   },
        // ]}
        options={{
          styles: clusterStyles, // Apply custom cluster styles
          gridSize: 60, // Adjust the grid size as needed (optional)
          maxZoom: 15, // Set the maximum zoom level for clustering (optional)
        }}
      >
        {(clusterer) =>
            markers && markers.map((marker, index) => {
            const onClick = props.onClick.bind(this, marker);
            var title = "";
            var fuelevel;
            var fuelimg;
            var tankpercent;
            var tankpercentbs6;
            var batterypercent;
            var batlevel;
            var batimg;
            var gearvalue;
            var speedlevels;
            var gpsspeed;
            var temperaturecool;
            var temperatureclass;
            var airpressuract1;
            var airpressuract2;
            var airpressure1;
            var airpressure2;
            var oilpressurelevels;
            var oilpressuract;
            var airpress2iconimgs;
            var airpress1iconimgs;
            var oilpress2iconimgs;
            var fuelevel_bs6;
            var fuelimg_bs6;
            var tankpercentaddblue;
            var fuelevel_addblue;
            var fuelimg_addblue;
            var tantpercentadbluelevel;
            var extemperaturecool;
            var exhtemperatureclass;

            var currentInfo = marker.currentInfo != undefined ? marker.currentInfo : marker;
            // console.log(currentInfo);

            title =
              title +
              "Reg.No : " +
              marker.regnNumber +
              "\n" +
              "Model : " +
              marker.vehicleModel +
              "\n" +
              "Speed : " +
              currentInfo.gpsSpeed +
              "KMPH \n" +
              "TimeStamp : " +
              moment(currentInfo.localTimeStamp).format(
                "DD-MM-YYYY h:mm:ss A"
              ) +
              "\n" + //MMM DD, YYYY, h:mm:Ss A
              "Location : " +
              currentInfo.location;
            //Fuel color fill Logics
            if(marker.isBS6===2 ){
              tankpercent = Math.round(currentInfo.cng_level_percentage);
            }else{
              tankpercent = Math.round(
                (currentInfo.fuelLevel / marker.fuelTankCap) * 100
              );
            }
            gearvalue =
              currentInfo.gearnumber === null || currentInfo.gearnumber === "N"
                ? "N"
                : currentInfo.gearnumber <= 0
                  ? "N"
                  : parseInt(currentInfo.gearnumber);
            //gearvalue =5;
            // console.log(gearvalue)
            if (tankpercent <= 9) {
              fuelevel = "fuelfill9";
              fuelimg = "fuelfillRed";
            } else if (tankpercent <= 19) {
              fuelevel = "fuelfill19";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 29) {
              fuelevel = "fuelfill20";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 39) {
              fuelevel = "fuelfill30";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 49) {
              fuelevel = "fuelfill40";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 59) {
              fuelevel = "fuelfill50";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 69) {
              fuelevel = "fuelfill60";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 79) {
              fuelevel = "fuelfill70";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 89) {
              fuelevel = "fuelfill80";
              fuelimg = "fuelfillimg";
            } else if (tankpercent <= 98) {
              fuelevel = "fuelfill90";
              fuelimg = "fuelfillimg";
            } else if (tankpercent >= 99) {
              fuelevel = "fuelfill95";
              fuelimg = "fuelfillimg";
            } else {
              fuelevel = "fuelfill90";
              fuelimg = "fuelfillimg";
            }

            //End of Fuel color fill Logics
            //BS6 fuel//

            tankpercentbs6 = Math.round(
              (currentInfo.fuelLevel / marker.fuelTankCap) * 100
            );
            //gearvalue = 5;
            if (tankpercentbs6 <= 9) {
              fuelevel_bs6 = "fuelfill_bs69";
              fuelimg_bs6 = "fuelfillRed_bs6";
            } else if (tankpercentbs6 <= 19) {
              fuelevel_bs6 = "fuelfill_bs619";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 29) {
              fuelevel_bs6 = "fuelfill_bs620";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 39) {
              fuelevel_bs6 = "fuelfill_bs630";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 49) {
              fuelevel_bs6 = "fuelfill_bs640";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 59) {
              fuelevel_bs6 = "fuelfill_bs650";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 69) {
              fuelevel_bs6 = "fuelfill_bs660";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 79) {
              fuelevel_bs6 = "fuelfill_bs670";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 89) {
              fuelevel_bs6 = "fuelfill_bs680";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 <= 98) {
              fuelevel_bs6 = "fuelfill_bs690";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else if (tankpercentbs6 >= 99) {
              fuelevel_bs6 = "fuelfill_bs695";
              fuelimg_bs6 = "fuelfillimg_bs6";
            } else {
              fuelevel_bs6 = "fuelfill_bs690";
              fuelimg_bs6 = "fuelfillimg_bs6";
            }

            //bs6 fuel end//
            // Add Blue//
            tankpercentaddblue = (currentInfo.catalyst_tank_level !== null && currentInfo.catalyst_tank_level !=='') ? formatValue(
              currentInfo.catalyst_tank_level
            ) : 0;
            tantpercentadbluelevel = (currentInfo.catalyst_tank_level !== null && currentInfo.catalyst_tank_level !=='') ? formatValue(
              (currentInfo.catalyst_tank_level / 100) *
              marker.adBlueTankCap
            ) : 0;

            //gearvalue = 5;
            if (tankpercentaddblue <= 9) {
              fuelevel_addblue = "fuelfill_addb9";
              fuelimg_addblue = "fuelfillRed_addb";
            } else if (tankpercentaddblue <= 19) {
              fuelevel_addblue = "fuelfill_addb19";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 29) {
              fuelevel_addblue = "fuelfill_addb20";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 39) {
              fuelevel_addblue = "fuelfill_addb30";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 49) {
              fuelevel_addblue = "fuelfill_addb40";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 59) {
              fuelevel_addblue = "fuelfill_addb50";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 69) {
              fuelevel_addblue = "fuelfill_addb60";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 79) {
              fuelevel_addblue = "fuelfill_addb70";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 89) {
              fuelevel_addblue = "fuelfill_addb80";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue <= 98) {
              fuelevel_addblue = "fuelfill_addb90";
              fuelimg_addblue = "fuelfillimg_addb";
            } else if (tankpercentaddblue >= 99) {
              fuelevel_addblue = "fuelfill_addb95";
              fuelimg_addblue = "fuelfillimg_addb";
            } else {
              fuelevel_addblue = "fuelfill_addb90";
              fuelimg_addblue = "fuelfillimg_addb";
            }
            //End of Add Blue//

            //Battery Logic//
            var batterythreshold;
            if (marker.batteryType === "12V Battery") {
              batterythreshold = 18;
            } else {
              batterythreshold = 32;
            }
            batterypercent =
              (currentInfo.battPotential / batterythreshold) * 100;
            if (batterypercent <= 9) {
              batlevel = "batteryfill9";
              batimg = "batteryfillred";
            } else if (batterypercent <= 19) {
              batlevel = "batteryfill19";
              batimg = "batteryfillred";
            } else if (batterypercent <= 29) {
              batlevel = "batteryfill20";
              batimg = "batteryfillred";
            } else if (batterypercent <= 39) {
              batlevel = "batteryfill30";
              batimg = "batteryfillred";
            } else if (batterypercent <= 49) {
              batlevel = "batteryfill40";
              batimg = "batteryfillred";
            } else if (batterypercent < 56) {
              batlevel = "batteryfill40";
              batimg = "batteryfillred";
            } else if (batterypercent <= 59) {
              batlevel = "batteryfill50";
              batimg = "batteryfill";
            } else if (batterypercent <= 69) {
              batlevel = "batteryfill60";
              batimg = "batteryfill";
            } else if (batterypercent <= 79) {
              batlevel = "batteryfill70";
              batimg = "batteryfill";
            } else if (batterypercent <= 89) {
              batlevel = "batteryfill80";
              batimg = "batteryfill";
            } else if (batterypercent >= 90) {
              batlevel = "batteryfill90";
              batimg = "batteryfill";
            } else {
              batlevel = "batteryfill90";
              batimg = "batteryfill";
            }
            //End of battery logic//
            gpsspeed = Math.round(currentInfo.gpsSpeed);
            if (gpsspeed === 0) {
              speedlevels = "speedfill";
            } else if (gpsspeed <= 5) {
              speedlevels = "speedfill5";
            } else if (gpsspeed <= 10) {
              speedlevels = "speedfill10";
            } else if (gpsspeed <= 15) {
              speedlevels = "speedfill15";
            } else if (gpsspeed <= 20) {
              speedlevels = "speedfill20";
            } else if (gpsspeed <= 25) {
              speedlevels = "speedfill25";
            } else if (gpsspeed <= 30) {
              speedlevels = "speedfill30";
            } else if (gpsspeed <= 35) {
              speedlevels = "speedfill35";
            } else if (gpsspeed <= 40) {
              speedlevels = "speedfill40";
            } else if (gpsspeed <= 45) {
              speedlevels = "speedfill45";
            } else if (gpsspeed <= 50) {
              speedlevels = "speedfill50";
            } else if (gpsspeed <= 55) {
              speedlevels = "speedfill55";
            } else if (gpsspeed <= 60) {
              speedlevels = "speedfill60";
            } else if (gpsspeed <= 65) {
              speedlevels = "speedfill65";
            } else if (gpsspeed <= 70) {
              speedlevels = "speedfill70";
            } else if (gpsspeed <= 80) {
              speedlevels = "speedfill80";
            } else if (gpsspeed <= 89) {
              speedlevels = "speedfill90";
            } else if (gpsspeed >= 90) {
              speedlevels = "speedfill95";
            }
            //speed color changes//
            //End of speed color //
            //Temperature//
            temperaturecool = Math.round(
              (currentInfo.eng_coolant_temp / 120) * 100
            );
            if (temperaturecool === 0) {
              temperatureclass = "tempfill";
            } else if (temperaturecool <= 5) {
              temperatureclass = "tempfill5";
            } else if (temperaturecool <= 10) {
              temperatureclass = "tempfill10";
            } else if (temperaturecool <= 15) {
              temperatureclass = "tempfill15";
            } else if (temperaturecool <= 20) {
              temperatureclass = "tempfill20";
            } else if (temperaturecool <= 25) {
              temperatureclass = "tempfill25";
            } else if (temperaturecool <= 30) {
              temperatureclass = "tempfill30";
            } else if (temperaturecool <= 35) {
              temperatureclass = "tempfill35";
            } else if (temperaturecool <= 40) {
              temperatureclass = "tempfill40";
            } else if (temperaturecool <= 45) {
              temperatureclass = "tempfill45";
            } else if (temperaturecool <= 50) {
              temperatureclass = "tempfill50";
            } else if (temperaturecool <= 55) {
              temperatureclass = "tempfill55";
            } else if (temperaturecool <= 60) {
              temperatureclass = "tempfill60";
            } else if (temperaturecool <= 65) {
              temperatureclass = "tempfill65";
            } else if (temperaturecool <= 70) {
              temperatureclass = "tempfill70";
            } else if (temperaturecool <= 75) {
              temperatureclass = "tempfill75";
            } else if (temperaturecool <= 80) {
              temperatureclass = "tempfill80";
            } else if (temperaturecool <= 85) {
              temperatureclass = "tempfill85";
            } else if (temperaturecool <= 90) {
              temperatureclass = "tempfill90";
            } else if (temperaturecool >= 91) {
              temperatureclass = "tempfill95";
            }
            //End of Temperature
            //Exhault //
            extemperaturecool = Math.round(
              currentInfo.aft1_exhaust_temp_1
            );
            if (extemperaturecool === 0) {
              exhtemperatureclass = "extempfill";
            } else if (extemperaturecool <= 45) {
              exhtemperatureclass = "extempfill5";
            } else if (extemperaturecool <= 90) {
              exhtemperatureclass = "extempfill10";
            } else if (extemperaturecool <= 135) {
              exhtemperatureclass = "extempfill15";
            } else if (extemperaturecool <= 180) {
              exhtemperatureclass = "extempfill20";
            } else if (extemperaturecool <= 225) {
              exhtemperatureclass = "extempfill25";
            } else if (extemperaturecool <= 270) {
              exhtemperatureclass = "extempfill30";
            } else if (extemperaturecool <= 315) {
              exhtemperatureclass = "extempfill35";
            } else if (extemperaturecool <= 360) {
              exhtemperatureclass = "extempfill40";
            } else if (extemperaturecool <= 405) {
              exhtemperatureclass = "extempfill45";
            } else if (extemperaturecool <= 450) {
              exhtemperatureclass = "extempfill50";
            } else if (extemperaturecool <= 495) {
              exhtemperatureclass = "extempfill55";
            } else if (extemperaturecool <= 540) {
              exhtemperatureclass = "extempfill60";
            } else if (extemperaturecool <= 585) {
              exhtemperatureclass = "extempfill65";
            } else if (extemperaturecool <= 630) {
              exhtemperatureclass = "extempfill70";
            } else if (extemperaturecool <= 675) {
              exhtemperatureclass = "extempfill75";
            } else if (extemperaturecool <= 720) {
              exhtemperatureclass = "extempfill80";
            } else if (extemperaturecool <= 765) {
              exhtemperatureclass = "extempfill85";
            } else if (extemperaturecool <= 810) {
              exhtemperatureclass = "extempfill90";
            } else if (extemperaturecool >= 855) {
              exhtemperatureclass = "extempfill95";
            } else {
              exhtemperatureclass = "extempfill";
            }
            //end of Exhault//
            //Air Pressure logic//
            if (marker.isBS6 === 1) {
              airpressuract1 = Math.round(
                currentInfo.airpressure_actual1
              );
              airpressuract2 = Math.round(
                currentInfo.airpressure_actual2
              );
              if (airpressuract1 === 0) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress";
              } else if (airpressuract1 === 1) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress1";
              } else if (airpressuract1 === 2) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress2";
              } else if (airpressuract1 === 3) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress3";
              } else if (airpressuract1 === 4) {
                airpress1iconimgs = "airpress1iconimgred";
                airpressure1 = "airpress4";
              } else if (airpressuract1 === 5) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress5";
              } else if (airpressuract1 === 6) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress6";
              } else if (airpressuract1 === 7) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress7";
              } else if (airpressuract1 === 8) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress8";
              } else if (airpressuract1 === 9) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress9";
              } else if (airpressuract1 === 10) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress10";
              } else if (airpressuract1 === 11) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress11";
              } else if (airpressuract1 === 12) {
                airpress1iconimgs = "airpress1iconimg";
                airpressure1 = "airpress12";
              }
              if (airpressuract2 === 0) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec";
              } else if (airpressuract2 === 1) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec1";
              } else if (airpressuract2 === 2) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec2";
              } else if (airpressuract2 === 3) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec3";
              } else if (airpressuract2 === 4) {
                airpress2iconimgs = "airpress1iconimg2red";
                airpressure2 = "airpresssec4";
              } else if (airpressuract2 === 5) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec5";
              } else if (airpressuract2 === 6) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec6";
              } else if (airpressuract2 === 7) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec7";
              } else if (airpressuract2 === 8) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec8";
              } else if (airpressuract2 === 9) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec9";
              } else if (airpressuract2 === 10) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec10";
              } else if (airpressuract2 === 11) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec11";
              } else if (airpressuract2 === 12) {
                airpress2iconimgs = "airpress1iconimg2";
                airpressure2 = "airpresssec12";
              }
            } else {
              if (marker.vehicleType === "EEA") {
                airpressuract1 = Math.round(
                  currentInfo.airpressure_actual1
                );
                airpressuract2 = Math.round(
                  currentInfo.airpressure_actual2
                );
                if (airpressuract1 === 0) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress";
                } else if (airpressuract1 === 1) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress1";
                } else if (airpressuract1 === 2) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress2";
                } else if (airpressuract1 === 3) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress3";
                } else if (airpressuract1 === 4) {
                  airpress1iconimgs = "airpress1iconimgred";
                  airpressure1 = "airpress4";
                } else if (airpressuract1 === 5) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress5";
                } else if (airpressuract1 === 6) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress6";
                } else if (airpressuract1 === 7) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress7";
                } else if (airpressuract1 === 8) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress8";
                } else if (airpressuract1 === 9) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress9";
                } else if (airpressuract1 === 10) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress10";
                } else if (airpressuract1 === 11) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress11";
                } else if (airpressuract1 === 12) {
                  airpress1iconimgs = "airpress1iconimg";
                  airpressure1 = "airpress12";
                }
                if (airpressuract2 === 0) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec";
                } else if (airpressuract2 === 1) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec1";
                } else if (airpressuract2 === 2) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec2";
                } else if (airpressuract2 === 3) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec3";
                } else if (airpressuract2 === 4) {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure2 = "airpresssec4";
                } else if (airpressuract2 === 5) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec5";
                } else if (airpressuract2 === 6) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec6";
                } else if (airpressuract2 === 7) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec7";
                } else if (airpressuract2 === 8) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec8";
                } else if (airpressuract2 === 9) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec9";
                } else if (airpressuract2 === 10) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec10";
                } else if (airpressuract2 === 11) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec11";
                } else if (airpressuract2 === 12) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure2 = "airpresssec12";
                }
              } else {
                if (currentInfo.air_pressure_digital !== 0) {
                  airpress2iconimgs = "airpress1iconimg2";
                  airpressure1 = "airpress12";
                  airpressure2 = "airpresssec12";
                } else {
                  airpress2iconimgs = "airpress1iconimg2red";
                  airpressure1 = "airpress";
                  airpressure2 = "airpresssec";
                }
              }
            }

            //End of Air Pressure logic//
            //oil Pressure//
            if (marker.vehicleType === "IL") {
              if (currentInfo.engineOilDig !== 0) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl";
              } else {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillszero";
              }
            } else {
              oilpressuract = Math.round(currentInfo.engine_oil_act);
              if (oilpressuract === 0) {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillszero";
              } else if (oilpressuract === 1) {
                oilpress2iconimgs = "oilpressiconimgred";
                oilpressurelevels = "oilfillslvl1";
              } else if (oilpressuract === 2) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl2";
              } else if (oilpressuract === 3) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl3";
              } else if (oilpressuract === 4) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl4";
              } else if (oilpressuract === 5) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl5";
              } else if (oilpressuract === 6) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl6";
              } else if (oilpressuract === 7) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl7";
              } else if (oilpressuract === 8) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl8";
              } else if (oilpressuract === 9) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl9";
              } else if (oilpressuract === 10) {
                oilpress2iconimgs = "oilpressiconimg";
                oilpressurelevels = "oilfillslvl";
              }
            }
            //End of oil pressure//
            let isHideAirPressure = marker.isBS6 === 0 && (marker.vehicleType === "IL" || marker.vehicleType === "EDC")
            return (
              <Marker
                label={
                  showlabelvals === true
                    ? {
                      text: marker.regnNumber,
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "9pt",
                      backgroundColor: "white",
                    }
                    : ""
                }
                key={"Mk_" + index}
                icon={marker.icons}
                clusterer={clusterer}
                title={title}
                position={{
                  lat: parseFloat(currentInfo.latitude),
                  lng: parseFloat(currentInfo.longitude),
                }}
                onClick={onClick}
              >
                {selectedMarker.obuId === marker.obuId &&
                  vehicledash === true && (
                    <OverlayView
                      position={{
                        lat: parseFloat(currentInfo.latitude),
                        lng: parseFloat(currentInfo.longitude),
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffsetlay={getPixelPositionOffset}
                    >
                      <div style={{
                          position: 'absolute',
                          width: 'auto', // Set width
                          height: 'auto', // Set height
                          zIndex: '99',
                        }}>
                      <div key={"dv" + index} className="layoutdesign">
                        <div className="ign_icon dash_title">
                          <img
                            className={
                              currentInfo.ignitionStatus !== 1
                                ? "imggray"
                                : ""
                            }
                            src="images/Vehicle_Dashboard/Ignition.png"
                            alt="dash"
                          />{" "}
                          IGNITION
                        </div>
                        <div className="dash_title">
                          <div className="dash_vehnumber">
                            REG.NUMBER <br />
                            {marker.regnNumber}
                          </div>
                          <div className="dash_uptime">
                            LAST UPDATED ON
                            <br />
                            {moment(currentInfo.localTimeStamp).format(
                              "DD-MM-YYYY h:mm:ss A"
                            )}
                          </div>{" "}
                          {/*MMM DD, YYYY, h:mm:ss A*/}
                          <div className="dash_drivernm">
                            DRIVER
                            <br />
                            {marker.driverName}
                          </div>
                          <div className="dash_location">
                            LOCATION
                            <br />
                            <span
                              style={{
                                width: "263px",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: "-6px 0px",
                              }}
                              className="ffu13"
                              title={currentInfo.location}
                            >
                              {currentInfo.location}
                            </span>
                          </div>
                        </div>
                        {/* {marker.alert_type_id===9 ?<div className="edc_icon"><img src="images/Vehicle_Dashboard/ambur-color/2.png"  alt="dash"/></div>:<div className="edc_icon"><img src="images/Vehicle_Dashboard/grey-color/2.png"  alt="dash"/></div>} */}
                        {new Date(currentInfo.localTimeStamp) >
                          ttldatecompare ? (
                          currentInfo.eng_coolant_temp >= 105 ? (
                            <div className="temp_icon">
                              <img
                                src="images/Vehicle_Dashboard/ambur-color/6.png"
                                alt="dash"
                                title={alert_types.temp_warning}
                              />
                            </div>
                          ) : (
                            <div className="temp_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/6.png"
                                alt="dash"
                                title={alert_types.temp_warning}
                              />
                            </div>
                          )
                        ) : (
                          <div className="temp_icon">
                            <img
                              src="images/Vehicle_Dashboard/grey-color/6.png"
                              alt="dash"
                              title={alert_types.temp_warning}
                            />
                          </div>
                        )}
                        {new Date(currentInfo.localTimeStamp) >
                          ttldatecompare ? (
                          currentInfo.ignitionStatus === 1 ? (
                            marker.batteryType === "12V Battery" ? (
                              currentInfo.battPotential <= 9 ? (
                                <div className="battery_icon">
                                  <img
                                    src="images/Vehicle_Dashboard/ambur-color/10.png"
                                    alt="dash"
                                    title={alert_types.low_battery_voltage}
                                  />
                                </div>
                              ) : (
                                <div className="battery_icon">
                                  <img
                                    src="images/Vehicle_Dashboard/grey-color/10.png"
                                    alt="dash"
                                    title={alert_types.low_battery_voltage}
                                  />
                                </div>
                              )
                            ) : marker.batteryType === "24V Battery" ? (
                              currentInfo.battPotential <= 17 ? (
                                <div className="battery_icon">
                                  <img
                                    src="images/Vehicle_Dashboard/ambur-color/10.png"
                                    alt="dash"
                                    title={alert_types.low_battery_voltage}
                                  />
                                </div>
                              ) : (
                                <div className="battery_icon">
                                  <img
                                    src="images/Vehicle_Dashboard/grey-color/10.png"
                                    alt="dash"
                                    title={alert_types.low_battery_voltage}
                                  />
                                </div>
                              )
                            ) : (
                              <div className="battery_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/10.png"
                                  alt="dash"
                                  title={alert_types.low_battery_voltage}
                                />
                              </div>
                            )
                          ) : (
                            <div className="battery_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/10.png"
                                alt="dash"
                                title={alert_types.low_battery_voltage}
                              />
                            </div>
                          )
                        ) : (
                          <div className="battery_icon">
                            <img
                              src="images/Vehicle_Dashboard/grey-color/10.png"
                              alt="dash"
                              title={alert_types.low_battery_voltage}
                            />
                          </div>
                        )}
                        {marker.isBS6 === 1 || marker.isBS6 === 2 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.edc_lamp_status === 1 ? (
                              <div className="edc_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/2.png"
                                  alt="dash"
                                  title={alert_types.edc_warning}
                                />
                              </div>
                            ) : (
                              <div className="edc_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/2.png"
                                  alt="dash"
                                  title={alert_types.edc_warning}
                                />
                              </div>
                            )
                          ) : (
                            <div className="edc_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/2.png"
                                alt="dash"
                                title={alert_types.edc_warning}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 || marker.isBS6 === 2 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.ecu_mil_status === 4 ? (
                              <div className="mil_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/1.png"
                                  alt="dash"
                                  title={alert_types.mil_status}
                                />
                              </div>
                            ) : (
                              <div className="mil_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/1.png"
                                  alt="dash"
                                  title={alert_types.mil_status}
                                />
                              </div>
                            )
                          ) : (
                            <div className="mil_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/1.png"
                                alt="dash"
                                title={alert_types.mil_status}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 || marker.isBS6 === 2 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.stop_status === 1 ? (
                              <div className="stp_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/3.png"
                                  alt="dash"
                                  title={alert_types.stop_lamp}
                                />
                              </div>
                            ) : (
                              <div className="stp_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/3.png"
                                  alt="dash"
                                  title={alert_types.stop_lamp}
                                />
                              </div>
                            )
                          ) : (
                            <div className="stp_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/3.png"
                                alt="dash"
                                title={alert_types.stop_lamp}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo
                              .exhaust_system_high_temp_lamp_command === 1 ? (
                              <div className="hext_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/4.png"
                                  alt="dash"
                                  title={alert_types.high_exa_temp}
                                />
                              </div>
                            ) : (
                              <div className="hext_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/4.png"
                                  alt="dash"
                                  title={alert_types.high_exa_temp}
                                />
                              </div>
                            )
                          ) : (
                            <div className="hext_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/4.png"
                                alt="dash"
                                title={alert_types.high_exa_temp}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.dpf1_regen_needed >= 1 &&
                              currentInfo.dpf1_regen_needed <= 3 ? (
                              <div className="dpf_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/5.png"
                                  alt="dash"
                                  title={alert_types.regeneration}
                                />
                              </div>
                            ) : (
                              <div className="dpf_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/5.png"
                                  alt="dash"
                                  title={alert_types.regeneration}
                                />
                              </div>
                            )
                          ) : (
                            <div className="dpf_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/5.png"
                                alt="dash"
                                title={alert_types.regeneration}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.nox_error >= 1 &&
                              currentInfo.nox_error <= 2 ? (
                              <div className="nox_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/7.png"
                                  alt="dash"
                                  title={alert_types.nox_error}
                                />
                              </div>
                            ) : (
                              <div className="nox_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/7.png"
                                  alt="dash"
                                  title={alert_types.nox_error}
                                />
                              </div>
                            )
                          ) : (
                            <div className="nox_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/7.png"
                                alt="dash"
                                title={alert_types.nox_error}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 || marker.isBS6 === 2 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.air_filter_blocked === 1 ? (
                              <div className="airfilbck_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/8.png"
                                  alt="dash"
                                  title={alert_types.air_filter_blocked}
                                />
                              </div>
                            ) : (
                              <div className="airfilbck_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/8.png"
                                  alt="dash"
                                  title={alert_types.air_filter_blocked}
                                />
                              </div>
                            )
                          ) : (
                            <div className="airfilbck_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/8.png"
                                alt="dash"
                                title={alert_types.air_filter_blocked}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 || marker.isBS6 === 2 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.coolant_level_warning === 1 ? (
                              <div className="lowcoollvl_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/9.png"
                                  alt="dash"
                                  title={alert_types.low_coolant_level}
                                />
                              </div>
                            ) : (
                              <div className="lowcoollvl_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/9.png"
                                  alt="dash"
                                  title={alert_types.low_coolant_level}
                                />
                              </div>
                            )
                          ) : (
                            <div className="lowcoollvl_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/9.png"
                                alt="dash"
                                title={alert_types.low_coolant_level}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {marker.isBS6===1 || marker.isBS6===2 ? new Date(currentInfo.localTimeStamp)>ttldatecompare?currentInfo.particulate_trap_regeneration_inhibit_switch === 1 ? <div className="particulate_icon"><img src="images/Vehicle_Dashboard/ambur-color/12.png"  alt="dash" title={alert_types.particulate_trap}/></div>:<div className="particulate_icon"><img src="images/Vehicle_Dashboard/grey-color/12.png"  alt="dash" title={alert_types.particulate_trap}/></div>:<div className="particulate_icon"><img src="images/Vehicle_Dashboard/grey-color/12.png"  alt="dash" title={alert_types.particulate_trap}/></div>:''}
                        {marker.isBS6 === 1 ? (
                          new Date(currentInfo.localTimeStamp) >
                            ttldatecompare ? (
                            currentInfo.low_adblue_level_warning >= 1 &&
                              currentInfo.low_adblue_level_warning <= 7 ? (
                              <div className="lowadblue_icon">
                                <img
                                  src="images/Vehicle_Dashboard/ambur-color/11.png"
                                  alt="dash"
                                  title={alert_types.low_def_level}
                                />
                              </div>
                            ) : (
                              <div className="lowadblue_icon">
                                <img
                                  src="images/Vehicle_Dashboard/grey-color/11.png"
                                  alt="dash"
                                  title={alert_types.low_def_level}
                                />
                              </div>
                            )
                          ) : (
                            <div className="lowadblue_icon">
                              <img
                                src="images/Vehicle_Dashboard/grey-color/11.png"
                                alt="dash"
                                title={alert_types.low_def_level}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        <div className="battery_img">
                          <img
                            src="images/Vehicle_Dashboard/Battery_Status.png"
                            alt="dash"
                          />
                          <div className="battery_txt">BATTERY</div>
                          <br />
                          <div className="battert_val">
                            {currentInfo.ignitionStatus === 1
                              ? currentInfo.battPotential === null
                                ? ""
                                : currentInfo.battPotential.toFixed(2)
                              : ""}
                          </div>
                          <div className="batteryunit">
                            {currentInfo.ignitionStatus === 1 ? "v" : ""}
                          </div>
                          <div
                            className={
                              currentInfo.ignitionStatus === 1
                                ? batimg
                                : "batteryfillgrey"
                            }
                          >
                            {currentInfo.ignitionStatus === 1 ? (
                              <img
                                className={batlevel}
                                src="images/Vehicle_Dashboard/Battery_Status.png"
                                alt="dash"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {currentInfo.ignitionStatus === 1 ? currentInfo.gsas_comp > 95 && currentInfo.gsas_comp <= 100 ? <div >
                          <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div>
                          <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div>
                          <div className="thumbsup_cls3"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div>
                        </div> : currentInfo.gsas_comp > 90 && currentInfo.gsas_comp <= 95 ? <div >
                          <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div>
                          <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div>
                        </div> : currentInfo.gsas_comp > 85 && currentInfo.gsas_comp <= 90 ? <div >
                          <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsup.png" alt="dash" />
                          </div></div> : currentInfo.gsas_comp > 80 && currentInfo.gsas_comp <= 85 ? <div >
                            <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                            </div></div> : currentInfo.gsas_comp > 75 && currentInfo.gsas_comp <= 80 ? <div >
                              <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                              </div>
                              <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                              </div>
                            </div> : currentInfo.gsas_comp > 0 && currentInfo.gsas_comp <= 75 ? <div >
                              <div className="thumbsup_cls"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                              </div>
                              <div className="thumbsup_cls2"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                              </div>
                              <div className="thumbsup_cls3"><img className="thumbsupimg" src="images/Vehicle_Dashboard/thumbsdown.png" alt="dash" />
                              </div>
                            </div> : '' : ''}
                        {marker.isBS6 === 1 ? (
                          <div className="fuelperctext_bs6">
                            {tankpercentbs6 > 100
                              ? 100
                              : tankpercentbs6 < 0
                                ? 0
                                : tankpercentbs6}
                            %
                          </div>
                        ) : (
                          <div className="fuelperctext">
                            {tankpercent > 100
                              ? 100
                              : tankpercent < 0
                                ? 0
                                : tankpercent}
                            %
                          </div>
                        )}
                        {marker.isBS6 === 1 ? (
                          <div className="fuelbsiv_bs6">
                            <img
                              src="images/Vehicle_Dashboard/Fuel_bs6.png"
                              alt="dash"
                            />
                            <div className={fuelimg_bs6}>
                              <img
                                className={fuelevel_bs6}
                                src="images/Vehicle_Dashboard/Fuel_bs6.png"
                                alt="dash"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="fuelbsiv">
                            <img
                              src="images/Vehicle_Dashboard/BS_iv.png"
                              alt="dash"
                            />
                            <div className={fuelimg}>
                              <img
                                className={fuelevel}
                                src="images/Vehicle_Dashboard/BS_iv.png"
                                alt="dash"
                              />
                            </div>
                          </div>
                        )}
                        {marker.isBS6 === 1 ? <div className="fuel_text_bs6">FUEL</div> : marker.isBS6 === 2 ? <div className="fuel_text_bs6">GAS</div> : <div className="fuel_text">FUEL</div>}
                        <div className="fuel_val">
                          {(currentInfo.fuelLevel !== null && currentInfo.fuelLevel !== '') ? getFuelLevelValue(currentInfo.fuelLevel) : 0}
                          <div className="fuel_units">&nbsp;{getFuelLevelUnit(marker.isBS6, marker.vehicleType)}</div>
                        </div>
                        {marker.isBS6 === 1 ? (
                          <div className="addbluetext">
                            {tankpercentaddblue}%
                          </div>
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          <div className="addblue">
                            <img
                              src="images/Vehicle_Dashboard/Adblue_bs6.png"
                              alt="dash"
                            />
                            <div className={fuelimg_addblue}>
                              <img
                                className={fuelevel_addblue}
                                src="images/Vehicle_Dashboard/Adblue_bs6.png"
                                alt="dash"
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          <div className="addblue_text">DEF</div>
                        ) : (
                          ""
                        )}
                        {marker.isBS6 === 1 ? (
                          <div className="addblue_val">
                            {formatValue(tantpercentadbluelevel)}
                            <div className="addblue_units">&nbsp;ltr</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="roadings">
                          <img
                            src="images/Vehicle_Dashboard/roads.png"
                            alt="dash"
                          />
                        </div>
                        <div className="road_text">ODO</div>
                        <div className="road_val">
                        {currentInfo.isBS6===1 || currentInfo.isBS6===2  ? currentInfo.vehicle_distance != undefined && (currentInfo.vehicle_distance !== null || currentInfo.vehicle_distance !== '')? Math.round(currentInfo.vehicle_distance):'0':marker.vehicleType === "EEA"?Math.round(currentInfo.vehicle_odo):Math.round(currentInfo.gps_odo)}
                          <div className="road_units">&nbsp;kms</div>
                        </div>
                        <div className="engHrs">
                          <img
                            src="images/Vehicle_Dashboard/Engine_Hours.png"
                            alt="engHrs.icon"
                          />
                        </div>
                        {marker.vehicleType !== "IL" ?
                          <div className="enginehrs_text">ENG HRS</div>
                          : ''
                        }
                        {marker.vehicleType !== "IL" ?
                          <div className="enginehrs_text_val">{(currentInfo.engineHrs !==null && currentInfo.engineHrs !=='') ? Math.round(currentInfo.engineHrs) : 0}
                            <span className="enginehrsspan"> hrs</span>
                          </div>
                          : ''
                        }
                        <div className="cool_text">COOLANT</div>
                        <div className="cool_val">
                          {currentInfo.ignitionStatus === 1 && currentInfo.eng_coolant_temp < 0
                          ? 0
                            : Math.round(currentInfo.eng_coolant_temp)}
                          <div className="cool_units"><sup>o</sup>C</div>
                        </div>
                        <div className="tempicon">
                          <img
                            src="images/Vehicle_Dashboard/Temperaturegray.png"
                            alt="dash"
                          />
                          <div className="tempfillimg">
                            <img
                              className={currentInfo.ignitionStatus === 1 ? temperatureclass : 'tempfill'}
                              src="images/Vehicle_Dashboard/Temperature.png"
                              alt="dash"
                            />
                          </div>
                        </div>
                        {marker.isBS6 === 1 ? (
                          <div className="exhtempicon">
                            <img
                              src="images/Vehicle_Dashboard/Temperaturegray.png"
                              alt="dash"
                            />
                            <div className="exhfillimg">
                              <img
                                className={currentInfo.ignitionStatus === 1 ? exhtemperatureclass : 'extempfill'}
                                src="images/Vehicle_Dashboard/Temperature.png"
                                alt="dash"
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {showExhaust(marker.isBS6) ? (
                          <div className="ext_text">EXHAUST</div>
                        ) : (
                          ""
                        )}
                        {showExhaust(marker.isBS6) ? (
                          <div className="ext_val">
                            {exhaustCalculationDashboard(marker.currentInfo)}
                            <div className="ext_units"><sup>0</sup>C</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isHideAirPressure ? '' : <div className="airpress1icon">
                          <img
                            src="images/Vehicle_Dashboard/PressureScale.png"
                            alt="dash"
                          />
                          <div
                            className={
                              marker.vehicleType === "EEA"
                                ? airpress1iconimgs
                                : airpress2iconimgs
                            }
                          >
                            <img
                              className={currentInfo.ignitionStatus === 1 ? airpressure1 : 'airpress'}
                              src="images/Vehicle_Dashboard/Pressure_Green_Scale.png"
                              alt="dash"
                            />
                          </div>
                        </div>}
                        {isHideAirPressure ? '' : <div className="air1_text0">0</div>}
                        {isHideAirPressure ? '' :marker.isBS6 === 1 ? (
                          <div className="air1_text6">6</div>
                        ) : marker.vehicleType === "EEA" ? (
                          <div className="air1_text6">6</div>
                        ) : (
                          ""
                        )}
                        {isHideAirPressure ? '' : (marker.isBS6 === 1 ? (
                          <div className="air1_text12">12</div>
                        ) : marker.vehicleType === "EEA" ? (
                          <div className="air1_text12">12</div>
                        ) : (
                          <div className="air1_text12">1</div>
                        ))}
                        {isHideAirPressure ? '' : <div className="air2_text0">0</div>}
                        {isHideAirPressure ? '' : marker.isBS6 === 1 ? (
                          <div className="air2_text6">6</div>
                        ) : marker.vehicleType === "EEA" ? (
                          <div className="air2_text6">6</div>
                        ) : (
                          ""
                        )}
                        {isHideAirPressure ? '' : marker.isBS6 === 1 ? (
                          <div className="air2_text12">12</div>
                        ) : marker.vehicleType === "EEA" ? (
                          <div className="air2_text12">12</div>
                        ) : (
                          <div className="air2_text12">1</div>
                        )}
                        {marker.oilPressure === true ? (
                          <div className="oil1_text0">0</div>
                        ) : (
                          ""
                        )}
                        {marker.oilPressure === true ? (
                          marker.isBS6 === 1 ? (
                            <div className="oil1_text6">6</div>
                          ) : marker.vehicleType !== "IL" ? (
                            <div className="oil1_text6">6</div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {marker.oilPressure === true ? (
                          marker.isBS6 === 1 ? (
                            <div className="oil1_text12">12</div>
                          ) : marker.vehicleType !== "IL" ? (
                            <div className="oil1_text12">12</div>
                          ) : (
                            <div className="oil1_text12">1</div>
                          )
                        ) : (
                          ""
                        )}
                        {isHideAirPressure ? '' : <div className="air1_text">
                          <strong>
                            AIR
                            <br /> PRESSURE 1
                          </strong>
                        </div>}
                        {isHideAirPressure ? '' : <div className="air1_val">
                          {currentInfo.ignitionStatus === 1 ? marker.isBS6 === 1
                            ? airpressuract1
                            : marker.vehicleType === "EEA"
                              ? airpressuract1
                              : "" : ""}
                          <div className="air1_units">
                            {currentInfo.ignitionStatus === 1 ? marker.isBS6 === 1
                              ? "bar"
                              : marker.vehicleType === "EEA"
                                ? "bar"
                                : "" : ""}
                          </div>
                        </div>}
                        {isHideAirPressure ? '' : <div className="airpress2icon">
                          <img
                            src="images/Vehicle_Dashboard/PressureScale.png"
                            alt="dash"
                          />
                          <div className={airpress2iconimgs}>
                            <img
                              className={currentInfo.ignitionStatus === 1 ? airpressure2 : 'airpresssec'}
                              src="images/Vehicle_Dashboard/Pressure_Green_Scale.png"
                              alt="dash"
                            />
                          </div>
                        </div>}
                        {marker.oilPressure === true ? (
                          <div className="air2_text">
                            <strong>
                              OIL
                              <br /> PRESSURE{" "}
                            </strong>
                          </div>
                        ) : (
                          ""
                        )}
                        {marker.oilPressure === true ? (
                          <div className="air2_val">
                            {currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? "" : oilpressuract : ""}
                            <div className="air2_units">
                              {currentInfo.ignitionStatus === 1 ? marker.vehicleType === "IL" ? "" : "bar" : ""}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {marker.oilPressure === true ? (
                          <div className="oilpressicon">
                            <img
                              src="images/Vehicle_Dashboard/PressureScale.png"
                              alt="dash"
                            />
                            <div className={oilpress2iconimgs}>
                              <img
                                className={currentInfo.ignitionStatus === 1 ? oilpressurelevels : 'oilfillszero'}
                                src="images/Vehicle_Dashboard/Pressure_Green_Scale.png"
                                alt="dash"
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isHideAirPressure ? '' : <div className="oil_text">
                          <strong>
                            AIR
                            <br /> PRESSURE 2
                          </strong>
                        </div>}
                        <div className="oil_val">
                          {currentInfo.ignitionStatus === 1 ? marker.isBS6 === 1
                            ? airpressuract2
                            : marker.vehicleType === "EEA"
                              ? airpressuract2
                              : "" : ""}
                          <div className="oil_units">
                            {currentInfo.ignitionStatus === 1 ? marker.isBS6 === 1
                              ? "bar"
                              : marker.vehicleType === "EEA"
                                ? "bar"
                                : "" : ""}
                          </div>
                        </div>
                        <div className="criticaldash">
                          <img
                            src="images/Vehicle_Dashboard/Criticaldash.png"
                            alt="dash"
                          />
                        </div>
                        <div className="critic_text">
                          CRITICAL
                          <br /> ALERT
                        </div>
                        <div className="critic_val">
                          {marker.criccnt !== undefined ? marker.criccnt : 0}
                        </div>
                        {/*<div className="eucalt"><img src="images/Vehicle_Dashboard/ECUdash.png"  alt="dash"/></div>
                    <div className="eucalt_text">ECU<br/> ALERT</div><div className="eucalt_val">5</div> */}
                        <div className="speedicon">
                          <img
                            src="images/Vehicle_Dashboard/Speedometer_scale.png"
                            alt="dash"
                          />
                          <div className="speedfillimg">
                            <img
                              className={speedlevels}
                              src="images/Vehicle_Dashboard/Gradient.png"
                              alt="dash"
                            />
                          </div>
                        </div>
                        <div className="speed_val">
                          {(currentInfo.gpsSpeed !== null && currentInfo.gpsSpeed !== '') ? Math.round(currentInfo.gpsSpeed) : 0}
                          <div className="speed_units">kmph</div>
                        </div>
                        <div className="rpm_val">
                          {(currentInfo.engineSpeed !== null && currentInfo.engineSpeed !== '') ? Math.round(currentInfo.engineSpeed) : 0}
                          <div className="rpm_units">rpm</div>
                        </div>
                        <div className="speed1">0</div>
                        <div className="speed2">10</div>
                        <div className="speed3">20</div>
                        <div className="speed4">30</div>
                        <div className="speed5">40</div>
                        <div className="speed6">60</div>
                        <div className="speed7">80</div>
                        <div className="speed8">100</div>
                        {showGearUI(marker.isBS6) && <>
                        <div className="geartxt">GEAR</div>
                        <div className="gearmarker">
                          <img
                            src="images/Vehicle_Dashboard/Gear_marker.png"
                            alt="dash"
                          />
                        </div>
                        <div className="gearimg">

                          {gearvalue - 3 > 0 ? (
                            gearvalue - 4 === 0 ? (
                              <span className="gearn">N</span>
                            ) : gearvalue - 3 <= 4 ? (
                              <span className="gearn">{gearvalue - 4}</span>
                            ) : (
                              <span className="gearn">{gearvalue - 4}</span>
                            )
                          ) : (
                            <span className="gearn clearvals">N</span>
                          )}
                          {gearvalue - 3 >= 0 ? (
                            gearvalue - 3 === 0 ? (
                              <span className="gear1">N</span>
                            ) : gearvalue <= 5 ? (
                              <span className="gear1">{gearvalue - 3}</span>
                            ) : (
                              <span className="gear1">{gearvalue - 3}</span>
                            )
                          ) : (
                            <span className="gear1 clearvals">N</span>
                          )}
                          {gearvalue - 2 >= 0 ? (
                            gearvalue - 2 === 0 ? (
                              <span className="gear2">N</span>
                            ) : gearvalue <= 5 ? (
                              <span className="gear2">{gearvalue - 2}</span>
                            ) : (
                              <span className="gear2">{gearvalue - 2}</span>
                            )
                          ) : (
                            <span className="gear2 clearvals">N</span>
                          )}
                          {gearvalue - 1 >= 0 ? (
                            gearvalue - 1 === 0 ? (
                              <span className="gear3">N</span>
                            ) : gearvalue <= 5 ? (
                              <span className="gear3">{gearvalue - 1}</span>
                            ) : (
                              <span className="gear3">{gearvalue - 1}</span>
                            )
                          ) : (
                            <span className="gear3 clearvals">N</span>
                          )}
                          <span className="gear4">{gearvalue}</span>
                          {gearvalue === "N" ? (
                            <span className="gear5 ">1</span>
                          ) : gearvalue >= 14 ? (
                            <span className="gear5 clearvals">N</span>
                          ) : (
                            <span className="gear5">{gearvalue + 1}</span>
                          )}
                          {gearvalue === "N" ? (
                            <span className="gear6">2</span>
                          ) : gearvalue >= 14 ? (
                            <span className="gear6 clearvals">N</span>
                          ) : (
                            <span className="gear6 ">{gearvalue + 2}</span>
                          )}
                          {gearvalue === "N" ? (
                            <span className="gear7">3</span>
                          ) : gearvalue >= 14 ? (
                            <span className="gear7 clearvals">N</span>
                          ) : (
                            <span className="gear7 ">{gearvalue + 3}</span>
                          )}
                          {gearvalue === "N" ? (
                            <span className="gear8">4</span>
                          ) : gearvalue >= 14 ? (
                            <span className="gear8 clearvals">N</span>
                          ) : (
                            <span className="gear8 ">{gearvalue + 4}</span>
                          )}

                        </div></>}
                        <div
                          className="moreinfotext"
                          menuclickid="1,2"
                          onClick={(e) =>
                            moreinfovalue("vehicleInfo/trend", e)
                          }
                        >
                          MORE INFO
                        </div>
                        <div
                          className="closetext"
                          onClick={() => closedashboardbox()}
                        >
                          CLOSE
                        </div>
                        <img
                          src="images/Vehicle_Dashboard/layoutdash.png"
                          alt="dash"
                        />
                      </div>
                      </div>
                    </OverlayView>
                  )}
                {selectedMarker.obuId === marker.obuId &&
                  dialviews === true && (
                    <OverlayView
                      position={{
                        lat: marker.currentInfo.latitude,
                        lng: marker.currentInfo.longitude,
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={getPixelPositionOffset}
                    >
                      <div style={{
                          position: 'absolute',
                          width: 'auto', // Set width
                          height: 'auto', // Set height
                          zIndex: '99',
                          transform: 'translate(-50%, -100%)' // Center above the marker position
                        }}
                      >
                        <div className="selected-highlight">
                          {" "}
                          <img
                            src="images/Map_screen//Dial_icons/Vehicle_highlighter.png"
                            alt="dial"
                          />{" "}
                        </div>
                        <div className="dial-align">
                          <img src="images/Map_screen/Dial.png" alt="dial" />

                          {children}
                          <div className="innercircle"></div>
                        </div>
                      </div>
                    </OverlayView>
                  )}
                {/* {selectedMarker === marker && <InfoWindow onCloseClick={onClick}>
          <div className="circle"><NavLink link='/vehicleInfo/trace' ><input type="button" value="Info Details" /> </NavLink> </div>
          </InfoWindow>} */}
              </Marker>
            );
          })}
      </MarkerClusterer>
      {showCircle && dealerLat && dealerLong && (
        <>
          <Circle
            center={{ lat: dealerLat, lng: dealerLong }} // lat: dealerLat, lng: dealerLong
            radius={circleRadius}
            editable={true}
            draggable={true}
            ref={circleRef_Func}
            onLoad={onCircleLoad}  // Load circle instance into the ref
            onRadiusChanged={onRadiusChanged}
            // defaultOptions={options}
          />
          <InfoWindow
            position={{ lat: parseFloat(dealerLat), lng: parseFloat(dealerLong) + 1}} // lat: 13.0558220, lng: 80.0962940
          >
            <p>
              <strong>{Math.floor(circleRadius / 1000)} km</strong>
            </p>
          </InfoWindow>
        </>
      )}
    </GoogleMap>
  ) : (
    <GoogleMap
      defaultZoom={4}
      center={{ lat: 20.5937, lng: 78.9629 }}>
    </GoogleMap>
  );
}

class DealerMaps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.CircleRef = React.createRef();

    globalprops = this.props;
    this.state = {
      listview_loader: true,
      circleRadius: 100000,
      classic_view: false,
      classicSortColumns: [],
      dropiconValue: 'desc',
      temp_column: {
        vehicle_odometer: true,
        gps_speed: true,
        engine_speed: true,
        fuel_level: true,
        duration: true,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: false,
        vehicle_type: false,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false,
        customer_name: false,
        customer_mobile_no: false,
        mapCenter: { lat: 20.5937, lng: 78.9629 },
        zoom: 3,
      },
      refreshItem: true,
      all_latitude: [],
      all_longtitude: []
    };
    this.classicSortingColums = sortingColumsClassicView;
    this.handleClickpage = this.handleClickpage.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.gmapsDispatch({ loader: true, listview: false, callGlobalApi: "sold",paginationcolor:1,currentPage:1 });
  }

  componentDidMount() {
    var currentdatecheck = new Date();
    var currentdateyesterday = currentdatecheck.setDate(
      currentdatecheck.getDate() - 1
    );
    var findmilliseconds = new Date(currentdateyesterday);
    this.setState({ ttldatetcompare: findmilliseconds });
    if (this.props.searchresult !== null) {
      if (this.props.searchresult.data.length === 0) {
        this.props.gmapsDispatch({ searchresultdataval: [] });
        this.callvehiclesdetails();
      } else {
        let searchobulist = [];
        for (var i = 0; i < this.props.searchresult.data.length; i++) {
          searchobulist.push(this.props.searchresult.data[i].obu_id);
        }
        this.props.gmapsDispatch({ searchresultdataval: searchobulist });
        this.callvehiclessearchresult(searchobulist);
      }
    } else {
      this.props.gmapsDispatch({ searchresultdataval: [],markers:[] });
      this.callvehiclesdetails();
    }
    // console.log('GoogleMap component has mounted!')
    this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
    this.props.gmapsDispatch({ selectedMarker: "" });
    this.settimeoutfn();

    this.selectedlistview = false;
    this.hmautoreset = false;
    // this.setState({ temp_column: this.props.gmapsState.column });
    let dataObj = {};
    dataObj.userid = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    const datas = postData(apilist.loadlistviewdetails, dataObj, vehtokens);
    datas.then((dataVals) => {
      // console.log(dataVals);
      if (dataVals.data && dataVals.data.length) {
        this.setState({
          temp_column: {
            vehicle_odometer: dataVals.data[0].vehicle_odometer,
            gps_speed: dataVals.data[0].gps_speed,
            engine_speed: dataVals.data[0].engine_speed,
            fuel_level: dataVals.data[0].fuel_level,
            duration: dataVals.data[0].duration,
            air_pressure: dataVals.data[0].air_pressure,
            oil_pressure: dataVals.data[0].oil_pressure,
            vehicle_battery_potentials:
              dataVals.data[0].vehicle_battery_potentials,
            fuel_consum: dataVals.data[0].fuel_consumption,
            altitude: dataVals.data[0].altitude,
            vin_number: dataVals.data[0].vin_number,
            obu_id: dataVals.data[0].obu_id,
            vehicle_model: dataVals.data[0].vehicle_model,
            vehicle_type: dataVals.data[0].vehicle_type,
            coolant_temp: dataVals.data[0].coolant_temp,
            sub_valid_from: dataVals.data[0].sub_valid_from,
            sub_valid_to: dataVals.data[0].sub_valid_to,
            is_bs6: dataVals.data[0].is_bs6,
            catalyst_tank_level: dataVals.data[0].catalyst_tank_level,
            adblue_tank_capacity: dataVals.data[0].adblue_tank_capacity,
            driver_name: dataVals.data[0].driver_name,
            engine_hours: dataVals.data[0].engine_hours,
            exhaust_temperature: dataVals.data[0].exhaust_temperature,
            customer_name: dataVals.data[0].customer_name
          },
        });

        this.props.gmapsDispatch({
          column: {
            vehicle_odometer: dataVals.data[0].vehicle_odometer,
            gps_speed: dataVals.data[0].gps_speed,
            engine_speed: dataVals.data[0].engine_speed,
            fuel_level: dataVals.data[0].fuel_level,
            duration: dataVals.data[0].duration,
            air_pressure: dataVals.data[0].air_pressure,
            oil_pressure: dataVals.data[0].oil_pressure,
            vehicle_battery_potentials:
              dataVals.data[0].vehicle_battery_potentials,
            fuel_consum: dataVals.data[0].fuel_consumption,
            altitude: dataVals.data[0].altitude,
            vin_number: dataVals.data[0].vin_number,
            obu_id: dataVals.data[0].obu_id,
            vehicle_model: dataVals.data[0].vehicle_model,
            vehicle_type: dataVals.data[0].vehicle_type,
            coolant_temp: dataVals.data[0].coolant_temp,
            sub_valid_from: dataVals.data[0].sub_valid_from,
            sub_valid_to: dataVals.data[0].sub_valid_to,
            is_bs6: dataVals.data[0].is_bs6,
            catalyst_tank_level: dataVals.data[0].catalyst_tank_level,
            adblue_tank_capacity: dataVals.data[0].adblue_tank_capacity,
            driver_name: dataVals.data[0].driver_name,
            engine_hours: dataVals.data[0].engine_hours,
            exhaust_temperature: dataVals.data[0].exhaust_temperature,
            customer_name: dataVals.data[0].customer_name
          },
        });
      } else {
        this.props.gmapsDispatch({
          column: {
            vehicle_odometer: true,
            gps_speed: true,
            engine_speed: true,
            fuel_level: true,
            duration: false,
            air_pressure: false,
            oil_pressure: false,
            vehicle_battery_potentials: false,
            fuel_consum: false,
            altitude: false,
            vin_number: false,
            obu_id: false,
            vehicle_model: false,
            vehicle_type: false,
            coolant_temp: false,
            sub_valid_from: false,
            sub_valid_to: false,
            is_bs6: false,
            catalyst_tank_level: false,
            adblue_tank_capacity: false,
            driver_name: false,
            engine_hours: false,
            customer_name: false
          },
        });

        this.setState({
          temp_column: {
            vehicle_odometer: true,
            gps_speed: true,
            engine_speed: true,
            fuel_level: true,
            duration: true,
            air_pressure: false,
            oil_pressure: false,
            vehicle_battery_potentials: false,
            fuel_consum: false,
            altitude: false,
            vin_number: false,
            obu_id: false,
            vehicle_model: false,
            vehicle_type: false,
            coolant_temp: false,
            sub_valid_from: false,
            sub_valid_to: false,
            is_bs6: false,
            catalyst_tank_level: false,
            adblue_tank_capacity: false,
            driver_name: false,
            engine_hours: false,
            customer_name: false
          },
        });
      }
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  groupChange(h, e) {
    var values = h.state.group_list.filter(function (item) {
      return item.group_name == e.target.value;
    });
    this.setState({
      groupsval: values[0].group_name,
    });
    this.setState({
      groupsvalid: values[0].poi_group_id,
    });
    let poiindividualid = {};
    poiindividualid.poiGroupId = values[0].poi_group_id;
    let vehtoken = this.props.ctr.token;
    const datas = postData(
      apilist.showGeofenceGroupList,
      poiindividualid,
      vehtoken
    );
    datas.then((dataVals) => {
      // console.log(dataVals.data);
      dataVals.data.map((data) => {
        var latlonglist = data.st_astext.split("(");
        var latlonglistedval = latlonglist[1].split(" ");
        data.poilat = parseFloat(latlonglistedval[0]);
        var latlonglisedonly = latlonglistedval[1].split(")");
        data.poilong = parseFloat(latlonglisedonly[0]);
      });
      this.setState({ totalarraywithpoi: dataVals.data });
    });
  }
  checkboxgroupChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ showselectedpoires: true });
    } else {
      this.setState({ groupsval: "" });
      this.setState({ totalarraywithpoi: [] });
      this.setState({ showselectedpoires: false });
    }
  };
  checkboxlabelChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ showlabelval: true });
    } else {
      this.setState({ showlabelval: false });
    }
  };

  getgroupData() {
    let vehtoken = this.props.ctr.token;
    const datas = postData(apilist.POIGroupList, null, vehtoken);
    datas.then((dataVals) => {
      // console.log(dataVals.data);
      this.setState({
        group_list: dataVals.data,
      });
    });
  }
  componentWillUnmount() {
    if (this.props.gmapsState.dropdownValue == "myTerritory") {
      this.props.gmapsDispatch({
        searchvechiclelist: [],
        previous_markers: [],
        dropdownValue: "mySoldVehicles",
      }).then(() => {
        let el = document.getElementsByClassName("Cls_btns")[0];
        if (el) {
          el.getElementsByClassName("fa-close")[0].click();
        }
        // this.props.gmapsDispatch({
        //   callGlobalApi: "sold",
        // })
      });
    }
    clearInterval(this.settimers);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  shouldComponentUpdate(nextProps) {
    //console.log('hello');
    if (nextProps.togglerefresh !== "NotRefresh") {
      this.props.onlyrefresh("NotRefresh");
      this.props.gmapsDispatch({ hardrefresh: false });
      this.props.gmapsDispatch({ loader: true });
      if (this.props.gmapsState.dropdownValue == "myTerritory") {
        this.getTerritoryDetails();
      }
      else {
        this.callvehiclesdetails();
      }
      this.hmautoreset = true;
      this.setState({ reseton: true });
      if (
        this.props.gmapsState.selectedMarker !== "" &&
        this.props.gmapsState.listview === true &&
        this.props.gmapsState.selectedMarker !== false
      ) {
        this.props.gmapsDispatch({ listview: false });
      } else if (
        this.props.gmapsState.selectedMarker === "" &&
        this.props.gmapsState.listview === true
      ) {
        this.props.gmapsDispatch({ listview: true });
      } else {
        this.props.setlistviewval(false);
        this.props.gmapsDispatch({ listview: false });
      }
    }
    if (nextProps.setlistview !== undefined) {
      if (
        this.props.location.state !== undefined &&
        this.props.location.state !== null
      ) {
        if (
          this.props.location.state.fromNotifications !== undefined &&
          this.props.location.state.fromNotifications !== null
        ) {
          if (nextProps.setlistview !== "listviewmenu") {
            if (nextProps.setlistview === true) {
              this.props.setlistviewval("listviewmenu");
              this.props.gmapsDispatch({ listview: true });
            } else {
              this.props.setlistviewval("listviewmenu");
              this.props.gmapsDispatch({ listview: false });

              if (this.props.gmapsState.markers) {
                this.zoomToMarkersVal(this.props.gmapsState);
              }
            }
          }
        }
      }
    }
    // if (nextProps.gmapsState.getRibbonAlerts) {
    //   this.props.gmapsDispatch({ getRibbonAlerts: false }).then(() => {
    //     this.callvehialert();
    //   });
    // }
    return true;
  }

  settimeoutfn = () => {
    this.settimers = 0;
    this.settimers = setInterval(() => {
      if (this.props.togglevalues === true) {
        this.hmautoreset = true;
        // if(this.props.gmapsState.selectedMarker !=''){
        //   this.props.gmapsDispatch({ listview: false })
        // }
        this.props.gmapsDispatch({ loader: true });
        if (this.props.gmapsState.dropdownValue == "myTerritory") {
          this.getTerritoryDetails();
        }
        else {
          this.callvehiclesdetails();
        }
        if (
          this.props.gmapsState.selectedMarker !== "" &&
          this.props.gmapsState.listview === true &&
          this.props.gmapsState.selectedMarker !== false
        ) {
          this.props.gmapsDispatch({ listview: false });
        }
      }
    }, 30000);
  };
  callvehiclessearchresult = (obulisted) => {
    this.getgroupData();
    this.props.searchresultbox(this.props.location);
    let dataObj = {};
    dataObj.userId = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    dataObj.platform = "w";
    dataObj.obuList = obulisted;
    const datas = postData(apilist.vehiclecount, dataObj, vehtokens);
    datas.then((dataVals) => {
      this.props.gmapsDispatch({
        vehCount: dataVals.data,
        mastervehCount: dataVals.data,
      });
    });
    this.props.gmapsDispatch({ markers: [] });
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
        this.props.gmapsDispatch({ listview: false });
        shownlistview = "showlisted";
        this.props.listviewshown();
        this.props.gmapsDispatch({
          Vehiclestatushigh: this.props.location.state.Vehiclestatushigh,
        });
      } else if (
        Object.keys(this.props.location.state)[0].trim() === "fromNotifications"
      ) {
        shownlistview = "showlist";
        this.props.listviewshown();
        this.props.setlistviewval(true);
        if (this.props.gmapsState.listview === "") {
          this.props.gmapsDispatch({
            listview: this.props.location.state.fromNotifications,
          });
        } else if (
          this.props.gmapsState.selectedMarker !== "" &&
          this.props.gmapsState.listview === false
        ) {
          this.props.gmapsDispatch({ listview: false });
        } else {
          this.props.gmapsDispatch({
            listview: this.props.location.state.fromNotifications,
          });
        }

        if (this.selectedlistview === undefined) {
          this.selectedlistview = false;
        } else {
          this.selectedlistview = true;
        }
        if (this.props.gmapsState.listview === false) {
          if (this.selectedlistview !== true) {
            this.props.gmapsDispatch({ selectedMarker: false });
          }
        }
      }
    } else {
      shownlistview = "showlist";
      this.props.listviewshown();
      this.props.gmapsDispatch({ listview: false, Vehiclestatushigh: "Total" });
    }
    if (
      this.props.gmapsState.selectedMarker !== "" &&
      this.props.gmapsState.selectedMarker !== false &&
      this.props.togglevalues !== false &&
      this.props.togglerefresh !== "NotRefresh"
    ) {
      this.props.gmapsDispatch({ listview: false });
    } else if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (this.props.gmapsState.listview === false) {
        if (this.selectedlistview === true) {
          this.props.gmapsDispatch({ listview: false });
        }
      }
    }
    let vehlist = {};
    vehlist.userId = this.props.ctr.userid;
    if (this.props.ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.obuIdList = obulisted;
    let vehtoken = this.props.ctr.token;
    vehlist.platform = "w";
    this.props.gmapsDispatch({ loader: true });
    const response = postData(apilist.allvehicledatadealer, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
    response.then((datas) => {
      if (datas.statuscode === 200) {
        this.assigningIcons(datas);
        if (this.props.listviewshows !== "showlist") {
          if (
            this.props.gmapsState.refreshselecteddata !== undefined &&
            this.props.gmapsState.selectedMarker === ""
          ) {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else if (
          this.props.togglevalues === true ||
          this.state.reseton === true
        ) {
          if (
            this.props.gmapsState.refreshselecteddata !== undefined &&
            this.props.gmapsState.selectedMarker === ""
          ) {
            if (this.hmautoreset === true) {
              this.clickstatus(this.props.gmapsState.refreshselecteddata);
            } else {
              this.clickstatus("Total");
            }
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else {
          this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
          this.sortingResposneData(datas.data);
        }
      } else {
        alert("No Data available");
        this.setState({ listview_loader: false });
      }
    });
  };

  callvehiclesdetails = () => {
    this.zoomToMarkersVal(this.props.gmapsState);
    this.getgroupData();
    this.props.searchresultbox(this.props.location);
    let dataObj = {};
    dataObj.userId = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    dataObj.platform = "w";
    dataObj.obuList = this.props.gmapsState.searchresultdataval;
    const datas = postData(apilist.vehiclecount, dataObj, vehtokens);
    datas.then((dataVals) => {
      this.props.gmapsDispatch({
        vehCount: dataVals.data,
        mastervehCount: dataVals.data,
      });
    });
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (Object.keys(this.props.location.state)[0] === "Vehiclestatushigh") {
        this.props.gmapsDispatch({ listview: false });
        shownlistview = "showlisted";
        this.props.listviewshown();
        this.props.gmapsDispatch({
          Vehiclestatushigh: this.props.location.state.Vehiclestatushigh,
        });
      } else if (
        Object.keys(this.props.location.state)[0].trim() === "fromNotifications"
      ) {
        shownlistview = "showlist";
        this.props.listviewshown();
        this.props.setlistviewval(true);
        if (this.props.gmapsState.listview === "") {
          this.props.gmapsDispatch({
            listview: this.props.location.state.fromNotifications,
          });
        } else if (
          this.props.gmapsState.selectedMarker !== "" &&
          this.props.gmapsState.listview === false
        ) {
          this.props.gmapsDispatch({ listview: false });
        } else {
          this.props.gmapsDispatch({
            listview: this.props.location.state.fromNotifications,
          });
        }

        if (this.selectedlistview === undefined) {
          this.selectedlistview = false;
        } else {
          this.selectedlistview = true;
        }
        if (this.props.gmapsState.listview === false) {
          if (this.selectedlistview !== true) {
            this.props.gmapsDispatch({ selectedMarker: false });
          }
        }
      }
    } else {
      shownlistview = "showlist";
      this.props.listviewshown();
      this.props.gmapsDispatch({ listview: false, Vehiclestatushigh: "Total" });
    }
    if (
      this.props.gmapsState.selectedMarker !== "" &&
      this.props.gmapsState.selectedMarker !== false &&
      this.props.togglevalues !== false &&
      this.props.togglerefresh !== "NotRefresh"
    ) {
      this.props.gmapsDispatch({ listview: false });
    } else if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (this.props.gmapsState.listview === false) {
        if (this.selectedlistview === true) {
          this.props.gmapsDispatch({ listview: false });
        }
      }
    }
    let vehlist = {};
    vehlist.userId = this.props.ctr.userid;
    if (this.props.ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.obuIdList = this.props.gmapsState.searchresultdataval;
    let vehtoken = this.props.ctr.token;
    vehlist.platform = "w";
    this.props.gmapsDispatch({ loader: true });
    const response = postData(apilist.allvehicledatadealer, vehlist, vehtoken); //"http://10.18.1.128:7000/api/map/ownVehicleList"
    response.then((datas) => {
      if (datas.statuscode === 200) {
        this.props.gmapsDispatch({ loader: false });
        this.assigningIcons(datas);

        if (this.props.listviewshows !== "showlist") {
          if (
            this.props.gmapsState.refreshselecteddata !== undefined &&
            this.props.gmapsState.selectedMarker === ""
          ) {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else if (
          this.props.togglevalues === true ||
          this.state.reseton === true
        ) {
          if (
            this.props.gmapsState.refreshselecteddata !== undefined &&
            this.props.gmapsState.selectedMarker === ""
          ) {
            if (this.hmautoreset === true) {
              this.clickstatus(this.props.gmapsState.refreshselecteddata);
            } else {
              this.clickstatus("Total");
            }
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else {
          this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
          this.sortingResposneData(datas.data);
        }
      } else {
        alert("No Data available");
        this.setState({ listview_loader: false });
        this.props.gmapsDispatch({ loader: false });
      }
    });
  };


  sortingResposneData(datas) {
    if (this.state.classic_view == false) {
      let sortedData = datas.sort(function (a, b) {
        return (
          new Date(b.currentInfo ? b.currentInfo.localTimeStamp : b.localTimeStamp) -
          new Date(a.currentInfo ? a.currentInfo.localTimeStamp : a.localTimeStamp)
        );
      });
      this.props.gmapsDispatch({
        markers: sortedData,
      });
    } else {
      this.props.gmapsDispatch({
        markers: datas
      }).then(() => {
        //this.zoomToMarkersVal(this.props.gmapsState);
        this.props.gmapsDispatch.markers = datas;
        this.refreshnewListView();
        let filterSortvalue = this.classicSortingColums.filter((item) => item.key == this.state.selectedSortValue);
        if (filterSortvalue.length > 0) {
          this.onSortkeySelect(filterSortvalue[0]);
        }
      });
    }
  }


  assigningIcons(datas) {
    let req_markers = [];
    var resposne_data = this.props.gmapsState.dropdownValue == "myTerritory"?datas.data.getvehicleinfo:datas.data;
    resposne_data.map((data) => {
      if(this.props.gmapsState.dropDownValue == "myTerritory"){
        data.regn_number = data.regnNumber;
        if (this.props.gmapsState.searchvechiclelist.length > 0) {
          this.props.gmapsState.searchresultdata.filter(function (en) {
            if (en.regn_number === data.regnNumber) {
              req_markers.push(data);
            }
          });
        }
      }
      var currentInfo = data.currentInfo?data.currentInfo:data;
      if (
        currentInfo.heading >= 337.5 &&
        currentInfo.heading <= 22.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/01.png";
      } else if (
        currentInfo.heading >= 22.5 &&
        currentInfo.heading <= 67.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/02.png";
      } else if (
        currentInfo.heading >= 67.5 &&
        currentInfo.heading <= 112.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/03.png";
      } else if (
        currentInfo.heading >= 112.5 &&
        currentInfo.heading <= 157.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/04.png";
      } else if (
        currentInfo.heading >= 157.5 &&
        currentInfo.heading <= 202.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/05.png";
      } else if (
        currentInfo.heading >= 202.5 &&
        currentInfo.heading <= 247.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/06.png";
      } else if (
        currentInfo.heading >= 247.5 &&
        currentInfo.heading <= 292.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/07.png";
      } else if (
        currentInfo.heading >= 292.5 &&
        currentInfo.heading <= 337.5
      ) {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/08.png";
      } else {
        data.icons =
          "/images/Map_screen/" + currentInfo.status + "/01.png";
      }
      return data;
    });

    if(this.props.gmapsState.dropdownValue != "myTerritory"){
      this.props.gmapsDispatch({
        markers: datas.data,
        sort: {
          column_name: "localTimeStamp",
          order: "desc",
        },
        totalmarkers: datas.data,
        excel_data: datas.data,
        searchresultdata: datas.data,
        previous_markers: datas.data,
      }).then(() => {
        this.setState({ listview_loader: false });
        this.props.gmapsDispatch({ loader: false });
        this.zoomToMarkersVal(this.props.gmapsState);
        this.vehiclealerts(this.props.gmapsState);
      });
    }else{
        // datas.data.getvehicleinfo.map((data, i) => {
        //   data.regn_number = data.regnNumber;

        // });
        if (this.props.gmapsState.searchvechiclelist.length == 0) {
          req_markers = datas.data.getvehicleinfo;
        }
      this.props
      .gmapsDispatch({
        markers: datas.data.getvehicleinfo,
        totalmarkers: datas.data.getvehicleinfo,
        dealerLat: Number(datas.data.dealerlat),
        dealerLong: Number(datas.data.dealerlong),
        vehCount: datas.data.labelCount,
        searchresultdata: req_markers,
        callGlobalApi: "territory"
      })
      .then(() => {
        this.setState({ listview_loader: false });
        this.zoomToMarkersVal(this.props.gmapsState);
        this.vehiclealerts(this.props.gmapsState);
        this.props.gmapsDispatch({ loader: false });
      });
    }
  }

  vehiclealerts = () => {
    let vehalert = {};
    let obuidlist = this.props.gmapsState.totalmarkers;
    let arrayobulist = [];
    obuidlist.map((obuid) => {
      if (this.props.gmapsState.dropdownValue != undefined && this.props.gmapsState.dropdownValue == "myTerritory") {
        arrayobulist.push(obuid.vinNumber);
      } else {
        arrayobulist.push(obuid.currentInfo != undefined ? obuid.currentInfo.vinNumber : obuid.vinNumber);
      }
      return obuidlist;
    });

    vehalert.userId = this.props.ctr.userid;
    vehalert.platform = "w";
    vehalert.vinList = arrayobulist;
    let vehtoken = this.props.ctr.token;
    const vehicleAlert = postData(
      apilist.vehicleAlertdatedealer,
      vehalert,
      vehtoken
    );
    vehicleAlert.then((datas) => {
      let alertdata;
      if(datas.statuscode === 200){
        alertdata = datas.data.alert_status;
      }else{
        alertdata = []
      }
      let temp = {};
      let obj = null;
      let countsvas = 0;
      //let criticalint;
      for (var i = 0; i < alertdata.length; i++) {
        obj = alertdata[i];
        if (!temp[obj.vin]) {
          temp[obj.vin] = obj;
          if (temp[obj.vin].severity === "Critical") {
            temp[obj.vin].criticalcounts = countsvas + 1;
          }
        } else {
          if (obj.severity === "Critical") {
            temp[obj.vin].criticalcounts = temp[obj.vin].criticalcounts + 1;
          }
          temp[obj.vin].alert_type_id =
            temp[obj.vin].alert_type_id + "," + obj.alert_type_id;
        }
      }
      var altresult = [];
      for (var prop in temp) altresult.push(temp[prop]);
      let dropDownValue = this.props.gmapsState.dropdownValue;
      altresult.forEach(function (elam) {
        let objs = obuidlist.filter(function (en) {
          return en.vinNumber === elam.vin;
        });
        if(objs.length>0){
          objs[0].severity = elam.severity;
          objs[0].alert_type_id = elam.alert_type_id;
          objs[0].criccnt = elam.criticalcounts;
          var currentInfo = dropDownValue == "myTerritory" ? objs[0] : objs[0].currentInfo
          if (
            currentInfo.heading >= 337.5 &&
            currentInfo.heading <= 22.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/01.png";
          } else if (
            currentInfo.heading >= 22.5 &&
            currentInfo.heading <= 67.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/02.png";
          } else if (
            currentInfo.heading >= 67.5 &&
            currentInfo.heading <= 112.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/03.png";
          } else if (
            currentInfo.heading >= 112.5 &&
            currentInfo.heading <= 157.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/04.png";
          } else if (
            currentInfo.heading >= 157.5 &&
            currentInfo.heading <= 202.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/05.png";
          } else if (
            currentInfo.heading >= 202.5 &&
            currentInfo.heading <= 247.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/06.png";
          } else if (
            currentInfo.heading >= 247.5 &&
            currentInfo.heading <= 292.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/07.png";
          } else if (
            currentInfo.heading >= 292.5 &&
            currentInfo.heading <= 337.5
          ) {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/08.png";
          } else {
            objs[0].icons =
              "/images/Map_screen/" +
              elam.severity +
              "/" +
              currentInfo.status +
              "/01.png";
          }
        }
        });
      this.clickstatus(this.props.gmapsState.refreshselecteddata)
      this.callvehialert();
    });
  };

  callvehialert = () => {
    let Totime = new Date().getTime();
    let fromtime = Totime - 144 * 60 * 60 * 1000;
    let Totimeft = new Date(Totime);
    let Totimeftmonth = Totimeft.getMonth() + 1;
    let Totimeftdate = Totimeft.getDate();
    let Totimeftyear = Totimeft.getFullYear();
    let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
    let fromtimeft = new Date(fromtime);
    let fromtimeftmonth = fromtimeft.getMonth() + 1;
    let fromtimeftdate = fromtimeft.getDate();
    let fromtimeftyear = fromtimeft.getFullYear();
    let formatfromdate =
      fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

    let dataobj_alert = {};
    dataobj_alert.userId = this.props.ctr.userid;
    let tokens = this.props.ctr.token;
    dataobj_alert.platform = "w";
    dataobj_alert.fromDate = formatfromdate;
    dataobj_alert.toDate = formattodate;
    dataobj_alert.isActive = true;

    var vinArr = [];
    if (this.props.gmapsState.dropdownValue === 'myTerritory' || this.props.gmapsState.dropDownValue === undefined) {
      if (this.props.gmapsState.totalmarkers && this.props.gmapsState.totalmarkers.length) {
        this.props.gmapsState.totalmarkers.forEach((marker) => {
          vinArr.push(marker.vinNumber);
        });
      }
    }

    dataobj_alert.vinList = vinArr;
    this.props.gmapsDispatch({loader:true});
    const alertcnt = postData(apilist.alertCountdealer, dataobj_alert, tokens);
    alertcnt.then((alertdataVals) => {
      let countwithalert = { ...alertdataVals.data, ...this.props.gmapsState.vehCount };
      this.props.gmapsDispatch({
        vehCount: countwithalert,
        loader:false
      });
    });
  };

  zoomToMarkersVal = (state) => {
    // console.log(state.markers);
    if (state.selectedMarker === false) {
      if (state.markers.length > 0) {
        if (state.selectedMarker !== false && state.selectedMarker !== "") {
          var lngs = [];
          var lats = [];
          let selectedzoomin = state.selectedMarker.currentInfo != undefined ? state.selectedMarker.currentInfo : state.selectedMarker;
          lngs.push(selectedzoomin.longitude);
          lats.push(selectedzoomin.latitude);
        } else {
          let markers = state.markers;
          lngs = markers.map((datas) => {
            return datas.currentInfo != undefined ? datas.currentInfo.longitude : datas.longitude;
          });
          lats = markers.map((datas) => {
            return datas.currentInfo != undefined ? datas.currentInfo.latitude : datas.latitude;
          });
        }

        this.props.gmapsDispatch({
          zoomToMarkers: (map) => {
            if (map) {
              map.fitBounds({
                west: Math.min.apply(null, lngs),
                east: Math.max.apply(null, lngs),
                north: Math.min.apply(null, lats),
                south: Math.max.apply(null, lats),
              });
            }
          },
        });
      }
    } else {
      this.zoomToMarkersValselected(state);
    }
  };

  zoomToMarkersValselected = (state) => {
    var selectedzoomin = []
    if (state.selectedMarker !== false) {
      selectedzoomin = state.markers.filter((datas) => {
        var item = datas.currentInfo != undefined ? datas.currentInfo : datas;
        return state.selectedMarker.obuId == item.obuId
      });
      if (state.markers.length > 0) {
        if (state.selectedMarker !== false && state.selectedMarker !== "") {
          var lngs = [];
          var lats = [];
          if (selectedzoomin.length > 0) {
            lngs.push(selectedzoomin[0].currentInfo != undefined ? selectedzoomin[0]['currentInfo'].longitude : selectedzoomin[0].longitude);
            lats.push(selectedzoomin[0].currentInfo != undefined ? selectedzoomin[0]['currentInfo'].latitude : selectedzoomin[0].latitude);
          }
        } else {
          let markers = state.markers;
          lngs = markers.map((datas) => {
            return datas.currentInfo ? datas.currentInfo.longitude : datas.longitude;
          });
          lats = markers.map((datas) => {
            return datas.currentInfo ? datas.currentInfo.latitude : datas.latitude;
          });
        }

        if(lngs.length > 1){
          this.setState({all_longtitude : lngs, all_latitude : lats})
        }

        this.props.gmapsDispatch({
          zoomToMarkers: map => {
            if (map) {
              if(this.props.gmapsState.dialviewshow === true || this.props.gmapsState.dashenable === true){
                map.fitBounds({
                  west: Math.min.apply(null, lngs),
                  east: Math.max.apply(null, lngs),
                  north: Math.min.apply(null, lats),
                  south: Math.max.apply(null, lats)
                });
              }
              else{
                map.fitBounds({
                  west: Math.min.apply(null, this.state.all_longtitude),
                  east: Math.max.apply(null, this.state.all_longtitude),
                  north: Math.min.apply(null, this.state.all_latitude),
                  south: Math.max.apply(null, this.state.all_latitude)
                });
              }
            }
          }
        });
      }
    }
  };

  clickstatus = (e) => {
    this.setState({ zoom: 5, mapCenter: { lat: 20.5937, lng: 78.9629 } });
    this.props.gmapsDispatch({ refreshselecteddata: e });
    let first_page_btn = document.getElementsByClassName(
      "page_symbol_listview"
    )[0];
    if (first_page_btn) {
      first_page_btn.click();
    }

    let vehiclesstatus = [];
    let bool = false;
    // this.props.gmapsDispatch({ vehCount: this.props.gmapsState.mastervehCount });
    this.props.gmapsDispatch({
      selectedMarker: "",
      dashenable: false,
      dialviewshow: false,
      markers: [],
      zoomToMarkers: map => {
        if (map) {
            map.fitBounds({
              west: Math.min.apply(null, this.state.all_longtitude),
              east: Math.max.apply(null, this.state.all_longtitude),
              north: Math.min.apply(null, this.state.all_latitude),
              south: Math.max.apply(null, this.state.all_latitude)
          })
        }
      }
    });
    let statusvalue = e;
    let totalvehicleslist = this.props.gmapsState.searchresultdata;
    for (var i = 0; i < totalvehicleslist.length; i++) {
      var currentInfo = totalvehicleslist[i].currentInfo != undefined ? totalvehicleslist[i].currentInfo : totalvehicleslist[i];
      if (currentInfo.status === statusvalue) {
        vehiclesstatus.push(totalvehicleslist[i]);
      } else if (statusvalue === "Total") {
        vehiclesstatus.push(totalvehicleslist[i]);
      } else if (totalvehicleslist[i].severity !== undefined) {
        if (totalvehicleslist[i].severity.includes(statusvalue) === true) {
          vehiclesstatus.push(totalvehicleslist[i]);
        }
      }
    }
    this.props.gmapsDispatch({ Vehiclestatushigh: e, currentPage: 1 });
    let sorted_table_data = Object.assign([], vehiclesstatus);
    this.sortingResposneData(sorted_table_data);
    if (vehiclesstatus.length === 0) {
      if(!this.props.gmapsState.listview){
        alert("No Data Available");
      }
      this.setState({ listview_loader: false });
    }
  };

  handleClick = (marker, listViewClick) => {
    // if (this.props.gmapsState.dialviewshow === true) {
    //   this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
    // } else {
    //   this.props.gmapsDispatch({ dashenable: true, dialviewshow: false });
    // }
    let mVal = {};

    //this.zoomToMarkersValselected(this.props.gmapsState);
    let _loader = false;
    if(this.props.gmapsState.dropdownValue == "myTerritory"){
      if(listViewClick == true){
        _loader = true;
      }
      marker.currentInfo = marker
      mVal.selectedVehicle = marker;
    }else{
      mVal.selectedVehicle = marker;
    }
    uservehicledate = mVal;
    localStorage.setItem("selectedVin",marker.currentInfo?marker.currentInfo.vinNumber:marker.vinNumber)
    this.props.ctrs();
    this.props
      .gmapsDispatch({
        selectedMarker: marker,
        listview: false,
        loader:_loader,
        dashenable: true,
        dialviewshow:false
      })
      .then(() => {
        if(this.props.gmapsState.dropdownValue == "myTerritory" && listViewClick == true){
        setTimeout(() => {
          // this.zoomToMarkersValselected(this.props.gmapsState);
          this.setState({
            mapCenter: { lat: marker.currentInfo.latitude, lng: marker.currentInfo.longitude },
            zoom: 15
          })
          this.props
          .gmapsDispatch({
            loader:false
          });
        }, 2000);
        }else{
          setTimeout(() => {
          // this.zoomToMarkersValselected(this.props.gmapsState);
          this.setState({
            mapCenter: { lat: marker.currentInfo.latitude, lng: marker.currentInfo.longitude },
            zoom: 15
          })
          },50);
        }
      });
  };

  handleClickpage(event) {
    this.props.gmapsDispatch({
      currentPage: Number(event.target.id),
    });
    this.props.gmapsDispatch({ paginationcolor: Number(event.target.id) });
    this.refreshnewListView();
  }

  refreshnewListView = () => {
    this.setState({ refreshItem: false }, () => {
      this.setState({
        refreshItem: true
      })
    })
  }

  handleChangeforpagi(event) {
    this.props.gmapsDispatch({ currentPage: 1 });
    let pagelist = Number(event.target.value);
    this.props.gmapsDispatch({ todosPerPage: pagelist });
  }

  redirectPage = (a, e) => {
    if(a === "fleetalerts"){
      this.setState({successmenu : true})
      this.props.navigate(`/${a}`)
    }
    var ids = e.target.getAttribute("menuclickid").split(",");
    var menuClickids = [];
    menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
    this.props.quicklinks(menuClickids);
    if (a === "fleetManagement") {
      this.props.history.push("/" + a, "Registration Update");
    } else if (a === "fleetinsightsrep") {
      this.props.history.push("/" + a, { Fleet: "Fleet" });
    } else {
      this.props.history.push("/" + a);
    }
  };

  callvehiclesrun = () => {
    var lngslist = [];
    var longslist = [];
    if (window.google !== undefined) {
      const DirectionsService = new window.google.maps.DirectionsService();
      this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
      if (this.props.gmapsState.selectedMarker !== "") {
        var currentInfo = this.props.gmapsState.selectedMarker.currentInfo ? this.props.gmapsState.selectedMarker.currentInfo : this.props.gmapsState.selectedMarker;
        var previouslat = currentInfo.latitude;
        var previouslong = currentInfo.longitude;
        let selectedtrkveh = this.props.gmapsState.selectedMarker.obuId;
        let vehlisttrk = {};
        vehlisttrk.userId = this.props.ctr.userid;
        vehlisttrk.userRole = this.props.ctr.userrole;
        vehlisttrk.obuIdList = [selectedtrkveh];
        let vehtoken = this.props.ctr.token;
        vehlisttrk.platform = "w";
        const response = postData(
          apilist.allvehicledatadealer,
          vehlisttrk,
          vehtoken
        ); //"http://10.18.1.128:7000/api/map/ownVehicleList"
        response.then((datas) => {
          var currentlat = datas.data[0].currentInfo.latitude;
          var currentlong = datas.data[0].currentInfo.longitude;
          if (previouslat !== currentlat && previouslong !== currentlong) {
            DirectionsService.route(
              {
                origin: new window.google.maps.LatLng(
                  previouslat,
                  previouslong
                ),
                destination: new window.google.maps.LatLng(
                  currentlat,
                  currentlong
                ),
                travelMode: window.google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  var points = result.routes[0].overview_path;
                  for (var i = 0; i < points.length; i++) {
                    lngslist.push(points[i].lng());
                    longslist.push(points[i].lat());
                    this.props.gmapsDispatch({
                      zoomToMarkers: (map) => {
                        if (map) {
                          map.fitBounds({
                            west: Math.min.apply(null, lngslist),
                            east: Math.max.apply(null, lngslist),
                            north: Math.min.apply(null, longslist),
                            south: Math.max.apply(null, longslist),
                          });
                        }
                      },
                    });
                  }
                }
              }
            );
          }
        });
      }
    }
  };

  trackingveh = () => {
    this.settimersrun = 0;
    this.settimersrun = setInterval(() => {
      if (this.props.gmapsState.selectedMarker !== "") {
        this.callvehiclesrun();
      }
    }, 20000);
  };

  closedashboard = () => {
    this.props.gmapsDispatch({ dashenable: false, dialviewshow: false });
  };

  changevaluee = () => {
    var a = "city";
    // var a = document.getElementById("cars").value;
    if (document.getElementById("cars") != null) {
      a = document.getElementById("cars").value;
    }

    if (a === "city") {
      return "city";
    } else if (a === "poi") {
      return "POI";
    } else {
      return "POI";
    }
  };

  loadvehdash = () => {
    this.props.gmapsDispatch({ dashenable: true, dialviewshow: false });
  };
  previousPage() {
    // let { todosPerPage } = this.props.gmapsState;
    let min = 1;
    let val = Number(this.props.gmapsState.paginationcolor) - 1;
    if (val >= min) {
      this.props.gmapsDispatch({
        currentPage: val,
      });
      this.props.gmapsDispatch({ paginationcolor: val });
      this.refreshnewListView();
    }
  }

  nextPage() {
    let { todosPerPage } = this.props.gmapsState;
    let max = Math.ceil(this.props.gmapsState.markers.length / todosPerPage);
    let val = Number(this.props.gmapsState.paginationcolor) + 1;
    if (val <= max) {
      this.props.gmapsDispatch({
        currentPage: val,
      });
      this.props.gmapsDispatch({ paginationcolor: val });
      this.refreshnewListView();
    }
  }

  firstPage() {
    this.props.gmapsDispatch({
      currentPage: 1,
    });
    this.props.gmapsDispatch({ paginationcolor: 1 });
    this.refreshnewListView();
  }

  lastPage() {
    let { todosPerPage } = this.props.gmapsState;
    let val = Math.ceil(this.props.gmapsState.markers.length / todosPerPage);
    this.props.gmapsDispatch({
      currentPage: val,
    });
    this.props.gmapsDispatch({ paginationcolor: val });
    this.refreshnewListView();
  }

  configure() {
    if (this.props.gmapsState.configureFormState === "none") {
      this.props.gmapsDispatch({
        configureFormState: "block",
      });

      let checkboxes = document.getElementsByClassName("configure_checkbox");
      let flag = 0;
      for (let x = 0; x < checkboxes.length; x++) {
        if (checkboxes[x].value === "true") {
          checkboxes[x].checked = true;
          checkboxes[x].disabled = false;
          flag++;
        } else {
          checkboxes[x].checked = false;
          checkboxes[x].disabled = true;
        }
      }

      if (flag < 4) {
        for (let x = 0; x < checkboxes.length; x++) {
          checkboxes[x].disabled = false;
        }
      }
    }
  }

  toggleColumns(col_no, col_name) {
    // console.log(this.props.gmapsState.column);
    var checkboxes = document.getElementsByClassName("configure_checkbox");
    // var checked = 0;
    var checked = document.querySelectorAll(
      '.config_div input[type="checkbox"]:checked'
    ).length;
    for (let x = 0; x < checkboxes.length; x++) {
      checkboxes[x].disabled = false;
      if (checked > 3 && checkboxes[x].checked === false) {
        checkboxes[x].disabled = true;
      }
    }

    let fI = this.state.temp_column;
    if (fI[col_name] === true) {
      fI[col_name] = false;
      this.setState({
        temp_column: fI,
      });
    } else {
      fI[col_name] = true;
      this.setState({
        temp_column: fI,
      });
    }
  }

  close_list_configuration() {
    this.props.gmapsDispatch({
      configureFormState: "none",
    });
  }

  save_column_config() {
    // console.log(this.state.temp_column);

    var dataObj = {
      user_id: this.props.ctr.userid.toString(),
      location_flag: true,
      time_stamp: true,

      vehicle_odometer: this.state.temp_column.vehicle_odometer,
      gps_speed: this.state.temp_column.gps_speed,
      engine_speed: this.state.temp_column.engine_speed,
      fuel_level: this.state.temp_column.fuel_level,
      duration: this.state.temp_column.duration,
      air_pressure: this.state.temp_column.air_pressure,
      oil_pressure: this.state.temp_column.oil_pressure,
      vehicle_battery_potentials: this.state.temp_column
        .vehicle_battery_potentials,
      fuel_consum: this.state.temp_column.fuel_consum,
      altitude: this.state.temp_column.altitude,
      vin_number: this.state.temp_column.vin_number,
      obu_id: this.state.temp_column.obu_id,
      vehicle_model: this.state.temp_column.vehicle_model,
      vehicle_type: this.state.temp_column.vehicle_type,
      coolant_temp: this.state.temp_column.coolant_temp,
      sub_valid_from: this.state.temp_column.sub_valid_from,
      sub_valid_to: this.state.temp_column.sub_valid_to,
      is_bs6: this.state.temp_column.is_bs6,
      catalyst_tank_level: this.state.temp_column.catalyst_tank_level,
      adblue_tank_capacity: this.state.temp_column.adblue_tank_capacity,
      driver_name: this.state.temp_column.driver_name,
      engine_hours: this.state.temp_column.engine_hours,
      exhaust_temperature: this.state.temp_column.exhaust_temperature,
      customer_name: this.state.temp_column.customer_name,
      customer_mobile_no: false,

      is_deleted: false,
      created_timestamp: "2019-01-08 14:40:01",
      updated_timestamp: "2019-01-08 14:40:01",
      mobile_ind: false,
      ignition_status: false,
      gprs_signal: false,
      gps_signal: false,
    };

    let vehtoke = this.props.ctr.token;
    const datas = postData(apilist.listviewcustupdate, dataObj, vehtoke);
    datas.then((dataVals) => {
      // console.log("Succes: 200", dataVals);
    });

    this.props.gmapsDispatch({
      column: {
        vehicle_odometer: this.state.temp_column.vehicle_odometer,
        gps_speed: this.state.temp_column.gps_speed,
        engine_speed: this.state.temp_column.engine_speed,
        fuel_level: this.state.temp_column.fuel_level,
        duration: this.state.temp_column.duration,
        air_pressure: this.state.temp_column.air_pressure,
        oil_pressure: this.state.temp_column.oil_pressure,
        vehicle_battery_potentials: this.state.temp_column
          .vehicle_battery_potentials,
        fuel_consum: this.state.temp_column.fuel_consum,
        altitude: this.state.temp_column.altitude,
        vin_number: this.state.temp_column.vin_number,
        obu_id: this.state.temp_column.obu_id,
        vehicle_model: this.state.temp_column.vehicle_model,
        vehicle_type: this.state.temp_column.vehicle_type,
        coolant_temp: this.state.temp_column.coolant_temp,
        sub_valid_from: this.state.temp_column.sub_valid_from,
        sub_valid_to: this.state.temp_column.sub_valid_to,
        is_bs6: this.state.temp_column.is_bs6,
        catalyst_tank_level: this.state.temp_column.catalyst_tank_level,
        adblue_tank_capacity: this.state.temp_column.adblue_tank_capacity,
        driver_name: this.state.temp_column.driver_name,
        engine_hours: this.state.temp_column.engine_hours,
        exhaust_temperature: this.state.temp_column.exhaust_temperature,
        customer_name: this.state.temp_column.customer_name
      },
    });

    this.props.gmapsDispatch({
      configureFormState: "none",
    });
  }

  download_listview_excel = () => {
    alasql.fn.moment = moment;

    alasql.fn.updateTimeStamp = function (val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss A");
    }

    alasql.fn.formattedTimeStamp = function (val) {
      return new Date(val);
    }

    alasql.fn.getIgnitionStatus = function (val) {
      if (val === 'RUNNING' || val === 'IDLING') {
        return 'ON';
      } else {
        return 'OFF';
      }
    }

    alasql.fn.getGearNuber = function (isBS6, val) {
      if (isBS6 === 0 || isBS6 === 3)
        return ""
      var gearvalue = val === "<null>" ? "N" : val === null ? "N" : val <= 0 ? "N" : parseInt(val) >= 15 ? "N" : val === "N" ? "N" : parseInt(val);
      return gearvalue
    }

    alasql.fn.getVehicleOdu = function (vehicleType, vehicle_distance, vehicle_odo, gps_odo, isBS6) {
      if (vehicleType === 'EDC_LCV' || vehicleType === 'EEA_LCV')
        return Math.round(gps_odo) + " km";
      if (isBS6 === 0) {
        return vehicleType === 'EEA' ? Math.round(vehicle_odo) : Math.round(gps_odo) || 0;
      } else {
          return vehicle_distance && !isNaN(vehicle_distance) ? Math.round(vehicle_distance) : '';
      }
    }

    alasql.fn.getAirPressure = function (isBS6, type, actual1, air_pressure_digital,ignitionStatus) {
      return airPressureCalculation(isBS6, type, actual1, air_pressure_digital, ignitionStatus);
    }

    alasql.fn.getOilPressure = function (isBS6, type, oilPressure, engineOilDig, engine_oil_act,ignitionStatus) {
      return oilPressureCalculation(isBS6, type, oilPressure, engineOilDig, engine_oil_act,ignitionStatus);
    }

    alasql.fn.getENormValue = function (isBS6, vehicleType) {
      if(vehicleType === 'EDC_LNG'){
        return 'BSVI LNG'
      }
      return vehicleTypes[isBS6] || '';
    }

    alasql.fn.getCatalystValue = function (isBS6, adBlueTankCalcValue) {
      // console.log(isBS6, adBlueTankCalcValue)
      if(isBS6 === 1){
        if(adBlueTankCalcValue !== null){
          return Math.round(adBlueTankCalcValue);
        }else{
          return '';
        }
      }else if(isBS6 === 2){
        return 'N/A'
      }else if(isBS6 === 0){
        return 'N/A'
      }
    }

    alasql.fn.getFluidValue = function (adBlueTankCap, aft1_total_diesel_exhaust_fluid_used) {
      return fluidValueCalculation(adBlueTankCap, aft1_total_diesel_exhaust_fluid_used);
    }

    alasql.fn.getStatusAndSeverity = function (severity, status) {
      return getStatusAndSeverity(severity, status);
    }

    alasql.fn.getEngineSpeed = function(engineSpeed) {
      let val = parseInt(engineSpeed);
      return val;
    }

    alasql.fn.geofencenamesarr = function(geofencenames) {
      if(geofencenames!==undefined){
        let val = geofencenames.join();
        return val;
      }else{
        let val = "NA";
        return val;
      }
    }

    alasql.fn.getstatusOnConditon = function(ignitionStatus,val){
      return getStatusOnConditon(ignitionStatus, val)
    }

    alasql.fn.getExhaustTemp = function(ignitionStatus,val,isBS6){
      return exhaustTempCalculation(ignitionStatus, val, isBS6);
    }

    alasql.fn.getBatteryPotential = function(ignitionStatus,val){
      return val.toFixed(2)
    }

    alasql.fn.getStatusAge = function(localTimeStamp){
      return diffTimeCalculator(localTimeStamp)
    }

    alasql.fn.getLocation = function(location){
      return location !='<null>' && location != null?location:'';
    }

    alasql.fn.isEvCheck = function (isBS6, value) {
      if(isBS6 === 4){
        return value ? value : 0
      }else{
        return 'N/A'
      }
    }

    alasql.fn.isFuelCheck = function (isBS6, value) {
      if(isBS6 === 4){
        return 'N/A'
      }
      else{
        return value ? value : 0
      }
    }

    alasql.fn.isFuelCons = function (value) {
      return getFuelLevelValue(value);
    }

    var ev_params;
    ev_params = "isFuelCheck(isBS6, currentInfo->fuelLevel) AS [FUEL(ltr) / GAS(bar/kg) LEVEL], isEvCheck(isBS6, currentInfo->fuelLevel)  AS [SOC(%)],"

    var questllist;
    if(this.props.gmapsState.dropdownValue == "myTerritory"){
      questllist = `SELECT
      regnNumber AS [VEHICLE REG. NO.],
      updateTimeStamp(localTimeStamp) AS [LOCATION TIMESTAMP],
      getLocation(location)  AS [VEHICLE LOCATION],
      getStatusAndSeverity(severity, status) AS [VEHICLE STATUS],
      getStatusAge(localTimeStamp)  AS [DURATION],
      gpsSpeed  AS [VEHICLE SPEED(kmph)],
      getEngineSpeed(engineSpeed)  AS [ENGINE SPEED / Motor Speed(rpm)],
      getAirPressure(isBS6, vehicleType, airpressure_actual1, air_pressure_digital,status)  AS [AIR PRESSURE(bar)],
      getOilPressure(isBS6, vehicleType, oilPressure, engineOilDig, engine_oil_act,status)  AS [OIL PRESSURE(bar)],
      getBatteryPotential(status,battPotential)  AS [VEHICLE BATTERY POTENTIAL(v)],`
      + ev_params + `
      getstatusOnConditon(status,eng_coolant_temp)  AS [COOLANT TEMPERATURE(℃)],
      isFuelCons(currentInfo->fuelConsumption) AS [FUEL(ltr) / GAS(kg) / Energy(kwh) CONSUMPTION],
      latitude  AS [ALTITUDE(m)],
      getIgnitionStatus(status) AS [IGNITION STATUS],
      getVehicleOdu(vehicleType, vehicle_distance, vehicle_odo, gps_odo, isBS6)  AS [VEHICLE ODOMETER(Km)],
      getGearNuber(isBS6, gearnumber) as [Gear Number],
      obuId  AS [OBU ID],
      vehicleModel  AS [VEHICLE MODEL],
      vehicleType AS [VEHICLE TYPE],
      vinNumber  AS [VIN NUMBER],
      driverName AS [DRIVER NAME],
      driverMobileNo AS [DRIVER MOBILE NO.],
      engineHrs AS [ENGINE HOURS(hrs)],
      validFrom AS [SUBSCRIPTION VALID FROM],
      validTo AS [SUBSCRIPTION VALID TO],
      isEvCheck(isBS6, currentInfo->dist_to_empty)  AS [Distance To Empty(km)],
      getENormValue(isBS6, vehicleType) AS [E-NORM],
      getCatalystValue(isBS6, adBlueTankCalcValue) AS [DEF TANK LEVEL(ltr)],
      getFluidValue(adBlueTankCap, aft1_total_diesel_exhaust_fluid_used) AS [DEF CONSUMPTION(ltr)],
      getExhaustTemp(status,aft1_exhaust_temp_1,isBS6) as [Exhaust Temp(℃)],
      customerName AS [CUSTOMER NAME],
      customerMobileNo AS [CUSTOMER MOBILE NO.]
      INTO XLSX("Territory_vehicle_list_"+updateTimeStamp(new Date()) , {headers:true}) FROM ?`

    }else{

      questllist = `SELECT
      regnNumber AS [VEHICLE REG. NO.],
      updateTimeStamp(currentInfo->localTimeStamp) AS [LOCATION TIMESTAMP],
      getLocation(currentInfo->location)  AS [VEHICLE LOCATION],
      getStatusAndSeverity(severity, currentInfo->status) AS [VEHICLE STATUS],
      currentInfo->statusAge  AS [DURATION],
      currentInfo->gpsSpeed  AS [VEHICLE SPEED(kmph)],
      getEngineSpeed(currentInfo->engineSpeed)  AS [ENGINE SPEED / Motor Speed(rpm)],
      getAirPressure(isBS6, vehicleType, currentInfo->airpressure_actual1, currentInfo->air_pressure_digital,currentInfo->status)  AS [AIR PRESSURE(bar)],
      getOilPressure(isBS6, vehicleType, oilPressure, currentInfo->engineOilDig, currentInfo->engine_oil_act,currentInfo->status)  AS [OIL PRESSURE(bar)],
      getBatteryPotential(currentInfo->status,currentInfo->battPotential)  AS [VEHICLE BATTERY POTENTIAL(v)],`
      + ev_params + `
      getstatusOnConditon(currentInfo->status,currentInfo->eng_coolant_temp)  AS [COOLANT TEMPERATURE(℃)],
      isFuelCons(currentInfo->fuelConsumption) AS [FUEL(ltr) / GAS(kg) / Energy(kwh) CONSUMPTION],
      currentInfo->latitude  AS [ALTITUDE(m)],
      getIgnitionStatus(currentInfo->status) AS [IGNITION STATUS],
      getVehicleOdu(vehicleType, currentInfo->vehicle_distance, currentInfo->vehicle_odo, currentInfo->gps_odo, isBS6)  AS [VEHICLE ODOMETER(Km)],
      currentInfo->obuId  AS [OBU ID],
      vehicleModel  AS [VEHICLE MODEL],
      vehicleType AS [VEHICLE TYPE],
      vinNumber  AS [VIN NUMBER],
      driverName AS [DRIVER NAME],
      driverMobileNo AS [DRIVER MOBILE NO.],
      currentInfo->engineHrs AS [ENGINE HOURS(hrs)],
      validFrom AS [SUBSCRIPTION VALID FROM],
      validTo AS [SUBSCRIPTION VALID TO],
      isEvCheck(isBS6, currentInfo->dist_to_empty)  AS [Distance To Empty(km)],
      getENormValue(isBS6, vehicleType) AS [E-NORM],
      getCatalystValue(isBS6, adBlueTankCalcValue) AS [DEF TANK LEVEL(ltr)],
      getFluidValue(adBlueTankCap, currentInfo->aft1_total_diesel_exhaust_fluid_used) AS [DEF CONSUMPTION(ltr)],
      getExhaustTemp(currentInfo->status,currentInfo->aft1_exhaust_temp_1,isBS6) as [Exhaust Temp(℃)],
      getGearNuber(isBS6, currentInfo->gearnumber) as [Gear Number],
      customerName AS [CUSTOMER NAME],
      customerMobileNo AS [CUSTOMER MOBILE NO.]
      INTO XLSX("Sold_vehicle_list_"+updateTimeStamp(new Date()) , {headers:true}) FROM ?`
    }
   alasql(questllist, [this.props.gmapsState.markers]);
  };

  reset_column_config() {
    this.props.gmapsDispatch({
      column: {
        vehicle_odometer: false,
        gps_speed: false,
        engine_speed: false,
        fuel_level: false,
        duration: false,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: true,
        vehicle_type: true,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false,
        customer_name: true,
        exhaust_temperature: true,
        time_stamp: true,
      },
    });

    this.setState({
      temp_column: {
        vehicle_odometer: false,
        gps_speed: false,
        engine_speed: false,
        fuel_level: false,
        duration: false,
        air_pressure: false,
        oil_pressure: false,
        vehicle_battery_potentials: false,
        fuel_consum: false,
        altitude: false,
        vin_number: false,
        obu_id: false,
        vehicle_model: true,
        vehicle_type: true,
        coolant_temp: false,
        sub_valid_from: false,
        sub_valid_to: false,
        is_bs6: false,
        catalyst_tank_level: false,
        adblue_tank_capacity: false,
        driver_name: false,
        engine_hours: false,
        customer_name: true,
        exhaust_temperature: true,
        time_stamp: true,
      },
    });

    var checkboxes = document.getElementsByClassName("configure_checkbox");
    for (let x = 0; x < checkboxes.length; x++) {
      // if (x < 4) {
      if (checkboxes[x].name ==="vehicle_model" || checkboxes[x].name ==="vehicle_type" || checkboxes[x].name ==="exhaust_temperature" || checkboxes[x].name ==="customer_name" ) {
        checkboxes[x].checked = true;
        checkboxes[x].disabled = false;
      } else {
        checkboxes[x].checked = false;
        checkboxes[x].disabled = true;
      }
    }

    var dataObj = {
      user_id: this.props.ctr.userid.toString(),
      location_flag: true,
      time_stamp: true,
      vehicle_odometer: false,
      gps_speed: false,
      engine_speed: false,
      fuel_level: false,
      duration: false,
      air_pressure: false,
      oil_pressure: false,
      vehicle_battery_potentials: false,
      fuel_consum: false,
      altitude: false,
      vin_number: false,
      obu_id: false,
      vehicle_model: true,
      vehicle_type: true,
      coolant_temp: false,
      sub_valid_from: false,
      sub_valid_to: false,
      is_bs6: false,
      catalyst_tank_level: false,
      adblue_tank_capacity: false,
      driver_name: false,
      engine_hours: false,
      customer_name: true,
      customer_mobile_no: false,
      exhaust_temperature : true,
      gprs_signal: false,
      gps_signal: false,
      is_deleted: false,
      created_timestamp: "2019-01-08 14:40:01",
      updated_timestamp: "2019-01-08 14:40:01",
      mobile_ind: false,
      ignition_status: false,
    };

    let vehtoke = this.props.ctr.token;
    const datas = postData(apilist.listviewcustupdate, dataObj, vehtoke);
    datas.then((dataVals) => {
      // console.log("Succes: 200", dataVals);
    });
  }

  sort_by_date(col_name) {
    let sortedData = [];
    let orderType = "";
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);
    if (col_name === "localTimeStamp") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDesc(sorted_table_data, 'localTimeStamp', 'date');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsce(sorted_table_data, 'localTimeStamp', 'date')
      }
    } else if (col_name === "sub_valid_to") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'validTo', 'date');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'validTo', 'date')
      }
    } else if (col_name === "sub_valid_from") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'validFrom', 'date');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'validFrom', 'date')
      }
    }
    this.props.gmapsDispatch({
      markers: sortedData,
      sort: {
        column_name: col_name,
        order: orderType,
      },
    });
    this.refreshnewListView();
  }

  sort_by_alphabet(col_name) {
    let orderType = "";
    let sortedData = [];
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);
    if (col_name === "regnNumber") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDescNormal(sorted_table_data, 'regnNumber', 'text')
        orderType = "asc";
      } else {
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'regnNumber', 'text')
        orderType = "desc";
      }
    } else if (col_name === "status") {
      // x = a.currentInfo.status.toLowerCase();
      // y = b.currentInfo.status.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDesc(sorted_table_data, 'status', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsce(sorted_table_data, 'status', 'text');
      }
    } else if (col_name === "location") {
      // x = a.currentInfo.location.toLowerCase();
      // y = b.currentInfo.location.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDesc(sorted_table_data, 'location', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsce(sorted_table_data, 'location', 'text');
      }
    } else if (col_name === "vin_number") {
      // x = a.currentInfo.vinNumber.toLowerCase();
      // y = b.currentInfo.vinNumber.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDesc(sorted_table_data, 'vinNumber', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsce(sorted_table_data, 'vinNumber', 'text');
      }
    } else if (col_name === "obu_id") {
      // x = a.currentInfo.obuId.toLowerCase();
      // y = b.currentInfo.obuId.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDesc(sorted_table_data, 'obuId', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsce(sorted_table_data, 'obuId', 'text');
      }
    } else if (col_name === "vehicle_model") {
      // x = a.vehicleModel.toLowerCase();
      // y = b.vehicleModel.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'vehicleModel', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'vehicleModel', 'text');
      }
    } else if (col_name === "vehicle_type") {
      // x = a.vehicleType.toLowerCase();
      // y = b.vehicleType.toLowerCase();
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'vehicleType', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'vehicleType', 'text');
      }
    } else if (col_name === "driver_name") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'driverName', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'driverName', 'text');
      }
    } else if (col_name === "customer_name") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        sortedData = this.sortValueDescNormal(sorted_table_data, 'customerName', 'text');
      } else {
        orderType = "desc";
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'customerName', 'text');
      }
    }
    this.props.gmapsDispatch({
      markers: sortedData,
      sort: {
        column_name: col_name,
        order: orderType,
      },
    });
    this.refreshnewListView();
  }

  sort_by_numeric(col_name) {
    let orderType = "";
    let sortedData = [];
    let sorted_table_data = Object.assign([], this.props.gmapsState.markers);
    if (col_name === "vehicle_odometer") {
      if (this.props.gmapsState.sort.order === "desc") {
        orderType = "asc";
        let dropDownValue = this.props.gmapsState.dropdownValue;
        sortedData = sorted_table_data.sort(function (a, b) {
          let firstElem = dropDownValue == "myTerritory" ? a : a.currentInfo;
          let secondElem = dropDownValue == "myTerritory" ? b : b.currentInfo;
          let x, y;
          if (a.isBS6 === 1 || a.isBS6 === 2) {
            if (
              firstElem.vehicle_distance !== null ||
              firstElem.vehicle_distance !== ""
            ) {
              x = Math.round(firstElem.vehicle_distance);
            } else {
              return "";
            }
          } else if (a.isBS6 === 0) {
            if (a.vehicleType === "EEA") {
              x = Math.round(firstElem.vehicle_odo);
            } else {
              x = Math.round(firstElem.gps_odo) || 0;
            }
          }

          if (b.isBS6 === 1||b.isBS6 === 2) {
            if (
              secondElem.vehicle_distance !== null ||
              secondElem.vehicle_distance !== ""
            ) {
              y = Math.round(secondElem.vehicle_distance);
            } else {
              return "";
            }
          } else if (b.isBS6 === 0) {
            if (b.vehicleType === "EEA") {
              y = Math.round(secondElem.vehicle_odo);
            } else {
              y = Math.round(secondElem.gps_odo) || 0;
            }
          }
          return y - x;
        });
      } else {
        orderType = "desc";
        let dropDownValue = this.props.gmapsState.dropdownValue;
        sortedData = sorted_table_data.sort(function (a, b) {
          let firstElem = dropDownValue == "myTerritory" ? a : a.currentInfo;
          let secondElem = dropDownValue == "myTerritory" ? b : b.currentInfo;
          let x, y;
          if (a.isBS6 === 1 || a.isBS6 === 2) {
            if (
              firstElem.vehicle_distance !== null ||
              firstElem.vehicle_distance !== ""
            ) {
              x = Math.round(firstElem.vehicle_distance);
            } else {
              return "";
            }
          } else if (a.isBS6 === 0) {
            if (a.vehicleType === "EEA") {
              x = Math.round(firstElem.vehicle_odo);
            } else {
              x = Math.round(firstElem.gps_odo) || 0;
            }
          }

          if (b.isBS6 === 1 || b.isBS6 === 2) {
            if (
              secondElem.vehicle_distance !== null ||
              secondElem.vehicle_distance !== ""
            ) {
              y = Math.round(secondElem.vehicle_distance);
            } else {
              return "";
            }
          } else if (b.isBS6 === 0) {
            if (b.vehicleType === "EEA") {
              y = Math.round(secondElem.vehicle_odo);
            } else {
              y = Math.round(secondElem.gps_odo) || 0;
            }
          }
          return x - y;
        });
      }
    } else if (col_name === "gps_speed") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'gpsSpeed', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'gpsSpeed', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "engine_speed") {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "engineSpeed");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(conditioned_sort_data, 'engineSpeed', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(conditioned_sort_data, 'engineSpeed', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "fuel_level") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'fuelLevel', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'fuelLevel', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "catalyst_tank_level") {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "adBlueTankCalcValue");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDescNormal(conditioned_sort_data, 'adBlueTankCalcValue', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsceNormal(conditioned_sort_data, 'adBlueTankCalcValue', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "adblue_tank_capacity") {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "aft1_total_diesel_exhaust_fluid_used");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(conditioned_sort_data, 'aft1_total_diesel_exhaust_fluid_used', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(conditioned_sort_data, 'aft1_total_diesel_exhaust_fluid_used', 'numeric');
        orderType = "desc"
      }

    } else if (col_name === "vehicle_battery_potentials") {
      //let conditioned_sort_data = this.checkNullBasedonStatus(sorted_table_data,"battPotential");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'battPotential', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'battPotential', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "fuel_consum") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'fuelConsumption', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'fuelConsumption', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "altitude") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'altitude', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'altitude', 'numeric');
        orderType = "desc"
      }

    } else if (col_name === "coolant_temp") {
      let conditioned_sort_data = this.checkNullBasedonStatus(sorted_table_data,"eng_coolant_temp");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(conditioned_sort_data, 'eng_coolant_temp', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(conditioned_sort_data, 'eng_coolant_temp', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "is_bs6") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDescNormal(sorted_table_data, 'isBS6', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsceNormal(sorted_table_data, 'isBS6', 'numeric');
        orderType = "desc"
      }
    } else if (col_name === "engine_hours") {
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(sorted_table_data, 'engineHrs', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(sorted_table_data, 'engineHrs', 'numeric');
        orderType = "desc"
      }
    }
    else if (col_name === "exhaust_temperature") {
      let conditioned_sort_data = this.checkNullValuesForSortData(sorted_table_data, "aft1_exhaust_temp_1");
      if (this.props.gmapsState.sort.order === "desc") {
        sortedData = this.sortValueDesc(conditioned_sort_data, 'aft1_exhaust_temp_1', 'numeric');
        orderType = "asc"
      } else {
        sortedData = this.sortValueAsce(conditioned_sort_data, 'aft1_exhaust_temp_1', 'numeric');
        orderType = "desc"
      }
    }
    this.props.gmapsDispatch({
      markers: sortedData,
      sort: {
        column_name: col_name,
        order: orderType,
      },
    });
    this.refreshnewListView();
  }

  checkNullBasedonStatus = (sorted_table_data,fieldName) =>{
      return sorted_table_data.map((item) => {
        if (item.currentInfo != undefined) {
          if(item.currentInfo.status != 'RUNNING' && item.currentInfo.status != 'IDLING'){
             item.currentInfo[fieldName] = null;
          }

          return item;

        }else{
          if(item.status != 'RUNNING' && item.status != 'IDLING'){
            item[fieldName] = null;
         }
           return item;
        }
      });
  }

  checkNullValuesForSortData = (sorted_table_data, fieldName) => {
    if (fieldName == "adBlueTankCalcValue") {
      return sorted_table_data.map((item) => {
        if (item.isBS6 == 0 || item.isBS6 == 2) {
          item[fieldName] = null;
          return item;
        } else {
          return item
        }
      });
    }else if(fieldName == "engineSpeed"){
      return sorted_table_data.map((item) => {
         item[fieldName] = Math.round(item[fieldName]) || 0;
         return item;
      });
    }
    else {
      return sorted_table_data.map((item) => {
        if (item.isBS6 == 0 || item.isBS6 == 2) {
          if (item.currentInfo != undefined) {
            item.currentInfo[fieldName] = null;
          } else {
            item[fieldName] = null;
          }
          return item;
        } else {
          if(fieldName == 'aft1_exhaust_temp_1'){
            if (item.currentInfo != undefined) {
              if (item.isBS6 == 1) {
                if(item.currentInfo.status != 'RUNNING' && item.currentInfo.status != 'IDLING'){
                  item.currentInfo[fieldName] = null;
                }
                else{
                  item.currentInfo[fieldName] = item.currentInfo[fieldName]<0 || item.currentInfo[fieldName] ==null?0:item.currentInfo[fieldName];
                }
              }else{
                item.currentInfo[fieldName] = null;
              }
            } else {
              if (item.isBS6 == 1) {
                if(item.status != 'RUNNING' && item.status != 'IDLING'){
                  item[fieldName] = null;
                }else{
                  item[fieldName] = item[fieldName]<0 || item[fieldName] == null?0:item[fieldName];
                }
              }
              else{
                item.currentInfo[fieldName] = null;
              }
            }
          }
          return item
        }
      });
    }
  }


  sortValueDesc(sorted_table_data, fieldName, type) {
    let dropDownValue = this.props.gmapsState.dropdownValue;
    let sortedData = sorted_table_data.sort(function (a, b) {
      let firstElem = dropDownValue == "myTerritory" ? a : a.currentInfo;
      let secondElem = dropDownValue == "myTerritory" ? b : b.currentInfo;
      if (type == 'numeric') {
          if (firstElem[fieldName] === null) {
            return 1;
          }
          else if (secondElem[fieldName] === null) {
              return -1;
          }else{
            return secondElem[fieldName] -firstElem[fieldName];
          }
      }
      else if (type == "text") {
        if (firstElem.status && secondElem.status) {
          a = firstElem[fieldName] != null ? firstElem[fieldName].toLowerCase() : firstElem[fieldName];
          b = secondElem[fieldName] != null ? secondElem[fieldName].toLowerCase() : secondElem[fieldName];
          return b < a ? -1 : b > a ? 1 : 0;
        }
      }
      else if (type == 'date') {
        return (
          new Date(firstElem[fieldName]) -
          new Date(secondElem[fieldName])
        );
      }
    });
    return sortedData;
  }

  sortValueAsce(sorted_table_data, fieldName, type) {
    let dropDownValue = this.props.gmapsState.dropdownValue;
    let sortedData = sorted_table_data.sort(function (a, b) {
      let firstElem = dropDownValue === "myTerritory" ? a : a.currentInfo;
      let secondElem = dropDownValue === "myTerritory" ? b : b.currentInfo;
      if (type == 'numeric') {
        if (firstElem[fieldName] === null) {
          return -1;
        }
        else if (secondElem[fieldName] === null) {
            return 1;
        }else{
          return firstElem[fieldName] - secondElem[fieldName];
        }
      }
      else if (type == "text") {
        if (firstElem.status && secondElem.status) {
          a = firstElem[fieldName] != null ? firstElem[fieldName].toLowerCase() : firstElem[fieldName];
          b = secondElem[fieldName] != null ? secondElem[fieldName].toLowerCase() : secondElem[fieldName];
          return a < b ? -1 : a > b ? 1 : 0;
        }
      }
      else if (type == "date") {
        return (
          new Date(secondElem[fieldName]) -
          new Date(firstElem[fieldName])
        );
      }
    });
    return sortedData;
  }

  sortValueDescNormal(sorted_table_data, fieldName, type) {
    let sortedData = sorted_table_data.sort(function (a, b) {
      if (type == 'numeric') {
        if (a[fieldName] === null) {
          return 1;
        }
        else if (b[fieldName] === null) {
            return -1;
        }else{
          return b[fieldName] -a[fieldName];
        }
      }
      else if (type == "text") {
        a = a[fieldName] != null ? a[fieldName].toLowerCase() : a[fieldName];
        b = b[fieldName] != null ? b[fieldName].toLowerCase() : b[fieldName];
        return b < a ? -1 : b > a ? 1 : 0;
      }
      else if (type == "date") {
        return new Date(a[fieldName]) - new Date(b[fieldName]);
      }
    });
    return sortedData;
  }

  sortValueAsceNormal(sorted_table_data, fieldName, type) {
    let sortedData = sorted_table_data.sort(function (a, b) {
      if (type == 'numeric') {
        if (a[fieldName] === null) {
          return -1;
        }
        else if (b[fieldName] === null) {
            return 1;
        }else{
          return a[fieldName] - b[fieldName];
        }
      }
      else if (type == "text") {
        a = a[fieldName] != null ? a[fieldName].toLowerCase() : a[fieldName];
        b = b[fieldName] != null ? b[fieldName].toLowerCase() : b[fieldName];
        return a < b ? -1 : a > b ? 1 : 0;
      }
      else if (type == "date") {
        return new Date(b[fieldName]) - new Date(a[fieldName]);
      }
    });
    return sortedData;
  }

  getPressureValue(todo, dropDownValue) {
    let currentInfo = dropDownValue && dropDownValue == "myTerritory" ? todo : todo.currentInfo;
    if(currentInfo.status == 'RUNNING' || currentInfo.status == 'IDLING'){
      if (todo.isBS6 === 1|| todo.isBS6 === 2) {
        if (currentInfo.airpressure_actual1) {
          // return todo.currentInfo.airpressure_actual1;
          if (currentInfo.airpressure_actual1 < 4.5) {
            return "BAD";
          } else if (currentInfo.airpressure_actual1 >= 4.5) {
            return "GOOD";
          }
        } else {
          return '-'
        }
      } else {
        if (todo.vehicleType === 'EEA') {
          if (currentInfo.airpressure_actual1) {
            if (currentInfo.airpressure_actual1 < 4.5) {
              return 'BAD';
            } else if (currentInfo.airpressure_actual1 >= 4.5) {
              return 'GOOD';
            }
          } else {
            return '-';
          }
        } else if (todo.vehicleType === 'EDC' || todo.vehicleType === 'IL') {
          // if (currentInfo.air_pressure_digital) {
          //   if (currentInfo.air_pressure_digital === 0) {
          //     return 'BAD';
          //   } else if (currentInfo.air_pressure_digital === 1) {
          //     return 'GOOD';
          //   }
          // } else {
          //   return '-';
          // }
          return '-';
        } else{
          return "-";
        }
      }
      //   } else if (todo.vehicleType === 'IL') {
      //     if (currentInfo.air_pressure_digital) {
      //       if (currentInfo.air_pressure_digital === 0) {
      //         return 'BAD';
      //       } else if (currentInfo.air_pressure_digital === 1) {
      //         return 'GOOD';
      //       }
      //     } else {
      //       return '-';
      //     }
      //   }
      // }
  }
};

  getOilPressureValue(todo, dropDownValue) {
    let currentInfo = dropDownValue && dropDownValue == "myTerritory" ? todo : todo.currentInfo;
    if(currentInfo.status == 'RUNNING' || currentInfo.status == 'IDLING'){
      if (todo.isBS6 === 1|| todo.isBS6 === 2) {
        if (todo.oilPressure && todo.currentInfo.engine_oil_act) {
          if (parseInt(currentInfo.engine_oil_act) < 0.5) {
            return "BAD";
          } else if (parseInt(currentInfo.engine_oil_act) >= 0.5) {
            return "GOOD";
          }
        } else {
          return "-";
        }
      } else {
        if (todo.vehicleType === "EEA") {
          if (currentInfo.engine_oil_act) {
            if (parseInt(currentInfo.engine_oil_act) < 0.5) {
              return "BAD";
            } else if (parseInt(currentInfo.engine_oil_act) >= 0.5) {
              return "GOOD";
            }
          } else {
            return "-";
          }
        } else if (todo.vehicleType === "EDC") {
          if (currentInfo.engine_oil_act) {
            if (parseInt(currentInfo.engine_oil_act) < 0.5) {
              return "BAD";
            } else if (parseInt(currentInfo.engine_oil_act) >= 0.5) {
              return "GOOD";
            }
          } else {
            return "-";
          }
        } else if (todo.vehicleType === "IL") {
          if (currentInfo.engineOilDig) {
            if (currentInfo.engineOilDig === 0) {
              return "BAD";
            } else if (currentInfo.engineOilDig === 1) {
              return "GOOD";
            }
          } else {
            return "-";
          }
        }
      }
   }
   else{
     return '-';
   }

  };

  getSeverity(todo) {
    if (todo.severity) {
      var str = todo.severity;
      var chars = str.split(",");
      let result;
      for (let i = 0; i < chars.length; i++) {
        if (chars[i] === "Critical") {
          result = "CRITICAL";
          break;
        } else if (chars[i] === "Warning") {
          result = "WARNING";
          break;
        } else if (chars[i] === "Service") {
          result = "SERVICE DUE";
          break;
        }
      }
      return result;
    }
  }

  getBS6Value(todo) {
    if (todo.isBS6 === 1) {
      return (
        <img
          className=""
          src="images/ListView/bsvi_logo.png  "
          alt=""
          style={{ maxWidth: "15%" }}
        />
      );
    } else if (todo.isBS6 === 2) {
      return (
        <div>
          <img
            className=""
            src="images/ListView/bsvi_logo.png  "
            alt=""
            style={{ maxWidth: "15%" }}
          /><p>{todo.vehicleType === 'EDC_LNG' ? 'LNG' : 'CNG'}</p>
        </div>
      );
    } else if (todo.isBS6 === 3) {
      return (
        <div>
          <img
            className=""
            src="images/ListView/bsvi_logo.png  "
            alt=""
            style={{ maxWidth: "15%" }}
          /><p>LCV</p>
        </div>
      );
    } else if (todo.isBS6 === 0) {
      return 'BSIV';
    } else if (todo.isBS6 === 4) {
      return 'EV';
    }
  }

  getCatalystTanlLevelValue(todo,dropDownValue) {
    let currentInfo = dropDownValue && dropDownValue == "myTerritory" ? todo : todo.currentInfo;
    if(todo.isBS6 === 1){
      if(todo.adBlueTankCalcValue !== null){
        let tankpercentaddblue = Math.round(currentInfo.catalyst_tank_level);
        if(tankpercentaddblue>100){
          return parseFloat(todo.adBlueTankCap).toFixed(1);
        }else{
          return  parseFloat(todo.adBlueTankCalcValue).toFixed(1);
        }
      }else{
        return '';
      }
    }else if(todo.isBS6 === 2){
      return 'N/A'
    }else if(todo.isBS6 === 0){
      return 'N/A'
    }
  }

  getFluidUsedValue(todo, dropDownValue) {
    let currentInfo = dropDownValue && dropDownValue == "myTerritory" ? todo : todo.currentInfo;
    // if(todo.isBS6 === 1){
    //   if(currentInfo.aft1_total_diesel_exhaust_fluid_used !== null){
    //     return formatValue(currentInfo.aft1_total_diesel_exhaust_fluid_used);
    //   }else{
    //     return '';
    //   }
    // }else if(todo.isBS6 === 2){
    //   return 'N/A'
    // }else if(todo.isBS6 === 0){
    //   return 'N/A'
    // }
    if (todo?.adBlueTankCap && !isNaN(todo?.adBlueTankCap) && parseInt(todo.adBlueTankCap) > 0) {
      return (parseFloat(currentInfo?.aft1_total_diesel_exhaust_fluid_used)).toFixed(1);
    } else {
      return 'N/A'
    }
  }

  getGpsSpeed(todo, dropDownValue) {
    let currentInfo = dropDownValue && dropDownValue == "myTerritory" ? todo : todo.currentInfo;
    // if(todo.isBS6 === 1){
    //   if(currentInfo.vehicle_distance !== null || todo.currentInfo.vehicle_distance !== ''){
    //     return Math.round(currentInfo.vehicle_distance);
    //   }else{
    //     return '';
    //   }
    // }else if(todo.isBS6 === 2){
    //   if(currentInfo.vehicle_distance !== null || currentInfo.vehicle_distance !== ''){
    //     return Math.round(currentInfo.vehicle_distance);
    //   }else{
    //     return '';
    //   }
    // }else if(todo.isBS6 === 0){
    //   if(todo.vehicleType === 'EEA'){
    //     return Math.round(currentInfo.vehicle_odo);
    //   }else{
    //     return Math.round(currentInfo.gps_odo) || 0;
    //   }
    // }
    if (todo.isBS6 === 0) {
      return todo.vehicleType === 'EEA' ? Math.round(currentInfo.vehicle_odo) : Math.round(currentInfo.gps_odo) || 0;
    } else {
        return  typeof currentInfo.vehicle_distance === 'number' ? Math.round(currentInfo.vehicle_distance) : '';
    }
  }

  callvascreen = (e, c) => {
    if (e.currentInfo.status === "DISCONNECTED") {
      var ids = c.target.getAttribute("menuclickid").split(",");
      var menuClickids = [];
      menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
      this.props.quicklinks(menuClickids);
      this.props.history.push("/createVas", { vehicledata: e.regnNumber });
    }
  };
  dialhighlight = (e) => {
    let status = e.currentTarget.dataset.status;
    this.props.gmapsDispatch({ howerdialcss: status });
  };

  handleDropdownChange = (e) => {
    // console.log(this.props.gmapsState.loader);
    try{
      this.props.gmapsDispatch({ dropdownValue: e.target.value, selectedMarker: "", loader: true }).then(() => {
        // console.log(this.props.gmapsState.dropdownValue);
        if (this.props.gmapsState.refreshselecteddata !== "Total") {
          this.clickstatus("Total");
        }
        let el = document.getElementsByClassName("Cls_btns")[0];
        if (el) {
          el.getElementsByClassName("fa-close")[0].click();
          this.props.gmapsDispatch({ searchvechiclelist: [], markers: [], onswitchDropdownValue: true });
        }
        else {
          if (this.props.gmapsState.dropdownValue === "mySoldVehicles") {
            let dataObj = {};
            dataObj.userId = this.props.ctr.userid;
            let vehtokens = this.props.ctr.token;
            dataObj.platform = "w";
            dataObj.obuList = [];
            const datas = postData(apilist.vehiclecount, dataObj, vehtokens); // vehicleInfo
            datas.then((dataVals) => {
              this.props.gmapsDispatch({
                vehCount: dataVals.data,
                callGlobalApi: "sold"
              });
            });
            // this.setState({ listview: this.props.location.state })
            let vehlist = {};
            vehlist.userId = this.props.ctr.userid;
            if (this.props.ctr.userid === undefined) {
              vehlist.userId = "";
            }
            vehlist.userRole = this.props.ctr.userrole;
            vehlist.obuIdList = [];
            let vehtoken = this.props.ctr.token;
            vehlist.platform = "w";
            const response = postData(
              apilist.ownVehicleListDealer,
              vehlist,
              vehtoken
            );
            response.then((datas) => {
              if (datas.statuscode === 200) {
                // this.setState({ loader: false });
                this.assigningIcons(datas);
                // datas.data.map((data, i) => {
                //   if (
                //     data.currentInfo.heading >= 337.5 &&
                //     data.currentInfo.heading <= 22.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/01.png";
                //   } else if (
                //     data.currentInfo.heading >= 22.5 &&
                //     data.currentInfo.heading <= 67.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/02.png";
                //   } else if (
                //     data.currentInfo.heading >= 67.5 &&
                //     data.currentInfo.heading <= 112.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/03.png";
                //   } else if (
                //     data.currentInfo.heading >= 112.5 &&
                //     data.currentInfo.heading <= 157.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/04.png";
                //   } else if (
                //     data.currentInfo.heading >= 157.5 &&
                //     data.currentInfo.heading <= 202.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/05.png";
                //   } else if (
                //     data.currentInfo.heading >= 202.5 &&
                //     data.currentInfo.heading <= 247.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/06.png";
                //   } else if (
                //     data.currentInfo.heading >= 247.5 &&
                //     data.currentInfo.heading <= 292.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/07.png";
                //   } else if (
                //     data.currentInfo.heading >= 292.5 &&
                //     data.currentInfo.heading <= 337.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/08.png";
                //   } else {
                //     data.icons =
                //       "/images/Map_screen/" + data.currentInfo.status + "/01.png";
                //   }
                //   return data;
                // });
                // this.props.gmapsDispatch({
                //   markers: datas.data,
                //   totalmarkers: datas.data,
                //   excel_data: datas.data,
                //   searchresultdata: datas.data
                // }).then(() => {
                //   this.zoomToMarkersVal(this.props.gmapsState);
                //   this.vehiclealerts(this.props.gmapsState);
                //   this.props.gmapsDispatch({ loader: false });
                // });
              } else {
                alert("No Data available");
                this.props.gmapsDispatch({
                  loader: false,
                  totalmarkers: [],
                  excel_data: [],
                  searchresultdata: [],
                  markers: []
                });
              }
            });
          } else if (this.props.gmapsState.dropdownValue === "myTerritory") {
            let dataObj = {};
            dataObj.userId = this.props.ctr.userid;
            dataObj.platform = 'w'
            dataObj.userRole = this.props.ctr.userrole;
            const token = this.props.ctr.token;
            const territoryData = postData(
              apilist.territoryVehiclesData,
              dataObj,
              token
            );
            territoryData.then((territoryData) => {
              // console.log('dls',territoryData);
              if (territoryData.statuscode === 200) {
                this.assigningIcons(territoryData);
                // territoryData.data.getvehicleinfo && territoryData.data.getvehicleinfo.map((data, i) => {
                //   data.regn_number = data.regnNumber;
                //   if (
                //     data.heading >= 337.5 &&
                //     data.heading <= 22.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/01.png";
                //   } else if (
                //     data.heading >= 22.5 &&
                //     data.heading <= 67.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/02.png";
                //   } else if (
                //     data.heading >= 67.5 &&
                //     data.heading <= 112.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/03.png";
                //   } else if (
                //     data.heading >= 112.5 &&
                //     data.heading <= 157.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/04.png";
                //   } else if (
                //     data.heading >= 157.5 &&
                //     data.heading <= 202.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/05.png";
                //   } else if (
                //     data.heading >= 202.5 &&
                //     data.heading <= 247.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/06.png";
                //   } else if (
                //     data.heading >= 247.5 &&
                //     data.heading <= 292.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/07.png";
                //   } else if (
                //     data.heading >= 292.5 &&
                //     data.heading <= 337.5
                //   ) {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/08.png";
                //   } else {
                //     data.icons =
                //       "/images/Map_screen/" + data.status + "/01.png";
                //   }
                //   return data;
                // });
                // console.log(territoryData.data);
                // this.props
                //   .gmapsDispatch({
                //     markers: territoryData.data.getvehicleinfo,
                //     totalmarkers: territoryData.data.getvehicleinfo,
                //     dealerLat: Number(territoryData.data.dealerlat),
                //     dealerLong: Number(territoryData.data.dealerlong),
                //     vehCount: territoryData.data.labelCount,
                //     searchresultdata: territoryData.data.getvehicleinfo,
                //     callGlobalApi: "territory"
                //   })
                //   .then(() => {
                //     this.zoomToMarkersVal(this.props.gmapsState);
                //     this.vehiclealerts(this.props.gmapsState);
                //     this.props.gmapsDispatch({ loader: false });
                //   });
              }
              else {
                alert("No Data available");
                this.props.gmapsDispatch({
                  loader: false,
                  totalmarkers: [],
                  markers: []
                });
              }
            }, (err) => {
              console.log(err);
              this.props.gmapsDispatch({
                loader: false,
              });
            });
          }
        }


      });
    }catch(e){
      this.props.gmapsDispatch({
        loader: false,
      });
      alert("Failed with some error.");
    }
  };

  getRadiuschangeFunc = () => {
    const maxRadius = 100000;
    // console.log("Radius", this.CircleRef.current.getRadius());
    if (this.CircleRef.getRadius() <= maxRadius) {
      this.setState({ circleRadius: this.CircleRef.getRadius() });
    } else if (this.state.circleRadius === 100000) {
      this.setState({ circleRadius: 100000.001 });
    } else if (this.state.circleRadius === 100000.001) {
      this.setState({ circleRadius: 100000 });
    } else {
      this.setState({ circleRadius: maxRadius });
    }
  };

  _changeListviewTemplate = (e) => {
    // console.log(e.target, e.target.value);
    try{
      let value = e.target.value;

      this.setState({
        dropiconValue: 'asc',
        selectedSortValue: 'TimeStamp'
      })

      this.props.gmapsDispatch({
        dropdownValue: value,
        loader: true,
        currentPage:1,
        paginationcolor:1,
        callGlobalApi:false
      });
      let el = document.getElementsByClassName("Cls_btns")[0];
      if (el) {
        el.getElementsByClassName("fa-close")[0].click();
        this.props.gmapsDispatch({ searchvechiclelist: [], onswitchDropdownValue: true });
      } else {
        if (value === "myTerritory") {
          // this.props.gmapsDispatch({ callGlobalApi: "territory" });
          this.getTerritoryDetails();

        } else {
          //this.props.gmapsDispatch({ callGlobalApi: false });
          let vehlist = {};
          vehlist.userId = this.props.ctr.userid;
          if (this.props.ctr.userid === undefined) {
            vehlist.userId = "";
          }
          vehlist.userRole = this.props.ctr.userrole;
          vehlist.obuIdList = [];
          let vehtoken = this.props.ctr.token;
          vehlist.platform = "w";

          const response = postData(
            apilist.allvehicledatadealer,
            vehlist,
            vehtoken
          );
          response.then((datas) => {
            if (datas.statuscode === 200) {
              this.props.gmapsDispatch({
                markers: datas.data,
                totalmarkers: datas.data,
                excel_data: datas.data,
                previous_markers: datas.data,
                searchresultdata: datas.data,
                callGlobalApi:"sold"
              }).then(() => {
                this.vehiclealerts(this.props.gmapsState);
                this.props.gmapsDispatch({ loader: false });
                this.refreshnewListView();
              });
            } else {
              alert("No Data available");
              this.props.gmapsDispatch({
                loader: false,
                totalmarkers: [],
                markers: [],
                excel_data: [],
                previous_markers: [],
                searchresultdata: []
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            if (this.props.gmapsState.refreshselecteddata !== "Total") {
              this.clickstatus("Total");
            }
          });
          let dataObj = {};
          dataObj.userId = this.props.ctr.userid;
          let vehtokens = this.props.ctr.token;
          dataObj.platform = "w";
          dataObj.obuList = [];
          const datas = postData(apilist.vehiclecount, dataObj, vehtokens); // vehicleInfo
          datas.then((dataVals) => {
            this.props.gmapsDispatch({
              vehCount: dataVals.data,
            });
          });
        }
      }
    }
    catch(e){
      this.props.gmapsDispatch({
        loader: false,
      });
      alert("Failed with some error.")
    }
  };


  getTerritoryDetails = () => {
    this.props.gmapsDispatch({ loader: true });
    let vehlist = {};
    vehlist.userId = this.props.ctr.userid;
    if (this.props.ctr.userid === undefined) {
      vehlist.userId = "";
    }
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.obuIdList = [];
    let vehtoken = this.props.ctr.token;
    vehlist.platform = "w";

    const response = postData(
      apilist.territoryVehiclesData,
      vehlist,
      vehtoken
    );
    response.then((datas) => {
      if (datas.statuscode === 200) {

        this.assigningIcons(datas);
       // this.sortingResposneData(datas.data.getvehicleinfo);
       // this.clickstatus(this.props.gmapsState.refreshselecteddata);

        if (this.props.listviewshows !== "showlist") {
          if (
            this.props.gmapsState.refreshselecteddata !== undefined &&
            this.props.gmapsState.selectedMarker === ""
          ) {
            this.clickstatus(this.props.gmapsState.refreshselecteddata);
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else if (this.props.togglevalues === true ||this.state.reseton === true) {
          if (this.props.gmapsState.refreshselecteddata !== undefined && this.props.gmapsState.selectedMarker === "") {
            if (this.hmautoreset === true) {
              this.clickstatus(this.props.gmapsState.refreshselecteddata);
            } else {
              this.clickstatus("Total");
            }
          } else {
            this.props.gmapsDispatch({
              Vehiclestatushigh: this.props.gmapsState.refreshselecteddata,
            });
            this.sortingResposneData(datas.data);
          }
          if (
            this.props.location.state !== undefined &&
            this.props.location.state !== null
          ) {
            if (
              Object.keys(this.props.location.state)[0] === "Vehiclestatushigh"
            ) {
              if (this.props.location.state.Vehiclestatushigh !== "Total") {
                this.clickstatus(this.props.location.state.Vehiclestatushigh);
              }
            }
          }
        } else {
          this.props.gmapsDispatch({ Vehiclestatushigh: "Total" });
          this.sortingResposneData(datas.data);
        }
        // this.props
        //   .gmapsDispatch({
        //     totalmarkers: datas.data.getvehicleinfo,
        //     excel_data: datas.data.getvehicleinfo,
        //     markers: req_markers,
        //     vehCount: datas.data.labelCount,
        //     dealerLat: Number(datas.data.dealerlat),
        //     dealerLong: Number(datas.data.dealerlong),
        //     searchresultdata: req_markers,
        //   })
        //   .then(() => {
        //     this.vehiclealerts(this.props.gmapsState);
        //     this.zoomToMarkersVal(this.props.gmapsState);
        //     this.props.gmapsDispatch({ loader: false, callGlobalApi: "territory" });
        //     this.refreshnewListView();
        //   });

      } else {
        alert("No Data available");
        this.props.gmapsDispatch({
          loader: false,
          totalmarkers: [],
          excel_data: [],
          markers: [],
          searchresultdata: []
        });
      }
    })
    .catch((err) => {
      console.error(err);
    })
    // .finally(() => {
    //   if (this.props.gmapsState.refreshselecteddata !== "Total") {
    //     this.clickstatus("Total");
    //   }
    // });;
  }

  listViewSwither = () => {
    this.props.gmapsDispatch({
      // markers: sortedData,
      sort: {
        column_name: 'localTimeStamp',
        order: 'asc'
      }
    }).then(() => {
      this.sort_by_date('localTimeStamp');
      this.setState({
        classic_view: this.state.classic_view ? false : true, refreshItem: true,
        showIconDropDown: false,
        showFilterDropDown: false,
        dropiconValue: 'asc',
        selectedSortValue: 'TimeStamp'
      });
    })

  }

  filterDropdownValueShow = () => {
    this.setState({
      showFilterDropDown: this.state.showFilterDropDown ? false : true,
      showIconDropDown: false,
      classicSortColumns: this.classicSortingColums
    })
  }

  onSortkeySelect = (sortValue) => {
    this.setState({ refreshItem: false });
    this.props.gmapsDispatch({
      // markers: sortedData,
      sort: {
        column_name: sortValue.key,
        order: this.state.dropiconValue
      }
    }).then(() => {
      if (sortValue.type == "alpha") {
        this.sort_by_alphabet(sortValue.value);

      }
      else if (sortValue.type == 'date') {
        this.sort_by_date(sortValue.value);

      }
      else if (sortValue.type == "numeric") {
        this.sort_by_numeric(sortValue.value);
      }
      this.setState({
        showFilterDropDown: false,
        refreshItem: true,
        selectedSortValue: sortValue.key
      });
    });

  }

  onFilterSearch = (event) => {
    try {
      let targetValue = event.target.value;
      let filteredItems = this.classicSortingColums.filter((val) => val['key'].toLowerCase().includes(targetValue.toLowerCase()));
      this.setState({
        selectedSortValue: targetValue,
        classicSortColumns: filteredItems
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  expandIconDropDown = () => {
    this.setState({
      showIconDropDown: false,
      dropiconValue: this.state.dropiconValue == 'asc' ? 'desc' : 'asc',
      showFilterDropDown: false
    }, () => {
      let filterSortvalue = this.classicSortingColums.filter((item) => item.key == this.state.selectedSortValue);
      if (filterSortvalue.length > 0) {
        this.onSortkeySelect(filterSortvalue[0]);
      }
    });
  }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      // alert('You clicked outside of me!');
      this.setState({
        showFilterDropDown: false
      });
    }

  }

  render() {
    // console.log(this.props.gmapsState);
    const {
      currentPage,
      todosPerPage,
      dropdownValue,
      listview,
    } = this.props.gmapsState;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;

    const currentTodos = this.props.gmapsState.markers.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );
    // for(var i = 0; i < currentTodos.length; i++){
    this.renderTodos = currentTodos.map((todo, index) => {
      if (this.state.classic_view == false) {
        return (
          <TableListView key={index} todo={todo} index={index} getGpsSpeed={this.getGpsSpeed} callvascreen={this.callvascreen} getCatalystTanlLevelValue={this.getCatalystTanlLevelValue} getBS6Value={this.getBS6Value} getOilPressureValue={this.getOilPressureValue} getPressureValue={this.getPressureValue}
            getFluidUsedValue={this.getFluidUsedValue} gmapsState={this.props.gmapsState} getSeverity={this.getSeverity} handleClick={this.handleClick} ></TableListView>
        );
      } else {
        return (
          <NewListView  key={index} data={todo} rowIndex={index} gpsSpeed={this.getGpsSpeed} gmapsState={this.props.gmapsState} getSeverity={this.getSeverity} onLinkClick={this.handleClick} fluidUsed={this.getFluidUsedValue} autoreload={true} viewType="Dealer" catalystTankLevel={this.getCatalystTanlLevelValue} oilPressure={this.getOilPressureValue} airPressure={this.getPressureValue}></NewListView>
        );
      }
    });
    // }
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.props.gmapsState.markers.length / todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (pageNumbers.length <= 6) {
        return (
          <td
            key={number}
            id={number}
            onClick={this.handleClickpage}
            className={
              this.props.gmapsState.paginationcolor === number
                ? "slectedbluepg pagesize"
                : "notselected pagesize"
            }
          >
            {number}
          </td>
        );
      } else {
        if (number === 1) {
          if (this.props.gmapsState.paginationcolor <= 5) {
            return (
              <td
                key={number}
                id={number}
                onClick={this.handleClickpage}
                className={
                  this.props.gmapsState.paginationcolor === number
                    ? "slectedbluepg pagesize"
                    : "notselected pagesize"
                }
              >
                {number}
              </td>
            );
          } else {
            return (
              <td
                key={number}
                id={number}
                onClick={this.handleClickpage}
                className={
                  this.props.gmapsState.paginationcolor === number
                    ? "slectedbluepg pagesize"
                    : "notselected pagesize"
                }
              >
                {number}...
              </td>
            );
          }
        } else if (
          number > 1 &&
          number <= 6 &&
          this.props.gmapsState.paginationcolor <= 6
        ) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              {number}
            </td>
          );
        } else if (
          number >= this.props.gmapsState.paginationcolor - 2 &&
          number <= this.props.gmapsState.paginationcolor + 2 &&
          this.props.gmapsState.paginationcolor > 6
        ) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              {number}
            </td>
          );
        } else if (number === pageNumbers.length) {
          return (
            <td
              key={number}
              id={number}
              onClick={this.handleClickpage}
              className={
                this.props.gmapsState.paginationcolor === number
                  ? "slectedbluepg pagesize"
                  : "notselected pagesize"
              }
            >
              <span>...</span>
              {number}
            </td>
          );
        }
      }
      return "";
    });

    return (
      <div className="">
        {/* <div className="container-wrapper">
          <Header />
        </div> */}
        {/* <div className="border-row"></div> */}

        {!this.props.gmapsState.listview && this.props.gmapsState.loader ? (
          <div className="Pageloading">
            {" "}
            <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" />
          </div>
        ) : (
          <div className="">
            <div className="menuStyle">
              <div className="container-wrapper">
                {/* <SearchField resetresult={this.resettheresult} searchresultprop={this.clicksearchresult} />  */}
              </div>
            </div>
          </div>
        )}

        {this.props.gmapsState.listview ? (
          <div>
            {/* <body className="table-heading"></body> */}

            <div className="trip_header">
              <div className="trip_container">
                <div className="trip-menu"
                >
                  <div className="col-3">
                    <button type="button" className="download_btn" onClick={this.listViewSwither.bind(this)} style={{ paddingLeft: "4px", outlineStyle: "none" }} >Classic View<div className={this.state.classic_view == false ? "myswitchgeo" : "myswitchgeo activatedgeo"} >
                      {this.state.classic_view ? <div style={{ marginLeft: "6px", marginTop: "6px", color: "white" }}>ON</div> : <div style={{ marginLeft: "6px", marginTop: "6px" }}>OFF</div>}</div><span className="btn-nw-info">New</span></button>
                  </div>
                  <div className="col-3">
                    <select
                      className="lv_tr_dropdown listviewDropdown"
                      name="listviewDropdown"
                      id=""
                      onChange={this._changeListviewTemplate}
                    >
                      <option value="mySoldVehicles">My Sold Vehicle</option>
                      <option value="myTerritory">My Territory Vehicle</option>
                    </select>
                  </div>
                  {this.state.classic_view == false ?
                    <div className="col-3">
                      <button
                        variant="warning"
                        type="button"
                        className="download_btn"
                        onClick={this.configure.bind(this)}
                      >
                        Configure
                        <i
                          className="fa fa-cog"
                          aria-hidden="true"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </button>
                    </div> : null}
                  <div className="col-3">
                    <button
                      variant="warning"
                      type="button"
                      className="configure_btn"
                      onClick={this.download_listview_excel.bind(this)}
                    >
                      Download
                      <i
                        className="fa fa-download"
                        aria-hidden="true"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </button>
                  </div>
                  {this.state.classic_view ?
                    <div className="col-3">
                      <div className="custom-input custom-input-mg">
                        <input type="text" className="form-control custom-int" placeholder="Sort By" value={this.state.selectedSortValue} onClick={this.filterDropdownValueShow} onChange={this.onFilterSearch} />


                        <div className={!this.state.showFilterDropDown ? "dropdown-menu dropdown-menu-menu" : "dropdown-menu dropdown-menu-menu showfilterDropdown"} ref={(node) => this.wrapperRef = node}>
                          {this.state.classicSortColumns.map((n) => {
                            return <><MenuItem onClick={() => {
                              this.onSortkeySelect(n)
                            }}>{n.key}</MenuItem></>
                          })}
                        </div>
                        <button className="btn dropdown_custom custom-btn " type="button" onClick={this.expandIconDropDown}><span className={this.state.dropiconValue == "asc" ? "fa fa-sort-alpha-asc show-img" : "fa fa-sort-alpha-desc show-img"}></span></button>
                      </div>
                    </div>
                    : null}

                  <div
                    className="popup configureForm"
                    style={{
                      display: this.props.gmapsState.configureFormState,
                    }}
                  >
                    <div className="popup_body listview_column_popup_body">
                      <div className="popup_header">
                        <p className="listview_column_popup_heading">
                          <strong>LIST VIEW CONFIGURATION</strong>
                          <span
                            className="close_list_configuration_btn"
                            onClick={this.close_list_configuration.bind(this)}
                            style={{ cursor: "pointer", float: "right" }}
                          >
                            ✖
                          </span>
                        </p>
                        <br />
                      </div>
                      <div className="popup_content">
                        <div className="row listview_column_config_popup_body config_div">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="air_pressure"
                                  value={
                                    this.props.gmapsState.column.air_pressure
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "9",
                                    "air_pressure"
                                  )}
                                />{" "}
                                <label>Air Pressure</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="altitude"
                                  value={this.props.gmapsState.column.altitude}
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "13",
                                    "altitude"
                                  )}
                                />{" "}
                                <label>Altitude</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="vehicle_battery_potentials"
                                  value={
                                    this.props.gmapsState.column
                                      .vehicle_battery_potentials
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "11",
                                    "vehicle_battery_potentials"
                                  )}
                                />{" "}
                                <label>Battery Potential</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="coolant_temp"
                                  value={
                                    this.props.gmapsState.column.coolant_temp
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "18",
                                    "coolant_temp"
                                  )}
                                />{" "}
                                <label>Coolant Temperature</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="customer_name"
                                  value={
                                    this.props.gmapsState.column.customer_name
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "26",
                                    "customer_name"
                                  )}
                                />{" "}
                                <label>Customer Name & Contact</label>
                                <br/>
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="adblue_tank_capacity"
                                  value={
                                    this.props.gmapsState.column
                                      .adblue_tank_capacity
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "23",
                                    "adblue_tank_capacity"
                                  )}
                                />{" "}
                                <label>DEF Consumption</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="catalyst_tank_level"
                                  value={
                                    this.props.gmapsState.column
                                      .catalyst_tank_level
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "22",
                                    "catalyst_tank_level"
                                  )}
                                />{" "}
                                <label>DEF Tank Level</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="driver_name"
                                  value={
                                    this.props.gmapsState.column.driver_name
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "24",
                                    "driver_name"
                                  )}
                                />{" "}
                                <label>Driver Name & Contact</label>
                                <br />
                              </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="engine_hours"
                                  value={
                                    this.props.gmapsState.column.engine_hours
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "25",
                                    "engine_hours"
                                  )}
                                />{" "}
                                <label>Engine Hours</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="engine_speed"
                                  value={
                                    this.props.gmapsState.column.engine_speed
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "6",
                                    "engine_speed"
                                  )}
                                />{" "}
                                <label>Engine Speed</label>
                                <br />
                              <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="is_bs6"
                                  value={this.props.gmapsState.column.is_bs6}
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "21",
                                    "is_bs6"
                                  )}
                                />{" "}
                                <label>E-Norm</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="exhaust_temperature"
                                  value={this.props.gmapsState.column.exhaust_temperature}
                                  onChange={this.toggleColumns.bind(this, '25', 'exhaust_temperature')}
                                />{" "}
                                <label>Exhaust Temp</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="fuel_consum"
                                  value={
                                    this.props.gmapsState.column.fuel_consum
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "12",
                                    "fuel_consum"
                                  )}
                                />{" "}
                                <label>Fuel Consumption</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="fuel_level"
                                  value={
                                    this.props.gmapsState.column.fuel_level
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "7",
                                    "fuel_level"
                                  )}
                                />{" "}
                                <label>Fuel Level</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="vehicle_model"
                                  value={
                                    this.props.gmapsState.column.vehicle_model
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "16",
                                    "vehicle_model"
                                  )}
                                />{" "}
                                <label>Model</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="obu_id"
                                  value={this.props.gmapsState.column.obu_id}
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "15",
                                    "obu_id"
                                  )}
                                />{" "}
                                <label>OBU ID</label>
                                <br />
                              <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="vehicle_odometer"
                                  value={
                                    this.props.gmapsState.column
                                      .vehicle_odometer
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "4",
                                    "vehicle_odometer"
                                  )}
                                />{" "}
                                <label>Odometer</label>
                                <br />
                                {/* <input className="configure_checkbox" type="checkbox" name="duration" value={this.props.gmapsState.column.duration} onChange={this.toggleColumns.bind(this, '8', 'duration')} /> <label>Duration</label><br /> */}

                              </div>

                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                              <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="oil_pressure"
                                  value={
                                    this.props.gmapsState.column.oil_pressure
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "10",
                                    "oil_pressure"
                                  )}
                                />{" "}
                                <label>Oil Pressure</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="sub_valid_from"
                                  value={
                                    this.props.gmapsState.column.sub_valid_from
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "19",
                                    "sub_valid_from"
                                  )}
                                />{" "}
                                <label>Subscription Valid From</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="sub_valid_to"
                                  value={
                                    this.props.gmapsState.column.sub_valid_to
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "20",
                                    "sub_valid_to"
                                  )}
                                />{" "}
                                <label>Subscription Valid To</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="gps_speed"
                                  value={this.props.gmapsState.column.gps_speed}
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "5",
                                    "gps_speed"
                                  )}
                                />{" "}
                                <label>Vehicle Speed</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="vehicle_type"
                                  value={
                                    this.props.gmapsState.column.vehicle_type
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "17",
                                    "vehicle_type"
                                  )}
                                />{" "}
                                <label>Vehicle Type</label>
                                <br />
                                <input
                                  className="configure_checkbox"
                                  type="checkbox"
                                  name="vin_number"
                                  value={
                                    this.props.gmapsState.column.vin_number
                                  }
                                  onChange={this.toggleColumns.bind(
                                    this,
                                    "14",
                                    "vin_number"
                                  )}
                                />{" "}
                                <label>VIN</label>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="listview_max_4_msg">
                              <span style={{ verticalAlign: "middle" }}>*</span>{" "}
                              Maximum of 4 columns can be selected.
                            </p>
                            <div className="button-container">
                              <p className="pull-right">
                                <span className="w200">
                                  <input
                                    type="button"
                                    className="submit_config_btn"
                                    name="submit"
                                    onClick={this.save_column_config.bind(this)}
                                    value="Submit"
                                  />
                                </span>
                                <span className="w200">
                                  <input
                                    type="button"
                                    className="reset_config_btn"
                                    name="reset"
                                    onClick={this.reset_column_config.bind(
                                      this
                                    )}
                                    value="Reset"
                                  />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="table-responsive table_align tableFixHead">
              {this.props.gmapsState.listview &&
                this.props.gmapsState.loader ? (
                <div className="Pageloading">
                  {" "}
                  <img
                    className="loadimg"
                    src="/images/loader_1.gif"
                    alt="iAlert"
                  />
                </div>
              ) : (
                this.state.classic_view == false ?
                  <table className="table listview_table_design" id="ListViewTable">
                    <thead>
                      <tr className="listview_table_header">
                        <th
                          onClick={this.sort_by_alphabet.bind(this, "regnNumber")}
                        >
                          Reg.No{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          className="listview_status_alert_column"
                          onClick={this.sort_by_alphabet.bind(this, "status")}
                        >
                          Status{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th onClick={this.sort_by_alphabet.bind(this, "location")}>
                          Location{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_date.bind(this, "localTimeStamp")}
                        >
                          TimeStamp{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(
                            this,
                            "vehicle_odometer"
                          )}
                          className={
                            this.props.gmapsState.column.vehicle_odometer === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Odometer{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "gps_speed")}
                          className={
                            this.props.gmapsState.column.gps_speed === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Vehicle Speed{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "engine_speed")}
                          className={
                            this.props.gmapsState.column.engine_speed === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Engine Speed{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "fuel_level")}
                          className={
                            this.props.gmapsState.column.fuel_level === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Fuel Level{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>

                        {/* <th className={this.props.gmapsState.column.duration === true ? 'listview_show' : 'listview_hide'}>Duration </th> */}
                        <th
                          className={
                            this.props.gmapsState.column.air_pressure === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Air Pressure{" "}
                        </th>
                        <th
                          className={
                            this.props.gmapsState.column.oil_pressure === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Oil Pressure{" "}
                        </th>

                        <th
                          onClick={this.sort_by_numeric.bind(
                            this,
                            "vehicle_battery_potentials"
                          )}
                          className={
                            this.props.gmapsState.column
                              .vehicle_battery_potentials === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Battery Potential{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "fuel_consum")}
                          className={
                            this.props.gmapsState.column.fuel_consum === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Fuel Consumption{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "altitude")}
                          className={
                            this.props.gmapsState.column.altitude === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Altitude{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_alphabet.bind(this, "vin_number")}
                          className={
                            this.props.gmapsState.column.vin_number === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          VIN{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>

                        <th
                          onClick={this.sort_by_alphabet.bind(this, "obu_id")}
                          className={
                            this.props.gmapsState.column.obu_id === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Obu Id{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_alphabet.bind(
                            this,
                            "vehicle_model"
                          )}
                          className={
                            this.props.gmapsState.column.vehicle_model === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Model{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_alphabet.bind(this, "vehicle_type")}
                          className={
                            this.props.gmapsState.column.vehicle_type === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Type{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "coolant_temp")}
                          className={
                            this.props.gmapsState.column.coolant_temp === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Coolant Temperature{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_date.bind(this, "sub_valid_from")}
                          className={
                            this.props.gmapsState.column.sub_valid_from === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Subscription Valid From{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_date.bind(this, "sub_valid_to")}
                          className={
                            this.props.gmapsState.column.sub_valid_to === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Subscription Valid To{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>

                        <th
                          onClick={this.sort_by_numeric.bind(this, "is_bs6")}
                          className={
                            this.props.gmapsState.column.is_bs6 === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          E-Norm{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(
                            this,
                            "catalyst_tank_level"
                          )}
                          className={
                            this.props.gmapsState.column.catalyst_tank_level ===
                              true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          DEF Tank Level{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(
                            this,
                            "adblue_tank_capacity"
                          )}
                          className={
                            this.props.gmapsState.column.adblue_tank_capacity ===
                              true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          DEF Consumption{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_alphabet.bind(this, "driver_name")}
                          className={
                            this.props.gmapsState.column.driver_name === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Driver Name & Contact{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, "engine_hours")}
                          className={
                            this.props.gmapsState.column.engine_hours === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Engine Hours{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th
                          onClick={this.sort_by_numeric.bind(this, 'exhaust_temperature')}
                          className={this.props.gmapsState.column.exhaust_temperature === true ? 'listview_show' : 'listview_hide'}>
                          Exhaust Temp{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}>
                          </i>
                        </th>
                        <th
                          onClick={this.sort_by_alphabet.bind(this, "customer_name")}
                          className={
                            this.props.gmapsState.column.customer_name === true
                              ? "listview_show"
                              : "listview_hide"
                          }
                        >
                          Customer Name & Contact{" "}
                          <i
                            className="fa fa-sort"
                            aria-hidden="true"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        </th>
                        <th className="listview_map_icon"></th>
                      </tr>
                    </thead>

                    {this.props.gmapsState.markers && this.props.gmapsState.markers.length ?
                      <tbody>{this.renderTodos}</tbody>
                      :
                      <h3 className="text-center mt-5">No Data Available</h3>
                    }
                  </table> :
                  this.props.gmapsState.markers && this.props.gmapsState.markers.length ?
                    this.state.refreshItem == true ? <div id="newListView">{this.renderTodos}</div> : '' :
                    <h3 className="text-center">No Data Available</h3>
              )}
            </div>
            <div className="row">
              <div className="pagination-tb">
                <select
                  value={this.props.gmapsState.todosPerPage}
                  onChange={this.handleChangeforpagi.bind(this)}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="pagina-length">
                <table>
                  <tbody>
                    <tr id="page_numbers">
                      <td
                        className="pagesize page_symbol_listview"
                        onClick={this.firstPage.bind(this)}
                      >
                        &laquo;
                      </td>
                      <td
                        className="pagesize page_symbol_listview"
                        onClick={this.previousPage.bind(this)}
                      >
                        &#x2039;
                      </td>
                      {renderPageNumbers}
                      <td
                        className="pagesize page_symbol_listview"
                        onClick={this.nextPage.bind(this)}
                      >
                        &#8250;
                      </td>
                      <td
                        className="pagesize page_symbol_listview"
                        onClick={this.lastPage.bind(this)}
                      >
                        &raquo;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="dealerMapsMain">
            <div>
              {/* {this.props.gmapsState.markers.length ? ( */}
              <MapWithAMarkerClusterer
                selectedMarker={this.props.gmapsState.selectedMarker}
                key={"cl_one"}
                onClick={this.handleClick}
                markers={this.props.gmapsState.markers}
                dealerLat={
                  dropdownValue === "myTerritory"
                    ? this.props.gmapsState.dealerLat
                    : ""
                }
                dealerLong={
                  dropdownValue === "myTerritory"
                    ? this.props.gmapsState.dealerLong
                    : ""
                }
                dealerIcon={"/images/dealerIcon.png"}
                zoomMarkers={this.props.gmapsState.zoomToMarkers}
                vehicledash={this.props.gmapsState.dashenable}
                dialviews={this.props.gmapsState.dialviewshow}
                moreinfovalue={this.redirectPage}
                closedashboardbox={this.closedashboard}
                onchangevalue={() => this.changevaluee(this)}
                poigroups={this.state.group_list}
                poigroupvalue={this.state.groupsval}
                poionchange={(e) => this.groupChange(this, e)}
                poilatlong={this.state.totalarraywithpoi}
                showselectpoi={this.state.showselectedpoires}
                poicheckbox={this.checkboxgroupChange}
                showlabelcheckbox={this.checkboxlabelChange}
                showlabelvals={this.state.showlabelval}
                radius={this.state.circleRadius}
                showCircle={
                  !listview && dropdownValue === "myTerritory" ? true : false
                }
                circleRef={this.CircleRef}
                renderCircle={this.renderCircle}
                ttldatecompare={this.state.ttldatetcompare}
                mapCenter={ dropdownValue === "myTerritory" ? { lat: this.props.gmapsState.dealerLat, lng: this.props.gmapsState.dealerLong } : this.state.mapCenter }
                zoom={dropdownValue === "myTerritory" ? 8 : this.state.zoom}
              >
                <div className="dial-design">
                  <div
                    className={
                      this.props.ctr.userrole !== "AL EMPLOYEE"
                        ? "img1"
                        : "img1 mouserpointer"
                    }
                    data-status="Geofence"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="3,14"
                    onClick={(e) =>
                      this.redirectPage("fleetmanagement/geofence", e)
                    }
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Geofence.png"
                      alt="test"
                      menuclickid="3,14"
                    />
                    <div className="dial-text txt9" menuclickid="3,14">
                      {this.props.gmapsState.howerdialcss === "Geofence" ? (
                        <u className="textunderline" menuclickid="3,14">
                          Geofence
                        </u>
                      ) : (
                        "Geofence"
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      this.props.ctr.userrole !== "AL EMPLOYEE"
                        ? "img2"
                        : "img2 mouserpointer"
                    }
                    onClick={(e) => this.redirectPage("createTrips", e)}
                    data-status="Trip"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="3,13,1"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/create_trip.png"
                      alt="test"
                      menuclickid="3,13,1"
                    />
                    <div className="dial-text txt1" menuclickid="3,13,1">
                      {this.props.gmapsState.howerdialcss === "Trip" ? (
                        <u className="textunderline" menuclickid="3,13,1">
                          Create Trip
                        </u>
                      ) : (
                        "Create Trip"
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      this.props.ctr.userrole !== "AL EMPLOYEE"
                        ? "img3"
                        : "img3 mouserpointer"
                    }
                    onClick={(e) => this.redirectPage("fleetManagement", e)}
                    data-status="update"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="3,10"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Update.png"
                      alt="test"
                      menuclickid="3,10"
                    />
                    <div className="dial-text txt3" menuclickid="3,10">
                      {this.props.gmapsState.howerdialcss === "update" ? (
                        <u className="textunderline" menuclickid="3,10">
                          Update
                        </u>
                      ) : (
                        "Update"
                      )}
                    </div>
                  </div>
                  <div
                    className="img4"
                    onClick={(e) => this.redirectPage("fleetinsightsrep", e)}
                    data-status="Insights"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="12,70,0"
                  >
                    {" "}
                    <img
                      src="images/Map_screen/Dial_icons/Insights.png"
                      alt="test"
                      menuclickid="12,70,0"
                    />
                    <div className="dial-text txt2" menuclickid="12,70,0">
                      {this.props.gmapsState.howerdialcss === "Insights" ? (
                        <u className="textunderline" menuclickid="12,70,0">
                          Insights
                        </u>
                      ) : (
                        "Insights"
                      )}
                    </div>
                  </div>
                  <div
                    className="img5"
                    onClick={(e) =>
                      this.redirectPage("vehicleInfo/findDealer", e)
                    }
                    data-status="Dealer"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="2,27,2"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Dealer.png"
                      alt="test"
                      menuclickid="2,27,2"
                    />
                    <div className="dial-text txt5" menuclickid="2,27,2">
                      {this.props.gmapsState.howerdialcss === "Dealer" ? (
                        <u className="textunderline" menuclickid="2,27,2">
                          Dealer
                        </u>
                      ) : (
                        "Dealer"
                      )}
                    </div>
                  </div>
                  <div
                    className="img6"
                    onClick={(e) =>
                      this.redirectPage("fleetalerts", e)
                    }
                    data-status="Alerts"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="2,27,2"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Alerts.png"
                      alt="test"
                      menuclickid="2,27,2"
                    />
                    <div className="dial-text txt4" menuclickid="2,27,2">
                      {this.props.gmapsState.howerdialcss === "Alerts" ? (
                        <u className="textunderline" menuclickid="2,27,2">
                          Alerts
                        </u>
                      ) : (
                        "Alerts"
                      )}
                    </div>
                  </div>
                  <div
                    className="img7"
                    onClick={(e) => this.redirectPage("vehicleInfo/trend", e)}
                    data-status="Trend"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="2,27,2"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Trends.png"
                      alt="test"
                      menuclickid="2,27,2"
                    />
                    <div className="dial-text txt8" menuclickid="2,27,2">
                      {this.props.gmapsState.howerdialcss === "Trend" ? (
                        <u className="textunderline" menuclickid="2,27,2">
                          Trend
                        </u>
                      ) : (
                        "Trend"
                      )}
                    </div>
                  </div>
                  <div
                    className="img8"
                    onClick={(e) => this.redirectPage("vehicleInfo/trace", e)}
                    data-status="Trace"
                    onMouseEnter={this.dialhighlight}
                    menuclickid="2,27,2"
                  >
                    <img
                      src="images/Map_screen/Dial_icons/Trace.png"
                      alt="test"
                      menuclickid="2,27,2"
                    />
                    <div className="dial-text txt7" menuclickid="2,27,2">
                      {this.props.gmapsState.howerdialcss === "Trace" ? (
                        <u className="textunderline" menuclickid="2,27,2">
                          Trace
                        </u>
                      ) : (
                        "Trace"
                      )}
                    </div>
                  </div>
                  <div className="img9" onClick={() => this.loadvehdash()}>
                    <img
                      src="images/Map_screen/Dial_icons/Ddash_Board.png"
                      alt="test"
                      data-status="DashBoard"
                      onMouseEnter={this.dialhighlight}
                    />
                    <div className="dial-text txt6" menuclickid="2,27,2">
                      {this.props.gmapsState.howerdialcss === "DashBoard" ? (
                        <u className="textunderline" menuclickid="2,27,2">
                          Dashboard
                        </u>
                      ) : (
                        "Dashboard"
                      )}
                    </div>
                  </div>
                </div>
              </MapWithAMarkerClusterer>
              {/* ) : (
                <MapWithAMarkerClusterer
                  key={"cl_two"}
                  selectedMarker={this.props.gmapsState.selectedMarker}
                  onClick={this.handleClick}
                  markers={this.props.gmapsState.markers}
                />
              )} */}
            </div>
          </div>
        )}
        {!this.props.gmapsState.listview &&
          <div className="customPopup_label_dealer">
            <input type="checkbox"  onChange={this.checkboxlabelChange}></input>
            <label className="txt-align-poi">Show Label</label>
          </div>
        }
        <div className="ribbon-box">
          {!this.props.gmapsState.listview ? (
            <select
              className={styles.slctdrpdwn}
              value={this.props.gmapsState.dropdownValue}
              onChange={this.handleDropdownChange}
            // style={{ display: listview === true ? "none" : "block" }}
            >
              <option value="mySoldVehicles">My Sold Vehicles</option>
              <option value="myTerritory">My Territory</option>
            </select>
          ) : null}
          <div className="row">
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Total"
                  ? " rib-sts-tot ribbon-count active_footer"
                  : "rib-sts-tot ribbon-count"
              }
              value="Running"
              onClick={() => {
                this.clickstatus("Total");
              }}
            >
              {" "}
              <div>
                <img src="images/Map_screen/Truck_Fleet.png" alt="Total" />{" "}
              </div>
              <div className="veh_status_all">Total</div>
              <div className="veh_status_cnt_all">
                {this.props.gmapsState.vehCount.Total != undefined
                  ? this.props.gmapsState.vehCount.Total
                  : 0}{" "}
              </div>{" "}
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "RUNNING"
                  ? "rib-sts-all ribbon-count active_footer"
                  : "rib-sts-all ribbon-count"
              }
              onClick={() => {
                this.clickstatus("RUNNING");
              }}
            >
              <img src="images/Map_screen/Running.png" alt="Running" />{" "}
              <div className="veh_status_run">Running</div>{" "}
              <div className="veh_status_cnt_run">
                {this.props.gmapsState.vehCount.Running != undefined
                  ? this.props.gmapsState.vehCount.Running
                  : 0}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "IDLING"
                  ? "rib-sts-idl ribbon-count active_footer"
                  : "rib-sts-idl ribbon-count"
              }
              onClick={() => {
                this.clickstatus("IDLING");
              }}
            >
              <img src="images/Map_screen/Idling.png" alt="vehicles" />
              <div className="veh_status_idl">Idling </div>
              <div className="veh_status_cnt_idl">
                {" "}
                {this.props.gmapsState.vehCount.Idling != undefined
                  ? this.props.gmapsState.vehCount.Idling
                  : 0}
              </div>{" "}
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "STOPPED"
                  ? "rib-sts-all ribbon-count active_footer"
                  : "rib-sts-all ribbon-count"
              }
              onClick={() => {
                this.clickstatus("STOPPED");
              }}
            >
              <img src="images/Map_screen/Stopped.png" alt="vehicles" />
              <div className="veh_status_stp">Stopped </div>
              <div className="veh_status_cnt_stp">
                {this.props.gmapsState.vehCount.Stopped != undefined
                  ? this.props.gmapsState.vehCount.Stopped
                  : 0}
              </div>{" "}
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "DISCONNECTED"
                  ? "rib-sts-discont ribbon-count active_footer"
                  : "rib-sts-discont ribbon-count"
              }
              onClick={() => {
                this.clickstatus("DISCONNECTED");
              }}
            >
              <img src="images/Map_screen/Disconnected.png" alt="vehicles" />
              <div className="veh_status_dst"> Disconnected </div>
              <div className="veh_status_cnt_dst">
                {this.props.gmapsState.vehCount.Disconnected != undefined
                  ? this.props.gmapsState.vehCount.Disconnected
                  : 0}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "NoGPS"
                  ? "rib-sts-twd ribbon-count active_footer"
                  : "rib-sts-twd ribbon-count"
              }
              onClick={() => {
                this.clickstatus("NoGPS");
              }}
            >
              <img src="images/Map_screen/No_GPS.png" alt="vehicles" />
              <div className="veh_status_gps">No gps </div>
              <div className="veh_status_cnt_gps">
                {this.props.gmapsState.vehCount.NoGPS != undefined
                  ? this.props.gmapsState.vehCount.NoGPS
                  : 0}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "TOWED"
                  ? "rib-sts-twd ribbon-count  active_footer"
                  : "rib-sts-twd ribbon-count"
              }
              onClick={() => {
                this.clickstatus("TOWED");
              }}
            >
              <img src="images/Map_screen/Towed.png" alt="vehicles" />
              <div className="veh_status_twd">Towed</div>{" "}
              <div className="veh_status_cnt_twd">
                {this.props.gmapsState.vehCount.Towed != undefined
                  ? this.props.gmapsState.vehCount.Towed
                  : 0}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Critical"
                  ? "rib-sts-crt ribbon-count active_footer"
                  : "rib-sts-crt ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Critical");
              }}
            >
              <img src="images/Map_screen/Critical.png" alt="vehicles" />
              <div className="veh_status_crtl">Critical</div>{" "}
              <div className="veh_status_cnt_crtl">
                {this.props.gmapsState.vehCount ? (
                  this.props.gmapsState.vehCount.Critical ? (
                    this.props.gmapsState.vehCount.Critical
                  ) : this.props.gmapsState.vehCount.Critical === 0 ? (
                    0
                  ) : (
                    <img
                      className="map-loadergif"
                      src="images/circle_loader_blue.gif"
                      alt="iAlert"
                    />
                  )
                ) : this.props.gmapsState.vehCount.Critical === 0 ? (
                  0
                ) : (
                  <img
                    className="map-loadergif"
                    src="images/circle_loader_blue.gif"
                    alt="iAlert"
                  />
                )}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Warning"
                  ? "rib-sts-wrt ribbon-count active_footer"
                  : "rib-sts-wrt ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Warning");
              }}
            >
              <img src="images/Map_screen/Warning.png" alt="vehicles" />
              <div className="veh_status_wrt">Warning</div>{" "}
              <div className="veh_status_cnt_wrt">
                {this.props.gmapsState.vehCount ? (
                  this.props.gmapsState.vehCount.Warning ? (
                    this.props.gmapsState.vehCount.Warning
                  ) : this.props.gmapsState.vehCount.Warning === 0 ? (
                    0
                  ) : (
                    <img
                      className="map-loadergif"
                      src="images/circle_loader_blue.gif"
                      alt="iAlert"
                    />
                  )
                ) : this.props.gmapsState.vehCount.Warning === 0 ? (
                  0
                ) : (
                  <img
                    className="map-loadergif"
                    src="images/circle_loader_blue.gif"
                    alt="iAlert"
                  />
                )}
              </div>
            </div>
            <div
              className={
                this.props.gmapsState.Vehiclestatushigh === "Service"
                  ? "rib-sts-serv ribbon-count active_footer"
                  : "rib-sts-serv ribbon-count"
              }
              onClick={() => {
                this.clickstatus("Service");
              }}
            >
              <img src="images/Map_screen/service.png" alt="vehicles" />
              <div className="veh_status_serv"> Service Due</div>{" "}
              <div className="veh_status_cnt_serv">
                {this.props.gmapsState.vehCount ? (
                  this.props.gmapsState.vehCount.Service ? (
                    this.props.gmapsState.vehCount.Service
                  ) : this.props.gmapsState.vehCount.Service === 0 ? (
                    0
                  ) : (
                    <img
                      className="map-loadergif"
                      src="images/circle_loader_blue.gif"
                      alt="iAlert"
                    />
                  )
                ) : this.props.gmapsState.vehCount.Service === 0 ? (
                  0
                ) : (
                  <img
                    className="map-loadergif"
                    src="images/circle_loader_blue.gif"
                    alt="iAlert"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div onClick={e => {this.resettheresult();}}>test</div>  */}
        </div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    ctr: state.userdata.id,
    searchresult: state.menus.globalSearch,
    gmapsState: state.GmapsReducer,
    togglevalues: state.menus.refresh,
    togglerefresh: state.menus.onlyrefresh,
    setlistview: state.menus.menulist,
    sud: state.menus.searchupdatedata,
    listviewshows: state.menus.listviewshown,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    quicklinks: (data) => dispatch({ type: "QUICKLINK", value: data }),
    ctrs: () => dispatch({ type: "VehicleDatas", value: uservehicledate }),
    gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
    onlyrefresh: (data) => dispatch({ type: "ONLYREFRESH", value: data }),
    setlistviewval: (data) => dispatch({ type: "MENULIST", value: data }),
    searchresultbox: (data) => dispatch({ type: "SEARCHBOXCNT", value: data }),
    listviewshown: () =>
      dispatch({ type: "listviewshow", value: shownlistview }),
    searchupdatedata: (data) =>
      dispatch({ type: "searchupdatedata", value: data }),
  };
};

export { uservehicledate };
export default withNavigate(connect(mapStateToprops, mapDispatchToprops)(
  DealerMaps,
  MapWithAMarkerClusterer
));
