// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderline_tnd {
  border-bottom: 1px solid #d6d3d3;
}

.datacomponent {
  margin-left: 2%;
}

#chartdiv {
  width: 100%;
  height: 400px;
}

#my-id {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TrendCompare/TrendCompare.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEE;EACE,WAAA;EACA,aAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":[".borderline_tnd{\r\n    border-bottom: 1px solid #d6d3d3;\r\n}\r\n\r\n.datacomponent{\r\n    margin-left: 2%;\r\n  }\r\n\r\n  #chartdiv {\r\n    width: 100%;\r\n    height: 400px;\r\n  }\r\n\r\n  #my-id{\r\n    color: gray;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
