// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicle-search {
  width: 100%;
  margin-top: -5px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Tracking/VehicleSearchBar.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".vehicle-search {\r\n    width: 100%;\r\n    margin-top: -5px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
