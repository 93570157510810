// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3px;
  border: solid 1px #ccc;
  max-height: 45vh;
  height: 45vh;
  overflow-y: scroll;
  margin: 10px;
}

.list-item {
  padding-left: 5px;
  text-align: left;
}

.check-css {
  font-size: 14px;
  display: flex;
  flex: 10px 1;
  flex-direction: row;
}

.text-css {
  font-size: 16px;
  color: #000;
  margin-left: 15px;
  text-align: left;
}

.search-item {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.no-data-avil {
  font-size: 20px;
  color: gray;
  margin-top: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Tracking/VehicleSearchResult.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,qCAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AACA;EACI,iBAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,eAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;AAGJ;;AADA;EACI,eAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AAIJ;;AAFA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;AAKJ;;AAHA;EACI,eAAA;EACA,WAAA;EACA,gBAAA;AAMJ","sourcesContent":[".list-container {\r\n    width: 100%;\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr);\r\n    grid-gap: 3px;\r\n    border: solid 1px #ccc;\r\n    max-height: 45vh;\r\n    height: 45vh;\r\n    overflow-y: scroll;\r\n    margin: 10px;\r\n}\r\n.list-item {\r\n    padding-left: 5px;\r\n    text-align: left;\r\n}\r\n.check-css {\r\n    font-size: 14px;\r\n    display: flex;\r\n    flex: 10px 1;\r\n    flex-direction: row;\r\n}\r\n.text-css {\r\n    font-size: 16px;\r\n    color: #000;\r\n    margin-left: 15px;\r\n    text-align: left;\r\n}\r\n.search-item {\r\n    display: flex;\r\n    flex: 1;\r\n    flex-direction: column;\r\n}\r\n.no-data-avil{\r\n    font-size: 20px;\r\n    color: gray;\r\n    margin-top: 3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
