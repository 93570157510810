// withNavigate.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const withNavigate = WrappedComponent => props => {
  const navigate = useNavigate();
  const location = useLocation();
  return <WrappedComponent {...props} navigate={navigate} location={location} />;
};

export default withNavigate;
