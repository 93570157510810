export const showGearUI = (isBS6) => {
    // show gear BS6. BS6 CNG
    return isBS6 === 1 || isBS6 === 2;
}
export const showAirPressure = (marker) => {
    // show air pressure BS6. BS6 CNG, EV, BS4 EEA
    return marker.isBS6 === 1 || marker.isBS6 === 2 || marker.isBS6 === 4 || (marker.isBS6 === 0 && (marker.vehicleType === "EEA"));
}

export const showOilPressure = (marker) => {
    return  (marker.isBS6 !== 3 && marker.isBS6 !== 4) && (marker.oilPressure === true && (marker.isBS6 === 1 || marker.isBS6 === 2 || (marker.isBS6 === 0 && marker.vehicleType === "IL" || marker.vehicleType === "EDC")));
}

export const showExhaust = (isBS6) => {
    return isBS6 === 1 || isBS6 === 3;
}

export const exhaustCalculation = (currentInfo) => {
    return (currentInfo.status === 'RUNNING' || currentInfo.status === 'IDLING') ? Math.round(currentInfo.aft1_exhaust_temp_1) < 0 ? 0 : Math.round(currentInfo.aft1_exhaust_temp_1) : '-';
}
export const exhaustCalculationDashboard = (currentInfo) => {
    return (currentInfo.status === 'RUNNING' || currentInfo.status === 'IDLING') ? Math.round(currentInfo.aft1_exhaust_temp_1) < 0 ? 0 : Math.round(currentInfo.aft1_exhaust_temp_1) : '0';
}
export const showDEF = (marker) =>{
    return (marker?.adBlueTankCap && !isNaN(marker?.adBlueTankCap) &&parseInt(marker?.adBlueTankCap) > 0 && marker?.adBlueTankCalcValue !== null) && marker?.isBS6 !== 4;
}
// to get fuel text class name based on E-norms in vehicle dashboard
export const  getFuelTextClass = (isBS6) => {
    if (isBS6 === 1 || isBS6 === 3) {
        return "fuel_text_bs6";
    } else if (isBS6 === 2) {
        return "fuel_text_bs6_cng";
    } else {
        return "fuel_text";
    }
}

// to get fuel text class name based on E-norms in vehicleinfo dashboard
export const vehicleDashboardFuelTextClass = (isBS6) => {
    if (isBS6 === 1) {
        return "fuel_text_bs6_ST";
    } else if (isBS6 === 2) {
        return "fuel_text_bs6_ST_cng";
    } else {
        return "fuel_text_ST";
    }
}

export const getFuelLabel = (isBS6) => {
    if (isBS6 === 4) {
        return "SOC";
    } else if (isBS6 === 2) {
        return "GAS";
    } else {
        return "FUEL";
    }
}
export const vehicleTypes = {
    1: 'BSVI',
    2: 'BSVI CNG',
    3: 'LCV',
    4: 'EV',
    0: 'BSIV'
  };

  export const oilPressureCalculation = (isBS6, type, oilPressure, engineOilDig, engine_oil_act,ignitionStatus) => {
    if (isBS6 === 3 || isBS6 === 4) {
      return "N/A";
    }

    // Check ignition status first
    if (ignitionStatus !== "RUNNING" && ignitionStatus !== "IDLING") {
      return "-";
    }

    // Combined oil pressure check (reduces redundancy)
    const oilPressureCheck = oilPressure && engine_oil_act;

    // Logic based on isBS6 with early return
    if ((isBS6 === 1 || isBS6 === 2) || (isBS6 === 0 && type === "EEA")) {
      return oilPressureCheck ? (parseInt(engine_oil_act) < 0.5 ? "BAD" : "GOOD") : "-";
    } else if (isBS6 === 0 && type === "EDC" || type === "IL") {
        return oilPressureCheck ? (engineOilDig === 0 ? "BAD" : "GOOD") : "-";
    } else {
        return "-";
    }
  }

  export const fluidValueCalculation = (adBlueTankCap, aft1_total_diesel_exhaust_fluid_used) => {
    if (adBlueTankCap && !isNaN(adBlueTankCap) && adBlueTankCap > 0) {
      return !isNaN(aft1_total_diesel_exhaust_fluid_used) ?  parseFloat(aft1_total_diesel_exhaust_fluid_used).toFixed(1): '';
    } else {
      return 'N/A'
    }
  }

  export const exhaustTempCalculation = (ignitionStatus,val,isBS6) => {
    if(isBS6 === 1 || isBS6 === 3){
      if((ignitionStatus ==="RUNNING" || ignitionStatus === "IDLING") && val){
        return Math.round(val)
      }
      else{
        return '-';
      }
   }else{
       return 'N/A'
   }
}
export const airPressureCalculation = (isBS6, type, actual1, air_pressure_digital, ignitionStatus) => {
    if (isBS6 === 3){
      return "N/A"
    }
    if (ignitionStatus === "RUNNING" || ignitionStatus === "IDLING") {
      if (isBS6 === 1 || isBS6 === 2) {
        if (actual1) {
          // return todo.currentInfo.airpressure_actual1;
          if (actual1 < 4.5) {
            return "BAD";
          } else if (actual1 >= 4.5) {
            return "GOOD";
          }
        } else {
          return '-'
        }
      } else {
        if (type === 'EEA' || isBS6 === 4) {
          if (actual1) {
            if (actual1 < 4.5) {
              return 'BAD';
            } else if (actual1 >= 4.5) {
              return 'GOOD';
            }
          } else {
            return '-';
          }
        } else if (type === 'EDC') {
            return '-';
        } else if (type === 'IL') {
          return '-';
        }  
      }
    } else {
      return "-";
    }
  }

  export const getStatusOnConditon = (ignitionStatus, val) => {
    if ((ignitionStatus === "RUNNING" || ignitionStatus === "IDLING") && val) {
      return Math.round(val)
    }
    else {
      return '-';
    }
  }

export const getStatusAndSeverity = (severity, status) => {
    if (severity) {
        return status + ' ' + severity;
    } else {
        return status;
    }
}
export const getGASVehicleUnit = (vehicleType) => {
  if (vehicleType !== "" && vehicleType?.includes("LNG")) {
    return " kg";
  }else{
    return " bar"
  }
}