import React, { useMemo } from "react";
import {
  StreetViewPanorama,
  GoogleMap,
  Marker,
  useLoadScript
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../apiList";


// const MapBox = compose(
//     withProps({
//       googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=places,geometry,visualization,drawing&key="+GOOGLE_MAP_API_KEY+"&v=3.exp&sensor=false",
//       loadingElement: <div style={{ height: `100%` }} />,
//       containerElement: <div style={{ height: `70vh` }} />,
//       mapElement: <div style={{ height: `100%` }} />,
//     }),
    
//     lifecycle({
//       componentDidMount() {
//         // this.interval =  setInterval(() =>  countdown() ,10000)
//       },
//     })
//   )(props =>
   
//     <GoogleMap  
//       zoom={props.zoom}
//       center={props.center}
//       ref={ props.zoomToMarkers }  
//     >
    
//     <StreetViewPanorama position={props.center} visible={props.visible}/>
//         {
//             props.traceData ?
//             props.traceData.map((mark, index) => {
//                var MarkerOptions = {
//                     // path: window.google.maps.SymbolPath.CIRCLE,
//                     fillOpacity: 3,
//                     scale: 3,
//                     strokeColor: '#009900', // ,#006622 dots
//                     strokeWeight: 3
//                 };
//                 var title = "Reg No : " +  props.traceData[0].regn_number + "\n" +
//                 "Location : " +  props.traceData[0].location + "\n" +
//                 "TimeStamp : " +  props.traceData[0].fleetTime;
//                 return <Marker  icon={MarkerOptions} key={index} position={{ lat: props.traceData[0].lat, lng: props.traceData[0].lng }} title={title} >
//                 </Marker>
//             }) : ''
//         }
  
//       {props.showMarker && <Marker position={props.markerPosition} />}
//     </GoogleMap>
//   );

const mapContainerStyle = {
  width: '100%',
  height: '70vh'
};

const MapBox = ({
  zoom,
  center,
  zoomToMarkers,
  traceData,
  showMarker,
  markerPosition,
  visible,
}) => {
   // Load the Google Maps API
   const libraries = useMemo(() => ["places", "geometry", "visualization", "drawing"], []);
   const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY, // Pass your API key here
    libraries: libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap zoom={zoom} center={center} onLoad={zoomToMarkers} mapContainerStyle={mapContainerStyle}>
      {/* StreetView */}
      <StreetViewPanorama position={center} visible={visible} />

      {/* Trace Data Markers */}
      {traceData &&
        traceData.map((mark, index) => {
          const markerOptions = {
            fillOpacity: 3,
            scale: 3,
            strokeColor: "#009900",
            strokeWeight: 3,
          };
          const title =
            `Reg No : ${mark.regn_number}\n` +
            `Location : ${mark.location}\n` +
            `TimeStamp : ${mark.fleetTime}`;

          return (
            <Marker
              key={index}
              icon={markerOptions}
              position={{ lat: mark.lat, lng: mark.lng }}
              title={title}
            />
          );
        })}

      {/* Marker Position */}
      {showMarker && <Marker position={markerPosition} />}
    </GoogleMap>
  );
};
  

class FleetMap extends React.Component{

    constructor(props){
      super(props);
    }

  
    render(){
      return <div>
            <MapBox zoom={this.props.zoom}  visible={this.props.visible}   mapTypeControl={true}
            fullscreenControl={false}
            streetViewControl={false}
            center={this.props.center}  markerPosition={this.props.markerPosition} 
            height={this.props.height}showMarker={this.props.showMarker}
            traceData={this.props.traceData}  zoomToMarkers={ this.props.zoomToMarkers }
            >
            </MapBox>
        </div>
              
   }
  }
  
  export default FleetMap;