import React from "react";
import "./Dashboard.scss";
import { GmapsDispatchThunk } from "../../store/actions/GmapsActions";
import NavLink from "../../Components/Link/Link";
import withNavigate from '../../hooks/withNavigate';
import { Link } from "react-router-dom";
import { getLocalStorage, postData, setLocalStorage } from "../../Util";
import * as apilist from "../../apiList";
import { connect } from "react-redux";
import fleetAlerts from "../FleetAlerts/fleetAlerts";
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import CustomModal from '../Modal/Modal';
import DatePicker from "react-datepicker";
import moment from 'moment';
import SelectInput from './SelectInput'
import FleetInsightDataWarning from "../Warning/FleetInsightDataWarning";
import FleetAlertBox from "./FleetAlertBox";
import { lazy, Suspense } from 'react';
import { Chatbot } from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from '../Chatbot/config';
import ActionProvider from '../Chatbot/ActionProvider';
import MessageParser from '../Chatbot/MessageParser';
// import ReleaseNotesMui from "./ReleaseNotesMui";
import ChatBotMapComponent from "../Chatbot/Map";
import { mapInfoChange } from "../../store/actions/action";
import { getAlertCountDate } from "../../Util/DateUtils";
import { MAPPED_SCREEN_DATA, MONTHLY_REPORT_DATA, RELEASE_NOTES } from "../../store/constants";
const ReleaseNotesMui = lazy(() => import("./ReleaseNotesMui"));

import { useLocation } from 'react-router-dom';
class dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.center = { lat: 20.5937, lng: 78.9629 };
    this.zoom = 4;
    this.baseArray = [
      "Total",
      "Running",
      "Idling",
      "Stopped",
      "Disconnected"
    ];

    // Conditionally add "Charging" based on `isEVVehicelAvailable`
    this.initialDashboardArray = this.props.isEVVehicelAvailable
      ? [...this.baseArray.slice(0, -1), "Charging", this.baseArray.slice(-1)[0]]
      : this.baseArray;

    this.initialDashboardLength = this.initialDashboardArray.length;
    this.isBackButtonClicked = false;
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
    this.hoverInterval = "";
    this.gif_path = "";
    this.gifImage = React.createRef();
    this.gifname = "Total";
    this.props.menuData(true);
    this.handleMapInfoChange = this.handleMapInfoChange.bind(this);
    this.state = {
      searchData: [],
      vehalertCount: [],
      feedback_select: '',
      selectedVin: '',
      feedback_comment: '',
      call_back: '',
      api_call: false,
      feedbackOptions: [],
      notInterstedOption: [],
      feedback_label: '',
      remind_me: '',
      callback_time: '',
      dialog: false,
      showMap: false,
      mapInformation: [],
      notification_content: [],
      dashboardArray: this.initialDashboardArray,
      dashboardLength: this.initialDashboardLength,
      hasAnySelectChanged: false,
      selectedMob: [],
      selectedTime: [],
      selectedComment: [],
      selectedRemind: [],
      dueVinlist:[],
      selectedIndices:0
      // ratingValue: "",
      // rating_comment: "",
    }
  }

  closeWarning = () => {
    this.props.gmapsDispatch({ showFleetWarningModal: false });
  };

  onBackButtonEvent = (e) => {
    // Browser back icon restriction
    //window.history.forward()
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      window.history.pushState(null, null, window.location.pathname);
      this.isBackButtonClicked = false;
    }
  };
  changeGif = () => {
    this.hoverInterval = setInterval(() => {
      const currentHoverIndex = this.state.dashboardArray.indexOf(this.props.gmapsState.howercss);
      const nextHoverIndex = (currentHoverIndex + 1) % this.state.dashboardLength;
      const nextHoverCss = this.state.dashboardArray[nextHoverIndex];

      this.props.gmapsDispatch({ howercss: nextHoverCss }).then(() => {
        if (this.gifImage.current != null) {
          this.gifImage.current.src = "/images/Dashboadimgs/GIF-" + nextHoverCss + ".gif";
        }
      });
    }, 3500);
  };

  handleHover = (e) => {
    clearInterval(this.hoverInterval);
    let status = e.currentTarget.dataset.status;
    this.props.gmapsDispatch({ howercss: status });
    this.gifImage.current.src = "/images/Dashboadimgs/GIF-" + status + ".gif";
  };

  handleMouseLeave = () => {
    this.changeGif();
  };

  handleAlUserPopupClick = () => {
    if (this.props.ctr.userrole === "AL EMPLOYEE" || this.props.ctr.userrole === "AL SME") {
      this.props.gmapsDispatch({ showWelcomeModal: true });
    }
  };

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.togglerefresh !== undefined) {
      if (nextProps.togglerefresh !== "NotRefresh") {
        clearInterval(this.hoverInterval);
        this.props.onlyrefresh("NotRefresh");
        this.getalertcounts();
      }
    }

    if (this.props.searchresult && this.props.searchresult.data && this.props.searchresult.data.length !== 0) {
      let searchData = [];
      if (nextProps.searchresult &&
        nextProps.searchresult.data &&
        JSON.stringify(nextProps.searchresult.data) !== JSON.stringify(nextState.searchData)
      ) {
        nextProps.searchresult.data.map((value) => {
          searchData.push(value);
        })
        this.setState({
          searchData: searchData
        }, () => {
          if (JSON.stringify(nextProps.gmapsState.vehalertCount) !== JSON.stringify(nextState.vehalertCount)) {
            this.setState({
              vehalertCount: nextProps.gmapsState.vehalertCount
            })
          }
        });
      }

      return true
    }
    return true
  }

  settimeoutfns = () => {
    this.settimerscnt = 0;
    this.settimerscnt = setInterval(() => {
      if (this.props.togglevalues === true) {
        clearInterval(this.hoverInterval);
        // this.getvehiclecount();
        this.getalertcounts();
      }
    }, 30000);
  };
  getvehiclecountAndAletCount = async (searchobulist, vinsearchlist) => {
    this.getvehiclecountsearch(searchobulist)
    this.getalertcountsearch(vinsearchlist)
    // await Promise.all([
    //   this.getvehiclecountsearch(searchobulist),
    //   this.getalertcountsearch(vinsearchlist)
    // ]);
  }
  getvehiclecountsearch = async (obulistval) => {
    try {
      const dataObj = {
        userId: this.props.ctr.userid,
        platform: "w",
        obuList: obulistval
      };
      const vehtoken = this.props.ctr.token;

      const datas = await postData(apilist.vehiclecount, dataObj, vehtoken);

      if (datas && datas.data) {
        this.props.gmapsDispatch({ vehCount: datas.data });

        if (this.props.ctr.userrole === "AL EMPLOYEE" && datas.data === "Error logged") {
          this.props.gmapsDispatch({ showWelcomeModal: true });
        }
      }
    } catch (error) {
      console.error("Error in getvehiclecountsearch:", error);
      // Handle error if necessary
    }
  };

  // getvehiclecount = () => {
  //   let searchobulist = this.getFilteredObuIdList();
  //   let dataObj = {};
  //   dataObj.userId = this.props.ctr.userid;
  //   let vehtoken = this.props.ctr.token;
  //   dataObj.platform = "w";
  //   dataObj.obuList = searchobulist;
  //   const datas = postData(apilist.vehiclecount, dataObj, vehtoken);
  //   datas.then((dataVals) => {
  //     this.props.gmapsDispatch({
  //       vehCount: dataVals.data,
  //     });
  //     // console.log(dataVals);
  //     if (
  //       this.props.ctr.userrole === "AL EMPLOYEE" &&
  //       dataVals.data === "Error logged"
  //     ) {
  //       this.props.gmapsDispatch({ showWelcomeModal: true });
  //     }
  //   });
  // };
  getvehiclecount = async () => {
    try {
      const searchobulist = this.getFilteredObuIdList();
      const dataObj = {
        userId: this.props.ctr.userid,
        platform: "w",
        obuList: searchobulist
      };
      const vehtoken = this.props.ctr.token;

      const datas = await postData(apilist.vehiclecount, dataObj, vehtoken);

      if (datas && datas.data) {
        this.props.gmapsDispatch({ vehCount: datas.data });

        if (this.props.ctr.userrole === "AL EMPLOYEE" && datas.data === "Error logged") {
          this.props.gmapsDispatch({ showWelcomeModal: true });
        }
      }
    } catch (error) {
      console.error("Error in getvehiclecount:", error);
      // Handle error if necessary
    }
  };
  handleAlertCounts = async (vinList, userId, userRole, token) => {
    try {
      const { previousDate, currentDate } = getAlertCountDate();
      const dataObj = {
        userId,
        platform: "w",
        fromDate: previousDate,
        toDate: currentDate,
        vinList,
        isActive: true
      };
      const url = userRole === "DEALER OUTLET ADMIN" ? apilist.alertCountdealer : apilist.alertCount;
      return await postData(url, dataObj, token);
    } catch (error) {
      console.error("Error:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };
  getalertcountsearch = async (vinlists) => {
    try {
      this.changeGif();
      const response = await this.handleAlertCounts(vinlists, this.props.ctr.userid, this.props.ctr.userrole, this.props.ctr.token);
      if (response && response.data) {
        this.props.gmapsDispatch({
          vehalertCount: response.data,
          tempAlertCount: response.data
        });
      }
    } catch (error) {
      console.error("Error in getalertcountsearch:", error);
    }
  };
  getalertcounts = async () => {
    try {
      this.getvehiclecount();
      this.changeGif();
      const vinsearchlist = this.getFilteredVins();
      const response = await this.handleAlertCounts(vinsearchlist, this.props.ctr.userid, this.props.ctr.userrole, this.props.ctr.token);
      if (response && response.data) {
        this.props.gmapsDispatch({
          vehalertCount: response.data,
          tempAlertCount: response.data
        });
        this.setState({ call_back: this.props.ctr.mobile });
      }
    } catch (error) {
      console.error("Error in getalertcounts:", error);
    }
  };
  // getalertcounts = () => {
  //   let vinsearchlist = this.getFilteredVins();
  //   this.getvehiclecount();

  //   this.changeGif();
  //   const { previousDate, currentDate } = getAlertCountDate();

  //   let dataobj_alert = {};
  //   dataobj_alert.userId = this.props.ctr.userid;
  //   let tokens = this.props.ctr.token;
  //   dataobj_alert.platform = "w";
  //   dataobj_alert.fromDate = previousDate;
  //   dataobj_alert.toDate = currentDate;
  //   dataobj_alert.vinList = vinsearchlist;
  //   dataobj_alert.isActive = true;
  //   let url;
  //   if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
  //     url = apilist.alertCountdealer;
  //   } else {
  //     url = apilist.alertCount;
  //   }
  //   const alertcnt = postData(url, dataobj_alert, tokens);
  //   alertcnt.then((alertdataVals) => {
  //     this.props.gmapsDispatch({
  //       vehalertCount: alertdataVals.data,
  //       tempAlertCount: alertdataVals.data
  //     });
  //     this.setState({ call_back: this.props.ctr.mobile});
  //   });
  // };

  getFilteredObuIdList () {
    return this.props.searchresult?.data?.map(({ obu_id }) => obu_id) || [];
  }

  getFilteredVins () {
    return this.props.searchresult?.data?.map(({ vin_numbers }) => vin_numbers) || [];
  }

  componentDidMount () {
    localStorage.setItem('selectedVin', [])
    //this.callinpiufunbction();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", this.onBackButtonEvent);
    this.props.searchresultbox(this.props.location);
    this.props.gmapsDispatch({ refreshselecteddata: 'Total' });
    if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
      this.checkmonthlyreport();
    }

    if (this.props.searchresult !== null) {
      if (this.props.searchresult.data.length === 0) {
        this.settimeoutfns();
        // this.getalertcounts();
      } else {
        let searchobulist = [];
        let vinsearchlist = [];
        for (var i = 0; i < this.props.searchresult.data.length; i++) {
          searchobulist.push(this.props.searchresult.data[i].obu_id);
          vinsearchlist.push(this.props.searchresult.data[i].vin_numbers);
        }
        this.getvehiclecountAndAletCount(searchobulist, vinsearchlist);
        // To refresh ui with filtered data
        this.settimeoutfns();
        // this.getalertcounts();
      }
    } else {
      this.settimeoutfns();
      // this.getalertcounts();
    }
    // this.setsubscritionsdetails();
    let tokens = this.props.ctr.token;
    this.getReleaseNotes(tokens)
    this.getReleaseNotesAndNotificationCount(tokens);
    // const tab_dashboads = postData(apilist.tab_masters, null, tokens);
    // tab_dashboads.then((alertdataVals) => {
    //   // console.log(alertdataVals);
    // });
  }

  getReleaseNotes = async (tokens) => {
    try {
      if (this.props.releaseNotes) {
        this.setState({
          notification_content: this.props.releaseNotes
        });
      } else {
        const response = await postData(apilist.ReleaseNotes, {}, tokens);

        if (!response) {
          console.log("No response received from server");
        }

        const { statuscode, data } = response;
        this.props.setReleaseNotes(statuscode === 200 ? data : []);
        this.setState(prevState => ({
          ...prevState,
          notification_content: statuscode === 200 ? data : []
        }));
      }
    } catch (error) {
      console.log("Error in getReleaseNotes:", error.message);
      // Handle error if necessary
    }
  }
  getFeedbackList = async () => {
    const tokens = this.props.ctr.token;
    try {
      const feedbackList = await postData(apilist.getFeedbackList, null, tokens);
      this.setState({ feedbackOptions: feedbackList?.data?.feedback, notInterstedOption: feedbackList?.data?.not_interested, dueVinlist: feedbackList?.data?.user_vin });
    } catch (error) {
      console.error(error);
    }
  }

  callinpiufunbction = () => {
    var iframe, iframe_google;
    iframe = document.createElement("iframe");
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";

    iframe.onload = function () {
      var script = iframe.contentWindow.document.createElement("script");

      var input = iframe.contentWindow.document.createElement("input");
      input.type = "text";
      input.id = "searchByLocationBoxes";
      input.className = "location_search_box";
      iframe.contentWindow.document
        .getElementsByTagName("body")[0]
        .appendChild(input);

      iframe.contentWindow.insertPlacesApi = function () {
        //google.maps.places = this.google.maps.places;
        iframe_google = this.google;
        var searchLocationBox = document.getElementById(
          "searchByLocationBoxes"
        );
        var autocomplete = new iframe_google.maps.places.Autocomplete(
          searchLocationBox
        );
      };

      script.type = "text/javascript";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=" + apilist.GOOGLE_API_KEY + "&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi";

      iframe.contentDocument
        .getElementsByTagName("head")[0]
        .appendChild(script);
    };
    document.body.append(iframe);
  };
  showMonthlyReport = (monthlyReportData) => {
    if (!monthlyReportData || !monthlyReportData.data) {
      // Handle empty or invalid response
      return;
    }

    const listofdatas = monthlyReportData.data;
    const showurlslist = listofdatas.filter(todo => todo.url_path !== "");

    this.setState({ entiredata: listofdatas });

    if (showurlslist.length === 0) {
      this.props.gmapsDispatch({ monthlyshows: false });
    } else {
      this.props.gmapsDispatch({ monthlyshows: true });
    }
  }
  checkmonthlyreport = async () => {
    try {
      if (this.props.monthReportAPIData) {
        this.showMonthlyReport(this.props.monthReportAPIData);
      } else {
        this.setState({ loader: true });
        let tokens = this.props.ctr.token;
        const datas = await postData(apilist.getmonthlyapi, null, tokens);
        this.setState({ loader: false });
        if (!datas) {
          console.log("No response received from server");
        } else if (datas.statuscode === 200) {
          this.props.setMonthlyReportData(datas);
          this.showMonthlyReport(datas);
        }
      }
    } catch (error) {
      console.log("Error in checkmonthlyreport:", error.message);
      // Handle error if necessary
      this.setState({ loader: false });
    }
  };
  showFuelFillScreen = (tab_dashboadsscreen) => {
    if (!tab_dashboadsscreen || !tab_dashboadsscreen.data) {
      // Handle empty or invalid response
      return;
    }

    const listofdatas = tab_dashboadsscreen.data;
    const showFuelFill = listofdatas.find(screen => screen.screenname === "Fuel Pilferage");

    if (showFuelFill) {
      this.props.gmapsDispatch({ fuelfillapi: true });
    }
  }
  getMappedScreens = async (tokens) => {
    try {
      if (this.props.mappedScreenAPIData) {
        this.showFuelFillScreen(this.props.mappedScreenAPIData);
      } else {
        const dataobj_screens = { userId: this.props.ctr.userid };
        const tab_dashboadsscreen = await postData(apilist.tab_screen, dataobj_screens, tokens);

        if (tab_dashboadsscreen.statuscode === 401) {
          this.props.navigate("/");
          return; // Exit function early
        }
        // Check if "Fuel Pilferage" screen is present in the data
        this.showFuelFillScreen(tab_dashboadsscreen);
        this.props.setMappedScreenData(tab_dashboadsscreen);
      }
    } catch (error) {
      console.error("Error in getMappedScreens:", error);
      // Handle error if necessary
    }
  };

  getUnreadNotificationCount = async () => {
    try {
      let payload = {
        platform: "w",
        userId: this.props.ctr.userid,
        pushToken: localStorage.getItem("pushToken")
      };
      let tokens = this.props.ctr.token;

      const countResponse = await postData(apilist.getNotificationUnreadCount, payload, tokens);

      if (countResponse && countResponse.data && countResponse.data.notifications !== null) {
        this.props.ctr.unReadMessageCount = countResponse.data.notifications;
      } else {
        this.props.ctr.unReadMessageCount = 0;
      }
    } catch (error) {
      console.log("Error in getUnreadNotificationCount:", error.message);
      // Handle error if necessary
    }
  }
  getReleaseNotesAndNotificationCount = async (tokens) => {
    await Promise.all([
      this.getUnreadNotificationCount(),
      this.getMappedScreens(tokens)
    ])
  }

  setsubscritionsdetails = () => {
    var expiredlist = [];
    var expired15list = [];
    var expired30list = [];
    let packagedata = {};
    packagedata.userId = this.props.ctr.userid;
    packagedata.packageId = 1;
    let tokens = this.props.ctr.token;
    const subsciptionapi = postData(
      apilist.billingmodule + "/vehicleVinInfo",
      packagedata,
      tokens
    );
    subsciptionapi.then((response) => {
      if (response.statuscode !== 401) {
        // console.log(response.data);
        if (response.data.length > 0) {
          this.props.gmapsDispatch({ tochecksubdata: true });
        }

        for (var i = 0; i < response.data.length; i++) {
          if (
            response.data[i].days_for_expiry >= 16 &&
            response.data[i].days_for_expiry <= 30
          ) {
            expired30list.push(response.data[i]);
          } else if (
            response.data[i].days_for_expiry > 0 &&
            response.data[i].days_for_expiry <= 15
          ) {
            expired15list.push(response.data[i]);
          } else if (response.data[i].days_for_expiry <= 0) {
            expiredlist.push(response.data[i]);
          }
        }
        this.props.gmapsDispatch({ expiredveh: expiredlist.length });
        this.props.gmapsDispatch({ expired15veh: expired15list.length });
        this.props.gmapsDispatch({ expired30veh: expired30list.length });
      } else {
        this.props.navigate("/");
      }
    });
  };
  getmenuClickValue = (e) => {
    clearInterval(this.hoverInterval);
    clearInterval(this.settimerscnt);
    var menuClickIdAttribute = e.target.getAttribute("menuclickid");
    if (menuClickIdAttribute !== null) {
      var ids = menuClickIdAttribute.split(",");
      var menuClickids = [];
      menuClickids.push({ mm: ids[0], sm: ids[1], tm: ids[2] });
      this.props.quicklinks(menuClickids);
    } else {
      // Handle the case where "menuclickid" attribute is not found
      console.error("Attribute 'menuclickid' not found on target element.");
    }
  };
  componentWillUnmount () {
    clearInterval(this.hoverInterval);
    clearInterval(this.settimerscnt);
  }
  updateDashboardState () {
    const chargingExists = this.initialDashboardArray.includes("Charging");

    const updatedDashboardArray = this.props.isEVVehicelAvailable && !chargingExists
      ? [...this.initialDashboardArray.slice(0, -1), "Charging", "Disconnected"]
      : this.initialDashboardArray;
    this.setState({
      dashboardArray: updatedDashboardArray,
      dashboardLength: updatedDashboardArray.length
    });
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.ctr.renewal_popup === true && this.state.api_call === false) {
      this.setState({ api_call: true });
      this.getFeedbackList()
    }
    if (prevProps.mapInfo !== this.props.mapInfo && this.props.mapInfo && this.props.mapInfo.length > 0) {
      this.handleMapInfoChange(this.props.mapInfo)
    }
    if (this.props.isEVVehicelAvailable && prevProps.isEVVehicelAvailable !== this.props.isEVVehicelAvailable) {
      this.updateDashboardState();
    }

  }

  rerenderParentCallback () {
    // this.forceUpdate();
    clearInterval(this.hoverInterval);
    // this.getvehiclecount();
    this.getalertcounts();
  }
  handleMouseDown = (index) => {
    const borderColor = index !== '' ? "red" : "9d9d9d";
    document.getElementById(`feedback_select_${index}`).style.border = `1px solid ${borderColor}`;
  };
  onChangeFunc = (e, index, vin) => {
    const { name, value, selectedOptions } = e.target;
    const feedbackLabelMap = {
      1: 'Interested',
      2: 'Remind me later',
      3: 'Call back',
      4: 'Not Interested',
    };
    const selectedIndex = this.state.feedbackOptions.findIndex(option => (option.id) === parseInt(value));
    this.setState({
      feedback_select: {
        ...this.state.feedback_select,
        [index]: value
      },
      selectedIndices: {
        ...this.state.selectedIndices,
        [index]: selectedIndex
      },
    },()=>{
      console.log('this.state.feedback_select', this.state.feedback_select)
      console.log('this.state.selectedIndices', this.state.selectedIndices)
    });
    this.setState((prevState) => ({
      selectedVin: {
        ...prevState.selectedVin,
        [index]: vin,
      },
      feedback_label: {
        ...prevState.feedback_label,
        [index]: feedbackLabelMap[value] || '',
      }

    }));
    // const newFeedbackSelect = [...this.state.feedback_select];
    // newFeedbackSelect[index] = value;
    const newMobSelect = [...this.state.selectedMob];
    newMobSelect[index] = this.state.call_back;
    const newTimeSelect = [...this.state.selectedTime]
    newTimeSelect[index] = this.state.callback_time;
    const newcommSelect = [...this.state.selectedComment]
    newcommSelect[index] = this.state.feedback_comment;
    const newRemindSelect = [...this.state.selectedRemind]
    newRemindSelect[index] = this.state.remind_me

    this.setState({
      // feedback_select: newFeedbackSelect,
      hasAnySelectChanged: true,
      selectedMob: newMobSelect,
      selectedTime: newTimeSelect,
      selectedComment: newcommSelect,
      selectedRemind: newRemindSelect
    });

    const borderColor = value !== "" ? "#9d9d9d" : "red";
    document.getElementById(`feedback_select_${index}`).style.border = `1px solid ${borderColor}`;
  }

  reasonChange = (index, e) => {
    const { name, value } = e.target;
    const updatedComment = [...this.state.selectedComment];
    updatedComment[index] = value;
    this.setState({ selectedComment: updatedComment });
    document.getElementById(`feedback_comment_${index}`).style.border = '1px solid #9d9d9d';
  }

  mobileChnage = (index, e) => {
    const { name, value } = e.target;
    if (value.length <= 10) {
      const updatedCallBack = [...this.state.selectedMob];
      updatedCallBack[index] = value;
      this.setState({ selectedMob: updatedCallBack });
      document.getElementById(`callback_mob${index}`).style.border = '1px solid #9d9d9d';
    }

  };

  timeChange = (e, index) => {
    const { name, value } = e.target;
    const updatedTime = [...this.state.selectedTime];
    updatedTime[index] = value;
    this.setState({ selectedTime: updatedTime });
    document.getElementById(`callback_time${index}`).style.border = '1px solid #9d9d9d';
  }

  handleChange = (date, name, index) => {
    const updatedRemind = [...this.state.selectedRemind];
    updatedRemind[index] = date;
    this.setState({ selectedRemind: updatedRemind });
    document.getElementById(`remind_me_${index}`).style.display = "none";
  };

  renewalFunc = () => {
    let isValid = true;
    
    const payloadData = Object.keys(this.state.feedback_select).map(key => {
      const feedbackId = this.state.feedback_select[key];
      const vinNumber = this.state.selectedVin[key];
      const dateRmarks = this.state.selectedRemind[key];
      const comRmarks = this.state.selectedComment[key];
      const mobRmarks = this.state.selectedMob[key];
      const timeRmarks = this.state.selectedTime[key];

      const formattedDate = dateRmarks ? moment(dateRmarks).format("YYYY-MM-DD") : '';
      const commentRemarks = comRmarks ? comRmarks : '';
      const mobileRemarks = mobRmarks ? mobRmarks : '';
      const timeigRemarks = timeRmarks ? timeRmarks : '';
      
      if (this.state.feedback_label[key] === "Not Interested" && this.state.selectedComment[key] === '') {
        document.getElementById(`feedback_comment_${key}`).style.border = "1px solid #ed0a0a";
        isValid = false;
      } else if (this.state.feedback_label[key] === "Call back" && this.state.selectedMob[key] === '' || this.state.selectedMob[key].length < 10) {
        document.getElementById(`callback_mob${key}`).style.border = "1px solid #ed0a0a";
        isValid = false;
      } else if (this.state.feedback_label[key] === "Call back" && this.state.selectedTime[key] === '') {
        document.getElementById(`callback_time${key}`).style.border = "1px solid #ed0a0a";
        isValid = false;
      } else if (this.state.feedback_label[key] === "Remind me later" && this.state.selectedRemind[key] === '') {
        document.getElementById(`remind_me_${key}`).style.display = "block";
        isValid = false;
      } else {
        return {
          vin_number: vinNumber,
          feedback_id: feedbackId,
          remarks: this.state.feedback_label[key] === "Remind me later"
            ? formattedDate
            : this.state.feedback_label[key] === "Not Interested"
              ? commentRemarks
              : this.state.feedback_label[key] === "Call back"
                ? mobileRemarks + ' : ' + timeigRemarks
                : ''
        };
      }
      return null;
    });

    if (!isValid) {
      // alert('Please fill in the required fields');
      return false;
    }

    this.insertFeedback(payloadData);
    return true;
  };

  insertFeedback = (data) => {
    const isLengthEqual = this.state.dueVinlist.length === data.length
    if (isLengthEqual) {
      let dataObj = {
        'data': data
      }
      let tokens = this.props.ctr.token;
      const insertFeedback = postData(apilist.insertFeedback, dataObj, tokens);
      insertFeedback.then((response) => {
        let userDet = {};
        userDet.userInfo = this.props.ctr;
        userDet.userInfo.renewal_popup = false;
        this.props.userData({ ...userDet.userInfo });
        if (this.state.feedback_label === "Interested") {
          clearInterval(this.hoverInterval);
          clearInterval(this.settimerscnt);
          var menuClickids = [];
          menuClickids.push({ mm: 5, sm: 129, tm: 1 });
          this.props.quicklinks(menuClickids);
          this.props.navigate("/billingmodule/packages");
        }
      });
    } else {
      console.error('All the Index are Not Selected')
    }
  }


  hideBirthday = () => {
    let userDet = {};
    userDet.userInfo = this.props.ctr;
    userDet.userInfo.birthday_popup = false;
    this.props.userData({ ...userDet.userInfo });
  }

  subDays = (date, days) => {
    let dateVal = date;
    dateVal.setDate(date.getDate() + days)
    return dateVal;
  }

  addDays = (date, days) => {
    let dateVal = date;
    dateVal.setDate(date.getDate() + days)
    return dateVal;
  }



  handleDialog = () => {
    this.setState(prevState => ({
      dialog: !prevState.dialog,
      showMap: false
    }));
  }

  handleMapClose = () => {
    this.props.mapInfoChange([])
    this.setState({
      showMap: false,
      mapInformation: []
    });
  }
  handleShowMapChange = () => {
    this.setState(prevState => ({
      showMap: !prevState.showMap,
    }));
  }


  handleMapInfoChange = (data) => {
    this.setState({
      showMap: true,
      mapInformation: data
    });
  }

  render () {
    const CustomDatePicker = ({ value, onClick, labelVal, inputname }) => (
      <div className="dateBlockreport" style={{ marginLeft: "-7%" }}>
        <p className="inputContainerreport" >
          <input id="dates" type="text" style={{ cursor: "pointer" }} value={value} onClick={onClick} placeholder="Date" required name={inputname} />
          <i className={this.state.remind_me !== '' ? 'fa fa-calendar-o icon' : 'fa fa-calendar-o icon icon-red'} onClick={onClick}></i>
        </p>
      </div>
    );

    return (
      <div className="logindash">
        {this.props.gmapsState.showWelcomeModal ? (
          <WelcomeModal
            rerenderParentCallback={this.rerenderParentCallback}
            token={this.props.ctr.token}
            userName={this.props.ctr.firstname}
            updateSubcription={this.setsubscritionsdetails}
          />
        ) : null}
        {this.props.ctr.phone_update === 1 ?
          <CustomModal />
          : null}
        <div>
          <div className="bannerbg">
            <div className="container-wrapper">
              <div className="row">
                {/* <body>
                <input type="text" placeholder="Enter Location Name"  className="location_search_box pac-target-input form-control" id="searchByLocationBoxes" />
              </body> */}
                {/* {this.state.notification_content.length <= 0 &&
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 fleet-stat fleetbox">
                    <span className="fleet_status">Fleet Status . <small>Overview</small> </span>
                  </div>
                } */}
                <div className='gif-dashboard2 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12'>

                  <>
                    <span className="fleet_status">Fleet Status . </span>
                    <span className="fleet_overview">Overview</span> <br></br>
                  </>

                  <img
                    ref={this.gifImage}
                    src={"images/Dashboadimgs/GIF-" + this.gifname + ".gif"}
                    alt="vehicles"
                    title="Status"
                  />
                </div>
                <div className='right-text2 col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12'>
                  <div className="fleet-stat col-md-8 right-text-fst">
                    Keep track of{" "}
                  </div>
                  <div className="fleet-stat right-space-text">
                    your trucks 24/7{" "}
                  </div>
                  <div className="right-below-txt right-below-txt-clr">
                    Idle or on the road? Here you get real time{" "}
                  </div>{" "}
                  <div className="right-below-txt-clr ">
                    status of your fleet.
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 release-notes-slider" style={{ visibility: this.state.notification_content.length > 0 ? 'visible' : 'hidden' }}>
                  <p className="rels-notes">Updates</p>
                  {/* <div>
                      <div className="landing-image_container">

                        <img  className="bannerimgwidth-release" onMouseEnter={this.handleHoverstop} onMouseLeave={this.handleMouseLeavestart} src={`/images/newLandingPage/Header-image_1/${this.state.hovercss}.png`} alt="trucks" />

                      </div>
                      <p className="read-more">Read More..</p>
                    </div> */}
                  <Suspense fallback={<div>Loading...</div>}>
                    <ReleaseNotesMui content={this.state.notification_content} />
                  </Suspense>
                </div>
              </div>


              <div className="row entire-count">
                <div className='offset col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12'>
                  <div className="row">
                      <Link
                        to={this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? "/Dealermap" : "/map"}
                        state={{ Vehiclestatushigh: "Total", showseletval: true }}
                        menuclickid="2,8,0"
                        onClick={this.getmenuClickValue.bind(this)}
                      >
                      <div
                        className="offset-xl-1 col-xl-2 offset-lg-1 col-lg-2 offset-md-1 col-md-2 col-sm-2 col-12 hower-vehi "
                        data-status="Total"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Total"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Total
                            ? this.props.gmapsState.vehCount.Total
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Total"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Total
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Total"
                              ? "veh-status-high-tot"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>

                    <Link
                      to={
                          this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                            ? "/Dealermap"
                            : "/map"
                      }
                      state= {{
                        Vehiclestatushigh: "RUNNING",
                        showseletval: true,
                      }}
                      menuclickid="2,8,0"
                      onClick={this.getmenuClickValue.bind(this)}
                    >
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 hower-vehi"
                        data-status="Running"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Running"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Running
                            ? this.props.gmapsState.vehCount.Running
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Running"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Running
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Running"
                              ? "veh-status-high-run"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>

                    <Link
                      to={
                          this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                            ? "/Dealermap"
                            : "/map"
                      }
                      state= {{
                        Vehiclestatushigh: "IDLING",
                        showseletval: true,
                      }}
                      menuclickid="2,8,0"
                      onClick={this.getmenuClickValue.bind(this)}
                    >
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 hower-vehi"
                        data-status="Idling"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Idling"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Idling
                            ? this.props.gmapsState.vehCount.Idling
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Idling"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Idling
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Idling"
                              ? "veh-status-high-idl"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>

                    <Link
                      to={
                          this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                            ? "/Dealermap"
                            : "/map"
                      }
                      state= {{
                        Vehiclestatushigh: "STOPPED",
                        showseletval: true,
                      }}
                      menuclickid="2,8,0"
                      onClick={this.getmenuClickValue.bind(this)}
                    >
                      {" "}
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 hower-vehi"
                        data-status="Stopped"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Stopped"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Stopped
                            ? this.props.gmapsState.vehCount.Stopped
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Stopped"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Stopped
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Stopped"
                              ? "veh-status-high-stp"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>
                    {this.props.isEVVehicelAvailable && <Link
                      to={
                          this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                            ? "/Dealermap"
                            : "/map"
                      }
                      state= {{
                        Vehiclestatushigh: "CHARGING",
                        showseletval: true,
                      }}
                      menuclickid="2,8,0"
                      onClick={this.getmenuClickValue.bind(this)}
                    >
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 hower-vehi"
                        data-status="Charging"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Charging"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Charging
                            ? this.props.gmapsState.vehCount.Charging
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Charging"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Charging
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Charging"
                              ? "veh-status-high-charge"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>
                    }
                    <Link
                      to={
                          this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                            ? "/Dealermap"
                            : "/map"
                      }
                      state= {{
                        Vehiclestatushigh: "DISCONNECTED",
                        showseletval: true,
                      }}
                      menuclickid="2,8,0"
                      onClick={this.getmenuClickValue.bind(this)}
                    >
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 hower-vehi"
                        data-status="Disconnected"
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleMouseLeave}
                      >
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Disconnected"
                              ? "count-value count-val-des"
                              : "count-value-hover count-val-des"
                          }
                        >
                          {this.props.gmapsState.vehCount.Disconnected
                            ? this.props.gmapsState.vehCount.Disconnected
                            : 0}
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Disconnected"
                              ? "count-text count-txt-des"
                              : "count-text-hover count-txt-des"
                          }
                        >
                          Disconnected
                        </div>
                        <div
                          menuclickid="2,8,0"
                          onClick={this.getmenuClickValue.bind(this)}
                          className={
                            this.props.gmapsState.howercss === "Disconnected"
                              ? "veh-status-high-dst"
                              : ""
                          }
                        ></div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 banner-texts">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <Link
                        name="Go to Maps page"
                        to={
                            this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                              ? "/Dealermap"
                              : "/map"
                        }
                        state= {{ fromNotifications: null }}
                      >
                        <input
                          type="button"
                          menuclickid={
                            this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                              ? "52,116,0"
                              : "2,8,0"
                          }
                          onClick={this.getmenuClickValue.bind(this)}
                          className='open-maps2 map-btn'
                          value="Open Map"
                        />
                      </Link>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <Link
                        to={this.props.ctr.userrole === "DEALER OUTLET ADMIN" ? "/Dealermap" : "/map"}
                        state={{ fromNotifications: true }}
                      >
                        <input
                          type="button"
                          menuclickid={
                            this.props.ctr.userrole === "DEALER OUTLET ADMIN"
                              ? "52,116,1"
                              : "2,8,1"
                          }
                          onClick={this.getmenuClickValue.bind(this)}
                          className='list-view2 map-btn'
                          value="List View"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <NavLink link="/monthlyreport">report</NavLink> */}
          <div className="bg-colors-quick">
            <div className="container-wrapper ">
              <div className="row ">
                {this.props.ctr.userrole !== "DEALER OUTLET ADMIN" ? (
                  <div className="guick-link-quick">
                    <div className={this.props.gmapsState.fuelfillapi && (this.props.ctr.userrole !== "AL EMPLOYEE" || this.props.ctr.userrole !== "AL SME") ? "row dash_icon_row dash_icon" : "row al_dash_icon"}>
                      {this.props.ctr.userrole === "OWNER ADMIN" && this.props.ctr.userrole !== "AL USER" && (
                        <div className="quck-img">
                          <NavLink link="/billingmodulepacks">
                            <img
                              className="quck-img-list"
                              src="images/Dashboadimgs/billing-module.png"
                              alt="iAlert"
                              menuclickid="5,129,0"
                              onClick={this.getmenuClickValue}
                            />
                            <div
                              className="quck-text fleet-qck-text"
                              menuclickid="5,129,0"
                              onClick={this.getmenuClickValue}
                            >
                              RENEW SMART PAY
                            </div>
                          </NavLink>
                        </div>
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER") && (
                        <span className="vert-line"></span>
                      )}

                      {this.props.ctr.userrole !== "AL USER" && (
                        <div className="quck-img">
                          <Link 
                            to={"/fleetinsightsrep"}
                            state={{ Fleet: 'Fleet' }}
                          >
                            
                            <img
                              className="quck-img-list"
                              menuclickid="12,70,0"
                              onClick={this.getmenuClickValue}
                              src="images/Dashboadimgs/Fleet_Insights.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text"
                              menuclickid="12,70,0"
                              onClick={this.getmenuClickValue}
                            >
                              FLEET INSIGHTS
                            </div>
                          </Link>
                        </div>
                      )}

                      {this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL USER" && (
                        <span className="vert-line"></span>
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME") && (
                        <div className=" quck-img">
                          <Link 
                            to={"/vehicleInfo/findDealer"}
                            state={{ Fleet: 'Fleet' }}
                          >
                            <img
                              className="quck-img-list"
                              menuclickid="2,27,2"
                              onClick={this.getmenuClickValue}
                              src="images/Dashboadimgs/Dealer_Locator.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text"
                              menuclickid="2,27,2"
                              onClick={this.getmenuClickValue}
                            >
                              Near by Places
                            </div>
                          </Link>
                        </div>
                      )}
                      {this.props.ctr.userrole !== "AL USER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL EMPLOYEE" && (
                        <span className="vert-line"></span>
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER") && (
                        <div className="quck-img ">
                          <NavLink link="/fleetmanagement/geofence">
                            <img
                              className="quck-img-list"
                              menuclickid="3,14"
                              onClick={this.getmenuClickValue}
                              src="images/Dashboadimgs/GeoFM.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text"
                              menuclickid="3,14"
                              onClick={this.getmenuClickValue}
                            >
                              GEOFENCE
                            </div>
                          </NavLink>
                        </div>
                      )}

                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER" && this.props.ctr.userrole !== "TRANSPORTER") && (
                        <span className="vert-line"></span>
                      )}

                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER") && (
                        <div className="quck-img dlocator">
                          <NavLink link="/fleetManagement">
                            <img
                              className="quck-img-list "
                              menuclickid="3,10"
                              onClick={this.getmenuClickValue}
                              src="images/Dashboadimgs/V1.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text poimargin"
                              menuclickid="3,10 "
                              onClick={this.getmenuClickValue}
                            >
                              FLEET MANAGEMENT
                            </div>
                          </NavLink>
                        </div>
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL USER" && this.props.ctr.userrole !== "AL SME") && (
                        <span className="vert-line"></span>
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER") && (
                        <div className="quck-img sarathidash">
                          <NavLink link="/tripList">
                            <img
                              className="quck-img-list "
                              menuclickid="3,13,0"
                              onClick={this.getmenuClickValue}
                              src="images/Dashboadimgs/V2.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text text-sarathi"
                              menuclickid="3,13,0"
                              onClick={this.getmenuClickValue}
                            >
                              TRIP LIST
                            </div>
                          </NavLink>
                        </div>
                      )}

                      {this.props.ctr.userrole === "OWNER ADMIN" && this.props.ctr.userrole !== "AL USER" && (
                        <span className="vert-line"></span>
                      )}
                      {this.props.gmapsState.fuelfillapi && (
                        <span className="vert-line"></span>
                      )}
                      {this.props.gmapsState.monthlyshows ? (
                        <div className="quck-img subval">
                          <Link
                            to="/monthlyreport"
                          >
                            <img
                              className="fuelfill-img report_img_padding"
                              src="images/Dashboadimgs/monthly-report-ico.png"
                              alt="iAlert"
                            />
                            <div className="quck-text fuelfill-dash">
                              REPORT
                            </div>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {(this.props.ctr.userrole !== "AL EMPLOYEE" && this.props.ctr.userrole !== "TRANSPORTER" && this.props.ctr.userrole !== "AL SME" && this.props.ctr.userrole !== "AL USER") && this.props.gmapsState.fuelfillapi && (
                        <span className="vert-line"></span>
                      )}
                      {this.props.gmapsState.fuelfillapi ? (
                        <div className="quck-img subval dash_width">
                          <Link
                            to={"/Dashboad"}
                            state={{ dashboadtype: 'fuelfill' }}
                          >
                            <img
                              className="fuelfill-img"
                              src="images/Dashboadimgs/FuelFill.png"
                              alt="iAlert"
                            />
                            <div className="quck-text fuelfill-dash">
                              FUEL FILL ANALYTICS
                            </div>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}

                      <span className="vert-line"></span>
                      {this.props.gmapsState.fuelfillapi ? (<div
                        className="quck-img subval al-user-link"

                      >
                        <Link 
                          to={"/Dashboad"}
                          state={{ dashboadtype: 'fuelPilferage' }}
                        >
                          <img
                            className="fuelfill-img"
                            src="images/Dashboadimgs/Fuelpilferage.png"
                            alt="iAlert"
                          />
                          <div className="quck-text fuelfill-dash">
                            FUEL PILFERAGE
                          </div>
                        </Link>
                      </div>) : ""}

                      {this.props.ctr.userrole === 'AL EMPLOYEE' ?
                        <span className="vert-line"></span> : null
                      }
                      {this.props.ctr.userrole === "AL EMPLOYEE" ?
                        <div className=" quck-img">
                          <Link
                            to={"/DealerPOI"}
                            state={{ DealerPOI: 'DealerPOI' }}
                          >
                            <img
                              className="quck-img-list "
                              src="images/Dashboadimgs/Dealer_Locator.png"
                              alt="iAlert"
                            />
                            <div
                              className="quck-text"
                            >
                              NETWORK ANALYSER
                            </div>
                          </Link>
                        </div> : null
                      }
                      {(this.props.ctr.userrole === "AL EMPLOYEE" || this.props.ctr.userrole === "AL SME") && (
                        <>
                          <span className="vert-line"></span>
                          <div className="quck-img monthvals" onClick={this.handleAlUserPopupClick}>
                            <img
                              className="fuelfill-img"
                              src="images/Dashboadimgs/SwapLogin.png"
                              alt="iAlert"
                            />
                            <div className="quck-text monthlyreport-dash">
                              ASSIGN VEHICLE
                            </div>
                          </div>
                        </>
                      )}

                      {this.props.ctr.userrole === "AL EMPLOYEE" &&
                        <>
                          <span className="vert-line"></span>
                          <div className=" quck-img">
                            <Link
                              to={"/vehicleDetails"}
                              state={{ vehicleDetails: 'vehicleDetails' }}
                            >
                              <img
                                className="quck-img-list "
                                src="images/Dashboadimgs/vehicle_details.png"
                                alt="iAlert"
                                style={{ width: "43px", height: "32px" }}
                              />
                              <div
                                className="quck-text"
                              >
                                VEHICLE DETAILS
                              </div>
                            </Link>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="bg-colors-alert">
            <div className="container-wrapper ">
              <div className="row ">
                <FleetAlertBox userrole={this.props.ctr.userrole} vehalertCount={this.props.gmapsState.vehalertCount} fleetAlerts={fleetAlerts} />
                <div className="sub-boxes-dash col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <div className="guick-link-alert-right">
                    <div className="row ">
                      <div className="col-md-12 cust-stp-text">
                        <div className="fleet-stat subscription ">
                          Subscription Expiry Alert
                        </div>
                      </div>
                      <div className="row-sub">
                        <div className="sub-width ">
                          <div>Expired</div>
                          <div className="aligntext-sub">
                            {this.props.gmapsState.expiredveh === 0
                              ? "-"
                              : this.props.gmapsState.expiredveh}
                          </div>
                        </div>
                        <span className="line-subs"></span>
                        <div className="sub-width margin-subs">
                          <div>Next 15 days</div>
                          <div className="aligntext-sub">
                            {this.props.gmapsState.expired15veh === 0
                              ? "-"
                              : this.props.gmapsState.expired15veh}
                          </div>
                        </div>
                        <span className="line-subs"></span>
                        <div className="sub-width margin-subs">
                          <div>Next 30 days</div>
                          <div className="aligntext-sub">
                            {this.props.gmapsState.expired30veh === 0
                              ? "-"
                              : this.props.gmapsState.expired30veh}
                          </div>
                        </div>
                      </div>
                      {this.props.gmapsState.tochecksubdata === true ? (
                        <span className="linkdashboard">
                          <Link
                            to={"/fleetalerts"}
                            state={{ alertActive: 'Events' }}
                          >
                            <u>More Info</u>
                          </Link>
                        </span>
                      ) : (
                        ""
                      )}
                      {this.props.ctr.userrole === "OWNER ADMIN" ? (
                        this.props.gmapsState.tochecksubdata === true ? (
                          <div className="sub-btn-dash-sub">
                            <NavLink link="/billingmodule/packages">
                              <input
                                type="button"
                                className="sub-btn  mar-width"
                                value="Renew Now"
                                menuclickid="5,129,1"
                                onClick={this.getmenuClickValue}
                              />
                            </NavLink>
                          </div>
                        ) : (
                          ""
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="support-box-dash col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12">
                  <div className="guick-link-alert-right">
                    <div className="row ">
                      <div className="col-md-12 cust-stp-text cust-stp-text-mt">
                        <div className="fleet-stat fleet-get-sup ">
                          Customer Support
                        </div>
                      </div>
                      <div className="contactdiv">
                        <div className="mailus-text">
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          <span className="change-color">
                            <strong>alert.care@ashokleyland.com</strong>
                          </span>
                        </div>
                        <div className="callus-text">
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          <span className="change-color">
                            <strong>0806-953-5555</strong>
                          </span>
                        </div>
                      </div>

                      <div className="sub-btn-dash">
                        <NavLink link="/CustomerSupport">
                          <input
                            type="button"
                            className="map-btn open-mail-icon mar-width"
                            value="Raise Ticket"
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(this.props.ctr.userroleid === 20 || this.props.ctr.userroleid === 6 || this.props.ctr.userroleid === 8 || this.props.ctr.userroleid === 21) &&
            <>
              <div className="chatbot-container2" onClick={(e) => { this.handleDialog(e) }}>
                {
                  <>
                    <button className='btn-dialog'>
                      <img src="Bot_gif.gif" alt='bot'></img>
                    </button>
                    <span className="may_help_txt">i-BOT</span>
                  </>
                }
              </div>

              <div className="chatbot-container">
                {this.state.dialog &&
                  <>
                    <img className='avatar-img-header' alt="BotAvatar" src='bot-header.png' />
                    <img className="chatbot-close-btn" src="./close_btn.png" onClick={(e) => { this.handleDialog(e) }}></img>
                    <Chatbot
                      config={config}
                      headerText="i-BOT"
                      messageParser={MessageParser}
                      actionProvider={ActionProvider}
                      disableScrollToBottom={true}
                      id="id_chatbot"

                    />
                  </>
                }
              </div>
              {this.state.showMap ?
                <div className="chatbot-map-popup">
                  <div className="popup" style={{ display: this.state.showMap }}>
                    <div className="popupbody">
                      <div className="popupContent">
                        <div className="col-lg backclose">
                          <img src="/images/TripList/Close.png" onClick={this.handleMapClose} alt=""></img>
                        </div>
                        {<ChatBotMapComponent zoom={this.zoom} center={this.center} traceData={this.state.mapInformation} />}
                      </div>
                    </div>
                  </div>
                </div> : ''
              }
            </>
          }

          <div className="renewalForm" style={{ display: this.props.ctr.renewal_popup === true && this.props.ctr.phone_update !== 1 && this.state.dueVinlist.length > 0 ? 'block' : 'none' }}>
            <div className="renewal_column_popup_body">
              <div className="popup_header">
                <p className="renewal_popup_heading">Vehicles due for renewal(s)</p>
              </div>
              <div className="popup_content">
                <div className="row renewal_config_popup_body config_div">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 feedback-div first-div">
                    <div className="vin_list_table">
                      <table className="table table-bordered table-top-bottom dueTable">
                        <thead>
                          <tr>
                            <th>Reg No / VIN</th>
                            <th>Expiry Date</th>
                            <th>Feedback <span className="icon-red">*</span></th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody className="customCss_TBody">
                          {this.state.dueVinlist?.map((vin, index) => {
                            return (
                              <tr key={vin.id}>
                                <td>{vin.regn_number}</td>
                                <td>{vin.expiry_date}</td>
                                <td className="feedBackTD">
                                  <select
                                    id={`feedback_select_${index}`}
                                    name='feedback_select'
                                    onChange={(e) => this.onChangeFunc(e, index, vin.vin_number)}
                                    onMouseDown={(e) => this.handleMouseDown(index)}
                                    value={this.state.feedback_select[index] || ""}
                                    className='form-control renewal'
                                  >
                                    <option value="" disabled>Select Feedback</option>
                                    {this.state.feedbackOptions?.filter(option =>
                                      !(option.id === 2 && !vin.is_remind_me_later)
                                    ).map((option) => {
                                      return (
                                        <option key={option.id} value={option.id}>
                                          {option.feedback_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td>
                                  {this.state.feedback_label[index] === "Call back" &&
                                    <div className="call-back">
                                      <div>
                                        <input
                                          type="number"
                                          id={`callback_mob${index}`}
                                          name="call_back"
                                          placeholder="Mobile Number"
                                          onChange={(e) => this.mobileChnage(index, e)}
                                          value={this.state.selectedMob[index] || ''}
                                          className="form-control renewal"
                                        />
                                        {/* <span className="charcheck" id="mobile_valid" style={{ display: 'none', color: 'red' }}>Please enter valid mobile number</span> */}
                                      </div>

                                      <SelectInput
                                        label="CallBack Time"
                                        id={`callback_time${index}`}
                                        options={[
                                          "09 am to 10 am",
                                          "10 am to 11 am",
                                          "11 am to 12 pm",
                                          "12 pm to 01 pm",
                                          "01 pm to 02 pm",
                                          "02 pm to 03 pm",
                                          "03 pm to 04 pm",
                                          "04 pm to 05 pm",
                                          "05 pm to 06 pm"
                                        ]}
                                        value={this.state.selectedTime[index] || ''}
                                        onChange={(e) => { this.timeChange(e, index) }}
                                        className='mr-2'
                                      />
                                    </div>
                                  }
                                  {this.state.feedback_label[index] === "Not Interested" &&
                                    <div className="reason-remarks">
                                      {/* <div>
                                        <label className="form-control-placeholder" htmlFor=""> Reason <span className="red">*</span></label>
                                      </div> */}
                                      <div className="reason-select mr-2">
                                        <select
                                          id={`feedback_comment_${index}`}
                                          name="feedback_comment"
                                          onChange={(e) => this.reasonChange(index, e)}
                                          value={this.state.selectedComment[index] || ''}
                                          className="form-control renewal"
                                        >
                                          <option value="" disabled>Select</option>
                                          {this.state.notInterstedOption?.map((option) => (
                                            <option key={option.value_name} value={option.value_name}>{option.value_name}</option>
                                          ))}
                                        </select>

                                      </div>
                                    </div>
                                  }
                                  {this.state.feedback_label[index] === "Remind me later" &&
                                    <>
                                      <div className="date-picker">
                                        <DatePicker id='remind_me' placeholderText="Date" selected={this.state.selectedRemind[index]} dateFormat="dd/MM/yyyy" onChange={date => this.handleChange(date, "remind_me", index)} minDate={this.subDays(new Date(), 2)} maxDate={this.addDays(new Date(), 30)} customInput={<CustomDatePicker labelVal="Date" inputname="Date" />} />
                                        <span id={`remind_me_${index}`} style={{ display: 'none', color: 'red' }}>Please enter date</span>
                                      </div>
                                    </>
                                  }
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>


                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="button-container">
                    <p className="text-center">
                      <span className="w200">
                        <input type="button" className="submit_config_btn" onClick={() => this.renewalFunc()} name="submit" value="Submit" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="birthday_popup" onClick={() => { this.hideBirthday() }} style={{ display: this.props.ctr.birthday_popup === true ? 'block' : 'none' }}>
          <div className="renewal_column_popup_body">
            <div className="popup_header">
              <p className="renewal_popup_heading text-center">
                <span className="close_list_configuration_btn" style={{ cursor: "pointer", float: "right" }}>✖</span>
              </p>
            </div>
          </div>
          <img className="birthday_img" src="/images/happy_birthday.gif" alt="happy birthday"></img>
        </div>
        {this.props.gmapsState.showFleetWarningModal && <FleetInsightDataWarning onClose={this.closeWarning} />}
      </div>
    );

  }
}
const mapStateToprops = (state) => {
  return {
    ctr: state.userdata.id,
    searchresult: state.menus.globalSearch,
    gmapsState: state.GmapsReducer,
    togglerefresh: state.menus.onlyrefresh,
    togglevalues: state.menus.refresh,
    isEVVehicelAvailable: state.menus.isEVEnormExits,
    mapInfo: state.DashReducer.mapInfo,
    releaseNotes: state.menus.releaseNotes,
    monthReportAPIData: state.menus.monthReportAPIData,
    mappedScreenAPIData: state.menus.mappedScreenAPIData
  };
};

const mapdispatchToprops = (dispatch) => {
  return {
    menuData: (data) => dispatch({ type: "MENU", value: data }),
    quicklinks: (data) => dispatch({ type: "QUICKLINK", value: data }),
    gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
    onlyrefresh: (data) => dispatch({ type: "ONLYREFRESH", value: data }),
    searchresultbox: (data) => dispatch({ type: "SEARCHBOXCNT", value: data }),
    userData: (data) => dispatch({ type: 'INCREMENT', value: data }),
    mapInfoChange: (mapInfo) => dispatch(mapInfoChange(mapInfo)),
    setReleaseNotes: (data) => dispatch({ type: RELEASE_NOTES, value: data }),
    setMonthlyReportData: (data) => dispatch({ type: MONTHLY_REPORT_DATA, value: data }),
    setMappedScreenData: (data) => dispatch({ type: MAPPED_SCREEN_DATA, value: data })
  };
};
export default withNavigate(connect(mapStateToprops, mapdispatchToprops)(dashboard));
