import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import './Date.scss'
import scroll from '../Scroll'
import moment from 'moment';

import { getVinData } from '../API/useApi'

import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { subDays } from "date-fns";
import Loader from '../Loader/Loader';
import DatePicker from "react-datepicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { TextField, InputAdornment } from '@mui/material';
import { DateRange } from '@mui/icons-material';

const DateSelect = (props) => {
    const ctr = useSelector((state) => state.userdata.id);
    const { setState } = props
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedRange, setSelectedRange] = useState(null);

    useEffect(() => {
        if (startDate && endDate && props.selected_vin !== '') {
          getVINData(); // Call your function here after the dates and selected VIN are valid
        }
      }, [startDate, endDate, props.selected_vin]);

    const handleChange = (dates) => {
        if(!props.state.result){
            const [start, end] = dates;
            
            const date1 = new Date(dates[0]);
            const date2 = new Date(dates[1]);

            // To calculate the time difference of two dates 
            var Difference_In_Time = date2.getTime() - date1.getTime(); 
            // To calculate the no. of days between two dates 
            var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24) + 1);

            if(Difference_In_Days >7){
                alert('Date range should not exceed seven days..')
                setStartDate(null)
                setEndDate(null) 
                return false;
                
            }
            else{
                setState(state => ({ ...state, startDate: dates[0] }))
                setStartDate(dates[0])
                
                setState(state => ({ ...state, endDate: dates[1] }))
                setEndDate(dates[1]) 
                setSelectedRange(dates)   
            }
        }
        else{
            setState(state => ({ ...state, expanded: props.state.index, yes_no_btn_clk: true}))
        }
        scroll();
    }

    const getVINData = async () => {
        try {
            var s_date = moment(startDate).format('DD-MM-YYYY');
            var e_date = moment(endDate).format('DD-MM-YYYY');
            props.actionProvider.inputHandler(props, s_date + ' to ' + e_date);
            setState(state => ({ ...state, loader: true, vinData: []}))
            const response = await getVinData(ctr, props, startDate, endDate);
            if(response.statuscode === 200 ){
                setState(state => ({ ...state, vinData: response.data, loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler(props, response.data, startDate, endDate);

            }
            else{
                setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
                props.actionProvider.resultHandler(props, [], startDate, endDate);
            }
        } catch (error) {
            console.error(error);
            setState(state => ({ ...state, vinData: [], loader: false, result: true, q_clicked: false, yes_no_btn_clk: false, fleet_continue: true}))
            props.actionProvider.resultHandler(props, [], startDate, endDate);
        }
        scroll();
    };

    // Create a reference to be passed to the DatePicker
    const datePickerRef = useRef<DatePicker>(null);

    // Optional: You can programmatically open the date picker
    const openDatePicker = () => {
        if (datePickerRef.current) {
        // Check if the date picker ref is available and open it
        (datePickerRef.current.setFocus()); // TypeScript may need a type cast
        }
    };

    // CustomInput component
    const CustomInput = React.forwardRef(
        ({ value, onClick }, ref) => (
          <div className="custom-date-input" onClick={onClick}>
            <input
              className="form-control"
              value={value}
              placeholder="DD-MM-YYYY"
              ref={ref}
            />
            <DateRangeIcon className="date-icon" onClick={openDatePicker} />
          </div>
        )
      );

     // Calculate the last 90 days' date
    const getLast90Days = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        return currentDate;
    };

    // Calculate one day before the current date
    const getMaxDate = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day from today
        return currentDate;
    };

  return (
    <>
        <div className='d-flex date-div'>
            {/* {(props.payload.api === 'f_report' && props.payload.column !== 'fuel_cons' && props.payload.column !== 'avg_kmpl') && 
                <div className='col-md-12 top-bottom-title'>
                    <span>{props.payload.parent_title} - {props.payload.title}</span>
                </div>
            } */}
            <div className='col-md-8'>
                {/* <DateRangePicker
                    value={selectedRange}
                    shouldDisableDate={combine(allowedMaxDays(7), combine((date) => isAfter(date, new Date().setDate(new Date().getDate() - 1)), (date) => isBefore(date, subDays(new Date(), 91))) )} 
                    showOneCalendar  
                    onChange={handleChange} 
                    placeholder='Start & End Date' 
                    ranges={[]} 
                    character=' to ' 
                    format='dd-MM-yyyy'
                /> */}
                <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    onKeyDown={(e) => e.preventDefault()} // Disable manual typing
                    placeholderText="Start & End Date"
                    dateFormat="dd-MM-yyyy"
                    maxDate={getMaxDate()} // Limit to one day before today
                    minDate={getLast90Days()} // Allow only dates from the last 90 days
                    customInput={
                    <TextField
                        onClick={() => {}}
                        placeholder="Start & End Date"
                        fullWidth
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <DateRange style={{ cursor: 'pointer' }} />
                            </InputAdornment>
                        ),
                        }}
                    />
                }
      />
            </div>
        </div>
    </>
  )
}

export default DateSelect