import React,{ Component } from 'react';
import './SarathyShowTrip.scss';
import '../../Components/TripList/tripList.scss';
import "../../Components/BottomRibbon/ribbonStyle.scss";
import "../../Components/GoogleMap/index.scss";
import TripBox from './tripBox';
import { postData, filePostData } from '../../Util';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SarathyCreateTrip from '../SarathyCreateTrip/SarathyCreateTrip';
import * as apilist from '../../apiList';
import moment from 'moment';
import classnames from 'classnames';
import AlertBox from '../AlertBox/alertBox';
import { Redirect } from 'react-router';
import { Navigate } from 'react-router-dom';

import * as alasql from 'alasql';
import XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = XLSX;

class SarathyShowTrip extends Component{

    constructor(props){
        super(props);
        this.state = {
            tripCounts : [],
            fliterTripData: [],
            filterclass:"TOTAL",
            showTripData: [],
            currentPage: 1,
            tripsPerPage: 7,
            loader:false,
            createdSwitch:"ALL",
            tripSearch:"",
            filter :{creationType:"ALL",status:"TOTAL"},
            showMessage:"",
            showAlertBox: false,
            nonUpdateAble:false, 
            messageType:"e",

            openUploadExcel:false,
            selectedFile:"",
            excelFileName:"Choose file...",
            errorFile:false
        }
    }

    componentDidMount(){
      if(this.props.showTrip.allowed){
        this.getTripDetailsList();
      }
      else{
        this.openModel(this.props.showTrip.message,"e");
      }
    }

    getTripDetailsList = () =>{
      let tripData = {};
      tripData.platform = "w";
      let tokens = this.props.ctr.token;
      tripData.userId = this.props.ctr.userid;
      //let tripList = postData("http://10.18.1.115:8000/api/sarathitrip/sarathiTripList",
      let tripList = postData(apilist.sarathyTripList,
          tripData, tokens);
      this.setState({loader: true});
      tripList.then((response)=>{
        this.setState({
            showTripData : response.data,
            fliterTripData : response.data,
            loader:false
        })
      })
      this.tripList("");
    }

    tripList = (creationType) =>{
      let tripData = {};
      tripData.platform = "w";
      tripData.userId = this.props.ctr.userid;
      tripData.tripCreationType = creationType;
      let tokens = this.props.ctr.token;
      const tripCount = postData(apilist.sarathyTripCount, tripData,tokens);
        tripCount.then((response) => {
            const count = response.data;
            this.setState({
              tripCounts : count
            });
        })
    }

    changePage = (pagenumber) => {
        let pageno;
        let { currentPage } = this.state;
        let lastVal = (Math.ceil(this.state.showTripData.length / this.state.tripsPerPage));
        if (pagenumber === "<") {
            pageno = currentPage - 1 !== 0 ? currentPage - 1 : 1;
        } else if (pagenumber === ">") {
            pageno = currentPage + 1 !== lastVal+1 ? currentPage + 1 : lastVal;
        } else if (pagenumber === "<<"){
            pageno = 1;
        } else if (pagenumber === ">>"){
            pageno = lastVal;
        }
         else {
            pageno = pagenumber;
        }
        this.setState({
            currentPage: pageno
        })
    }

    filterTripList = (value, category, event) =>{
      var filter = this.state.filter;
      var searchText = "";

      if(category !== "search"){
        this.setState({
          tripSearch:""
        })
      }

      if(category === "creationType"){
        filter.creationType = value;
      }
      else if(category === "search"){
        searchText = category;
      }
      else{
        filter.status = value;
      }

      var tripData = [];
      if(filter.status === "TOTAL" && filter.creationType === "ALL"){
        tripData = this.state.showTripData;
      }
      else{
        tripData = this.state.showTripData.filter((name) =>{
          if(filter.status === "TOTAL"){
            return name.trip_creation_type === filter.creationType;
          }
          else if(filter.creationType === "ALL"){
            return name.trip_status === filter.status;
          }
          return (name.trip_status === filter.status) && 
              (name.trip_creation_type === filter.creationType);
        });
      }

      if(searchText !== ""){
        tripData = tripData.filter((name) =>{
          let eventValue = event.target.value;
          return (name.trip_id.toString().indexOf(eventValue) !== -1 ||
            this.filterIndexOf(name.trip_status,eventValue) ||
            this.filterIndexOf(name.trip_creation_type,eventValue) ||
            this.filterIndexOf(name.vin_number,eventValue) ||
            this.filterIndexOf(name.regn_number,eventValue) ||
            this.filterIndexOf(name.driver_name,eventValue) ||
            this.filterIndexOf(name.application_segment,eventValue) ||
            this.filterIndexOf(name.start_location,eventValue) ||
            this.filterIndexOf(name.end_location,eventValue) ||
            this.filterIndexOf(name.continuous_driving,eventValue) ||
            this.filterIndexOf(name.night_driving,eventValue) ||
            // name.trip_status.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.trip_creation_type.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.vin_number.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.regn_number.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.driver_name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            name.target_kmpl.toString().indexOf(eventValue) !== -1 )
            // name.application_segment.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.start_location.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.end_location.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.continuous_driving.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||
            // name.night_driving.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 )
        });
      }
      
      this.tripList(filter.creationType === "ALL" ? "" : filter.creationType === "Fleet Owner" ? "fleet" : filter.creationType.toLowerCase());
      this.setState({
        createdSwitch:filter.creationType,
        filterclass:filter.status,
        fliterTripData:tripData,
        currentPage:1
      })

    }

    filterIndexOf = (nameValue, eventValue) =>{
      return (nameValue && nameValue.toLowerCase().indexOf(eventValue.toLowerCase()) !== -1)
    }

    //  filterTable = (searchText) =>{
    //   var table, tr, td, cell, i, j;

    //   table = document.getElementById("tripTable");
    //   tr = table.getElementsByTagName("tr");
    //   for (i = 1; i < tr.length; i++) {
    //     // Hide the row initially.
    //     tr[i].style.display = "none";
      
    //     td = tr[i].getElementsByTagName("td");
    //     for (j = 0; j < td.length; j++) {
    //       cell = tr[i].getElementsByTagName("td")[j];
    //       if (cell) {
    //         if (cell.innerHTML.toLowerCase().indexOf(searchText) > -1) {
    //           tr[i].style.display = "";
    //           break;
    //         } 
    //       }
    //     }
    //   }
    // }

    changeShowTrip = (event) =>{
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    downloadExcel = () =>{

      alasql.fn.updateTimeStamp = function (val) {
          if(val === "" || val === undefined){
              return '';
          }

          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          let current_datetime = new Date(val);
          let hours = current_datetime.getHours();
          let minutes = current_datetime.getMinutes();
          let ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;

          let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear() + " " + strTime;
          return formatted_date;
      }

      alasql.fn.nullCheck = function (val){
        if(val === null){
            return "0";
        }
        else{
            return val;
        }
      }

      alasql('SELECT nullCheck(trip_id) AS [Trip Id], nullCheck(trip_status) AS [Trip Status], nullCheck(trip_creation_type) AS [Trip Creation Type], updateTimeStamp(created_timestamp) AS [Created Timestamp], nullCheck(vin_number) AS [Vin Number],nullCheck(regn_number) AS [Registration Number], nullCheck(driver_name) AS [Driver Name], nullCheck(target_kmpl) AS [Target KMPL], nullCheck(application_segment) AS [Application Segment], nullCheck(start_location) AS [Start Location], nullCheck(end_location) AS [End Location], nullCheck(continuous_driving) AS [Continuous Driving], nullCheck(night_driving) AS [Night Driving], updateTimeStamp(planned_trip_start) AS [Planned Trip Start], updateTimeStamp(planned_trip_end) AS [Planned Trip End], updateTimeStamp(updated_timestamp) AS [Updated Timestamp] INTO XLSX("Sarathi_Trip_Management", {headers:true}) FROM ?', [this.state.fliterTripData]);
    }

    passingVal=()=>{
      this.props.quicklinks([{mm:"82", sm:"86", tm:"0"}]);
      //this.props.quicklinks([{mm:"7", sm:"1", tm:"0"}]);
    }

    openModel = (message,msgType) => {
      this.setState({
          showAlertBox: true,
          showMessage:message,
          messageType:msgType
      });
    };

    hideModal = () => {
      if(this.props.showTrip.allowed){
        this.setState({
          showAlertBox: false
        })
      }
      else{
        this.props.navigateToHome(false);
        this.setState({
            redirect: true
        })
      }
    };

    bulkUploadExcel = () =>{
      this.setState({
        openUploadExcel: true
      })
    }

    closeUploadExcel = () =>{
      this.setState({
        openUploadExcel: false,
        selectedFile:"",
        excelFileName:"Choose file...",
        errorFile:false
      })
    }

    downloadTemplate = () =>{
      let tokens = this.props.ctr.token;
      window.open(apilist.sararthiTripCreationTemplate+'/?token='+tokens);
    }

    uploadExcel = () =>{

      if(this.state.selectedFile){
        const tokens = this.props.ctr.token;
        const formData = new FormData(); 
      
        // Update the formData object 
        formData.append( 
          "value", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 

        const sarathiTripBulkUpload = filePostData(apilist.sarathiTripBulkUpload,formData,tokens);

        sarathiTripBulkUpload.then((response)=>{

          const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const fileExtension = '.xlsx';
      
          const ws = XLSX.utils.json_to_sheet(response);
          const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], {type: fileType});
          FileSaver.saveAs(data, 'Sarathi_Trip_Creation_Response' + fileExtension);
  
          // alasql('SELECT nullCheck(Registration_Number) AS [Registration Number], nullCheck(Driver_Name) AS [Driver Name],'
          // +'nullCheck(Application_Segment) AS [Application Segment], nullCheck(Origin) AS [Origin],+ nullCheck(Destination) AS [Destination],'
          // +'nullCheck(Planned_Trip_Distance) AS [Planned Trip Distance], nullCheck(Target_KMPL) AS [Target KMPL],'
          // +'nullCheck(Overstoppage_Limit) AS [Overstoppage_Limit],nullCheck(Night_Driving(Y/N)) AS [Night Driving(Y/N)],'
          // +'nullCheck(Night_Driving_Start_Time_(hh:mm)) AS [Night Driving Start Time(HH:MM)],nullCheck(Night_Driving_End_Time_(hh:mm)) AS [Night Driving End Time(HH:MM)],'
          // +'nullCheck(Continuos_Driving(Y/N)) AS [Continuos Driving(Y/N)], Continuos_Driving_Hours AS [Continuos Driving Hours],'
          // +'Trip_Start_Date_and_Time AS [Trip Start Date and Time], Trip_End_Date_and_Time AS [Trip End Date and Time],'
          // +'Productivity_Weightage AS [Productivity Weightage], Performance_Weightage AS [Performance Weightage],'
          // +'Safety_Weightage AS [Safety Weightage], Payload  AS [Payload], Remarks AS [Remarks]' 
          // +' INTO XLSX("Sarathi_Trip_Creation_Response", {headers:true}) FROM ?', [response]);

          this.openModel("Document uploaded successfully","s");
          this.setState({
            openUploadExcel: false,
            selectedFile:"",
            excelFileName:"Choose file...",
            errorFile:false
          },this.getTripDetailsList)
        }).catch((error)=>{
          console.log(error);
        })
      }else{
        this.setState({
          errorFile: true
        })
      }
    }

    changeFile = (event) =>{
      const selectedFile = event.target.files[0];
      this.setState({
        selectedFile:selectedFile,
        excelFileName:selectedFile.name
      })
    }

    render(){

        const { fliterTripData, currentPage, tripsPerPage, tripCounts, filterclass,
          createdSwitch, showMessage, showAlertBox, messageType, redirect,openUploadExcel } = this.state;

        if(redirect){
          return <Navigate to="/home" />;
        }

        const indexOfLastTrip = currentPage * tripsPerPage;
        const indexOfFirstTrip = indexOfLastTrip - tripsPerPage;
        const currentTrips = fliterTripData.slice(indexOfFirstTrip, indexOfLastTrip);

        const showTripTableValues = currentTrips.map((tripVal,index)=>{
            return <tr key ={index}>
                <td><Link onClick={this.passingVal} to={ {pathname: '/SarathyCreateTrip', 
                  component: SarathyCreateTrip, state: {tripId: tripVal.trip_id}}}>
                  {tripVal.trip_id}</Link></td>
                <td>{tripVal.trip_status}</td>
                <td>{tripVal.trip_creation_type}</td>
                <td>{moment(tripVal.created_timestamp).format("DD-MM-YYYY h:mm:ss A")}</td>
                <td>{tripVal.vin_number}</td>
                <td>{tripVal.regn_number}</td>
                <td>{tripVal.driver_name}</td>
                <td>{tripVal.target_kmpl}</td>
                <td>{tripVal.application_segment}</td>
                <td>{tripVal.start_location}</td>
                <td>{tripVal.end_location}</td>
                <td>{tripVal.continuous_driving}</td>
                <td>{tripVal.night_driving}</td>
                <td>{moment(tripVal.planned_trip_start).format("DD-MM-YYYY h:mm:ss A")}</td>
                <td>{tripVal.planned_trip_end ? moment(tripVal.planned_trip_end).format("DD-MM-YYYY h:mm:ss A") : ""}</td>
                <td>{moment(tripVal.updated_timestamp).format("DD-MM-YYYY h:mm:ss A")}</td>
            </tr>
        });

        let length = fliterTripData.length;
        let pageNumbers = [];
        if(Math.ceil(length/tripsPerPage) > 1){
            for(let i=1;i<=Math.ceil(length/tripsPerPage);i++){
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map(number => {
            if(pageNumbers.length <= 6){
              return (
                <td
                  key={number}
                  id={number}
                  onClick={this.changePage.bind(this,number)}
                  className={
                    this.state.currentPage === number
                      ? "slectedbluepg pagesize"
                      : "notselected pagesize"
                  }
                >
                  {number}
                </td>
              );
            }else{
              if(number === 1){
                if(this.state.currentPage <= 5){
                  return (
                    <td
                      key={number}
                      id={number}
                      onClick={this.changePage.bind(this,number)}
                      className={
                        this.state.currentPage === number
                          ? "slectedbluepg pagesize"
                          : "notselected pagesize"
                      }
                    >
                    {number}
                    </td>
                  );
                }else{
                  return (
                    <td
                      key={number}
                      id={number}
                      onClick={this.changePage.bind(this,number)}
                      className={
                        this.state.currentPage === number
                          ? "slectedbluepg pagesize"
                          : "notselected pagesize"
                      }
                    >
                    {number}...
                    </td>
                  );
                }
              }else if(number >1 && number <= 6 && this.state.currentPage <= 6){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  {number}
                  </td>
                );
              }else if((number >= (this.state.currentPage-2) && number <= (this.state.currentPage+2)) && this.state.currentPage > 6){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  {number}
                  </td>
                )
              }else if(number === pageNumbers.length){
                return (
                  <td
                    key={number}
                    id={number}
                    onClick={this.changePage.bind(this,number)}
                    className={
                      this.state.currentPage === number
                        ? "slectedbluepg pagesize"
                        : "notselected pagesize"
                    }
                  >
                  <span>...</span>{number}
                  </td>
                )
              }else{
                  return "";
              }
            }
          });

        const tripCountBoxes = tripCounts.map((value,index) =>{
          return <TripBox click={this.filterTripList.bind(this,value.trip_status,"status")} filterclass={filterclass}
            key = {index} name={value.trip_status} value={value.count}></TripBox>
        });

        return (
            <div>
              {this.state.loader ?<div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :''}
              <div className={classnames(this.props.showTrip.allowed ? null: 'bg-blur')}>
                <div className="sarathyTripHeader">
                    <div className="sarathy-filter">
                      <div className="sarathy-list-input">
                          <input type="text" className="search-text" id="tripSearch"
                              value={this.state.tripSearch} 
                              onKeyUp={this.filterTripList.bind(this,"","search")}
                              onChange={this.changeShowTrip.bind(this)} 
                              placeholder="Search"
                              name="tripSearch" autoComplete="off">
                          </input>
                          <span className="input-group-addon fa-input">
                            <span className="fa fa-search fa-white-search"></span>
                          </span>
                      </div>
                      <div className="created-switch">
                        <div onClick={this.filterTripList.bind(this,"ALL","creationType")} 
                          className={classnames('switch-border',createdSwitch === 'ALL' ? 'switch-border-active':null) }>
                          <p>ALL</p>
                        </div>
                        <div onClick={this.filterTripList.bind(this,"Driver","creationType")} 
                          className={classnames('switch-border',createdSwitch === 'Driver' ? 'switch-border-active':null) }>
                          <p>Driver</p>
                        </div>
                        <div onClick={this.filterTripList.bind(this,"Fleet Owner","creationType")} 
                          className={classnames('switch-border',createdSwitch === 'Fleet Owner' ? 'switch-border-active':null) }>
                          <p>Fleet Owner</p>
                        </div>
                      </div>
                      <div className="created-switch ml-4">
                        <div>
                            <button type="button" disabled={!fliterTripData.length} 
                              className="export_btn" 
                              onClick={this.downloadExcel}>
                              <abbr className="abbrCls" title="Download in Excel">
                              <span className="fa fa-download"></span></abbr>
                            </button>
                        </div>
                        <div className="ml-2">
                            <button type="button" 
                              className="export_btn" 
                              onClick={this.bulkUploadExcel}>
                              <abbr className="abbrCls" title="Bulk Upload">
                              <span className="fa fa-upload"></span></abbr>
                            </button>
                        </div>
                      </div>
                    </div>
                    
                    <div className="sarathytripcontainer">
                        <div className="row">
                            {
                              tripCountBoxes
                            }
                        </div>
                    </div>
                </div>
                <div className="sarathy-table-responsive">
                    <table id="tripTable" className="table sarathyTable">
                        <thead>
                            <tr>
                                <th>Trip Id</th>
                                <th>Trip Status</th>
                                <th>Trip Creation Type</th>
                                <th>Created Timestamp</th>
                                <th>Vin Number</th>
                                <th>Registration Number</th>
                                <th>Driver Name</th>
                                <th>Target KMPL</th>
                                <th>Application Segment</th>
                                <th>Start Location</th>
                                <th>End Location</th>
                                <th>Continuous Driving</th>
                                <th>Night Driving</th>
                                <th>Planned Trip Start</th>
                                <th>Planned Trip End</th>
                                <th>Updated Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              showTripTableValues.length ? showTripTableValues :
                              <tr>
                                <td colSpan="11">
                                  <div className="errorDiv">
                                    <p className="errorMsg">
                                        No Data Available
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pageNumbers.length ?
                    <div className="row pagination-row">
                        <div className="pagina-length">
                            <table>
                                <tbody>
                                    <tr id="page-numbers">
                                    <td className="pagesize page_symbol" onClick={this.changePage.bind(this,"<<")}>&laquo;</td>
                                    <td className="pagesize page_symbol" onClick={this.changePage.bind(this,"<")}>&#x2039;</td>
                                    {renderPageNumbers}
                                    <td className="pagesize page_symbol" onClick={this.changePage.bind(this,">")}>&#8250;</td>
                                    <td className="pagesize page_symbol" onClick={this.changePage.bind(this,">>")}>&raquo;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                }
                </div>
                <AlertBox show={showAlertBox} showMessage={showMessage} 
                    click={this.hideModal} messageType={messageType}></AlertBox>

                <div className={classnames("upload-modal", openUploadExcel ? "upload-active-modal" : null)}>
                    <div className="upload-modal-content">
                        <div className="upload-modal-header">
                            <span className="upload-close" onClick={this.closeUploadExcel}>&times;</span>
                            <p className="upload-heading">Trips Bulk Upload</p>
                        </div>
                        <div className="upload-modal-body">
                            <div className="upload-location-div">
                              <div className="p-2">
                                <div class="position-relative">
                                  <abbr className="abbrCls" title="Download Trip Creation Template">
                                    <p className="sub-btn-div" onClick={this.downloadTemplate}>Trip Creation Template</p>
                                    <span className="fa fa-download fa-cust-download"></span>
                                  </abbr>
                                </div>
                                <div className="pt-3 pb-2">
                                  <label className="file">
                                    <input type="file" aria-label="Upload Excel" onChange={this.changeFile} 
                                      onClick={(event)=> {event.target.value = null}}
                                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                    </input>
                                    <span className={classnames("file-custom",this.state.errorFile?"file-custom-active":null)}>
                                      {this.state.excelFileName}</span>
                                  </label>
                                  <span className="red">*Allowed Only xls/xlsx file upto 5MB.</span>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="upload-modal-footer">
                            <div className="row">
                                <div className="btn col-md-4">
                                </div>
                                <div className="btn col-md-4">
                                    <input type="button" className='trip-btn' value="Upload"
                                    onClick={this.uploadExcel}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

const mapStateToprops = state =>{
    return{
        ctr:state.userdata.id,
        showTrip:state.sarathyReducer
        //ctr:{userid: 655}
    };
} 
const mapdispatchToprops = dispatch => {
  return {
      quicklinks:(data) => dispatch({type:'QUICKLINK', value:data}),
      navigateToHome:(data) => dispatch({type:'HOMEPAGE', value:data}),
  };
}
export default connect(mapStateToprops,mapdispatchToprops)(SarathyShowTrip);