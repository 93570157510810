import React from 'react';
import './createTrips.scss';
import Button from './button';
import CreateMap from './createTripMap';
import { postData, alphaNumericSpace, changeformatDate, changeTimeFormat, isDate,alphabetswithspace,numbers} from '../../Util';
import * as apilist from '../../apiList';
import Searchbox from './searchbox';
import RouteBox from './routeBox';
import CustomDatePicker from "./datepicker";
import { Link } from 'react-router-dom';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import EditHeader from './editHeader';
import Geocode from "react-geocode";
import { connect } from 'react-redux';
import AlertBox from '../AlertBox/alertBox';
import withNavigate from '../../hooks/withNavigate';

import classnames from 'classnames';

Geocode.setApiKey(apilist.GOOGLE_MAP_API_KEY);
Geocode.enableDebug();


const Placesoptions = {
    componentRestrictions: { country: "IN" },
    strictBounds: false,
  };

class CreateTrips extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trip_id: "",
            tripName: "",
            drivers: [],
            assignDriver: "",
            RegisteredVehicles: [],
            vehicleRegName: "",
            tripType: "",
            limit: 0.5,
            searchroute: "",
            routeName: "",
            route_id: "",
            assignDriverid : '',
            startDate: "",
            startMindate: "",
            startMaxdate: "",
            startTime: "",
            endDate: "",
            endMindate: "",
            endMaxdate: "",
            endTime: "",

            actualStartDate:"",
            actualEndDate:"",
            actualStartTime:"",
            actualEndTime:"",
            actualStartMindate:"",
            actualStartMaxdate:"",
            actualEndMindate:"",
            actualEndMaxdate:"",

            eventName: "",
            startPoint: "",
            destPoint: "",
            startPoint_places: "",
            destPoint_places: "",
            waypoints: [],
            startroute: "",
            endroute: "",
            start_location_latitude: "",
            start_location_longitude: "",
            end_location_latitude: "",
            end_location_longitude: "",
            //addStops: [{ id:0, addIndicator: true, deletekey: false, addstop: "" }],
            addStops: [{ id: "addstop1", value: "" }],
            addstop: "",
            addstop_places: "",
            tripDistance: "",
            tripValueList: [],
            filteredData: [],
            editTrip: "",
            disabledInd: false,
            noneditable: false,
            places: "",
            location: { lat: 20.5937, lng: 78.9629 },
            markerPosition: { lat: "", lng: "" },
            route_directions: {},
            temp_route_directions: {},
            routeIndex: 0,
            zoom: 4,
            editheaderInd: false,
            routeHideInd: false,
            alternateRoutes: [],
            activeRouteName: "Route 1",
            showDirection: false,
            showMarker: true,
            saveValue: "Create",
            startPointInd:true,
            traceData:[],
            showMessage:"",
            showAlertBox: false,
            messageType: "e",
            loader:false,
            trip_status :'',
            showRegnNumber : false,
            regNumber : '',
            showdriver : false,
            vehvinNum : '',plannedEndDate :""
        }
        this.baseState = this.state;
        //this.handlePlaces = this.handlePlaces.bind(this);
        this.handleRoutes = this.handleRoutes.bind(this);
        this.interval = setInterval(() => this.getTraceDetails(new Date), 60000);   
    }

    handleRoutes = (_State) => {
        this.setState({
            alternateRoutes: _State ? _State.directions.routes : []
        })
    }

    handlePlaces = (position,placeVals) => {
        let location = {};
        let waypoints = this.state.waypoints;
        let addStops = this.state.addStops;
        let zoom = 12;
        location.lat = placeVals.geometry.location.lat();
        location.lng = placeVals.geometry.location.lng();
        let route_directions = this.state.temp_route_directions;
        var startInd = true;

        if (this.state.eventName === "startPoint") {
            route_directions.startLat = placeVals.geometry.location.lat();
            route_directions.startLng = placeVals.geometry.location.lng();
            startInd = false;
        }
        else if (this.state.eventName === "destPoint") {
            route_directions.destLat = placeVals.geometry.location.lat();
            route_directions.destLng = placeVals.geometry.location.lng();
        } else {
            let location = { lat: "", lng: "" };
            location.lat = placeVals.geometry.location.lat();
            location.lng = placeVals.geometry.location.lng();

            waypoints.splice(position,1,location);
            //var selectedAddStop = this.search(this.state.eventName,addStops);
            //selectedAddStop.addstop1 = placeVals.formatted_address;
            addStops.map((addstop, index) => {
                return this.state.eventName === addstop.id ?
                    addStops[index].value = placeVals.formatted_address : addStops;
            })
        }

        this.setState({
            [this.state.eventName]: placeVals.formatted_address,
            [this.state.eventName + "_places"]: placeVals,
            addStops: addStops,
            zoom: zoom,
            location: location,
            markerPosition: location,
            routeIndex: 0,
            temp_route_directions: route_directions,
            route_directions: ('destLat' in route_directions && 'startLat' in route_directions) ? route_directions : {},
            waypoints: waypoints,
            startPointInd : startInd
        });

        if (this.state.startPoint_places && this.state.destPoint_places) {

            var origins = [];
            var startplace = this.state.startPoint_places;
            origins.push(
                (startplace.geometry.location.lat() + "," +
                    startplace.geometry.location.lng()).toString());

            var destinations = [];
            var destplace = this.state.destPoint_places;
            destinations.push(
                (destplace.geometry.location.lat() + "," +
                    destplace.geometry.location.lng()).toString());

            this.distanceCalculate(origins, destinations);
        }
    }

    tripNameApi = () =>{
        var tripData = {};
        let tokens = this.props.ctr.token;
        const tripName = postData(apilist.getTrip + '/getTripName', tripData,tokens);
        tripName.then((info) => {
            if (info.statuscode === 200) {
                this.setState({
                    tripName: info.data[0].trip_name
                })
            }
        });
    }
    callinpiufunbction1 =()=>{
        console.log(this)
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes1';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes1");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox,Placesoptions);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(0,placed)    
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);                                      
      }
      callinpiufunbction2 =()=>{
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes2';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes2");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox,Placesoptions);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(0,placed)
                console.log('Places',placed)
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);
    // this.setState({
    //     center: cen,
    //     markers: mark,
    //     zoomVal: 14
    // });                                        
      }

      callinpiufunbction3 =()=>{
        console.log(this)
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes3_0';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes3_0");
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox,Placesoptions);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(0,placed)
                console.log('Places',placed)
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);
    // this.setState({
    //     center: cen,
    //     markers: mark,
    //     zoomVal: 14
    // });                                        
      }

      iframeaddstops=(indexval)=>{
          if (indexval>0){
        var statevalue= this;
        var iframe,iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
        
      var script = iframe.contentWindow.document.createElement('script');
  
      var input = iframe.contentWindow.document.createElement('input');
      input.type= 'text';
      input.id='searchByLocationBoxes3_'+indexval;
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
            //google.maps.places = this.google.maps.places;
            var placed
            iframe_google = this.google;
            var searchLocationBox = document.getElementById("searchByLocationBoxes3_"+indexval);
            var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox,Placesoptions);
            autocomplete.addListener("place_changed", () => {
            placed = autocomplete.getPlace();
            statevalue.handlePlaces(indexval,placed)
                console.log('Places',placed)
            })
            
      };
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+apilist.GOOGLE_API_KEY+'&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
  
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };
    document.body.append(iframe);
}
      }

      getDistance2 = (resourceArray,val,endpoint,vProp) => {
        if(val !== ""){
            endpoint = val
        }
        let distanceVal = 0;
        if (resourceArray.length > 1 && endpoint > 0) {
          //EDC, IL => GPS ODO
          //EEA	=> Vehicle ODO
    
          let initialOdo = 0;
          if (vProp.is_bs6 === 1 || vProp.is_bs6 === 2) {
            let firstVal = resourceArray?.filter(obj => obj.vehdistance > 0);
            initialOdo = firstVal.length > 0 ? firstVal[0].vehdistance : 0;
          } else {
            if (vProp.vehicle_type.toUpperCase() === "EEA") {
              let firstVal = resourceArray?.filter(obj => obj.vehicleodo > 0);
              initialOdo = firstVal.length > 0 ? firstVal[0].vehicleodo : 0;
            } else {
              let firstVal = resourceArray?.filter(obj => obj.gpsodo > 0);
              initialOdo = firstVal.length > 0 ? firstVal[0].gpsodo : 0;
            }
          }
    
    
    
          // let initialOdo = vProp.vehicleType.toUpperCase() === "EEA" ? resourceArray[0].vehicleodo : resourceArray[0].gpsodo;
          let OdoVal = vProp.is_bs6 === 1 || vProp.is_bs6 === 2 ? resourceArray[endpoint].vehdistance : vProp.vehicle_type.toUpperCase() === "EEA" ? resourceArray[endpoint].vehicleodo : resourceArray[endpoint].gpsodo;
    
          OdoVal = OdoVal || 0;
          let prevOdoVal = vProp.is_bs6 === 1 || vProp.is_bs6 === 2 ? resourceArray[endpoint - 1].vehdistance : vProp.vehicle_type.toUpperCase() === "EEA" ? resourceArray[endpoint - 1].vehicleodo : resourceArray[endpoint - 1].gpsodo;
    
          let currObuId = resourceArray[endpoint].obuid,
            prevObuId = resourceArray[endpoint - 1].obuid;
    
          let prevDistVal = resourceArray[endpoint - 1].distance;
          if (!OdoVal) {
            distanceVal = 0;
            if (prevOdoVal) {
              OdoVal = prevOdoVal;
            } else {
              OdoVal = 0;
            }
          }
    
          if (currObuId !== prevObuId) {
    
            //resourceArray[indVal]
          }
    
          if (OdoVal < initialOdo) {
            //initialOdo=OdoVal;
            distanceVal = 0;
            let indVal = resourceArray.map(function (obj) { return obj.obuid; }).indexOf(currObuId);
            //          let startVal = vProp.vehicleType.toUpperCase() === "EEA" ? resourceArray[indVal].vehicleodo : resourceArray[indVal].gpsodo;
            if (indVal === endpoint) {
              //OdoVal=0+prevOdoVal;
              distanceVal = 0 + prevDistVal;
            } else {
              //let myVal=OdoVal-startVal;
              distanceVal = (OdoVal - prevOdoVal) + prevDistVal;
              //let prevstartVal = vProp.vehicleType.toUpperCase() === "EEA" ? resourceArray[indVal-1].vehicleodo : resourceArray[indVal-1].gpsodo;
              //OdoVal=myVal+prevstartVal;
              //distanceVal = 0;
            }
            //OdoVal = prevOdoVal;
            //distanceVal = OdoVal-initialOdo;
          } else {
    
            distanceVal = OdoVal - initialOdo;
            if (distanceVal <= 0) {
              //initialOdo=OdoVal;
              //prevDistVal
              //distanceVal = OdoVal-initialOdo;
              distanceVal = prevDistVal;
            }
          }
        }
        return distanceVal;
      }

      calcFuel = (resourceArray, endpoint) => {
        let fuelVal = 0;
    
        // let firstVal = resourceArray.filter(obj=> {
        //   if(!obj.fuelcons){
        //     obj.fuelcons=0;
        //   }
        //   return obj.fuelcons>0})[0].fuelcons;
    
        let firstVal = resourceArray.trace_points?.filter(obj => obj.fuelcons > 0);
        // if(firstVal.length>0) {
        //   firstVal=[0].fuelcons;
        // } else {
        //   firstVal=0
        // }
    
        firstVal = firstVal?.length > 0 ? firstVal[0].fuelcons : 0;
    
    
        if (firstVal && resourceArray.length > 1 && endpoint > 0) {
          if (resourceArray[endpoint].fuelcons) {
            fuelVal = resourceArray[endpoint].fuelcons - firstVal;
          } else {
            fuelVal = resourceArray[endpoint - 1].fuelVal;
          }
        } else {
          fuelVal = 0;
        }
        return fuelVal;
      }

      getTraceDetails = (timeval)=>{
        var today = new Date()
        if (this.props.location.state !== undefined && this.props.location.state !== null&& this.props.location.state.tripId ) {
            if((this.props.location.state.trip_status !== "Completed" && timeval !== "")|| timeval === ""){
            if((timeval !== "" && this.state.plannedEndDate > today.getHours() + ':' + today.getMinutes()) || timeval ===""){        
            let tripid = this.props.location.state.tripId;
            let trip_status_state = this.props.location.state.trip_status;
            var editData = {};
            editData.platform = "w";
            editData.tripId = tripid;
            let tokens = this.props.ctr.token;
            this.setState({loader:true})
            const tripValueList = postData(apilist.getTrip + '/singleTrip', editData,tokens);
            tripValueList.then((tripValue) => {
                if (tripValue.statuscode === 200) {
                    var tripData = tripValue.data.tripData;
                    tripData.trip_status_state = trip_status_state;
                    var routeData = tripValue.data.routeData;
                    var route_directions = {};
                    // Geocode.fromLatLng(routeData.start_location_latitude.toString(),
                    //     routeData.start_location_longitude.toString()).then(
                    //         response => {
                    //             this.setState({
                    //                 startPoint: response.results[0].formatted_address,
                    //                 trip_id: tripid,
                    //             })
                    //         },
                    //         error => {
                    //             console.error(error);
                    //         }
                    //     );
                    // Geocode.fromLatLng(routeData.end_location_latitude.toString(), 
                    //     routeData.end_location_longitude.toString()).then(
                    //         response => {
                    //             this.setState({
                    //                 destPoint: response.results[0].formatted_address,
                    //             })
                    //         },
                    //         error => {
                    //             console.error(error);
                    //         }
                    //     );
                    this.setState({startPoint: routeData.origin_location,destPoint: routeData.destination_location})
                    route_directions.startLat = routeData.start_location_latitude;
                    route_directions.startLng = routeData.start_location_longitude;
                    route_directions.destLat = routeData.end_location_latitude;
                    route_directions.destLng = routeData.end_location_longitude;
                    var latstart= []   
                    var lngstart= []
                    latstart.push(route_directions.startLat, route_directions.destLat)
                    lngstart.push(route_directions.startLng, route_directions.destLng)      
                    var waypoints = this.getWayPoints(routeData.way_points);
                    
                    let statVals = {};
                    if(tripData.actl_start_date===null || tripData.actl_end_date===null){
                    statVals.fromDate = tripData.plnnd_start_date;
                    statVals.toDate = tripData.plnnd_end_date;
                    statVals.fromTime = tripData.plnnd_start_time.substr(0,5);
                    statVals.toTime = tripData.plnnd_end_time.substr(0,5);
                    }else{
                    var actalstarttimesplit;
                    var actalendtimesplit;
                    actalstarttimesplit = tripData.actl_start_date.split("T");
                    actalendtimesplit = tripData.actl_end_date.split("T");
                    statVals.fromDate = actalstarttimesplit[0];
                    statVals.toDate =actalendtimesplit[0];
                    statVals.fromTime = actalstarttimesplit[1].substr(0,5);
                    statVals.toTime = actalendtimesplit[1].substr(0,5);
                    }  
                    if(timeval !== ""){
                        if(this.state.traceData.length){
                            let dataVal = this.state.traceData[this.state.traceData.length - 1]
                            let startDate = new Date(dataVal.localtimestamp)
                            statVals.fromDate = moment(dataVal.localtimestamp).format("YYYY-MM-DD")
                            statVals.fromTime = startDate.getHours() + ':' + startDate.getMinutes()
                            statVals.toTime = today.getHours() + ':' + today.getMinutes()
                            statVals.toDate =moment(today).format("YYYY-MM-DD")
                        }
                    }                  
                    //statVals.fromDate = "2021-04-10"
                    //statVals.toDate =  "2021-04-29"                   
                    
                    statVals.obuId = tripData.obu_id;
                    statVals.is_bs6 = tripData.is_bs6;
                    statVals.frequency = this.getFrequencyTrace(tripData);
                    statVals.vinNumberdetails = tripData.vin_number_trip;
                    statVals.ad_blue_cap =null;
                    statVals.fuel_tank_cap=null;
                    this.setState({plannedEndDate : tripData.plnnd_end_time})
                    if(timeval === "" ||( ( timeval !== "" && trip_status_state !== "Completed") && (timeval !== "" && tripData.plnnd_end_time > today.getHours() + ':' + today.getMinutes()))){
                    // if(timeval === "" && !( timeval !== "" && trip_status_state !== "Completed") && !(timeval !== "" && tripData.plnnd_end_time < today.getHours() + ':' + today.getMinutes())){
                        const datas = postData(apilist.getTrace, statVals,tokens);
                        datas.then((dataVals)=> {
                            if(dataVals?.statuscode === 200) {
                                console.log('dataVals.data.trace_points', dataVals.data.trace_points);
                                let finalData;
                                let traceData = dataVals.data.trace_points.map((datas,index) =>{
                                    datas.lat = datas.latitude;
                                    datas.lng = datas.longitude;
                                    datas.fuelVal = this.calcFuel(dataVals.data.trace_points, index);
                                    datas.status = trip_status_state
                                    if(timeval !== "" && trip_status_state !== "Completed"){
                                        finalData = this.state.traceData.concat(dataVals.data.trace_points)
                                        datas.distance = this.getDistance2(finalData, finalData.length-1, index, tripData);
                                    }
                                    else{
                                        datas.distance = this.getDistance2(dataVals.data.trace_points,"", index, tripData);
                                    }
                                    return datas;
                                });
                                if(timeval !== "" && trip_status_state !== "Completed"){
                                    // let finalData = this.state.traceData.concat(traceData)
                                    this.setState({
                                        traceData : finalData
                                    },()=>this.updatedData(finalData,latstart,lngstart))
                                }
                                else{
                                    this.setState({
                                        traceData : traceData
                                    },()=>this.updatedData(dataVals.data.trace_points,latstart,lngstart))
                                }
                            } else {
                                this.updatedData([],latstart,lngstart)
                            }
                        });
                    }
                    let actualStartDate = "", actualStartTime="", actualEndDate="",actualEndTime="";
                    if(tripData.actl_start_date !== null){
                        let actualStartDateArr = tripData.actl_start_date.split('T');

                        actualStartDate = new Date(actualStartDateArr[0]);
                        actualStartTime = new Date(actualStartDateArr[0]+" "+actualStartDateArr[1]);
                        if(tripData.actl_end_date !== null){
                            let actualEndDateArr = tripData.actl_end_date.split('T');
                            actualEndDate = new Date(actualEndDateArr[0]);
                            actualEndTime = new Date(actualEndDateArr[0]+" "+actualEndDateArr[1]);
                        }
                    }
                    
                    this.setState({
                        trip_status : trip_status_state,
                        disabledInd: tripData.trip_status_state === "Completed" ? true : false,
                        editheaderInd: true,
                        noneditable: true,
                        editTrip: tripData,
                        route_id: routeData.route_id_trip,

                        tripName: tripData.trip_name,
                        assignDriver: tripData.driver_name,
                        tripType:tripData.trip_type,
                        vehicleRegName: tripData.regn_number,
                        vehvinNum : tripData.vin_number_trip,
                        searchroute: routeData.route_name,
                        limit: routeData.route_deviation,
                        temp_route_directions: route_directions,
                        route_directions: route_directions,
                        waypoints:waypoints,
                        routeIndex: routeData.google_route_no,
                        saveValue:"Update",
                        //location:this.state.location,
                        startDate: new Date(tripData.plnnd_start_date),
                        endDate: new Date(tripData.plnnd_end_date),
                        startTime: new Date(tripData.plnnd_start_date + ' ' + tripData.plnnd_start_time),
                        endTime: new Date(tripData.plnnd_end_date + ' ' + tripData.plnnd_end_time),
                        endMindate : new Date(tripData.plnnd_start_date),
                        startMaxdate : new Date(tripData.plnnd_end_date),
                        actualStartDate: actualStartDate,
                        actualEndDate: actualEndDate,
                        actualStartTime: actualStartTime,
                        actualEndTime: actualEndTime,
                        actualStartMaxdate: actualEndDate,
                        actualEndMindate: actualStartDate,
                    })

                } else {
                    //alert("Failure");
                }
                this.setState({loader:false})
            })
            }
            }
        }
        
      }
    

    componentDidMount() {
        this.callinpiufunbction1();      
        this.callinpiufunbction2();
        this.callinpiufunbction3();     
        this.driverListApi();
        this.vehicleListApi();
        this.routeListApi();
        //this.tripNameApi();
        this.getTraceDetails("")
    }

    updatedData=(dV,a,b)=> {
        if(dV!== undefined){
        var finallat=[]
        var finallngs
        let propsData = dV;
        var lngs = propsData.trace_points?.map((datas)=>{
          return datas.longitude;
        });
        var lats = propsData.trace_points?.map((datas)=>{
          return datas.latitude;
        });
        finallat = lats?.concat(a);
        finallngs= lngs?.concat(b);
    //     this.setState({
    //       zoomToMarkers: map => {
    //         //debugger
    //         if(map && finallngs && finallat) {  
    //         map.fitBounds({
    //           west: Math.min.apply(null, finallngs),
    //           east: Math.max.apply(null, finallngs),
    //           north: Math.min.apply(null, finallat),
    //           south: Math.max.apply(null, finallat),
    //       });
    //     }
    //   }
    //   });
    }
    }
  
    getFrequencyTrace = (stat) =>{
        let sD = new Date(stat.plnnd_start_date), 
        eD = new Date(stat.plnnd_end_date), 
        sT = new Date(stat.plnnd_start_time), 
        eT = new Date(stat.plnnd_end_time);
    
        sD = new Date(sD).setHours(sT.getHours());
        sD = new Date(sD).setMinutes(sT.getMinutes());

        eD = new Date(eD).setHours(eT.getHours());
        eD = new Date(eD).setMinutes(eT.getMinutes());
        eD = new Date(eD);
        sD = new Date(sD);
        
        let dDiff = this.dateDiff(eD, sD);
        
        return this.getFrequency(dDiff);
    }

    dateDiff=(earlierDate, laterDate)=>{
        var oDiff = {};
        var nTotalDiff = laterDate.getTime() - earlierDate.getTime();
        oDiff.days = Math.round(nTotalDiff / 1000 / 60 / 60 / 24);
        oDiff.hours = Math.round(nTotalDiff / 1000 / 60 / 60);
        oDiff.minutes = Math.round(nTotalDiff / 1000 / 60);
        oDiff.seconds = Math.round(nTotalDiff / 1000);
        var hourtext = '00';
        if (oDiff.days > 0){ hourtext = String(oDiff.days);}
        if (hourtext.length === 1){hourtext = '0' + hourtext};
        var mintext = '00';
        if (oDiff.minutes > 0){ mintext = String(oDiff.minutes);}
        if (mintext.length === 1) { mintext = '0' + mintext };
        var sectext = '00';
        if (oDiff.seconds > 0) { sectext = String(oDiff.seconds); }
        if (sectext.length === 1) { sectext = '0' + sectext };
        var sDuration = hourtext + ':' + mintext + ':' + sectext;
        oDiff.duration = sDuration;
        return oDiff;
    }

    openRegnNumber = () =>{
        this.setState({
            showRegnNumber:true,
            vehicleRegName:""
        })
    }
    opendriverList = () =>{
        this.setState({
            showdriver : true,
            assignDriver : '',
            assignDriverid :''
        })
    }


    ChangeValueHandler = (event,validation) =>{
        var value = event.target.value;
        switch (validation){
            case "numberic":
                if(value && !numbers.test(value)){
                    return false;
                }
                break;
            case "alphaNumeric":
                if(value && !alphaNumericSpace.test(value)){
                    return false;
                }
                break;
            default:
                if(value && !alphabetswithspace.test(value)){
                    return false;
                }
                break;
        }
        this.setState({[event.target.name] : event.target.value});
    }

    getFrequency = (daycount) =>{
        var freq = daycount;
        daycount.days = Math.abs(daycount.days)
        var fr = ".5";
        if(freq.days > 3 && freq.days <= 5 ){
          fr = "5";
        } else if(freq.days > 5 && freq.days <= 10 ){
          fr = "7";
        } else if(freq.days > 10 && freq.days <= 15 ){
          fr = "10";
        } else if(freq.days > 15 && freq.days <= 30 ){
          fr = "14";
        } else if(freq.days > 30 && freq.days <= 45 ){
          fr = "20";
        } else if(freq.days > 45 && freq.days <= 60 ){
          fr = "30";
        } else if(freq.days > 60 && freq.days <= 90 ){
          fr = "45";
        } else if(freq.days >90 && freq.days <= 120 ){
          fr = "30";
        } else if(freq.days > 120 && freq.days <= 150 ){
          fr = "45";
        } else if(freq.days > 150 && freq.days <= 180 ){
          fr = "60";
        } 
        return fr;
    }

    driverListApi = () =>{
        var tripData = {};
        tripData.platform = "w";
        tripData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        const driverList = postData(apilist.getTrip + '/driverList', tripData,tokens);
        driverList.then((info) => {
            if (info.statuscode === 200) {
                this.setState({
                    drivers: info.data
                })
            } else {
                //alert("Failure");
            }
        });
    }

    vehicleListApi = () =>{
        var tripData = {};
        tripData.platform = "w";
        tripData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        const vehicleInfo = postData(apilist.getTrip + '/vehicleInfo', tripData,tokens);
        vehicleInfo.then((info) => {
            this.setState({
                RegisteredVehicles: info.data
            })
        })
    }

    routeListApi = () => {
        var routeData = {};
        routeData.platform = "w";
        routeData.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        const routeList = postData(apilist.getTrip + '/routeList', routeData,tokens);
        //const routeList = postData('http://10.18.1.128:7676/FMS/routeList', routeData);
        routeList.then((info) => {
            if (info.statuscode === 200) {
                this.setState({
                    tripValueList: info.data
                })
            } else {
                //alert("Failure");
            }
        })
    }

    onChangeTripInfo = (event) => {
        if(!alphaNumericSpace.test(event.target.value)){
            return false;
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeSearchMap = (event) =>{
        let eventname = event.target.name;

        this.setState({ 
            [event.target.name]: event.target.value,
            eventName: eventname 
        });
    }

    onChangeAddStop = (event,index) => {
        const thisVehicleIndex = index;
        let addStops = this.state.addStops;
        let eventname = event.target.name;

        addStops[thisVehicleIndex].value = event.target.value;
        this.setState({
            addStops: addStops,
            eventName: eventname
        })
    }

    incrementHandler = () => {
        if (this.state.disabledInd || this.state.routeHideInd) {
            return false
        }
        this.setState({
            limit: this.state.limit + 0.5 > 2 ? this.state.limit : this.state.limit + 0.5
        })
    }

    decrementHandler = () => {
        if (this.state.disabledInd || this.state.routeHideInd) {
            return false
        }
        this.setState({
            limit: this.state.limit - 0.5 < 0 ? this.state.limit : this.state.limit - 0.5
        })
    }

    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && ('administrative_area_level_2' || 'locality' === addressArray[i].types[0])) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };
    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    
    hideOptions = () =>{
        if(this.state.showRegnNumber || this.state.showdriver){
            this.setState({
                showRegnNumber: false,showdriver : false
            })
        }
    }
    

    composeRouteDataRequest = () => {
        if (this.state.routeName.trim() === "") {
            this.openModel("Route Name must be mandatory","e");
            return false;
        }
        else if (!alphaNumericSpace.test(this.state.routeName)) {
            this.openModel("Route Name must be valid","e");
            return false;
        }
        else if (this.state.startPoint.trim() === "" || !this.state.startPoint_places) {
            this.openModel("Start Point must be mandatory and valid","e");
            return false;
        } 
        else if (this.state.destPoint.trim() === "" || !this.state.destPoint_places) {
            this.openModel("Destination Point must be mandatory and valid","e");
            return false;
        }
        else if(this.state.limit < 0.5 || this.state.limit > 2){
            this.openModel("Deviation Limit must between 0.5KM and 2KM","e");
            return false;
        }
        else if (this.state.alternateRoutes.length===0){
            this.openModel("Please Select Start and End point from the dropdown options to find a correct Route","e");
            return false;
        }

        let startPoint_places = this.state.startPoint_places;
        let destPoint_places = this.state.destPoint_places;
        let add_places = this.state.waypoints;
        let tripDistance = this.state.alternateRoutes.map((name) => {
            var distanceTravelled = 0;
            for (var i = 0; i < name.legs.length; i++) {
                distanceTravelled += name.legs[i].distance.value;
            }
            return distanceTravelled /= 1000;
        });
        let routeData = {};
        routeData.platform = "w";
        routeData.origin_location = startPoint_places.formatted_address;
        routeData.destination_location = destPoint_places.formatted_address;
        routeData.route_name = this.state.routeName.trim();
        routeData.start_location_latitude = startPoint_places.geometry.location.lat();
        routeData.start_location_longitude = startPoint_places.geometry.location.lng();
        routeData.end_location_latitude = destPoint_places.geometry.location.lat();
        routeData.end_location_longitude = destPoint_places.geometry.location.lng();
        routeData.route_deviation = this.state.limit;

        var way_points = [];
        for (var i = 0; i < add_places.length; i++) {
            way_points.push(add_places[i].lat.toString() + ',' + add_places[i].lng.toString());
        }

        routeData.way_points = JSON.stringify(way_points);
        routeData.deviation_route_box = "";
        routeData.start_city = this.getCity(startPoint_places.address_components);
        routeData.end_city = this.getCity(destPoint_places.address_components);
        routeData.google_route_no = this.state.routeIndex;
        routeData.trip_distance = tripDistance[this.state.routeIndex].toFixed(2);
        routeData.owner_id_1 = this.props.ctr.userid;
        routeData.created_by1 = this.props.ctr.userid;
        routeData.is_deleted = false;
        routeData.bidirrouteflag = false;
        return routeData;
    }

    routeCreationHandler = () => {

        let routeData = this.composeRouteDataRequest();
        let tokens = this.props.ctr.token;
        if (routeData) {
            const datas = postData(apilist.getTrip + '/routeCreate', routeData,tokens);
            //const datas = postData('http://10.18.1.128:7676/FMS/routeCreate', routeData);
            datas.then((data) => {
                if (data.statuscode === 200) {
                    this.clearRoute();
                    this.openModel("Route is Created Successfully","s");
                    this.routeListApi();
                }
                else{
                    this.openModel(data.message,"e");
                }
            })
        }
    }

    tripCreationHandler = () => {
        let tripData = {};
        tripData.platform = "w";
        // if (this.state.tripName.trim() === "") {
        //     this.openModel("Trip Name must be Mandatory","e");
        //     return false;
        // }
        
        if (this.state.tripType === "") {
            this.openModel("Please Select Trip type","e");
            return false;
        }
        else if (this.state.vehicleRegName === "") {
            this.openModel("Please Select the Vehicle","e");
            return false;
        }
        // else if (this.state.assignDriver === "") {
        //     this.openModel("Please Select the Driver","e");
        //     return false;
        // }
        else if (this.state.startDate === "") {
            this.openModel("Please Select Planned Start Date","e");
            return false;
        }
        else if (this.state.startTime === "") {
            this.openModel("Please Select Planned Start Time","e");
            return false;
        }
        else if (this.state.endDate === "") {
            this.openModel("Please Select Planned End Date","e");
            return false;
        }
        else if (this.state.endTime === "") {
            this.openModel("Please Select Planned End Time","e");
            return false;
        }
        if (this.state.actualEndDate && !this.state.actualStartDate) {
            return this.openModel("Please Select Actual Start date","e");
        }
        if(this.state.actualEndTime  && !this.state.actualStartTime) {
            return this.openModel("Please Select Actual Start time","e");
        }

        if (this.state.route_id) {
            tripData.route_id_trip = this.state.route_id;
            tripData.route_details = {};
            tripData.actl_start_location_latitude = this.state.start_location_latitude;
            tripData.actl_start_location_longitude = this.state.start_location_longitude;
            tripData.actl_end_location_latitude = this.state.end_location_latitude;
            tripData.actl_end_location_longitude = this.state.end_location_longitude;
        } else {
            tripData.route_id_trip = null;
            tripData.route_details = this.composeRouteDataRequest();
            if (!tripData.route_details) {
                return false;
            }
            tripData.actl_start_location_latitude = null;
            tripData.actl_start_location_longitude = null;
            tripData.actl_end_location_latitude = null;
            tripData.actl_end_location_longitude = null;
        }
        tripData.driver_id_trip = this.state.assignDriverid ? this.state.assignDriverid.toString() : null;
        tripData.trip_type = this.state.tripType;
        tripData.trip_name = this.state.tripName;

        let startDate = changeformatDate(this.state.startDate);
        let startTime = changeTimeFormat(this.state.startTime);
        let endDate = changeformatDate(this.state.endDate);
        let endTime = changeTimeFormat(this.state.endTime);

        let formatted_startDate = new Date(startDate+" "+startTime);
        let formatted_endDate = new Date(endDate+" "+endTime);
        
        if(formatted_startDate >= formatted_endDate){
            this.openModel("Trip Planned End Date Should be greater than Planned Start Date","e");
            return false;
        }else{
            tripData.trip_status = this.tripStatus(formatted_startDate,formatted_endDate);
        }

        tripData.plnnd_start_date = startDate;
        tripData.plnnd_start_time = startTime;
        tripData.plnnd_end_date = endDate;
        tripData.plnnd_end_time = endTime;
        tripData.actl_start_date = null;
        tripData.actl_start_time = null;
        tripData.actl_end_date = null;
        tripData.actl_end_time = null;
        if(this.state.actualStartDate && this.state.actualStartTime){
            if (!this.state.actualEndDate) {
                return this.openModel("Please Select Actual End date","e");
            }
            if(!this.state.actualEndTime) {
                return this.openModel("Please Select Actual End time","e");
            }
            let actualendTime = null,actualendDate = null
            let actualstartDate = changeformatDate(this.state.actualStartDate);
            let actualstartTime = changeTimeFormat(this.state.actualStartTime);
            if (this.state.actualEndDate !== "" && this.state.actualEndDate !== null && this.state.actualEndTime === "") {
                this.openModel("Please Select Actual End Time","e");
                return false;
            }
            if( this.state.actualEndTime  && this.state.actualEndDate){
                actualendTime = changeTimeFormat(this.state.actualEndTime);
                actualendDate = changeformatDate(this.state.actualEndDate);
            }


            let formatted_actualstartDate = new Date(actualstartDate+" "+actualstartTime);
            let formatted_actualendDate = new Date(actualendDate+" "+actualendTime);
            
            if(formatted_actualstartDate >= formatted_actualendDate){
                this.openModel("Trip Actual End Date Should be greater than Actual Start Date","e");
                return false;
            }else{
                tripData.trip_status = this.tripStatus(formatted_actualstartDate,formatted_actualendDate);
            }

            tripData.actl_start_date = actualstartDate;
            tripData.actl_start_time = actualstartTime;
            tripData.actl_end_date = actualendDate;
            tripData.actl_end_time = actualendTime;
        }
        else if((!this.state.actualStartDate || !this.state.actualStartTime ||
                !this.state.actualEndDate || !this.state.actualEndTime) && 
                !(!this.state.actualStartDate && !this.state.actualStartTime &&
                !this.state.actualEndDate && !this.state.actualEndTime)){

             if (this.state.actualStartDate === "") {
                this.openModel("Please Select Actual Start Date","e");
                return false;
            }
            else if (this.state.actualStartTime === "") {
                this.openModel("Please Select Actual Start Time","e");
                return false;
            }
            // else if (this.state.actualEndDate === "") {
            //     this.openModel("Please Select Actual End Date","e");
            //     return false;
            // }
            else if (this.state.actualEndDate !== "" && this.state.actualEndDate !== null &&  this.state.actualEndTime === "") {
                this.openModel("Please Select Actual End Time","e");
                return false;
            }
            else if (this.state.alternateRoutes.length===0){
                this.openModel("Please Select Start and End point from the dropdown options to find a correct Route","e");
                return false;
            }            
        }

        let tripDistance = this.state.alternateRoutes.map((name) => {
            var travellingTime = 0;
            for (var i = 0; i < name.legs.length; i++) {
                travellingTime += name.legs[i].distance.value;
            }
            return travellingTime /= 1000;
        })
        if(tripDistance[this.state.routeIndex]!= undefined ||tripDistance[this.state.routeIndex]!= null){
            tripData.planned_trip_dis = tripDistance[this.state.routeIndex].toFixed(2);
        }
        else{
            tripData.planned_trip_dis = tripDistance[0];
        }
        tripData.actual_trip_dis = null;
        tripData.owner_id_trip = this.props.ctr.userid;
        tripData.created_id_trip = this.props.ctr.userid;
        
        tripData.remarks = "";
        let tripdataresponse = "";
        let tokens = this.props.ctr.token;
        this.setState({
            loader: true
        })
        if (this.state.trip_id) {
            tripData.trip_id = this.state.trip_id;
            tripData.vin_number_trip = this.state.vehvinNum
            tripdataresponse = postData(apilist.getTrip + '/updatetrip', tripData,tokens);
        } else {
            tripData.vin_number_trip = this.state.vehvinNum;
            tripdataresponse = postData(apilist.getTrip + '/tripCreate', tripData,tokens);
        }

        //const tripdataresponse = postData('http://10.18.1.115:8000/FMS/tripCreate', tripData);
        tripdataresponse.then((response) => {
            if (response.statuscode === 200) {
                this.clearTrip();
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.openModel(response.message,"s");
                this.driverListApi();
                this.vehicleListApi();
                this.routeListApi();
                if(this.state.addStops.length === 0){
                    this.setState({
                        addStops: [{ id: "addstop1", value: "" }],
                    })
                }
            } else{
                this.setState({
                    loader: false
                })
                this.openModel(response.message,"e");
                this.routeListApi();
            }
        })
    }

    tripStatus = (startDate,endDate) =>{
        var todayDate = new Date();
        if(todayDate < startDate && todayDate < endDate){
            return "Yet to start";
        }else if(todayDate > startDate && todayDate > endDate){
            return "Completed";
        }else{
            return "In progress";
        }
    }

    clearTrip = () => {
        this.setState(this.baseState);
        this.clearRoute();
    }

    callGeoCode = (lat, long, key) => {
        Geocode.fromLatLng(lat, long).then(
            response => {
                this.setState({
                    [key]: response.results[0].formatted_address,
                })
            },
            error => {
                console.error(error);
            }
        );
    }

    getWayPoints = (routePoints) =>{
        var waypoints = [];
        this.state.addStops.splice(0, this.state.addStops.length);
        for (var i = 0; i < routePoints.length; i++) {
            var waypointArr = routePoints[i].split(',');
            let location = { lat: "", lng: "" };
            location.lat = waypointArr[0];
            location.lng = waypointArr[1];
            waypoints.push(location);

            Geocode.fromLatLng(waypointArr[0], waypointArr[1]).then(
                response => {
                    var addStops = this.state.addStops;
                    addStops.push({
                        id: "addstop " + addStops ? addStops.length + 1 : 1,
                        value: response.results[0].formatted_address
                    })
                    this.setState({
                        addStops: addStops,
                    })
                },
                error => {
                    console.error(error);
                }
            );
        };
        return waypoints;
    }

    selectRouteHandler = (route) => {
        // this.callGeoCode(route.start_location_latitude.toString(),
        //     route.start_location_longitude.toString(), "startPoint");

        // this.callGeoCode(route.end_location_latitude.toString(),
        //     route.end_location_longitude.toString(), "destPoint");
        this.setState({startPoint: route.origin_location,destPoint: route.destination_location})
        
        var waypoints = this.getWayPoints(route.way_points);

        var route_directions = {};
        route_directions.startLat = route.start_location_latitude;
        route_directions.startLng = route.start_location_longitude;
        route_directions.destLat = route.end_location_latitude;
        route_directions.destLng = route.end_location_longitude;
        this.setState({
            start_location_latitude: route.start_location_latitude,
            start_location_longitude: route.start_location_longitude,
            end_location_latitude: route.end_location_latitude,
            end_location_longitude: route.end_location_longitude,
            routeIndex: route.google_route_no,
            route_directions: route_directions,
            searchroute: route.route_name,
            route_id: route.route_id,
            limit: route.route_deviation,
            waypoints: waypoints,
            filteredData: [],
            routeHideInd: true
        });
    }

    routeSearchHandler = event => {
        const query = event.target.value;
        const tripValueList = [...this.state.tripValueList];
        let newTripList = [];
        if (query !== "") {
            newTripList = tripValueList.filter(element => {
                return element.route_name.toLowerCase().includes(query.toLowerCase());
            })
        }

        this.setState({
            searchroute: query,
            filteredData: newTripList
        });
    }

    handleChange = (date, name) => {
        let mindate = "";
        let maxdate = "";
        let mindateValue = "";
        let maxDateValue = "";
        
        if (name === "startDate") {
            mindate = "endMindate";
            mindateValue = isDate(date) ? new Date(changeformatDate(date)) : "";
        } 
        else if (name === "endDate") {
            maxdate = "startMaxdate";
            maxDateValue = isDate(date) ? new Date(changeformatDate(date)) : "";
        } 
        else if (name === "actualStartDate"){
            mindate = "actualEndMinDate";
            mindateValue = isDate(date) ? new Date(changeformatDate(date)) : "";
        }
        else if(name === "actualEndDate"){
            maxdate = "actualStartMaxDate";
            maxDateValue = isDate(date) ? new Date(changeformatDate(date)) : "";
        }
        this.setState({
            [name]: date,
            [mindate]: mindateValue,
            [maxdate]: maxDateValue
        });
    };

    addstopHandler = () => {
        if (this.state.disabledInd || this.state.routeHideInd) {
            return false
        }
        var addstops = this.state.addStops;
        var length = this.state.addStops.length;
        if (addstops[length - 1].value.trim() === "") {
            this.openModel("Please Enter Above Add Stops First","e");
            return false;
        }
        else if (length < 8) {
            addstops.push(
                { id: "addstop" + (length + 1), value: "" }
            )
            this.iframeaddstops(length)
        }
        this.setState({
            addStops: addstops
        })
    }

    removestopHandler = (index) => {
        if (this.state.disabledInd || this.state.routeHideInd) {
            return false
        }
        var addStopsarray = [...this.state.addStops];
        var waypointsArr = [...this.state.waypoints];
        if (index) {
            addStopsarray.splice(index, 1);
            waypointsArr.splice(index, 1);
        } else {
            waypointsArr.splice(index, 1);
            addStopsarray[index].value = "";
        }
        this.setState({
            addStops: addStopsarray,
            waypoints: waypointsArr,
            markerPosition: ""
        });
    }

    // editTripHandler = () => {
    //     this.setState({
    //         disabledInd: false,
    //         saveValue: "Save Trip"
    //     });
    // }

    distanceCalculate = (origins, destinations) => {
        var distanceService = new window.google.maps.DistanceMatrixService();

        distanceService.getDistanceMatrix({
            origins: origins,
            destinations: destinations,
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
        }, (response, status) => {
            if (status !== window.google.maps.DistanceMatrixStatus.OK) {
                console.log('Error:', status);
            } else {
                //console.log(response)
                this.setState({
                    tripDistance: response.rows[0].elements[0].distance === undefined ? 0 : response.rows[0].elements[0].distance.text
                })
            }
        });
    }

    clearRoute = () => {
        var route_directions = {};
        route_directions.startLat = "";
        route_directions.startLng = "";
        route_directions.destLat = "";
        route_directions.destLng = "";
        this.setState({
            limit: 0.5,
            routeName: "",
            startPoint: "",
            destPoint: "",
            searchroute: "",
            route_id: "",
            addStops: [{ id: "addstop1", value: "" }],
            route_directions: route_directions,
            waypoints: [],
            routeHideInd: false
        });
    }

    alternateRouteHandler = (index) => {
        if (this.state.disabledInd || this.state.routeHideInd) {
            return false
        }
        this.setState({
            routeIndex: index,
            activeRouteName: "Route " + (index + 1)
        })
    }

    // openModel = (msg,error) =>{
    //     var message = msg;
    //     this.setState({
    //         showMessage:message
    //     })
    //     var modalcontent = document.getElementById("modal-content");

    //     if(error === "s"){
    //         modalcontent.classList.add("modal-content-success")
    //     }else {
    //         modalcontent.classList.add("modal-content-error")
    //     }
        
    //     var modal = document.getElementById("myModal");
    //     modal.style.display = "block";
    // }

    openModel = (message,msgType) => {
        this.setState({
            showAlertBox: true,
            showMessage:message,
            messageType:msgType
        });
    };

    hideModal = () => {
        this.setState({
            showMessage:"",
            showAlertBox: false 
        });
    };

    changeDriver = (driver) =>{
        if(driver){
            this.setState({
                assignDriver: driver.driver_id
            })
        }else{
            this.setState({
                assignDriver:""
            })
        }
    }

    changeVehicle = (event) =>{
        let vin_number = event.target.value;
        const vehicles = this.state.RegisteredVehicles.find( vehicle => vehicle.vin_number === vin_number);

        let driver = "";
        if(vehicles && vehicles.driver_id !== 0){
            driver = this.state.drivers.find( driver => driver.driver_id === vehicles.driver_id);
        }

        this.setState({
            vehicleRegName: vin_number,
            assignDriver: driver ? driver.driver_id : ""
        })
    }

    openCalender = (date) => {
        document.getElementById(date).focus();
    }

    passingVal=()=>{
        this.props.quicklinks([{mm:"3", sm:"13", tm:"0"}])
    }
    custDtls = (custDtls) =>{
        this.setState({
            showRegnNumber:false,
            vehicleRegName:custDtls.regn_number,
            vehvinNum : custDtls.vin_number
        }) 
    }
    driverDtls = (driverdtls)=>{
        //this.changeDriver();
        this.setState({
            showdriver:false,
            assignDriver:driverdtls.driver_name,
            assignDriverid : driverdtls.driver_id
        }) 
    }

    render() {
        
        const { saveValue, showMessage, showAlertBox, messageType,showRegnNumber,vehicleRegName,assignDriver,showdriver} = this.state;
        let customerDetailList = [],driverDetailList = []
        if(showRegnNumber){
            if(vehicleRegName == ""){
                if(this.state.RegisteredVehicles.length !== 0){
                    customerDetailList = this.state.RegisteredVehicles.map((custDtls,index)=>{
                        return <button key={index} onClick={this.custDtls.bind(this,custDtls)} 
                                className="dropdown-item"> 
                                {custDtls.regn_number}
                            </button>
                    });
                }
            }
            else{
                customerDetailList = this.state.RegisteredVehicles.map((custDtls, index) =>{
                    if(custDtls.regn_number.toUpperCase().indexOf(vehicleRegName.toUpperCase()) > -1){
                        return (<button key={index} 
                            onClick={this.custDtls.bind(this,custDtls)} 
                            className="dropdown-item"> {custDtls.regn_number}
                            </button>);
                    }
                });
            }
        }
        if(showdriver){
            if(assignDriver == ""){
                if(this.state.drivers.length !== 0){
                    driverDetailList = this.state.drivers.map((custDtls,index)=>{
                        return (<button key={index} onClick={this.driverDtls.bind(this,custDtls)} 
                                className="dropdown-item"> 
                                {custDtls.driver_name}
                            </button>);
                    });
                }
            }
            else{
                driverDetailList = this.state.drivers.map((custDtls, index) =>{
                    if(custDtls.driver_name.toUpperCase().indexOf(assignDriver.toUpperCase()) > -1){
                        return (<button key={index} 
                            onClick={this.driverDtls.bind(this,custDtls)} 
                            className="dropdown-item"> {custDtls.driver_name}
                            </button>);
                    }
                });
            }
        }

        var tripDetails = this.state.filteredData.map((route) => {
            return <Searchbox key={route.route_id} keyvalue={route.route_id}
                routeName={route.route_name}
                click={() => this.selectRouteHandler(route)} />
        });

        console.log(this.state.alternateRoutes);
        var routeDetails = this.state.alternateRoutes.map((name, index) => {
            var travellingTime = 0;
            var distance = 0;
            var routeName = "Route " + (index + 1);
            for (var i = 0; i < name.legs.length; i++) {
                travellingTime += name.legs[i].duration.value;
                distance += name.legs[i].distance.value;
            }
            
            var hours = Math.floor(travellingTime / 3600);
            var mins = Math.floor(travellingTime % 3600 / 60);
        
            var hDisplay = hours > 0 ? hours + (hours === 1 ? " hr " : " hrs ") : "";
            var mDisplay = mins > 0 ? mins + (mins === 1 ? " min " : " mins ") : "";

            // var minutes = (travellingTime/60) % 60;
            // var hours = (travellingTime - minutes) / 60;

            let timeinHrs = hDisplay + mDisplay;
            distance /= 1000;

            return <RouteBox key={index} index={index} routeName={routeName}
                travellingTime={timeinHrs}
                distance={distance.toFixed(2)} activeRouteName={this.state.activeRouteName}
                click={this.alternateRouteHandler.bind(this, index)} />
        });

        var addStops = this.state.addStops.map((name, index) => {
            return <div data-standalone-searchbox="" className={classnames("form-group" ,"col-lg-11")}><input
            key = {index}
            item={index}
            type="text"
            id={'searchByLocationBoxes3_'+index}
            className="form-control custom-control pl-lg-4 pac-target-input"
            placeholder="Add stops in between"
            disabled={this.state.noneditable || this.state.routeHideInd}
            // onChange={this.handlePlaces.bind(this,0)} 
            onChange={(event)=>this.onChangeAddStop(event,index)} 
            // onClick={}
            name={"addstop" + (index + 1)} 
            value={name.value}
            className="form-control custom-control pl-lg-4"
            />
            <span onClick={this.addstopHandler}
                    className="fa fa-plus-circle fa-custom-ellipsis fa-custom-plus-circle">
                </span>
                {
                    index + 1 === this.state.addStops.length ?
                        <span onClick={this.removestopHandler.bind(this, index)}
                            className="fa fa-close fa-custom-close">
                        </span> : null
                }
           </div>
                
            // <AutoCompleteContent key={index} name={"addstop" + (index + 1)} value={name.value}
            //     change={this.onChangeAddStop} item={index}
            //     className="form-control custom-control pl-lg-4"
            //     placeholder="Add stops in between" disabled={this.state.noneditable || this.state.routeHideInd}
            //     placeVals={this.handlePlaces.bind(this,index)}>
            //     <span onClick={this.addstopHandler}
            //         className="fa fa-plus-circle fa-custom-ellipsis fa-custom-plus-circle">
            //     </span>
            //     {
            //         index + 1 === this.state.addStops.length ?
            //             <span onClick={this.removestopHandler.bind(this, index)}
            //                 className="fa fa-close fa-custom-close">
            //             </span> : null
            //     }
            // </AutoCompleteContent>
        });

        var statuscolor = (trip) => {
            switch (trip.trip_status_state) {
                case "Cancelled":
                    return <EditHeader trip={trip} statusColor={"statusCancelled"} />
                case "In progress":
                    return <EditHeader trip={trip} statusColor={"statusProgress"} />
                case "Completed":
                    return <EditHeader trip={trip} statusColor={"statusCompleted"} />
                default:
                    return <EditHeader trip={trip} statusColor={"statusYetstart"} />
            }
        }

        return (
            <div onClick={this.hideOptions}>
                {this.state.loader ?<div className="Pageloading"> <img className="loadimg" src="/images/loader_1.gif" alt="iAlert" /></div> :''}
                {this.state.editheaderInd ?
                    <div className="edit-box col-lg-12">
                        <div className="createtripcontainer row">
                            <div className="back_fa">
                                <Link onClick={this.passingVal} to="/tripList">
                                    {/* <span className="fa fa-arrow-left fa-lg"></span> */}
                                    <img src="/images/TripList/back.png" alt=""></img>
                                </Link>
                            </div>
                            {
                                statuscolor(this.state.editTrip)
                            }
                            
                        </div>
                    </div> : null
                }
                <div className="createtripcontainer row">
                    <div className="col-lg-5 rowMargin">
                        <div className="tripHeaderDiv">
                            <p className="tripHeader mb-4">Trip Details</p>
                            <div className="row">
                                <div className="form-group col-lg-5">
                                    <input id="tripName" type="text" className="form-control"
                                        disabled={this.state.noneditable & this.state.trip_status == "Completed"}
                                        name="tripName" value={this.state.tripName} maxLength="100"
                                        onChange={this.onChangeTripInfo} autoComplete="off" required></input>
                                    <label className="form-control-placeholder" 
                                        htmlFor="tripName">Enter Trip Name
                                        {/* <span className="red">*</span> */}
                                    </label>
                                </div>

                                <div className="form-group form-custom-group-height col-lg-5">
                                    <select name="tripType" value={this.state.tripType}
                                        disabled={this.state.disabledInd} onChange={this.onChangeTripInfo}
                                        className="form-control cursoralign">
                                        <option value="">--- Select Trip Type ---</option>
                                        <option value="One-way">One-way</option>
                                        <option value="Round Trip">Round Trip</option>
                                    </select>
                                    <label className="form-control-placeholder"
                                        htmlFor="tripType">Trip Type
                                    <span className="red">*</span></label>
                                </div>

                                <div className="form-group col-lg-5 autocompleteTrip">
                                    {/* <select name="vehicleRegName" value={this.state.vehicleRegName}
                                        disabled={this.state.disabledInd} onChange={this.changeVehicle}
                                        className="form-control">
                                        <option value="">--- Select Vehicle ---</option>
                                        {this.state.RegisteredVehicles.map(vehicle => <option key={vehicle.vin_number}
                                            value={vehicle.vin_number}>{vehicle.regn_number}</option>)}
                                    </select> */}
                                    <input type="text" className="form-control cursoralign"
                                        name="vehicleRegName" disabled={this.state.disabledInd}
                                        value={this.state.vehicleRegName} maxLength="17"
                                        onChange={(e) => this.ChangeValueHandler(e,"alphaNumeric")} onClick={this.openRegnNumber}
                                        autoComplete="off" required></input>
                                    <label className="form-control-placeholder"
                                        htmlFor="vehicleRegName">Vehicle Registration Number
                                    <span className="red">*</span></label>
                                    <span onClick={this.openRegnNumber} className="fa fa-caret-down select-fa-custom"></span>
                                    <div className={!showRegnNumber ? "dropdown-menu dropdown-menu-menu" :"dropdown-menu dropdown-menu-menu showfilterDropdown vas-Dropdown"}>{customerDetailList}</div>
                                </div>  

                                <div className="form-group col-lg-5 autocompleteTrip">
                                    {/* <select name="assignDriver" disabled={this.state.disabledInd}
                                        value={this.state.assignDriver} onChange={this.onChangeTripInfo}
                                        className="form-control">
                                        <option value="">--- Select Driver ---</option>
                                        {this.state.drivers.map((driver,index) => <option key={index}
                                            value={driver.driver_id}>{driver.driver_name}</option>)}
                                    </select> */}
                                    <input type="text" className="form-control"
                                        name="assignDriver" disabled={this.state.disabledInd}
                                        value={this.state.assignDriver} maxLength="17" onClick={this.opendriverList}
                                        onChange={(e) => this.ChangeValueHandler(e,"alphaNumeric")}
                                        autoComplete="off" required></input>
                                    <label className="form-control-placeholder"
                                        htmlFor="assignDriver">Assign Driver
                                    {/* <span className="red">*</span> */}
                                    </label>
                                    <span onClick={this.opendriverList} className="fa fa-caret-down select-fa-custom"></span>
                                    <div className={!showdriver ? "dropdown-menu dropdown-menu-menu" :"dropdown-menu dropdown-menu-menu showfilterDropdown vas-Dropdown"}>{driverDetailList}</div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <p className="tripHeader mb-4">Route Details <span className="red">*</span></p>
                            <div className="form-group col-lg-11">
                                <input id="routeNameSearch" type="text" disabled={this.state.disabledInd} className="form-control"
                                    value={this.state.searchroute} onChange={this.routeSearchHandler}
                                    autoComplete="off" required></input>
                                <label className="form-control-placeholder-search" htmlFor="routeNameSearch">Search Route Name</label>
                                {
                                    this.state.routeHideInd ? <span onClick={this.clearRoute} className="fa fa-times form-times-control"></span> : null
                                }
                                <span className="fa fa-search form-control-search">search</span>
                            </div>
                            <div className="row searchrow">
                                {tripDetails}
                            </div>
                        </div>
                        <div className="routeInfo">
                            <p className="tripsubHeader">Add New Route</p>
                            <div data-standalone-searchbox="" className={classnames("form-group" ,"col-lg-11")}>
                                <input
                                type="text"
                                id='searchByLocationBoxes1'
                                className="form-control custom-control pl-lg-4"
                                placeholder="Enter Start Location"
                                disabled={this.state.noneditable || this.state.routeHideInd}
                                onChange={this.onChangeSearchMap}
                                // onChange={this.handlePlaces.bind(this,0)}
                                name="startPoint" 
                                value={this.state.startPoint}
                                className="form-control custom-control pl-lg-4"
                                />
                                <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                                </div>
                                <div data-standalone-searchbox="" className={classnames("form-group" ,"col-lg-11")}>
                            <input
                                type="text"
                                id='searchByLocationBoxes2'
                                className="form-control custom-control pl-lg-4"
                                placeholder="Enter End Location"
                                disabled={this.state.noneditable || this.state.routeHideInd}
                                // onChange={this.handlePlaces.bind(this,0)} 
                                onChange={this.onChangeSearchMap}
                                name="destPoint" 
                                value={this.state.destPoint}
                                className="form-control custom-control pl-lg-4"
                                />
                                <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                                </div>
                                
                            {/* <AutoCompleteContent name="startPoint" value={this.state.startPoint}
                                change={this.onChangeSearchMap}
                                className="form-control custom-control pl-lg-4"
                                placeholder="Enter Start Location"
                                placeVals={this.handlePlaces.bind(this,0)} 
                                disabled={this.state.noneditable || this.state.routeHideInd}>
                                <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                            </AutoCompleteContent> */}
                            {/* <AutoCompleteContent name="destPoint" value={this.state.destPoint} */}
                            {/* //     change={this.onChangeSearchMap}
                            //     className="form-control custom-control pl-lg-4"
                            //     placeholder="Enter End Location"
                            //     placeVals={this.handlePlaces.bind(this,0)} 
                            //     disabled={this.state.noneditable || this.state.routeHideInd}>
                            //     <span className="fa fa-ellipsis-v fa-custom-ellipsis"></span>
                            // </AutoCompleteContent> */}
                            {
                                addStops
                            }
                            {this.state.noneditable || this.state.routeHideInd ? null :
                                <div className="form-group route-form-group col-lg-11 mt-lg-2">
                                    <input type="text" id="routeName" className="form-control custom-control" maxLength="100"
                                        placeholder="Enter Route Name" name="routeName" value={this.state.routeName}
                                        onChange={this.onChangeTripInfo} autoComplete="off" required>
                                    </input>
                                </div>
                            }
                            {/* <div>
                                <p className="tripsubHeader">Deviation Limit</p>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-9 quantity">
                                            <input type="button" className="btn quantity__minus" value="-"
                                                disabled={this.state.noneditable || this.state.routeHideInd}
                                                onClick={this.decrementHandler} />
                                            <input name="limit" type="number"
                                                disabled={this.state.noneditable || this.state.routeHideInd}
                                                className="quantity__input" step="1" min="0.5" max="2" 
                                                required autoComplete="off"
                                                value={this.state.limit} onChange={this.onChangeTripInfo}></input>
                                            <input type="button" className="btn quantity__plus" value="+"
                                                disabled={this.state.noneditable || this.state.routeHideInd}
                                                onClick={this.incrementHandler} />
                                            <p className="quantity_text">Kilometers</p>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div> */}
                            {this.state.noneditable || this.state.routeHideInd ? null :
                                            <div className="btn col-lg-2">
                                                <Button value="Save" btnImg="save-btn" click={this.routeCreationHandler} />
                                            </div>
                                        }
                        </div>
                        <div className="tripschedule">                       
                            <p className="tripHeader mb-4 mt-4">Planned Trip Schedule</p>
                            <div className="row">
                                <CustomDatePicker
                                    disabled={this.state.disabledInd}
                                    value={this.state.startDate}
                                    change={date => this.handleChange(date, "startDate")}
                                    datepicker={true}
                                    showTimeSelect={false}
                                    showTimeSelectOnly={false}
                                    minDate={new Date()}
                                    maxDate={this.state.startMaxdate}
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Planned Start Date"
                                    id="startdate"
                                    spanClick={this.openCalender.bind(this,'startdate')}
                                />
                                <CustomDatePicker
                                    disabled={this.state.disabledInd}
                                    value={this.state.endDate}
                                    change={date => this.handleChange(date, "endDate")}
                                    datepicker={true}
                                    showTimeSelect={false}
                                    showTimeSelectOnly={false}
                                    minDate={this.state.endMindate}
                                    maxDate={this.state.endMaxdate}
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Planned End Date"
                                    id="endate"
                                    spanClick={this.openCalender.bind(this,'endate')}
                                />
                                <CustomDatePicker
                                    disabled={this.state.disabledInd}
                                    value={this.state.startTime}
                                    change={date => this.handleChange(date, "startTime")}
                                    datepicker={false}
                                    showTimeSelect={true}
                                    showTimeSelectOnly={true}
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                    placeholderText="Planned Start Time"
                                    id="starttime"
                                    spanClick={this.openCalender.bind(this,'starttime')}
                                />
                                <CustomDatePicker
                                    disabled={this.state.disabledInd}
                                    value={this.state.endTime}
                                    change={date => this.handleChange(date, "endTime")}
                                    datepicker={false}
                                    showTimeSelect={true}
                                    showTimeSelectOnly={true}
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                    placeholderText="Planned End Time"
                                    id="endtime"
                                    spanClick={this.openCalender.bind(this,'endtime')}
                                />
                            </div>

                            <p className="tripHeader mb-4 mt-4">Actual Trip Schedule</p>
                            <div className="row">
                                <CustomDatePicker
                                    value={this.state.actualStartDate}
                                    change={date => this.handleChange(date, "actualStartDate")}
                                    datepicker={true}
                                    showTimeSelect={false}
                                    showTimeSelectOnly={false}
                                    minDate={this.state.actualStartMinDate}
                                    maxDate={this.state.actualStartMaxDate}
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Actual Start Date"
                                    mandatory ="false"
                                    id="actualStartDate"
                                    spanClick={this.openCalender.bind(this,'actualStartDate')}
                                />
                                <CustomDatePicker
                                    value={this.state.actualEndDate}
                                    change={date => this.handleChange(date, "actualEndDate")}
                                    datepicker={true}
                                    showTimeSelect={false}
                                    showTimeSelectOnly={false}
                                    minDate={this.state.actualEndMinDate}
                                    maxDate={this.state.actualEndMaxDate}
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Actual End Date"
                                    mandatory ="false"
                                    id="actualEndDate"
                                    spanClick={this.openCalender.bind(this,'actualEndDate')}
                                />
                                <CustomDatePicker
                                    value={this.state.actualStartTime}
                                    change={date => this.handleChange(date, "actualStartTime")}
                                    datepicker={false}
                                    showTimeSelect={true}
                                    showTimeSelectOnly={true}
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                    placeholderText="Actual Start Time"
                                    mandatory ="false"
                                    id="actualStartTime"
                                    spanClick={this.openCalender.bind(this,'actualStartTime')}
                                />
                                <CustomDatePicker
                                    value={this.state.actualEndTime}
                                    change={date => this.handleChange(date, "actualEndTime")}
                                    datepicker={false}
                                    showTimeSelect={true}
                                    showTimeSelectOnly={true}
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                    placeholderText="Actual End Time"
                                    mandatory ="false"
                                    id="actualEndTime"
                                    spanClick={this.openCalender.bind(this,'actualEndTime')}
                                />
                            </div>
                            
                            <div className="container">
                                {
                                    //this.state.disabledInd ? null : 
                                    <div className="row">
                                        <div className="col-md-9"></div>
                                        <div className="btn col-md-2">
                                            <input type="button" value={saveValue} className="trip-btn" disabled={this.state.trip_status == "Completed"} value={saveValue} onClick={this.tripCreationHandler.bind(this)} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 rowMargin" style={{top : '-58px'}}>
                        <CreateMap center={this.state.location} handleRoutes={this.handleRoutes}
                            markerPosition={this.state.markerPosition} routeIndex={this.state.routeIndex}
                            height='100vh' route_directions={this.state.route_directions}
                            zoom={this.state.zoom} waypoints={this.state.waypoints}
                            showDirection={this.state.showDirection} showMarker={this.state.showMarker}
                            traceData ={this.state.traceData}
                            zoomToMarkers={ this.state.zoomToMarkers }></CreateMap>
                           
                        {
                            this.state.noneditable || this.state.routeHideInd ? null :
                            <div className="row routerow">
                                {this.state.alternateRoutes && routeDetails}
                            </div>
                        }
                        
                    </div>
                    <div className="col-lg back_fav">
                        <Link onClick={this.passingVal} to="/tripList">
                            <img src="/images/TripList/Close.png" alt=""></img>
                            {/* <span className="fa fa-times fa-lg"></span> */}
                        </Link>
                    </div>
                </div>
                <AlertBox show={showAlertBox} showMessage={showMessage} 
                    click={this.hideModal} messageType={messageType}></AlertBox>
            </div>
        );
    }
}

const mapStateToprops = state => {
    return {
        ctr:state.userdata.id
        //ctr: { userid: 655 }
    };
}
const mapdispatchToprops = dispatch => {
    return {
        quicklinks:(data) => dispatch({type:'QUICKLINK', value:data})
    };
}
export default withNavigate(connect(mapStateToprops, mapdispatchToprops)(CreateTrips));