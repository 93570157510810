import React from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../apiList";

// const MapBox = compose(
//   withProps({
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+GOOGLE_MAP_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `100vh` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   lifecycle({
//     componentDidMount() {
      
//     },
//     componentWillReceiveProps(nextProps){
//       if(nextProps.route_directions
//         || nextProps.routeIndex !== this.props.routeIndex){
//         const DirectionsService = new window.google.maps.DirectionsService();
//         if('startLat' in nextProps.route_directions && 'destLat' in nextProps.route_directions){
//           DirectionsService.route({
//             origin: new window.google.maps.LatLng(nextProps.route_directions.startLat, nextProps.route_directions.startLng),
//             destination: new window.google.maps.LatLng(nextProps.route_directions.destLat, nextProps.route_directions.destLng),
//             travelMode: window.google.maps.TravelMode.DRIVING,
//             provideRouteAlternatives: true,
//             optimizeWaypoints:true,
//           }, (result, status) => {
//             if (status === window.google.maps.DirectionsStatus.OK) {
//               this.setState({
//                 directions: result,
//                 showDirection:true,
//                 showMarker:false
//               },()=>{
//                 this.props.handleRoutes(this.state)
//               });
//             } else {
//               console.error(`error fetching directions ${result}`);
//               this.setState({
//                 directions: result,
//                 showDirection: false,
//               },()=>{
//                 this.props.handleRoutes("")
//               });
//             }
//           });
//         }else{
//           this.setState({
//             showDirection:false
//           })
//         }
//       }
//     },
//   })
// )(props =>
 
//   <GoogleMap
//     zoom={props.zoom}
//     center={props.center}
//     onClick={ props.click }
//   > 
//   { props.markerPosition && !props.showDirection && <Marker position={props.markerPosition} /> }
//   { props.showDirection ? <DirectionsRenderer directions={props.directions} 
//       routeIndex={props.routeIndex}/> : null}
//   </GoogleMap>
// );

const MapBox = ({
  zoom,
  center,
  markerPosition,
  handleRoutes,
  route_directions,
  routeIndex,
  showDirection,
  click,
}) => {
  const [directions, setDirections] = useState(null);
  const [showMarker, setShowMarker] = useState(true);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["geometry", "drawing", "places"],
  });

  useEffect(() => {
    if (route_directions?.startLat && route_directions?.destLat) {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(
            route_directions.startLat,
            route_directions.startLng
          ),
          destination: new window.google.maps.LatLng(
            route_directions.destLat,
            route_directions.destLng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: true,
          optimizeWaypoints: true,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            handleRoutes({ directions: result, showDirection: true, showMarker: false });
            setShowMarker(false);
          } else {
            console.error(`Error fetching directions ${result}`);
            setDirections(result);
            handleRoutes("");
          }
        }
      );
    } else {
      setDirections(null);
      setShowMarker(true);
    }
  }, [route_directions, routeIndex, handleRoutes]);

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap zoom={zoom} center={center} onClick={click} mapContainerStyle={{ height: "100vh", width: "100%" }}>
      {/* Marker */}
      {markerPosition && showMarker && <Marker position={markerPosition} />}

      {/* Directions Renderer */}
      {showDirection && directions && <DirectionsRenderer directions={directions} routeIndex={routeIndex} />}
    </GoogleMap>
  );
};


class CreateMap extends React.Component{

  constructor(props){
    super(props);
    this.handleRoutes = this.handleRoutes.bind(this);
  }

  handleRoutes = (_State) =>{
    this.props.handleRoutes(_State);
  }

  render(){
    return <MapBox zoom={this.props.zoom} center={this.props.center}  
              markerPosition={this.props.markerPosition} height={this.props.height}
              handleRoutes={this.handleRoutes} route_directions={this.props.route_directions}
              waypoints={this.props.waypoints} routeIndex={this.props.routeIndex}
              showDirection={this.props.showDirection} showMarker={this.props.showMarker}
              click={this.props.click }>
           </MapBox>
  }
}

export default CreateMap;