import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { postData } from './Util';
import * as apilist from './apiList';
import { connect } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history';
import * as actionTypes from './store/constants';
import AsyncComponent from './AsyncComponent';
import TripList from './Components/TripList';
import CreateTrips from "./Components/CreateTrips";
import FleetAlerts from "./Components/FleetAlerts/fleetAlerts"
import Rawdata from "./Pages/Rawdata/rawdataScreen";
import DashboardHeader from './Components/DashboardHeader/index';
import SarathyShowTrip from './Components/SarathyShowTrip/SarathyShowTrip';
import SarathyCreateTrip from './Components/SarathyCreateTrip/SarathyCreateTrip';
import ErrorBoundary from './Components/ErrorBoundry/ErrorBoundry';
import { IdleTimeOutModal } from './Components/IdlBox/idlbox';
import { Prompt } from 'react-router';
import { getToken, onMessageListener, requestPermission } from './firebase';
import { messaging } from './firebase/firebaseConfig';
import ShowToast from './Components/ShowToast/ShowToast';
import AppRouters from './Routes';
import { Loader } from './Pages/Tracking/Loader';
import withNavigate from './hooks/withNavigate';
import { withRouter } from './Components/withNavigateBlocker';

// import './main.scss';

const termsAndConditionRoutePath = "/termsandconditions";
const switchTermsAndConditionsRoutePath = '/iontermsandconditions'

const history = createHistory();

const AppWithFallback = (props) => (
  <React.Suspense fallback={<Loader />}>
    <AppRouters props={props} />
  </React.Suspense>
);

export class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    console.log('props.location', props.location);
    this.state = {
      timeout: 1140000,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      reload: ''
    }
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.onUnload = this.onUnload.bind(this);
    sessionStorage.reload = true;
    if (window.location.href.indexOf("/success") === -1) {
      window.localStorage.clear();
    }
  }
  _onAction() {
    // console.log('user did something', e)
    this.setState({ isTimedOut: false })
    clearInterval(this.settimerscnt)
  }

  _onActive() {
    // console.log('user is active', e)
    clearInterval(this.settimerscnt)
    this.setState({ isTimedOut: false })
  }

  _onIdle() {
    // console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (history.location.pathname !== '/login' && history.location.pathname !== '/' && history.location.pathname !== 'camportal') {
      if (isTimedOut) {
        this.setState({ showModal: false })
        setTimeout(() => {
          history.push('/login')
        }, 100)
      } else {
        this.setState({ showModal: true })
        this.idleTimer.reset();
        this.setState({ isTimedOut: true })
        this.idltimeoutbox()
      }
    }
  }

  idltimeoutbox = () => {
    this.settimerscnt = 0;
    this.settimerscnt = setInterval(() => {
      this.setState({ showModal: false })
      this.setState({ isTimedOut: false })
      history.push('/login')
    }, 60000)


  }
  handleClose() {
    this.setState({ showModal: false })
    clearInterval(this.settimerscnt)
  }

  handleLogout() {
    this.setState({ showModal: false })
    window.location.reload()
  }
  onUnload() {
    localStorage.reload = true;
  }
  // Handle foreground FCM messages
  handleForegroundMessage = (payload) => {
    console.log('Message received in foreground: ', payload);
    alert(`Foreground Message: ${payload.notification.title}`);
    if (payload !== false) {
      this.setState({
        showToastBox: true,
        notificationTitle: payload.notification.title,
        notifcationBody: payload.notification.body
      });
    }
  };

  // Handle background FCM messages (for when the app is not in the foreground)
  handleBackgroundMessage = (payload) => {
    console.log('Background Message received: ', payload);
    // Handle background notifications here (e.g., show a notification)
    // You could also use the Notification API to show a system notification.
    if (payload !== false) {
      this.setState({
        showToastBox: true,
        notificationTitle: payload.notification.title,
        notifcationBody: payload.notification.body
      });
    }
  };


  componentDidMount() {

    try {
      requestPermission().then((permissionGranted) => {
        if (permissionGranted) {
          this.pushNotificationSunscribe();
        }
      });
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { title, body } = event.data.notification;
        console.log("title ",  title, body)
        this.setState({
          showToastBox: true,
          notificationTitle: title,
          notifcationBody: body
        });
      });

    } catch (error) {
        console.error('Permission request failed:', error);
    }
    window.addEventListener('beforeunload', this.handleLeavePage.bind(this));
    window.addEventListener("unload", this.successful_listener.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage.bind(this));
    window.addEventListener("unload", this.successful_listener.bind(this));
  }

  successful_listener = () => {
    let vehtoken = document.cookie;
    let dataObj = {};
    const datas = window.fetch(apilist.userLogout, {
      'method': 'POST',
      'Accept': '*',
      'Access-Control-Allow-Origin': '*',
      "headers": {
        'Content-Type': 'application/json',
        "Authorizationl": vehtoken
      },
      'body': JSON.stringify(dataObj)
    });
    return datas.json();
  }

  handleLeavePage = (e) => {
    var el = document.getElementsByClassName('logoutbox')[0];
    if (this.props.location.pathname !== '/success' && this.props.location.pathname !== '/billingmodule/packages') {
      document.cookie = this.props.ctr.token;
      if (!el) {
        const confirmationMessage = 'Changes you made may not be saved.';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    }
  }

  pushNotificationSunscribe = () => {
    onMessageListener()
      .then(payload => {
        console.log('payload received:', payload);  // Log payload to confirm
        if (payload !== false) {
          this.setState({
            showToastBox: true,
            notificationTitle: payload.notification.title,
            notifcationBody: payload.notification.body
          }, () => {
            this.pushNotificationSunscribe();
          });
        }
      })
      .catch(err => console.error('Failed to subscribe to push notifications:', err));
  };

  onToastClose = () => {
    this.setState({
      showToastBox: false
    })
  }

  resettheresult = () => {
    // console.log('reset')
    let dataObj = {};
    let vehlist = {};
    dataObj.userId = this.props.ctr.userid;
    let vehtokens = this.props.ctr.token;
    dataObj.platform = "w";
    dataObj.obuList = [];
    var self = this;
    this.props.gmapsDispatch({ loader: true });
    vehlist.platform = "w";
    vehlist.userRole = this.props.ctr.userrole;
    vehlist.userId = this.props.ctr.userid;
    vehlist.obuIdList = [];
    var dropdownValue = this.props.gmapsState.dropdownValue;
    this.props.gmapsDispatch({ searchresultdataval: [] });
    // if(this.props.ctr.userrole==="DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === "myTerritory"){
    //   urls = apilist.territoryVehiclesData
    // }else if(this.props.ctr.userrole==="DEALER OUTLET ADMIN"){
    //   urls =apilist.allvehicledatadealer
    // }else{
    //   urls =apilist.allvehicledata
    // }
    // const dataslist = postData(urls, vehlist, vehtokens);
    // dataslist.then(dataValist => {
    // console.log('1')
    let resData = [];
    if (this.props.ctr.userrole === "DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === "myTerritory") {
      resData = Object.assign([], this.props.gmapsState.totalmarkers);
    } else {
      resData = Object.assign([], this.props.gmapsState.totalmarkers);
    }
    let arrayobulist = [];
    resData.map((data) => {
      var currentInfo = dropdownValue === "myTerritory" ? data : data.currentInfo;
      if ((currentInfo.heading >= 337.5) && (currentInfo.heading <= 22.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/01.png'; }
      else if ((currentInfo.heading >= 22.5) && (currentInfo.heading <= 67.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/02.png'; }
      else if ((currentInfo.heading >= 67.5) && (currentInfo.heading <= 112.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/03.png'; }
      else if ((currentInfo.heading >= 112.5) && (currentInfo.heading <= 157.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/04.png'; }
      else if ((currentInfo.heading >= 157.5) && (currentInfo.heading <= 202.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/05.png'; }
      else if ((currentInfo.heading >= 202.5) && (currentInfo.heading <= 247.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/06.png'; }
      else if ((currentInfo.heading >= 247.5) && (currentInfo.heading <= 292.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/07.png'; }
      else if ((currentInfo.heading >= 292.5) && (currentInfo.heading <= 337.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/08.png'; }
      else { data.icons = '/images/Map_screen/' + currentInfo.status + '/01.png'; }
      if (this.props.gmapsState.dropdownValue !== undefined && this.props.gmapsState.dropdownValue === "myTerritory") {
        arrayobulist.push(data.vinNumber);
      } else {
        arrayobulist.push(data.currentInfo !== undefined ? data.currentInfo.vinNumber : data.vinNumber);
      }
      return data;
    });
    let vehlistalt = {};
    vehlistalt.platform = "w";
    vehlistalt.userId = this.props.ctr.userid;
    vehlistalt.vinList = arrayobulist;
    let alerturl;
    if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
      alerturl = apilist.vehicleAlertdatedealer
    } else {
      alerturl = apilist.vehicleAlertdate
    }
    const vehicleAlert = postData(alerturl, vehlistalt, vehtokens);
    vehicleAlert.then((datas) => {
      let alertdata = datas.data.alert_status;
      let temp = {};
      let obj = null;
      let countsvas = 0;
      for (var i = 0; i < alertdata.length; i++) {
        obj = alertdata[i];
        if (!temp[obj.vin]) {
          temp[obj.vin] = obj;
          if (temp[obj.vin].severity === "Critical") {
            temp[obj.vin].criticalcounts = countsvas + 1
          }
        } else {
          if (obj.severity === "Critical") {
            temp[obj.vin].criticalcounts = temp[obj.vin].criticalcounts + 1
          }
          temp[obj.vin].alert_type_id = temp[obj.vin].alert_type_id + ',' + obj.alert_type_id;

        }
      }
      var altresult = [];

      for (var prop in temp)
        altresult.push(temp[prop]);
      altresult.forEach(function (elam) {
        let objs = resData.filter(function (en) {
          return en.vinNumber === elam.vin;
        })
        if (objs[0] !== undefined) {
          objs[0].severity = elam.severity;
          objs[0].alert_type_id = elam.alert_type_id;
          objs[0].criccnt = elam.criticalcounts;
          var currentinfo = dropdownValue === "myTerritory" ? objs[0] : objs[0].currentInfo;
          if ((currentinfo.heading >= 337.5) && (currentinfo.heading <= 22.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
          else if ((currentinfo.heading >= 22.5) && (currentinfo.heading <= 67.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/02.png'; }
          else if ((currentinfo.heading >= 67.5) && (currentinfo.heading <= 112.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/03.png'; }
          else if ((currentinfo.heading >= 112.5) && (currentinfo.heading <= 157.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/04.png'; }
          else if ((currentinfo.heading >= 157.5) && (currentinfo.heading <= 202.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/05.png'; }
          else if ((currentinfo.heading >= 202.5) && (currentinfo.heading <= 247.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/06.png'; }
          else if ((currentinfo.heading >= 247.5) && (currentinfo.heading <= 292.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/07.png'; }
          else if ((currentinfo.heading >= 292.5) && (currentinfo.heading <= 337.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/08.png'; }
          else { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
        }
        // })

      });

      this.props.gmapsDispatch({
        totalmarkers: resData,
        markers: resData,
        selectedMarker: "",
        Vehiclestatushigh: "Total",
        searchvechiclelist: [],
        searchresultdata: resData,
        loader: false
      });
      let Totime = new Date().getTime();
      let fromtime = Totime - (144 * 60 * 60 * 1000);
      let Totimeft = new Date(Totime);
      let Totimeftmonth = Totimeft.getMonth() + 1;
      let Totimeftdate = Totimeft.getDate();
      let Totimeftyear = Totimeft.getFullYear();
      let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
      let fromtimeft = new Date(fromtime);
      let fromtimeftmonth = fromtimeft.getMonth() + 1;
      let fromtimeftdate = fromtimeft.getDate();
      let fromtimeftyear = fromtimeft.getFullYear();
      let formatfromdate =
        fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

      let nogpsobj = {}
      nogpsobj.platform = "w"
      nogpsobj.fromDate = formatfromdate
      nogpsobj.toDate = formattodate;
      nogpsobj.vinList = []
      self.props.gmapsDispatch({ nogpscountvals: '' })
      let vehtokensgps = self.props.ctr.token;
      const nogpsdatas = postData(apilist.getNoGPSAlertStatus, nogpsobj, vehtokensgps);
      nogpsdatas.then(dataVals => {
        self.props.gmapsDispatch({ nogpscountvals: dataVals.data.alert_count.NO_GPS })
        let temp_gps = {};
        let obj_gps = null;
        let countsvas = 0;
        var gpsdata = dataVals.data.alert_status
        for (var i = 0; i < gpsdata.length; i++) {
          obj_gps = gpsdata[i];
          if (!temp_gps[obj_gps.vin]) {
            temp_gps[obj_gps.vin] = obj_gps;
            if (temp_gps[obj_gps.vin].noseverity === "Not Applicable") {
              temp_gps[obj_gps.vin].criticalcounts = countsvas + 1
            }
          } else {
            if (obj_gps.noseverity === "Not Applicable") {
              temp_gps[obj_gps.vin].criticalcounts = temp_gps[obj_gps.vin].criticalcounts + 1
            }
            temp_gps[obj_gps.vin].nogpsdata = temp_gps[obj_gps.vin].noseverity;
            temp_gps[obj_gps.vin].nogpsalert_type_id = temp_gps[obj_gps.vin].alert_type_id + ',' + obj_gps.alert_type_id;

          }
        }
        var altresult_gps = [];
        for (var prop in temp_gps)
          altresult_gps.push(temp_gps[prop]);
        altresult_gps.forEach(function (elam) {
          let objsgps = resData.filter(function (en) {
            return en.vinNumber === elam.vin;
          })
          objsgps[0].nogpsdata = elam.severity;
          objsgps[0].nogpsalert_type_id = elam.alert_type_id;
          var currentinfo = self.props.gmapsState.dropdownValue === "myTerritory" ? objsgps[0] : objsgps[0].currentInfo;
          if ((currentinfo.heading >= 337.5) && (currentinfo.heading <= 22.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
          else if ((currentinfo.heading >= 22.5) && (currentinfo.heading <= 67.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/02.png'; }
          else if ((currentinfo.heading >= 67.5) && (currentinfo.heading <= 112.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/03.png'; }
          else if ((currentinfo.heading >= 112.5) && (currentinfo.heading <= 157.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/04.png'; }
          else if ((currentinfo.heading >= 157.5) && (currentinfo.heading <= 202.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/05.png'; }
          else if ((currentinfo.heading >= 202.5) && (currentinfo.heading <= 247.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/06.png'; }
          else if ((currentinfo.heading >= 247.5) && (currentinfo.heading <= 292.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/07.png'; }
          else if ((currentinfo.heading >= 292.5) && (currentinfo.heading <= 337.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/08.png'; }
          else { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
        })
      })
      const callgeoname = postData(apilist.getGeofenceNameForListView, null, vehtokens);
      callgeoname.then(dataVals => {
        let temp = dataVals.data;
        var altresult = [];
        let objs = null;
        for (var prop in temp)
          altresult.push(temp[prop]);
        altresult.forEach(function (elam) {
          objs = resData.filter(function (en) {
            return en.vinNumber === elam.vin_number;
          })
          if (objs[0] !== undefined) {
            objs[0].geofencenames = elam.geofence_name
          }
        })
        this.props.gmapsDispatch({ totalmarkers: resData })
      })
    });
  }
  searchresultcnt = e => {
    let searchobulist = [];
    let searchvechlisttt = [];
    let territory_req = [];
    for (let i = 0; i < e.data.length; i++) {
      searchobulist.push(e.data[i].obu_id);
      searchvechlisttt.push(e.data[i].vin_numbers);
      territory_req.push({
        "obu": e.data[i].obu_id,
        "vin": e.data[i].vin_numbers
      });
    }
    this.props.gmapsDispatch({ searchresultdataval: searchobulist });
    var today = new Date();
    var prevdate = today.getDate() - 1;
    var MyDateString = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + prevdate).slice(-2);
    let newObj = {};
    newObj.platform = 'w';
    newObj.login_id = this.props.ctr.loginid;
    newObj.fdate = MyDateString;
    newObj.tdate = MyDateString;
    newObj.regn = searchvechlisttt;

    this.props.gmapsDispatch({ loader: true });
    this.setState({ loader: true });
    this.props.gmapsDispatch({ searchvechiclelist: searchvechlisttt });
    this.props.gmapsDispatch({
      fleetdashrep: "hit",
      alertssafety: "hit",
      fidashboard: "hit",
      figearutildash: "hit",
      fireport: "hit",
      fikpi: "hit",
      fireportutil: "hit",
    });
  }

  handleHeaderShow = () => {
    this.props.headerStateDispatch([])
  }

  clicksearchresult = (e) => {
    return new Promise((resolve, reject) => {
      this.props.gmapsDispatch({ loader: true });
      let samvins = e;
      let vehlist = {}
      let sampleresults = []
      let searchobulistval = []
      let searchvechlistttval = []
      let objlists = {};
      var self = this;
      objlists.Disconnected = 0;
      objlists.Idling = 0;
      objlists.Running = 0;
      objlists.Stopped = 0;
      objlists.Charging = 0;
      objlists.Towed = 0;
      objlists.NoGPS = 0;
      objlists.Total = 0;
      this.props.gmapsDispatch({
        Vehiclestatushigh: "Total",
        selectedMarker: "",
        dashenable: false,
        dialviewshow: true,
        currentPage: 1,
        paginationcolor: 1
      });
      for (let i = 0; i < e.data.length; i++) {
        searchobulistval.push(e.data[i].obu_id);
        searchvechlistttval.push(e.data[i].vin_numbers)
      }
      vehlist.platform = "w";
      vehlist.userRole = this.props.ctr.userrole;
      vehlist.userId = this.props.ctr.userid;
      vehlist.obuIdList = searchobulistval;
      let vehtokens = this.props.ctr.token;
      let urls;
      if (this.props.ctr.userrole === "DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === "myTerritory") {
        urls = apilist.territoryVehiclesData
      } else if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
        urls = apilist.allvehicledatadealer
      } else {
        urls = apilist.allvehicledata
      }
      const dataslist = postData(urls, vehlist, vehtokens);
      dataslist.then(dataValist => {
        let resData = [];

        if (this.props.ctr.userrole === "DEALER OUTLET ADMIN" && this.props.gmapsState.dropdownValue === "myTerritory") {
          resData = Object.assign([], dataValist.data.getvehicleinfo);
        } else {
          resData = Object.assign([], dataValist.data);
        }
        resData.map((data) => {
          var currentInfo = this.props.gmapsState.dropdownValue === "myTerritory" ? data : data.currentInfo;
          if ((currentInfo.heading >= 337.5) && (currentInfo.heading <= 22.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/01.png'; }
          else if ((currentInfo.heading >= 22.5) && (currentInfo.heading <= 67.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/02.png'; }
          else if ((currentInfo.heading >= 67.5) && (currentInfo.heading <= 112.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/03.png'; }
          else if ((currentInfo.heading >= 112.5) && (currentInfo.heading <= 157.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/04.png'; }
          else if ((currentInfo.heading >= 157.5) && (currentInfo.heading <= 202.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/05.png'; }
          else if ((currentInfo.heading >= 202.5) && (currentInfo.heading <= 247.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/06.png'; }
          else if ((currentInfo.heading >= 247.5) && (currentInfo.heading <= 292.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/07.png'; }
          else if ((currentInfo.heading >= 292.5) && (currentInfo.heading <= 337.5)) { data.icons = '/images/Map_screen/' + currentInfo.status + '/08.png'; }
          else { data.icons = '/images/Map_screen/' + currentInfo.status + '/01.png'; }
          return data;
        });

        this.props.gmapsDispatch({ getRibbonAlerts: true, totalmarkers: resData });
        resolve(true);

        // if(this.props.gmapsState.dropdownValue == "myTerritory"){
        //   this.props.gmapsDispatch({callGlobalApi: "territory",onswitchDropdownValue:false})
        // }else{
        //   this.props.gmapsDispatch({callGlobalApi: "sold",onswitchDropdownValue:false})
        // }

        var currentProps = this.props.gmapsState;

        resData.forEach(function (elam) {
          var currentInfo = currentProps.dropdownValue === "myTerritory" ? elam : elam.currentInfo;
          var searchvalues = samvins.data.filter(function (en) {
            if (en.regn_number === elam.regnNumber) {
              sampleresults.push(elam);
              self.calculateRibbonAlerts(currentInfo, objlists);
            }
            return searchvalues;
          });
          if (samvins.data.length === 0) {
            sampleresults.push(elam);
            self.calculateRibbonAlerts(currentInfo, objlists);
          }
        }, (err) => {
          reject(err)
        });
        this.props.gmapsDispatch({ vehCount: objlists, loader: false });

        let Totime = new Date().getTime();
        let fromtime = Totime - (144 * 60 * 60 * 1000);
        let Totimeft = new Date(Totime);
        let Totimeftmonth = Totimeft.getMonth() + 1;
        let Totimeftdate = Totimeft.getDate();
        let Totimeftyear = Totimeft.getFullYear();
        let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
        let fromtimeft = new Date(fromtime);
        let fromtimeftmonth = fromtimeft.getMonth() + 1;
        let fromtimeftdate = fromtimeft.getDate();
        let fromtimeftyear = fromtimeft.getFullYear();
        let formatfromdate =
          fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

        let dataobj_alert = {};
        dataobj_alert.userId = this.props.ctr.userid;
        let tokens = this.props.ctr.token;
        dataobj_alert.platform = "w";
        dataobj_alert.fromDate = formatfromdate;
        dataobj_alert.toDate = formattodate;
        if (this.props.gmapsState.dropdownValue === 'myTerritory') {
          let vinArr = [];
          if (this.props.gmapsState.totalmarkers && this.props.gmapsState.totalmarkers.length) {
            this.props.gmapsState.totalmarkers.forEach((marker) => {
              vinArr.push(marker.vinNumber);
            });
            dataobj_alert.vinList = vinArr;
          }
        } else {
          dataobj_alert.vinList = searchvechlistttval;
        }
        dataobj_alert.isActive = true;
        let alerturl
        if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
          alerturl = apilist.alertCountdealer
        } else {
          alerturl = apilist.alertCount
        }
        const alertcnt = postData(alerturl, dataobj_alert, tokens);
        alertcnt.then(alertdataVals => {
          let countwithalert = { ...alertdataVals.data, ...objlists };
          if (this.props.gmapsState.dropdownValue === "myTerritory") {
            if (samvins.data.length > 0) {
              countwithalert.Critical = 0;
              countwithalert.Warning = 0;
              countwithalert.Service = 0;
              sampleresults.forEach((item) => {
                if (item.severity === "Warning") {
                  countwithalert.Warning = countwithalert.Warning + 1;
                }
                else if (item.severity === "Critical") {
                  countwithalert.Critical = countwithalert.Critical + 1;
                }
                else if (item.severity === "Service") {
                  countwithalert.Service = countwithalert.Service + 1;
                }
              })
            }
          }
          this.props.gmapsDispatch({
            vehCount: countwithalert,
            vehalertCount: alertdataVals.data
          });
        });

        if (searchvechlistttval.length !== 0) {
          let vehlistalt = {};
          vehlistalt.platform = "w";
          vehlistalt.userId = this.props.ctr.userid;
          vehlistalt.vinList = searchvechlistttval;
          let alerturl
          if (this.props.ctr.userrole === "DEALER OUTLET ADMIN") {
            alerturl = apilist.vehicleAlertdatedealer
          } else {
            alerturl = apilist.vehicleAlertdate
          }
          const vehicleAlert = postData(alerturl, vehlistalt, vehtokens);
          vehicleAlert.then((datas) => {
            let alertdata = datas.data.alert_status;
            let temp = {};
            let obj = null;
            let countsvas = 0;
            for (var i = 0; i < alertdata.length; i++) {
              obj = alertdata[i];
              if (!temp[obj.vin]) {
                temp[obj.vin] = obj;
                if (temp[obj.vin].severity === "Critical") {
                  temp[obj.vin].criticalcounts = countsvas + 1
                }
              } else {
                if (obj.severity === "Critical") {
                  temp[obj.vin].criticalcounts = temp[obj.vin].criticalcounts + 1
                }
                temp[obj.vin].alert_type_id = temp[obj.vin].alert_type_id + ',' + obj.alert_type_id;

              }
            }
            var altresult = [];
            var dropdownValue = this.props.gmapsState.dropdownValue;
            for (var prop in temp)
              altresult.push(temp[prop]);
            altresult.forEach(function (elam) {
              let objs = sampleresults.filter(function (en) {
                return en.vinNumber === elam.vin;
              })
              objs[0].severity = elam.severity;
              objs[0].alert_type_id = elam.alert_type_id;
              objs[0].criccnt = elam.criticalcounts;
              var currentinfo = dropdownValue === "myTerritory" ? objs[0] : objs[0].currentInfo;
              if ((currentinfo.heading >= 337.5) && (currentinfo.heading <= 22.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
              else if ((currentinfo.heading >= 22.5) && (currentinfo.heading <= 67.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/02.png'; }
              else if ((currentinfo.heading >= 67.5) && (currentinfo.heading <= 112.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/03.png'; }
              else if ((currentinfo.heading >= 112.5) && (currentinfo.heading <= 157.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/04.png'; }
              else if ((currentinfo.heading >= 157.5) && (currentinfo.heading <= 202.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/05.png'; }
              else if ((currentinfo.heading >= 202.5) && (currentinfo.heading <= 247.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/06.png'; }
              else if ((currentinfo.heading >= 247.5) && (currentinfo.heading <= 292.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/07.png'; }
              else if ((currentinfo.heading >= 292.5) && (currentinfo.heading <= 337.5)) { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/08.png'; }
              else { objs[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }

            })
          });
        }
        if (searchvechlistttval.length !== 0) {
          let Totime = new Date().getTime();
          let fromtime = Totime - (144 * 60 * 60 * 1000);
          let Totimeft = new Date(Totime);
          let Totimeftmonth = Totimeft.getMonth() + 1;
          let Totimeftdate = Totimeft.getDate();
          let Totimeftyear = Totimeft.getFullYear();
          let formattodate = Totimeftyear + "-" + Totimeftmonth + "-" + Totimeftdate;
          let fromtimeft = new Date(fromtime);
          let fromtimeftmonth = fromtimeft.getMonth() + 1;
          let fromtimeftdate = fromtimeft.getDate();
          let fromtimeftyear = fromtimeft.getFullYear();
          let formatfromdate =
            fromtimeftyear + "-" + fromtimeftmonth + "-" + fromtimeftdate;

          let nogpsobj = {}
          nogpsobj.platform = "w"
          this.props.gmapsDispatch({ nogpscountvals: '' })
          nogpsobj.fromDate = formatfromdate
          nogpsobj.toDate = formattodate;
          nogpsobj.vinList = searchvechlistttval

          let vehtokensgps = this.props.ctr.token;
          const nogpsdatas = postData(apilist.getNoGPSAlertStatus, nogpsobj, vehtokensgps);
          nogpsdatas.then(dataVals => {
            // console.log(dataVals.data.alert_count.NO_GPS)
            this.props.gmapsDispatch({ nogpscountvals: dataVals.data.alert_count.NO_GPS })
            let temp_gps = {};
            let obj_gps = null;
            let countsvas = 0;
            var gpsdata = dataVals.data.alert_status
            for (var i = 0; i < gpsdata.length; i++) {
              obj_gps = gpsdata[i];
              if (!temp_gps[obj_gps.vin]) {
                temp_gps[obj_gps.vin] = obj_gps;
                if (temp_gps[obj_gps.vin].noseverity === "Not Applicable") {
                  temp_gps[obj_gps.vin].criticalcounts = countsvas + 1
                }
              } else {
                if (obj_gps.noseverity === "Not Applicable") {
                  temp_gps[obj_gps.vin].criticalcounts = temp_gps[obj_gps.vin].criticalcounts + 1
                }
                temp_gps[obj_gps.vin].nogpsdata = temp_gps[obj_gps.vin].noseverity;
                temp_gps[obj_gps.vin].nogpsalert_type_id = temp_gps[obj_gps.vin].alert_type_id + ',' + obj_gps.alert_type_id;

              }
            }
            var altresult_gps = [];
            var dropdownValue = this.props.gmapsState.dropdownValue;
            for (var prop in temp_gps)
              altresult_gps.push(temp_gps[prop]);
            altresult_gps.forEach(function (elam) {
              let objsgps = sampleresults.filter(function (en) {
                return en.vinNumber === elam.vin;
              })
              if (objsgps[0] !== undefined) {
                objsgps[0].nogpsdata = elam.severity;
                objsgps[0].nogpsalert_type_id = elam.alert_type_id;
                var currentinfo = dropdownValue === "myTerritory" ? objsgps[0] : objsgps[0].currentInfo;
                if ((currentinfo.heading >= 337.5) && (currentinfo.heading <= 22.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
                else if ((currentinfo.heading >= 22.5) && (currentinfo.heading <= 67.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/02.png'; }
                else if ((currentinfo.heading >= 67.5) && (currentinfo.heading <= 112.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/03.png'; }
                else if ((currentinfo.heading >= 112.5) && (currentinfo.heading <= 157.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/04.png'; }
                else if ((currentinfo.heading >= 157.5) && (currentinfo.heading <= 202.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/05.png'; }
                else if ((currentinfo.heading >= 202.5) && (currentinfo.heading <= 247.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/06.png'; }
                else if ((currentinfo.heading >= 247.5) && (currentinfo.heading <= 292.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/07.png'; }
                else if ((currentinfo.heading >= 292.5) && (currentinfo.heading <= 337.5)) { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/08.png'; }
                else { objsgps[0].icons = "/images/Map_screen/" + elam.severity + "/" + currentinfo.status + '/01.png'; }
              }
            })
          })
        }
        if (this.props.ctr.userrole !== "DEALER OUTLET ADMIN") {
          const callgeoname = postData(apilist.getGeofenceNameForListView, null, vehtokens);
          callgeoname.then(dataVals => {
            // console.log(dataVals.data)
            let temp = dataVals.data;
            // let obuidlist = this.props.gmapsState.totalmarkers;
            var altresult = [];
            let objs = null;
            for (var prop in temp)
              altresult.push(temp[prop]);
            altresult.forEach(function (elam) {
              objs = resData.filter(function (en) {
                return en.vinNumber === elam.vin_number;
              })
              if (objs[0] !== undefined) {
                objs[0].geofencenames = elam.geofence_name
              }
            })
            this.props.gmapsDispatch({ totalmarkers: resData })
            this.zoomToMarkersVal(this.props.gmapsState);
          })
        }
      });

      this.props.gmapsDispatch({ markers: sampleresults, searchresultdata: sampleresults, selectedvrhcicount: sampleresults });
    });
  }

  calculateRibbonAlerts = (currentInfo, objlists) => {
    if (currentInfo.status === "DISCONNECTED") {
      objlists.Disconnected = objlists.Disconnected + 1
    } else if (currentInfo.status === "IDLING") {
      objlists.Idling = objlists.Idling + 1
    }
    else if (currentInfo.status === "STOPPED") {
      objlists.Stopped = objlists.Stopped + 1
    }
    else if (currentInfo.status === "RUNNING") {
      objlists.Running = objlists.Running + 1
    }
    else if (currentInfo.status === "CHARGING") {
      objlists.Charging = objlists.Charging + 1
    }
    else if (currentInfo.status === "NoGPS") {
      objlists.NoGPS = objlists.NoGPS + 1
    }
    else if (currentInfo.status === "Towed") {
      objlists.Towed = objlists.Towed + 1
    }
    objlists.Total = objlists.Total + 1;
  }

  zoomToMarkersVal = (state) => {
    if (state.selectedMarker === false || state.selectedMarker === "") {
      if (state.totalmarkers.length > 0) {
        if (state.selectedMarker !== false && state.selectedMarker !== "") {
          var lngs = [];
          var lats = [];
          let selectedzoomin = state.selectedMarker.currentInfo !== undefined ? state.selectedMarker.currentInfo : state.selectedMarker;
          lngs.push(selectedzoomin.longitude);
          lats.push(selectedzoomin.latitude);
        } else {
          let markers = state.totalmarkers;
          lngs = markers.map((datas) => {
            return datas.currentInfo !== undefined ? datas.currentInfo.longitude : datas.longitude;
          });
          lats = markers.map((datas) => {
            return datas.currentInfo !== undefined ? datas.currentInfo.latitude : datas.latitude;
          });
        }

        this.props.gmapsDispatch({
          zoomToMarkers: (map) => {
            if (map) {
              map.fitBounds({
                west: Math.min.apply(null, lngs),
                east: Math.max.apply(null, lngs),
                north: Math.min.apply(null, lats),
                south: Math.max.apply(null, lats),
              });
            }
          },
        });
      }
    } else {
      // this.zoomToMarkersValselected(state);
    }
  };
  render() {
    var menuBind = null;
    if (this.props.ctr?.token || (history.location.pathname === '/success')) {
      menuBind = <DashboardHeader resetresult={this.resettheresult} searchresultprop={this.clicksearchresult} searchcount={this.searchresultcnt} />
    }
    // if (history.location.pathname === '/success') {
    //   sessionStorage.reload = "";
    // } else if (history.location.pathname === '/camportal') {
    //   sessionStorage.reload = "";
    // } else if (history.location.pathname === '/apps') {
    //   sessionStorage.reload = "";
    // }
    // else if (history.location.pathname.startsWith('/tracking/')) {
    //   history.location.path = '/tracking/:id';
    //   sessionStorage.reload = "";
    // }
    // else if (sessionStorage.reload && history.location.pathname !== '/') {
    //   if(history.location.pathname.includes(termsAndConditionRoutePath)){
    //     sessionStorage.reload = "";
    //     history.push(termsAndConditionRoutePath);
    //   } else if (history.location.pathname.includes(switchTermsAndConditionsRoutePath)) {
    //     sessionStorage.reload = "";
    //     history.push(switchTermsAndConditionsRoutePath);
    //   } else {
    //     sessionStorage.reload = "";
    //     history.push('/login');
    //   }

    // }
    // else if (window.location.pathname === "/billingmodule/packages" && this.props.gmapsState.naviagteLink === "billingpackages") {
    //   if (this.props.gmapsState.naviagteLink === "billingpackages" && history.location.pathname === "/billingmodule/packages") {
    //     history.location.path = "/billingmodule/packages";
    //   }
    // else {
    //   history.location.path = "";
    // }
    // }
    // else {
    //   sessionStorage.reload = "";
    // }
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <ErrorBoundary>
          <div className="App">
            {this.state.showToastBox && (
              <ShowToast
                open={this.state.showToastBox}
                title={this.state.notificationTitle}
                body={this.state.notifcationBody}
                close={this.onToastClose}
              />
            )}
            <div
              style={{
                position: "sticky",
                background: "#fff",
                zIndex: "100",
                outline: "none",
                top: "0"
              }}
              className="menuStyle"
              tabIndex="0"
              onBlur={this.handleHeaderShow}
            >
              <div className="container-wrapper">
                {menuBind}
              </div>

              {/* Custom confirmation logic if necessary */}
              {/* {window.location.pathname === "/billingmodule/packages" &&
              this.props.gmapsState.naviagteLink === "billingpackages" && (
                <div className="confirmation-modal">
                  Are you sure you want to leave without proceeding with the payment?
                </div>
            )} */}
              {/* <Prompt when={window.location.pathname === "/billingmodule/packages" && history.location.path === "/billingmodule/packages" && this.props.gmapsState.naviagteLink === "billingpackages"} message="Are you sure, Do you want to leave without proceeding the payment ?" /> */}
            </div>
            <AppWithFallback props={this.props} token={this.props.ctr.token} resetresult={this.resettheresult} searchresultprop={this.clicksearchresult} searchcount={this.searchresultcnt} />
          </div>
        </ErrorBoundary>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
          remainingTime={this.countdown}
        />
      </>
    );
  }
}
const mapStateToprops = state => {
  return {
    ctr: state.userdata.id,
    menuStatus: state.menus.show,
    gmapsState: state.GmapsReducer
  };
}

const mapDispatchToprops = dispatch => {
  return {
    gmapsDispatch: (payload) => dispatch({ type: actionTypes.UPDATE_GMAPS_STATE, payload }),
    headerStateDispatch: (payload) => dispatch({ type: actionTypes.TOGGLE_HEADER, payload })
  };
};

export default withRouter(connect(mapStateToprops, mapDispatchToprops)(App));
