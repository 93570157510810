import React, { Component } from 'react';
import { GoogleMap, Marker, LoadScriptNext, Circle, DirectionsRenderer, MarkerClusterer, InfoBox } from '@react-google-maps/api';
import './common.css';
import apilist from '../../apiList';
import { connect } from 'react-redux';
import { GOOGLE_MAP_API_KEY, GOOGLE_API_KEY } from '../../apiList';
import withNavigate from '../../hooks/withNavigate';

const mapContainerStyle = {
  width: '100%',
  height: '80vh'
};

const libraries = ["geometry", "drawing", "places"]; // Define outside

class MyMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      searchcenter: {},
      markers: [],
    };
  }

  callinpiufunbction = () => {
    var statevalue = this;
    var iframe, iframe_google;   
    iframe = document.createElement('iframe');
    iframe.id = "map_frame";
    iframe.frameBorder = 0;
    iframe.src = "/dummy.html";
    iframe.sandbox = "allow-same-origin allow-scripts allow-top-navigation";
    
    iframe.onload = function () {
      var script = iframe.contentWindow.document.createElement('script');
      var input = iframe.contentWindow.document.createElement('input');
      input.type = 'text';
      input.id = 'searchByLocationBoxes';
      input.className = 'location_search_box';
      iframe.contentWindow.document.getElementsByTagName('body')[0].appendChild(input);
        
      iframe.contentWindow.insertPlacesApi = function() {
        iframe_google = this.google;
        var searchLocationBox = document.getElementById("searchByLocationBoxes");
        var autocomplete = new iframe_google.maps.places.Autocomplete(searchLocationBox);
        autocomplete.addListener("place_changed", () => {
          var placed = autocomplete.getPlace();
          const place = placed;
          const bounds = new iframe_google.maps.LatLngBounds();
  
          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }

          var center = {
            lat: 20.5937,
            lng: 78.9629
          };
          const nextMarkers = { position: place.geometry.location };
          const nextCenter = nextMarkers.position || center;

          statevalue.setState({ searchcenter: nextCenter, markers: [nextMarkers] });
          statevalue.props.searchupdatedata(nextCenter);
        });
      };

      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API_KEY + '&sensor=false&libraries=geometry,visualization,drawing,places&callback=insertPlacesApi';
      iframe.contentDocument.getElementsByTagName('head')[0].appendChild(script);
    };

    document.body.append(iframe);
  };

  UNSAFE_componentWillMount() {
    this.callinpiufunbction();
  }

  render() {
    const { sud, directionsed, center, truck, kmsradiusval, mydata, displaytexted, dropdown_value, getDatas, CircleRef, options, onRadiusChanged } = this.props;
    const { markers } = this.state;

    return (
      <LoadScriptNext googleMapsApiKey={GOOGLE_MAP_API_KEY} libraries={libraries} onLoadError={(e) => console.error("Google Maps API load error:", e)}>
        <GoogleMap
          zoom={4}
          center={sud}
          mapContainerStyle={mapContainerStyle}
        >
          {directionsed.length === 0 ? (
            <InfoBox
              position={window.google && new window.google.maps.LatLng(center)}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: `#427ae6`, padding: `5px`, borderRadius: `25px`, position: `relative` }}>
                <div style={{ fontSize: `14px`, fontWeight: `bold`, color: `white` }}>
                  {(displaytexted === undefined || dropdown_value === true) ? kmsradiusval : displaytexted} kms
                </div>
              </div>
            </InfoBox>
          ) : null}

          <Marker
            key={"0000"}
            position={center}
            options={{ icon: truck }}
          />

          <MarkerClusterer
            averageCenter
            styles={[
              {
                textColor: "white",
                height: 58,
                url: "/images/Map_screen/blue.png",
                width: 78,
                fontWeight: "750"
              }
            ]}
          >
            {(clusterer) =>
              mydata && mydata.map((mapData) => (
                <Marker
                  key={mapData.dealer_outlet_id}
                  clusterer={clusterer}
                  options={{ icon: mapData.icon }}
                  position={{ lat: parseFloat(mapData.lattitude), lng: parseFloat(mapData.longitude) }}
                  onClick={() => getDatas(mapData)}
                />
              ))
            }
          </MarkerClusterer>

          {directionsed.length !== 0 && <DirectionsRenderer directions={directionsed} options={{ suppressMarkers: true }} routeIndex={0} />}

          {kmsradiusval === undefined ? (
            <Circle
              key={sud.lat}
              center={center}
              radius={this.state.isCircleLoaded ? 199999 : 0}
              editable={true}
              onLoad={CircleRef}
              defaultOptions={options}
              onRadiusChanged={onRadiusChanged}
              options={{
                strokeColor: '#427ae6',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          ) : (
            <Circle
              key={sud.lat}
              center={center}
              radius={kmsradiusval * 1000}
              editable={true}
              onLoad={CircleRef}
              defaultOptions={options}
              onRadiusChanged={onRadiusChanged}
              options={{
                strokeColor: '#427ae6',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          )}

          <p className="googleMapSearch" style={{ position: "absolute", marginTop: "-15px", right: "33%" }}>
            <input
              type="text"
              style={{
                boxSizing: "border-box",
                border: "1px solid transparent",
                width: "235px",
                height: "32px",
                padding: "0 12px 0 30px",
                borderRadius: "25px",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                fontSize: "14px",
                outline: "none",
                textOverflow: "ellipsis",
                top: "25px",
                position: "absolute"
              }}
              placeholder="&#128269; Search Location"
              id="searchByLocationBoxes"
              className="location_search_box"
            />
          </p>
        </GoogleMap>
      </LoadScriptNext>
    );
  }
}

const mapStateToProps = (state) => ({
  sud: state.menus.searchupdatedata
});

const mapDispatchToProps = (dispatch) => ({
  searchupdatedata: (data) => dispatch({ type: 'searchupdatedata', value: data })
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(MyMapComponent));
